import { ContentIcon } from '@dropbox/dash-component-library';
import { Skeleton } from '@dropbox/dig-components/skeleton';
// This file is where the Link replacement is implemented.
// eslint-disable-next-line no-restricted-imports
import { Link as DigLink, Text } from '@dropbox/dig-components/typography';
import { ThemeContainer, ThemeProvider } from '@dropbox/dig-foundations';
import { UIIcon } from '@dropbox/dig-icons';
import { useMirageAnalyticsContext } from '@mirage/analytics/AnalyticsProvider';
import { useFeatureFlagValue } from '@mirage/service-experimentation/useFeatureFlagValue';
import { openURL } from '@mirage/service-platform-actions';
import { IconButtonWithTooltip } from '@mirage/shared/icons/IconButtonWithTooltip';
import { useIsHoverFriendly } from '@mirage/shared/responsive/hover';
import { DashTruncateWithTooltip } from '@mirage/shared/util/DashTruncateWithTooltip';
import { faviconSrcForSrcUrl } from '@mirage/shared/util/favicon';
import { absoluteOrURLFailover } from '@mirage/shared/util/urls';
import { getTheme, StackTheme } from '@mirage/stacks/themes';
import i18n from '@mirage/translations';
import classnames from 'classnames';
import React, { ReactNode, useEffect, useRef, useState } from 'react';
import { Link as ReactRouterLink } from 'react-router-dom';
import { FavIcon } from '../Favicon/Favicon';
import {
  AccessoryInfo,
  Link,
  LinkExposurePapEvent,
  LinkOpenPAPEvent,
  ListItemSize,
} from '../types';
import styles from './Link.module.css';

export interface LinkComponentProps<T extends Link = Link> {
  link: T;
  accessoryInfo?: AccessoryInfo[];
  /**
   * Accessory Components automatically have pointer events disabled to allow
   * for the link to be clicked through the accessory component. If you need
   * elements to be clickable, set their pointer events to auto.
   */
  accessoryComponent?: ReactNode;
  children?: ReactNode;
  decorationIcon?: React.ComponentType<React.SVGAttributes<SVGElement>>;
  icon?: JSX.Element;
  iconTheme?: StackTheme;
  metadata?: ReactNode[];
  onOpenLink?: (link: Link) => void;
  openPapEvents?: LinkOpenPAPEvent[];
  fallbackURLForLocalFiles?: string;
  shouldShowHoverState?: boolean;
  tooltipTitle?: string;
  // This is used to indicate that link is being added to the event
  isLoading?: boolean;
  // If set, this link will be treated as a SPA navigation rather than an external link.
  isInternalLink?: {
    // React router state parameter
    state: unknown;
  };
  // If present will trigger a PAP event log when
  // the link is first rendered. If unmounted and remounted, the event
  // will trigger again.
  exposurePapEvents?: Array<LinkExposurePapEvent>;
  listItemSize: ListItemSize;
  linkContainerClassName?: string;
  index?: number;
  // In order to enable drag, the link can't be an href link
  enableDrag?: boolean;
  // Enables the new content row even if the flag is off for this user
  forceContentRowEnabled?: boolean;
}

export const LinkComponent: React.FC<LinkComponentProps> = ({
  link,
  accessoryInfo,
  accessoryComponent,
  children,
  decorationIcon,
  icon,
  iconTheme,
  metadata,
  tooltipTitle,
  isInternalLink,
  listItemSize,
  linkContainerClassName,
  onOpenLink,
  openPapEvents,
  fallbackURLForLocalFiles = '',
  exposurePapEvents,
  shouldShowHoverState,
  isLoading,
  enableDrag = false,
  forceContentRowEnabled = false,
}) => {
  const { reportPapEvent } = useMirageAnalyticsContext();
  const isHoverFriendly = useIsHoverFriendly();
  const [accessoryWidth, setAccessoryWidth] = useState(0);
  const dashComponents = !!useFeatureFlagValue(
    'dash_use_dash_components',
    false,
  );
  const contentRowEnabled = forceContentRowEnabled || dashComponents;

  const { dashRequestId, predictionIdHash, itemIdHash } = link;
  const exposureLoggedRef = useRef(false);

  useEffect(() => {
    if (!exposurePapEvents?.length || exposureLoggedRef.current) return;

    for (const exposurePapEvent of exposurePapEvents) {
      exposurePapEvent.properties = {
        dashRequestId,
        predictionIdHash,
        itemIdHash,
        ...exposurePapEvent.properties,
      };
      reportPapEvent(exposurePapEvent);
    }
    exposureLoggedRef.current = true;
  }, [
    dashRequestId,
    exposurePapEvents,
    itemIdHash,
    predictionIdHash,
    reportPapEvent,
  ]);

  return (
    <div
      className={classnames(
        styles.linkContainer,
        linkContainerClassName,
        isLoading ? styles.isLoadingLink : undefined,
      )}
    >
      <div className={styles.topContainer}>
        <UsedLink
          link={link}
          isInternalLink={isInternalLink}
          listItemSize={listItemSize}
          onOpenLink={onOpenLink}
          openPapEvents={openPapEvents}
          fallbackURLForLocalFiles={fallbackURLForLocalFiles}
          enableDrag={enableDrag}
          forceContentRowEnabled={forceContentRowEnabled}
        >
          <LinkHeader
            link={link}
            accessoryWidth={accessoryWidth}
            listItemSize={listItemSize}
            icon={icon}
            iconTheme={iconTheme}
            isLoading={isLoading}
            metadata={metadata}
            decorationIcon={decorationIcon}
            tooltipTitle={tooltipTitle}
            forceContentRowEnabled={forceContentRowEnabled}
          />
        </UsedLink>
        {(accessoryComponent || accessoryInfo) && (
          <LinkAccessories
            shouldShowHoverState={shouldShowHoverState || !isHoverFriendly}
            setAccessoryWidth={setAccessoryWidth}
            accessoryComponent={accessoryComponent}
            accessoryInfo={accessoryInfo}
          />
        )}
      </div>
      <div
        className={classnames(
          styles.bodyContent,
          styles[
            `bodyContentSize--${
              contentRowEnabled
                ? listItemSize
                : listItemSize >= ListItemSize.Large
                  ? ListItemSize.Large
                  : ListItemSize.Small
            }`
          ],
        )}
      >
        {children}
      </div>
    </div>
  );
};

interface LinkHeaderProps {
  accessoryWidth: number;
  icon?: JSX.Element;
  iconTheme?: StackTheme;
  isLoading?: boolean;
  link: Link;
  listItemSize: ListItemSize;
  metadata?: ReactNode[];
  decorationIcon?: React.ComponentType<React.SVGAttributes<SVGElement>>;
  tooltipTitle?: string;
  forceContentRowEnabled?: boolean;
}

const LinkHeader: React.FC<LinkHeaderProps> = ({
  icon,
  iconTheme,
  isLoading,
  link,
  listItemSize,
  metadata,
  decorationIcon,
  accessoryWidth,
  tooltipTitle,
  forceContentRowEnabled = false,
}) => {
  const dashComponents = !!useFeatureFlagValue(
    'dash_use_dash_components',
    false,
  );
  const contentRowEnabled = forceContentRowEnabled || dashComponents;

  return (
    <div className={styles.faviconTitleContainer}>
      <ThemeProvider
        overrides={iconTheme ? getTheme(iconTheme).primary : undefined}
      >
        <ThemeContainer>
          <ContentIcon
            size={
              contentRowEnabled
                ? listItemSize
                : listItemSize === ListItemSize.Small
                  ? 'small'
                  : 'large'
            }
            icon={
              <span
                className={classnames(
                  styles.favicon,
                  !contentRowEnabled &&
                    (listItemSize === ListItemSize.Large
                      ? styles.faviconLarge
                      : styles.faviconSmall),
                  isLoading && styles.loadingFavicon,
                )}
              >
                {isLoading ? (
                  <Skeleton.Box shouldAnimate />
                ) : (
                  (icon ?? (
                    <FavIcon
                      src={faviconSrcForSrcUrl(link.url)}
                      size={
                        contentRowEnabled ? listItemSize : ListItemSize.Small
                      }
                    />
                  ))
                )}
              </span>
            }
            badgeIcon={decorationIcon}
            hasBackground={!isLoading}
            backgroundColor={iconTheme ? 'Primary Surface' : 'Background Base'}
            className={classnames(
              iconTheme !== undefined && styles.colorfulFavicon,
              styles.faviconContainer,
            )}
          />
        </ThemeContainer>
      </ThemeProvider>
      <div
        className={classnames(styles.titleContainer, {
          [styles.titleContainerLoading]: isLoading,
        })}
        style={{
          marginRight: `${accessoryWidth + 8}px`,
        }}
      >
        <div className={styles.textContainer}>
          {isLoading ? (
            <Skeleton.Text
              size={listItemSize === ListItemSize.Small ? 'small' : 'large'}
              shouldAnimate
            />
          ) : (
            <Text className={styles.linkTitle} isBold={contentRowEnabled}>
              <DashTruncateWithTooltip
                tooltipProps={{
                  title: isLoading
                    ? i18n.t('loading')
                    : (tooltipTitle ?? link.title),
                  placement: 'top-start',
                }}
              >
                {link.title}
              </DashTruncateWithTooltip>
              {link.subtitle ? (
                <>
                  <Text color="subtle">{'·'}</Text>
                  <Text color="subtle">{link.subtitle}</Text>
                </>
              ) : null}
            </Text>
          )}
        </div>
        {contentRowEnabled && metadata && metadata.length > 0 && (
          <Text color="faint" className={styles.linkMetadata}>
            {metadata.map((meta, i) => (
              <span key={i}>{meta}</span>
            ))}
          </Text>
        )}
      </div>
    </div>
  );
};

interface LinkAccessoriesProps {
  shouldShowHoverState: boolean;
  setAccessoryWidth: (width: number) => void;
  accessoryInfo?: AccessoryInfo[];
  accessoryComponent?: ReactNode;
}

const LinkAccessories: React.FC<LinkAccessoriesProps> = ({
  shouldShowHoverState,
  setAccessoryWidth,
  accessoryInfo,
  accessoryComponent,
}) => {
  const accessoryComponentClassNames = classnames(
    styles.accessoryButtonContainer,
    shouldShowHoverState && styles.accessoryButtonContainerHoverState,
  );

  const accessoryComponentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const accessoryComponent = accessoryComponentRef.current;

    const resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        const { width } = entry.contentRect;
        setAccessoryWidth(width);
      }
    });

    if (accessoryComponent) {
      resizeObserver.observe(accessoryComponent);
    }

    return () => {
      if (accessoryComponent) {
        resizeObserver.unobserve(accessoryComponent);
      }
    };
  }, [setAccessoryWidth]);

  return (
    <div className={accessoryComponentClassNames} ref={accessoryComponentRef}>
      {accessoryComponent && accessoryComponent}
      {accessoryInfo &&
        accessoryInfo.map((accessoryInfo, index) => {
          return (
            <IconButtonWithTooltip
              key={`accessoryInfo-${index}`}
              tooltipProps={{
                title: accessoryInfo.accessoryTooltipTitle,
              }}
              variant="transparent"
              onClick={() => {
                accessoryInfo?.onClickAccessory();
              }}
              onAuxClick={() => {
                accessoryInfo?.onClickAccessory();
              }}
              size="small"
              className={styles.accessoryButton}
            >
              <UIIcon src={accessoryInfo.accessoryIcon} />
            </IconButtonWithTooltip>
          );
        })}
    </div>
  );
};

const UsedLink = <T extends Link>({
  link,
  listItemSize,
  isInternalLink,
  onOpenLink,
  openPapEvents,
  fallbackURLForLocalFiles = '',
  enableDrag = false,
  children,
  forceContentRowEnabled = false,
}: LinkComponentProps<T> & { children: React.ReactNode }) => {
  const dashComponents = !!useFeatureFlagValue(
    'dash_use_dash_components',
    false,
  );
  const contentRowEnabled = forceContentRowEnabled || dashComponents;

  const { reportPapEvent } = useMirageAnalyticsContext();
  const { dashRequestId, predictionIdHash, itemIdHash } = link;

  const logPapEvent = () => {
    if (!openPapEvents?.length) return;

    for (const openPapEvent of openPapEvents) {
      openPapEvent.properties = {
        dashRequestId,
        predictionIdHash,
        itemIdHash,
        ...openPapEvent.properties,
      };

      reportPapEvent(openPapEvent);
    }
  };

  const linkHeight =
    styles[
      `linkHeight--${
        contentRowEnabled
          ? listItemSize
          : listItemSize >= ListItemSize.Large
            ? ListItemSize.Large
            : ListItemSize.Small
      }`
    ];

  const onClickExternalLink = (e: React.MouseEvent) => {
    openURL(link.url);
    e.preventDefault();
    e.stopPropagation();
    logPapEvent();
    onOpenLink?.(link);
  };

  const onClickInternalLink = () => {
    logPapEvent();
    onOpenLink?.(link);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      openURL(link.url);
      logPapEvent();
      onOpenLink?.(link);
    }
  };

  return isInternalLink ? (
    <ReactRouterLink
      to={link.url}
      className={classnames(
        styles.link,
        linkHeight,
        styles.linkHeightMarch,
        styles.reactRouterLink,
      )}
      state={isInternalLink.state}
      onClick={onClickInternalLink}
      onAuxClick={onClickInternalLink}
    >
      {children}
    </ReactRouterLink>
  ) : enableDrag ? (
    /**
     * Dragging href links gets really wonky, as the browser will try to turn
     * the link into a droppable url cross-app, etc. If we disable onDragStart,
     * then the link will not get turned into a droppable url but it also prevent
     * the DND library drag from triggering. So to avoid all this, we create
     * a div instead and add as many accessability features as we can. A DigLink
     * is still preferred, so this is opt-in per LinkComponent
     */
    <div
      className={classnames(styles.link, linkHeight, styles.linkHeightMarch)}
      role="link"
      onKeyDown={handleKeyDown}
      tabIndex={0}
      onClick={onClickExternalLink}
      onAuxClick={onClickExternalLink}
    >
      {children}
    </div>
  ) : (
    <DigLink
      href={absoluteOrURLFailover(link.url, fallbackURLForLocalFiles)}
      hasNoUnderline
      className={classnames(styles.link, linkHeight, styles.linkHeightMarch)}
      onClick={onClickExternalLink}
      onAuxClick={onClickExternalLink}
    >
      {children}
    </DigLink>
  );
};
