import { dash_feed } from '@dropbox/api-v2-client';
import { ServiceId } from '@mirage/discovery/id';
import * as services from '@mirage/discovery/services';
import { APIv2Callable } from '@mirage/service-dbx-api/service';
import { getCachedOrFetchFeatureValue } from '@mirage/service-experimentation';
import { convertFeatureValueToBool } from '@mirage/service-experimentation/util';
import { tagged } from '@mirage/service-logging';
import WithDefaults from '@mirage/storage/with-defaults';
import * as rx from 'rxjs';
import * as op from 'rxjs/operators';
import { ActivityFeedFilters, UserActivityFeed } from '../types';
import { convertActivityItemToFeedItem } from '../util/compat';
import { listActivityFeed, listFeedItems } from './api';

import type { LogoutServiceConsumerContract } from '@mirage/service-logout';
import type { KVStorage } from '@mirage/storage';
import type { Observable } from 'rxjs';

const logger = tagged('service-feed');

export type Service = ReturnType<typeof provideFeedService>;

export type StoredActivityFeed = {
  activitiesV2?: dash_feed.FeedItem[];
  lastViewed: number | undefined;
  filtersV2: ActivityFeedFilters; // v2 filters so it gets reset for everyone as part of DASHWEB-3954
};

interface DbxApiServiceContract {
  callApiV2: APIv2Callable;
}

export const DEFAULT_FILTERS: ActivityFeedFilters = {
  isOnlyMine: true,
  applications: undefined,
  actor: undefined,
};

export default function provideFeedService(
  storage: KVStorage<StoredActivityFeed>,
  { callApiV2 }: DbxApiServiceContract,
  logoutService: LogoutServiceConsumerContract,
) {
  const adapter = new WithDefaults(storage, {
    activitiesV2: undefined,
    lastViewed: undefined,
    filtersV2: DEFAULT_FILTERS,
  });

  const isRefreshingActivities$ = new rx.BehaviorSubject<boolean>(false);
  const isLoadingMoreActivities$ = new rx.BehaviorSubject<boolean>(false);
  const hasMoreActivities$ = new rx.BehaviorSubject<boolean>(false);
  const activities$ = new rx.BehaviorSubject<dash_feed.FeedItem[] | undefined>(
    undefined,
  );

  const filters$ = new rx.BehaviorSubject<ActivityFeedFilters>(DEFAULT_FILTERS);

  // we do not persist the cursor past the current session since we reload everything on refresh
  let currentCursor: string | undefined;
  let showCollaboratorDocs: boolean = false;

  // Load data from storage
  adapter
    .get('activitiesV2')
    .then((activities) => activities$.next(activities));
  adapter.get('filtersV2').then((filters) => filters$.next(filters));

  const activityFeed$: rx.Observable<UserActivityFeed> = rx
    .combineLatest([
      activities$,
      isRefreshingActivities$,
      isLoadingMoreActivities$,
      hasMoreActivities$,
      filters$,
    ])
    .pipe(
      op.map(([activities, isRefreshing, isLoadingMore, hasMore, filters]) => ({
        activities,
        isRefreshing,
        isLoadingMore,
        hasMore,
        filters,
      })),
    );

  async function setActivityFeedFilters(filters: ActivityFeedFilters) {
    const newFilters = { ...filters };
    await adapter.set('filtersV2', newFilters);
    filters$.next(newFilters);
    await refreshActivityFeed(true);
  }

  async function getCurrentFilters() {
    return await adapter.get('filtersV2');
  }

  async function setActivities(feed: dash_feed.FeedItem[] | undefined) {
    await adapter.set('activitiesV2', feed);
    activities$.next(feed);
  }

  async function clearActivities() {
    await setActivities(undefined);
  }

  // used to prevent "old" refresh requests from overwriting newer requests
  let refreshActivityFeedRequestId = 0;

  async function getShouldUseCollaboratorDocs() {
    if (await getShouldUseListFeedItems()) {
      return { isEnabled: false, threshold: 0 };
    }

    const collaboratorDocsFallbackFeature = await getCachedOrFetchFeatureValue(
      'dash_2024_10_07_activity_feed_fallback_collaborator_docs',
    );

    const isEnabled = convertFeatureValueToBool(
      collaboratorDocsFallbackFeature,
    );
    let threshold = 5;
    if (isEnabled) {
      try {
        const collaboratorDocsFallbackThresholdFeature =
          await getCachedOrFetchFeatureValue(
            'dash_2024_11_21_activity_feed_fallback_collaborator_docs_threshold',
          );
        threshold = Number(collaboratorDocsFallbackThresholdFeature);
      } catch (e) {
        logger.error('Failed to fetch collaborator docs threshold feature', e);
      }
    }

    return { isEnabled, threshold };
  }

  async function getShouldUseListFeedItems() {
    const useListFeedItemsValue = await getCachedOrFetchFeatureValue(
      'dash_2024_12_23_activity_feed_use_list_feed_items',
    );
    return convertFeatureValueToBool(useListFeedItemsValue);
  }

  // loads a fresh user activity feed
  async function refreshActivityFeed(shouldClearActivities = false) {
    const {
      isEnabled: collaboratorDocsFallbackEnabled,
      threshold: showCollaboratorDocsThreshold,
    } = await getShouldUseCollaboratorDocs();
    showCollaboratorDocs = false; // each refresh should clear the collaborator docs flag
    const currentRequestId = ++refreshActivityFeedRequestId;
    try {
      isRefreshingActivities$.next(true);
      if (shouldClearActivities) {
        await clearActivities();
      }
      let items = await _listFeed();
      if (
        collaboratorDocsFallbackEnabled &&
        items.length <= showCollaboratorDocsThreshold
      ) {
        // if we have few personalized results, merge in collaborator docs
        showCollaboratorDocs = true;
        const collaboratorItems = await _listFeed();
        items = items.concat(collaboratorItems);
        items.sort(
          (a, b) =>
            (b.activities?.at(0)?.ts || 0) - (a.activities?.at(0)?.ts || 0),
        ); // sort by timestamp, descending
      }
      if (currentRequestId == refreshActivityFeedRequestId) {
        await setActivities(items);
        await adapter.set('lastViewed', Date.now());
        isRefreshingActivities$.next(false);
      }
    } catch (e) {
      isRefreshingActivities$.next(false);
    }
  }

  // loads the next page of activities using the current cursor
  async function loadMoreActivities() {
    try {
      isLoadingMoreActivities$.next(true);
      const items = await _listFeed(currentCursor);
      const currentActivities = activities$.getValue() || [];
      await setActivities(currentActivities.concat(items));
    } finally {
      isLoadingMoreActivities$.next(false);
    }
  }

  async function _listFeed(cursor?: string, limit: number = 25) {
    try {
      const lastViewed = await adapter.get('lastViewed');
      const activityFeedFilters = await getCurrentFilters();
      const filters = {
        ...activityFeedFilters,
        isOnlyMine: showCollaboratorDocs
          ? false
          : activityFeedFilters.isOnlyMine,
      };

      const { items, cursor: nextCursor } = await callFeedApi(
        limit,
        cursor,
        lastViewed,
        filters,
      );

      currentCursor = nextCursor;
      hasMoreActivities$.next(!!currentCursor);
      return items;
    } catch (e) {
      // if we fail to load more activities, we should reset the cursor
      currentCursor = undefined;
      hasMoreActivities$.next(false);
      throw e;
    }
  }

  async function callFeedApi(
    limit?: number,
    cursor?: string,
    last_viewed_ts?: number,
    filters?: ActivityFeedFilters,
  ) {
    const useListFeedItems = await getShouldUseListFeedItems();
    if (useListFeedItems) {
      const response = await listFeedItems(
        callApiV2,
        limit,
        cursor,
        last_viewed_ts,
        filters,
      );

      return {
        items: response.items || [],
        cursor: response.cursor,
      };
    } else {
      const response = await listActivityFeed(
        callApiV2,
        limit,
        cursor,
        last_viewed_ts,
        filters,
      );
      return {
        items: (response.items || []).map(convertActivityItemToFeedItem),
        cursor: response.cursor,
      };
    }
  }

  function activityFeed(): Observable<UserActivityFeed> {
    return activityFeed$;
  }

  async function tearDown() {
    await adapter.clear();
  }

  logoutService.registerLogoutCallback(ServiceId.FEED, async () => {
    logger.debug('Handling logout in feed service');
    await tearDown();
    logger.debug('Done handling logout in feed service');
  });

  return services.provide(
    ServiceId.FEED,
    {
      activityFeed,
      refreshActivityFeed,
      loadMoreActivities,
      setActivityFeedFilters,
    },
    [ServiceId.DBX_API],
  );
}
