import { v4 as uuidv4 } from 'uuid';

import type { RecentContent } from '../types';

// NOTE: CAUTION FOR EDITING
// This is mock data used specifically for OTC roadshow demos and Sundance by Umesh/Morgan.
// Please check with them before making changes
export const mockRecentData: RecentContent[] = [
  {
    url: 'https://festival.sundance.org/program/categories',
    title: 'Program Guide | 2025 Sundance Film Festival',
    visit_ms: 1630000000000,
    uuid: uuidv4(),
    connectorInfo: { type: 'browser_history' },
    connectionId: 'browser_history',
  },
  {
    url: 'https://www.creativereview.co.uk/',
    title: 'Creative Review',
    visit_ms: 1630000000000,
    uuid: uuidv4(),
    connectorInfo: { type: 'browser_history' },
    connectionId: 'browser_history',
  },
  {
    url: 'https://letterboxd.com/',
    title: 'Letterboxd • Social film discovery.',
    visit_ms: 1630000000000,
    uuid: uuidv4(),
    connectorInfo: { type: 'browser_history' },
    connectionId: 'browser_history',
  },
  {
    url: 'https://creativemornings.com/talks/upcoming',
    title: 'Upcoming chapter events | CreativeMornings',
    visit_ms: 1630000000000,
    uuid: uuidv4(),
    connectorInfo: { type: 'browser_history' },
    connectionId: 'browser_history',
  },
  {
    url: 'https://www.itsnicethat.com/',
    title: "It's Nice That",
    visit_ms: 1630000000000,
    uuid: uuidv4(),
    connectorInfo: { type: 'browser_history' },
    connectionId: 'browser_history',
  },
  {
    url: 'https://www.youtube.com/watch?v=L-vOVwuRf8Y',
    title: '5 Sundance Film Festival Tips - Youtube',
    visit_ms: 1630000000000,
    uuid: uuidv4(),
    connectorInfo: { type: 'browser_history' },
    connectionId: 'browser_history',
  },
  {
    url: 'https://www.canva.com/',
    title: 'Canva: Visual Suite for Everyone',
    visit_ms: 1630000000000,
    uuid: uuidv4(),
    connectorInfo: { type: 'browser_history' },
    connectionId: 'browser_history',
  },
  {
    url: 'https://www.nowness.com/',
    title: 'NOWNESS',
    visit_ms: 1630000000000,
    uuid: uuidv4(),
    connectorInfo: { type: 'browser_history' },
    connectionId: 'browser_history',
  },
];
