import { Box } from '@dropbox/dig-foundations';
import { EnvCtx } from '@mirage/service-environment-context/global-env-ctx';
import i18n, { Trans } from '@mirage/translations';
import classNames from 'classnames';
import { RefObject, useEffect, useMemo, useState } from 'react';
import styles from './SearchHeader.module.css';

export const SearchInputPlaceholder = ({
  placeholderTexts = [],
  isDarwin = true,
  animatePlaceholder = false,
  filtersActive,
  fullWidth = false,
  inputRef,
}: {
  isDarwin?: boolean;
  placeholderTexts?: string[];
  animatePlaceholder?: boolean;
  filtersActive: boolean;
  fullWidth?: boolean;
  inputRef: RefObject<HTMLInputElement>;
}) => {
  const [hasStablePlaceholder, setHasStablePlaceholder] = useState(false);
  const [suggestionIndex, setSuggestionIndex] = useState(0);
  const [cachedPlaceholder, setCachedPlaceholder] = useState<string | null>(
    null,
  );

  const defaultPlaceholder: string = i18n.t('search_placeholder_default');

  const placeholders: string[] = useMemo(
    () => [defaultPlaceholder, ...placeholderTexts],
    [defaultPlaceholder, placeholderTexts],
  );

  const isInputActiveDocumentElement =
    inputRef?.current === document.activeElement;
  const inputValue = inputRef?.current?.value;

  // Don't start rotating the placeholder until we've had it stable for at least one cycle.
  useEffect(() => {
    if (!animatePlaceholder || placeholders.length <= 1) return;

    const id = setInterval(() => {
      if (isInputActiveDocumentElement && inputValue === '' && !filtersActive) {
        setHasStablePlaceholder(true);
        setCachedPlaceholder(null);
        setSuggestionIndex((prev) => (prev + 1) % placeholders.length);
      }
    }, 4000);

    return () => {
      clearInterval(id);
    };
  }, [
    animatePlaceholder,
    placeholders,
    suggestionIndex,
    isInputActiveDocumentElement,
    inputValue,
    filtersActive,
  ]);
  useEffect(() => {
    if (!isInputActiveDocumentElement || inputValue !== '') {
      if (suggestionIndex % placeholders.length > 0) {
        setCachedPlaceholder(placeholders.at(suggestionIndex) || null);
      }
      setSuggestionIndex(0);
    }
  }, [isInputActiveDocumentElement, placeholders, suggestionIndex, inputValue]);
  const currentSuggestion =
    placeholders.at(suggestionIndex) ?? defaultPlaceholder;
  const previousSuggestion =
    cachedPlaceholder ??
    placeholders.at(suggestionIndex - 1) ??
    defaultPlaceholder;

  if (filtersActive) {
    return null;
  }
  return (
    <Box
      as="span"
      pointerEvents="none"
      whiteSpace="nowrap"
      overflow="hidden"
      position="absolute"
      height="100%"
      width="100%"
      color="Text Subtle"
      fontSize={fullWidth ? 'Title Medium' : 'Text Large'}
      display="flex"
      fontFamily="Text"
      aria-hidden={true}
      className={classNames(styles.fakePlaceholder, {
        [styles.hasStablePlaceholder]: hasStablePlaceholder,
      })}
    >
      {isInputActiveDocumentElement ? (
        <ReachTextSearchInputPlaceholder
          current={currentSuggestion}
          previous={
            animatePlaceholder && placeholders.length > 1
              ? previousSuggestion
              : undefined
          }
        />
      ) : EnvCtx.surface === 'desktop' ? (
        <ReachTextSearchInputPlaceholder
          current={defaultPlaceholder}
          previous=""
        />
      ) : (
        <Trans
          i18nKey="search_placeholder_default_with_tab"
          values={{
            tab: isDarwin ? 'TAB' : 'Tab',
          }}
          components={{
            arrowTag: <div className={styles.placeholderKeyCapSymbol} />,
            tabTag: <div className={styles.placeholderKeyCap} />,
          }}
        ></Trans>
      )}
    </Box>
  );
};

const ReachTextSearchInputPlaceholder = ({
  current,
  previous,
}: {
  current: string;
  previous?: string | undefined;
}) => {
  return (
    <Trans
      i18nKey="search_placeholder_base"
      values={{
        current,
        previous,
      }}
      components={{
        rootTag: (
          <Box
            display="inline-flex"
            position="relative"
            flexGrow={1}
            alignItems="center"
          />
        ),
        contentTag: (
          <Box
            key={current}
            as="span"
            position="absolute"
            display="inline-flex"
            alignItems="center"
            className={styles.currentSuggestion}
          />
        ),
        previousTag: (
          <Box
            key={previous}
            as="span"
            position="absolute"
            className={styles.previousSuggestion}
          />
        ),
      }}
    ></Trans>
  );
};
