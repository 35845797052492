import { LabelGroup } from '@dropbox/dash-component-library';
import { Button, IconButton } from '@dropbox/dig-components/buttons';
import { LayerContext } from '@dropbox/dig-components/layer';
import { Menu } from '@dropbox/dig-components/menu';
import { Modal } from '@dropbox/dig-components/modal';
import { Snackbar } from '@dropbox/dig-components/snackbar';
import { TextArea } from '@dropbox/dig-components/text_fields';
import { Text, Title } from '@dropbox/dig-components/typography';
import {
  Split,
  Stack,
  ThemeContainer,
  ThemeProvider,
} from '@dropbox/dig-foundations';
import { UIIcon } from '@dropbox/dig-icons';
import {
  CheckmarkLine,
  CloseLine,
  EditLine,
  GlobeLine,
  LinkLine,
  LockLine,
  ShowLine,
  TeamLine,
} from '@dropbox/dig-icons/assets';
import { useAugustRevisionEnabled } from '@mirage/august-revision-hook';
import { DashStackIcon } from '@mirage/mosaics/DCLWrappers/DashStackIcon';
import { dashThemeOverrides } from '@mirage/service-settings/theming/DashThemeProvider';
import { useIsPublicSharingAllowed } from '@mirage/service-stack-admin-settings/hooks';
import { useIsDfbUser } from '@mirage/shared/hooks/useIsDfbUser';
import { getDefaultColorIndex, getTheme } from '@mirage/stacks/themes';
import i18n from '@mirage/translations';
import classNames from 'classnames';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  EMOJI_CLEARED_SENTINEL_VALUE,
  iconSrcForAccessLevel,
} from '../Helpers/Utils';
import { UnthemedWrapper } from '../UnthemedWrapper';
import { AccessRow } from './AccessRow';
import { InviteForm } from './InviteForm';
import { InvitePeople } from './InvitePeople';
import styles from './ShareModal.module.css';
import { SharingTypeahead, SharingTypeaheadProps } from './SharingTypeahead';
import { ShowMembers } from './ShowMembers';
import { ShowMembersV2 } from './ShowMembersV2';
import {
  SharingMember,
  SharingMemberKey,
  SharingMemberKeyWithPermission,
  SharingStackPermission,
  SharingUserContactKey,
  StackAccessLevel,
  StackInfo,
  StackMember,
  StackPermission,
} from './Types';
import {
  hasStackWritePermissions,
  newTitleForAccessLevel,
  textForPermission,
  validateEmail,
} from './Utils';

import type { stacks } from '@dropbox/api-v2-client';

interface ShareModalInternalProps {
  stack: stacks.Stack;
  isOpen: boolean;
  onCancel: () => void;
  currentMember: StackMember;
  stackInfo: StackInfo;
  getSharingMembers: (searchText: string) => Promise<SharingMember[]>;
  onAddSharingMemberEvent: SharingTypeaheadProps['onMemberSelectedEvent'];
  onCopyStackShareLink: () => void;
  onShareStackWithMembers: (
    sendNotifications: boolean,
    notificationMessage: string,
    members: SharingMemberKeyWithPermission[],
    onFailure: () => void,
  ) => Promise<void>;
  onRevokeStackMember: (
    member: StackMember,
  ) => Promise<stacks.RemoveStackSharingMembersResponse | undefined>;
  onUpdateStackSharedLink: (
    accessLevel: StackAccessLevel,
    permission: SharingStackPermission,
  ) => Promise<boolean>;
}

export const ShareModalInternal: React.FC<ShareModalInternalProps> = ({
  stack,
  isOpen,
  onCancel,
  currentMember,
  stackInfo,
  getSharingMembers,
  onAddSharingMemberEvent,
  onCopyStackShareLink,
  onShareStackWithMembers,
  onRevokeStackMember,
  onUpdateStackSharedLink,
}) => {
  const [isSharingStack, setIsSharingStack] = useState(false);
  const [typeaheadKey, setTypeaheadKey] = useState(0);
  const [invitees, setInvitees] = useState<
    (SharingMember | SharingUserContactKey)[]
  >([]);
  const [inviteePermission, setInviteePermission] =
    useState<SharingStackPermission>(StackPermission.WRITE);
  const [shareNotificationEnabled, setShareNotificationEnabled] =
    useState(true);
  const [notificationNoteText, setNotificationNoteText] = useState('');
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const closeSnackbar = useCallback(() => {
    setIsSnackbarOpen(false);
    setSnackbarMessage('');
  }, [setIsSnackbarOpen, setSnackbarMessage]);
  const openSnackbar = useCallback(
    (message: string) => {
      setSnackbarMessage(message);
      setIsSnackbarOpen(true);
    },
    [setSnackbarMessage, setIsSnackbarOpen],
  );
  const canShare = useMemo(
    () => hasStackWritePermissions(stackInfo.permission),
    [stackInfo.permission],
  );

  const isDfbUser = useIsDfbUser();
  const augustRevisionEnabled = useAugustRevisionEnabled();
  const dfb = !!isDfbUser && augustRevisionEnabled;

  const canEditSharingLinkPermissions = useMemo(
    () => hasStackWritePermissions(stackInfo.permission),
    [stackInfo.permission],
  );

  const disableInvitePeopleViaEmail =
    !canEditSharingLinkPermissions && stackInfo.isCompanyStack;

  const shareWithMemberKeys = useCallback(
    async (
      memberKeys: SharingMemberKey[],
      notifEnabled: boolean = false,
      notifText: string = '',
    ) => {
      setIsSharingStack(true);
      // Add api call to async queue.
      onShareStackWithMembers(
        notifEnabled,
        notifText,
        memberKeys
          .filter(
            (memberKey) =>
              memberKey['.tag'] === 'group' || validateEmail(memberKey.email),
          )
          .map((memberKey) => ({ memberKey, permission: inviteePermission })),
        () => setSnackbarMessage(i18n.t('failed_to_add_sharing_members')),
      );

      setIsSharingStack(false);
    },
    [onShareStackWithMembers, inviteePermission],
  );

  const resetTypeahead = useCallback(() => {
    setInvitees([]);
    setTypeaheadKey((prev) => prev + 1);
  }, [setInvitees]);

  const shareWithCollectedMembers = useCallback(() => {
    resetTypeahead();

    shareWithMemberKeys(
      invitees,
      shareNotificationEnabled,
      notificationNoteText,
    );
  }, [
    shareWithMemberKeys,
    invitees,
    shareNotificationEnabled,
    notificationNoteText,
    resetTypeahead,
  ]);

  const isInvitingMembers = invitees.length > 0;

  return (
    // This element is not user-interactable.
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
    <div
      // Important: Prevent a random click from closing the modal.
      onClick={(e) => e.stopPropagation()}
      // Prevent the modal anchor from changing the size of a parent component with a flex box
      // and gap property set
      style={{ display: 'none' }}
    >
      <Modal
        isCentered
        open={isOpen}
        withCloseButton={dfb ? undefined : i18n.t('close')}
        onRequestClose={onCancel}
        shouldCloseOnOverlayClick={dfb}
        className={classNames(
          { [styles.sharingModal]: !dfb },
          styles.sharingModalAll,
        )}
        data-testid="ShareModal"
        width={dfb ? 500 : 450}
      >
        <ThemeProvider overrides={dashThemeOverrides}>
          <ThemeContainer>
            {dfb ? (
              <ThemeProvider
                overrides={getTheme(stack.stack_data?.color_index).subtle}
              >
                <ThemeContainer>
                  <Modal.Header className={styles.sharingModalHeaderDfb}>
                    <Split gap="Micro Large" alignY="center">
                      <Split.Item marginRight="auto">
                        <LabelGroup
                          direction="horizontal"
                          verticalAlignment="center"
                          withStartAccessory={
                            <DashStackIcon
                              size="medium"
                              colorIndex={
                                stack?.stack_data?.color_index ||
                                getDefaultColorIndex()
                              }
                              emoji={
                                stack?.stack_data?.emoji ||
                                EMOJI_CLEARED_SENTINEL_VALUE
                              }
                            />
                          }
                          withLabel={
                            <Title
                              className={styles.sharingModalTitle}
                              size="small"
                              weightVariant="emphasized"
                            >
                              {stack?.stack_data?.name || 'Untitled Stack'}
                            </Title>
                          }
                        ></LabelGroup>
                      </Split.Item>
                      <Split.Item>
                        <IconButton
                          variant="borderless"
                          size="medium"
                          aria-label="Close"
                          onClick={onCancel}
                        >
                          <UIIcon src={CloseLine} />
                        </IconButton>
                      </Split.Item>
                    </Split>
                  </Modal.Header>
                </ThemeContainer>
              </ThemeProvider>
            ) : (
              <Modal.Header className={styles.sharingModalHeader}>
                <Modal.Title size="small" className={styles.sharingModalTitle}>
                  {i18n.t('modal_title', { stackName: stackInfo.name })}
                </Modal.Title>
              </Modal.Header>
            )}
            <Modal.Body
              className={classNames(
                dfb ? styles.sharingModalBodyDfb : styles.sharingModalBody,
              )}
            >
              <LayerContext.Provider
                value={{
                  zIndex: 1_000_010,
                  portalRootElement: document.documentElement,
                }}
              >
                {dfb ? (
                  <Modal.FullBleed>
                    <Stack
                      className={styles.sharingModalBodyDfbStack}
                      display="flex"
                      gap="Macro Small"
                    >
                      {canShare && (
                        <div className={styles.typeaheadContainer}>
                          <SharingTypeahead
                            key={typeaheadKey}
                            stackMemberKeys={stackInfo.members}
                            isSharing={isSharingStack}
                            excludedMembers={[]}
                            getSharingMembers={getSharingMembers}
                            allowExternalShare
                            onMemberSelectedEvent={onAddSharingMemberEvent}
                            setInvitees={setInvitees}
                            inviteesPermission={inviteePermission}
                            setInviteesPermission={setInviteePermission}
                            showAddIconInResultRow
                            disableInvitePeopleViaEmail={
                              disableInvitePeopleViaEmail
                            }
                          />
                        </div>
                      )}

                      {isInvitingMembers ? (
                        <div className={styles.messageInputContainer}>
                          <TextArea
                            className={styles.messageInputTextArea}
                            placeholder={i18n.t('add_a_note_optional')}
                            value={notificationNoteText}
                            onChange={(e) => {
                              setNotificationNoteText(e.currentTarget.value);
                            }}
                            isTransparent
                          />
                        </div>
                      ) : (
                        <div
                          className={classNames(styles.membersContainer, {
                            [styles.canShare]: canShare,
                          })}
                        >
                          <ShowMembersV2
                            stackInfo={stackInfo}
                            currentUserMember={currentMember}
                            handleRevokeStackMember={onRevokeStackMember}
                            handleShareStackWithMembers={
                              onShareStackWithMembers
                            }
                            openSnackbar={openSnackbar}
                          />
                        </div>
                      )}
                    </Stack>
                  </Modal.FullBleed>
                ) : (
                  <>
                    <InvitePeople
                      stackInfo={stackInfo}
                      getSharingMembers={getSharingMembers}
                      invitees={invitees}
                      setInvitees={setInvitees}
                      inviteePermission={inviteePermission}
                      setInviteePermission={setInviteePermission}
                    />
                    {isInvitingMembers ? (
                      <InviteForm
                        setNotificationNoteText={setNotificationNoteText}
                        notificationNoteText={notificationNoteText}
                        shareNotificationEnabled={shareNotificationEnabled}
                        setShareNotificationEnabled={
                          setShareNotificationEnabled
                        }
                      />
                    ) : (
                      <>
                        <ShowMembers
                          stackInfo={stackInfo}
                          currentMember={currentMember}
                          handleRevokeStackMember={onRevokeStackMember}
                          handleShareStackWithMembers={onShareStackWithMembers}
                          openSnackbar={openSnackbar}
                        />
                        <AccessRow
                          stackInfo={stackInfo}
                          handleUpdateStackSharedLink={onUpdateStackSharedLink}
                          openSnackbar={openSnackbar}
                        />
                      </>
                    )}
                  </>
                )}
              </LayerContext.Provider>
            </Modal.Body>
            <Modal.Footer
              className={dfb ? styles.footerBlockDfb : styles.footerBlock}
              // Disable right-alignment of the footer content if on the members list view
              preferComposition={dfb && !isInvitingMembers}
            >
              <FooterContent
                stackInfo={stackInfo}
                isInvitingMembers={isInvitingMembers}
                shareWithMembers={shareWithCollectedMembers}
                resetTypeahead={resetTypeahead}
                isSharingStack={isSharingStack}
                onCopyStackShareLink={onCopyStackShareLink}
                onUpdateStackSharedLink={onUpdateStackSharedLink}
                openSnackbar={openSnackbar}
                namespaceId={stackInfo.namespaceId}
                dfb={dfb}
              />
              <Snackbar
                className={
                  dfb ? styles.snackbarContainerDfb : styles.snackbarContainer
                }
                open={isSnackbarOpen}
                onRequestClose={closeSnackbar}
                preferComposition
              >
                <Snackbar.Content>
                  <Snackbar.Message>{snackbarMessage}</Snackbar.Message>
                  <Snackbar.Actions>
                    <Button variant="transparent" onClick={closeSnackbar}>
                      {i18n.t('dismiss')}
                    </Button>
                  </Snackbar.Actions>
                </Snackbar.Content>
              </Snackbar>
            </Modal.Footer>
          </ThemeContainer>
        </ThemeProvider>
      </Modal>
    </div>
  );
};

const FooterContent: React.FC<{
  stackInfo: StackInfo;
  isInvitingMembers: boolean;
  onCopyStackShareLink: () => void;
  shareWithMembers: () => void;
  resetTypeahead: () => void;
  namespaceId: string | undefined;
  isSharingStack: boolean;
  onUpdateStackSharedLink: (
    accessLevel: StackAccessLevel,
    permission: SharingStackPermission,
  ) => Promise<boolean>;
  openSnackbar: (message: string) => void;
  dfb: boolean;
}> = ({
  stackInfo,
  isInvitingMembers,
  onCopyStackShareLink,
  shareWithMembers,
  resetTypeahead,
  namespaceId,
  isSharingStack,
  onUpdateStackSharedLink,
  openSnackbar,
  dfb,
}) => {
  const [linkCopied, setLinkCopied] = useState<boolean>(false);

  const handleClickCopyLink = useCallback(() => {
    onCopyStackShareLink();
    setLinkCopied(true);
  }, [onCopyStackShareLink, setLinkCopied]);

  useEffect(() => {
    // Note that if the timestamp changes because the user clicks again, the 5s timer is reset.
    if (linkCopied) {
      const id = setTimeout(() => setLinkCopied(false), 5000);
      return () => clearTimeout(id);
    }
  }, [linkCopied]);

  if (dfb) {
    return isInvitingMembers ? (
      <FooterContentInvitingOctober
        onShare={shareWithMembers}
        onCancel={resetTypeahead}
      />
    ) : (
      <FooterContentShowMembersOctober
        onClickCopyLink={handleClickCopyLink}
        {...{
          stackInfo,
          namespaceId,
          linkCopied,
          onUpdateStackSharedLink,
          openSnackbar,
        }}
      />
    );
  }

  return isInvitingMembers ? (
    <FooterContentInviting
      {...{ shareWithMembers, namespaceId, isSharingStack }}
    />
  ) : (
    <FooterContentShowMembers
      onClickCopyLink={handleClickCopyLink}
      {...{ namespaceId, linkCopied }}
    />
  );
};

const FooterContentShowMembers: React.FC<{
  onClickCopyLink: () => void;
  namespaceId: string | undefined;
  linkCopied: boolean;
}> = ({ onClickCopyLink, namespaceId, linkCopied }) => {
  return (
    <Button
      variant="outline"
      onClick={onClickCopyLink}
      disabled={!namespaceId}
      size="large"
      fullWidth
      className={styles.copyLinkButton}
      data-testid="Stacks-copyLinkButton"
    >
      <UIIcon src={linkCopied ? CheckmarkLine : LinkLine} />
      <Text className={styles.standardText} isBold>
        {i18n.t(linkCopied ? 'link_copied2' : 'copy_link')}
      </Text>
    </Button>
  );
};

const FooterContentInviting: React.FC<{
  shareWithMembers: () => void;
  namespaceId: string | undefined;
  isSharingStack: boolean;
}> = ({ shareWithMembers, namespaceId, isSharingStack }) => {
  return (
    <div className={styles.sendButtonParent}>
      <Button
        variant="primary"
        onClick={shareWithMembers}
        disabled={!namespaceId}
        size="large"
        isLoading={isSharingStack}
      >
        <Text inverse>{i18n.t('send')}</Text>
      </Button>
    </div>
  );
};

const FooterContentShowMembersOctober: React.FC<{
  stackInfo: StackInfo;
  namespaceId: string | undefined;
  linkCopied: boolean;
  onClickCopyLink: () => void;
  onUpdateStackSharedLink: (
    accessLevel: StackAccessLevel,
    permission: SharingStackPermission,
  ) => Promise<boolean>;
  openSnackbar: (message: string) => void;
}> = ({
  stackInfo,
  namespaceId,
  linkCopied,
  onClickCopyLink,
  onUpdateStackSharedLink,
  openSnackbar,
}) => {
  const isPublicSharingAllowed = useIsPublicSharingAllowed();
  const [isLoading, setIsLoading] = useState(false);
  const canEditSharingLinkPermissions = useMemo(
    () => hasStackWritePermissions(stackInfo.permission),
    [stackInfo.permission],
  );

  const updateStackSharedLink = useCallback(
    async ({
      accessLevel,
      permission,
    }: {
      accessLevel: StackAccessLevel;
      permission: SharingStackPermission;
    }) => {
      if (
        accessLevel === stackInfo.accessLevel &&
        permission === stackInfo.linkPermission
      ) {
        return;
      }

      setIsLoading(true);

      try {
        const result = await onUpdateStackSharedLink(accessLevel, permission);
        if (!result) {
          openSnackbar(i18n.t('failed_to_change_permissions'));
        }
      } finally {
        setIsLoading(false);
      }
    },
    [
      setIsLoading,
      onUpdateStackSharedLink,
      openSnackbar,
      stackInfo.accessLevel,
      stackInfo.linkPermission,
    ],
  );

  return (
    <Split alignY="center">
      <Split.Item marginRight="auto">
        <UnthemedWrapper>
          <Menu.Wrapper onSelection={updateStackSharedLink}>
            {({ getContentProps, getTriggerProps }) => (
              <>
                <Button
                  {...getTriggerProps()}
                  variant="borderless"
                  withIconStart={
                    <UIIcon
                      src={iconSrcForAccessLevel(
                        stackInfo.accessLevel,
                        isPublicSharingAllowed,
                      )}
                    />
                  }
                  withDropdownIcon={canEditSharingLinkPermissions}
                  disabled={isLoading || !canEditSharingLinkPermissions}
                >
                  <Text size="medium">
                    {newTitleForAccessLevel(
                      stackInfo.accessLevel,
                      undefined,
                      isPublicSharingAllowed,
                    )}{' '}
                    {textForPermission(stackInfo.linkPermission).toLowerCase()}
                  </Text>
                </Button>
                <Menu.Content placement="top-start" {...getContentProps()}>
                  <Menu.Segment
                    withLabel={i18n.t('stack_share_who_has_access')}
                  >
                    {isPublicSharingAllowed && (
                      <Menu.SelectItem
                        withRightAccessory={<UIIcon src={GlobeLine} />}
                        selected={
                          stackInfo.accessLevel === StackAccessLevel.PUBLIC
                        }
                        value={{
                          accessLevel: StackAccessLevel.PUBLIC,
                          permission: stackInfo.linkPermission,
                        }}
                        // disable if company stack
                        disabled={stackInfo.isCompanyStack}
                      >
                        {newTitleForAccessLevel(
                          StackAccessLevel.PUBLIC,
                          undefined,
                          true,
                        )}
                      </Menu.SelectItem>
                    )}
                    <Menu.SelectItem
                      withRightAccessory={<UIIcon src={TeamLine} />}
                      selected={
                        stackInfo.accessLevel === StackAccessLevel.TEAM ||
                        (stackInfo.accessLevel === StackAccessLevel.PUBLIC &&
                          !isPublicSharingAllowed)
                      }
                      value={{
                        accessLevel: StackAccessLevel.TEAM,
                        permission: stackInfo.linkPermission,
                      }}
                    >
                      {newTitleForAccessLevel(StackAccessLevel.TEAM)}
                    </Menu.SelectItem>
                    <Menu.SelectItem
                      withRightAccessory={<UIIcon src={LockLine} />}
                      selected={
                        stackInfo.accessLevel === StackAccessLevel.INVITED
                      }
                      value={{
                        accessLevel: StackAccessLevel.INVITED,
                        permission: stackInfo.linkPermission,
                      }}
                      // disable if company stack
                      disabled={stackInfo.isCompanyStack}
                    >
                      {newTitleForAccessLevel(StackAccessLevel.INVITED)}
                    </Menu.SelectItem>
                  </Menu.Segment>
                  <Menu.Segment
                    withLabel={i18n.t('stack_share_what_can_they_do')}
                  >
                    <Menu.SelectItem
                      data-testid="canEdit"
                      withRightAccessory={<UIIcon src={EditLine} />}
                      selected={
                        stackInfo.linkPermission === StackPermission.WRITE
                      }
                      value={{
                        accessLevel: stackInfo.accessLevel,
                        permission: StackPermission.WRITE,
                      }}
                      // disable if company stack
                      disabled={stackInfo.isCompanyStack}
                    >
                      {i18n.t('can_edit')}
                    </Menu.SelectItem>
                    <Menu.SelectItem
                      data-testid="canView"
                      withRightAccessory={<UIIcon src={ShowLine} />}
                      selected={
                        stackInfo.linkPermission === StackPermission.READ
                      }
                      value={{
                        accessLevel: stackInfo.accessLevel,
                        permission: StackPermission.READ,
                      }}
                    >
                      {i18n.t('can_view')}
                    </Menu.SelectItem>
                  </Menu.Segment>
                </Menu.Content>
              </>
            )}
          </Menu.Wrapper>
        </UnthemedWrapper>
      </Split.Item>
      <Split.Item>
        <Button
          variant="primary"
          onClick={onClickCopyLink}
          disabled={!namespaceId}
          data-testid="Stacks-copyLinkButton"
          withIconStart={<UIIcon src={linkCopied ? CheckmarkLine : LinkLine} />}
        >
          {i18n.t(linkCopied ? 'link_copied2' : 'copy_link')}
        </Button>
      </Split.Item>
    </Split>
  );
};

const FooterContentInvitingOctober: React.FC<{
  onCancel: () => void;
  onShare: () => void;
}> = ({ onCancel, onShare }) => {
  return (
    <Split>
      <Split.Item marginRight="auto">
        <Button variant="opacity" onClick={onCancel}>
          {i18n.t('cancel')}
        </Button>
      </Split.Item>
      <Split.Item>
        <Button variant="primary" onClick={onShare}>
          {i18n.t('share_stack')}
        </Button>
      </Split.Item>
    </Split>
  );
};
