import { PAP_Click_AddSourcesButton } from '@mirage/analytics/events/types/click_add_sources_button';
import { PAP_Click_AppType } from '@mirage/analytics/events/types/click_app_type';
import { PAP_Click_SaveSources } from '@mirage/analytics/events/types/click_save_sources';
import { ContextInputMessages } from '@mirage/mosaics/ComposeAssistant/components/chat/ContextInputMessages';
import { ConversationFollowUpSuggestions } from '@mirage/mosaics/ComposeAssistant/components/chat/ConversationFollowUpSuggestions';
import { ConversationInput } from '@mirage/mosaics/ComposeAssistant/components/chat/ConversationInput';
import { ConversationMessages } from '@mirage/mosaics/ComposeAssistant/components/chat/ConversationMessages';
import { ConversationMessageSourceChips } from '@mirage/mosaics/ComposeAssistant/components/chat/ConversationMessageSourceChips';
import {
  ClickableEmptyChatInstructionsOption,
  EmptyChatInstructions,
} from '@mirage/mosaics/ComposeAssistant/components/chat/EmptyChatInstructions';
import { AddSourcesModal } from '@mirage/mosaics/ComposeAssistant/components/compose-sources/AddSourcesModal';
import { useComposeAnalyticsContext } from '@mirage/mosaics/ComposeAssistant/data/ComposeAnalyticsContext';
import { useComposeCurrentSessionContext } from '@mirage/mosaics/ComposeAssistant/data/current-session/ComposeCurrentSessionContext';
import { getSelectionPromptString } from '@mirage/mosaics/ComposeAssistant/data/llm/llm-prompts';
import { TransientSource } from '@mirage/mosaics/ComposeAssistant/data/TransientSources';
import { useFeatureFlagValue } from '@mirage/service-experimentation/useFeatureFlagValue';
import { openURL } from '@mirage/service-platform-actions';
import {
  ComposeAssistantConversationMessage,
  ComposeAssistantConversationMessageActionContext,
  ComposeAssistantConversationMessageMessage,
  ComposeSource,
  getFirstMarkdownArtifact,
  getSourceUUID,
} from '@mirage/shared/compose/compose-session';
import i18n from '@mirage/translations';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

interface ComposeConversationProps {
  variant?: 'default' | 'condensed';
  rightPaneExpanded: boolean;
  setDidJustSendFirstWriteCommand?: (
    didJustSendFirstWriteCommand: boolean,
  ) => void;
  onOpenArtifact: () => void;
}
export const ComposeConversation = memo(
  ({
    variant = 'default',
    rightPaneExpanded,
    setDidJustSendFirstWriteCommand,
    onOpenArtifact,
  }: ComposeConversationProps) => {
    const chatWithDashEnabled =
      useFeatureFlagValue('dash_assist_2024_10_12_chat_v1') === 'ON';
    const {
      messagesHistory,
      sources,
      addSource,
      removeSource,
      artifacts,
      setDraftConfig,
      sourcesContentCache,
      addRawMessage,
      postUserMessage,
      inputContext,
      setInputContext,
      currentSessionID,
      isWaitingForResponse,
      progressString,
      newSession,
      transientSources,
    } = useComposeCurrentSessionContext();
    const { logComposeEvent } = useComposeAnalyticsContext({
      actionSurfaceComponent: 'compose_chat_pane',
      currentSessionID,
    });
    const markdownArtifact = useMemo(
      () => getFirstMarkdownArtifact(artifacts),
      [artifacts],
    );
    const transientSourcesMessage = useMemo(() => {
      return getTransientSourcesMessage(transientSources, messagesHistory);
    }, [messagesHistory, transientSources]);
    const messagesToDisplay = useMemo(() => {
      return transientSourcesMessage
        ? [...messagesHistory, transientSourcesMessage]
        : messagesHistory;
    }, [messagesHistory, transientSourcesMessage]);

    const handleCompleteContextInputs = useCallback(() => {
      if (messagesHistory.length > 0) {
        // no-op if already started talking
        return;
      }
      const text = markdownArtifact?.draftConfig.contentType
        ? i18n.t('compose_assistant_message_with_content_type', {
            contentType: markdownArtifact.draftConfig.contentType,
          })
        : i18n.t('compose_assistant_message_generic');
      addRawMessage({
        type: 'message',
        role: 'assistant',
        text,
        ts: Date.now(),
      });
    }, [
      addRawMessage,
      markdownArtifact?.draftConfig.contentType,
      messagesHistory.length,
    ]);
    const [isAddSourceModalOpen, setIsAddSourceModalOpen] = useState(false);
    const handleClickAdd = useCallback(() => {
      setIsAddSourceModalOpen(true);
      logComposeEvent(
        PAP_Click_AddSourcesButton({
          actionType: 'chat_pane',
        }),
      );
    }, [logComposeEvent]);
    const handleCloseAddSourcesModal = useCallback(() => {
      if (isAddSourceModalOpen) {
        logComposeEvent(
          PAP_Click_SaveSources({
            numberOfSelectedItems: sources.length,
          }),
          { actionSurfaceComponent: 'compose_source_modal' },
        );
      }
      setIsAddSourceModalOpen(false);
      if (messagesHistory.length === 0) handleCompleteContextInputs();
    }, [
      handleCompleteContextInputs,
      messagesHistory.length,
      logComposeEvent,
      isAddSourceModalOpen,
      sources.length,
    ]);

    const showEmptyChatInstructions =
      messagesToDisplay.length === 0 && markdownArtifact === undefined;
    const handleSubmitInput = useCallback(
      (text: string) => {
        const rawPromptText = inputContext
          ? getSelectionPromptString(text, inputContext.selectedText)
          : undefined;
        const actionContext:
          | ComposeAssistantConversationMessageActionContext
          | undefined = inputContext
          ? {
              type: 'compose_selection_edit',
              selectedText: inputContext.selectedText,
            }
          : undefined;
        const hasNonEmptyMarkdownContent = Boolean(
          markdownArtifact && markdownArtifact.markdownContent.length > 0,
        );

        // if there was a message about transient sources that the user is responding to, add that
        // message to the message history first
        if (transientSourcesMessage) {
          addRawMessage(transientSourcesMessage);
        }

        if (
          getUserMessages(messagesHistory).length === 0 &&
          !hasNonEmptyMarkdownContent
        ) {
          // first user message with empty draft must trigger new draft generation
          postUserMessage({
            text,
            rawPromptText,
            mustGenerateDraft: true,
            mustIncludeSourceContents: true,
            actionContext,
          });
          setDidJustSendFirstWriteCommand?.(true);
        } else {
          postUserMessage({
            text,
            rawPromptText,
            actionContext,
          });
        }
        if (showEmptyChatInstructions) {
          logComposeEvent(PAP_Click_AppType({ actionType: 'open_chat' }));
        }
      },
      [
        addRawMessage,
        inputContext,
        markdownArtifact,
        messagesHistory,
        postUserMessage,
        setDidJustSendFirstWriteCommand,
        transientSourcesMessage,
        logComposeEvent,
        showEmptyChatInstructions,
      ],
    );

    const hasCustomInstruction = messagesHistory.some(
      (message) => message.type === 'instruction',
    );
    // Update the URL based on the current state: for empty sessions clear search params,
    // if user clicked on the sidebar 'Assistant' button then go back to new session page,
    // and for non-empty sessions set the 'app' param to 'chat' or 'write'
    const location = useLocation();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    useEffect(() => {
      if (location.state?.reset) {
        newSession([]);
        navigate(location.pathname, { replace: true }); // go to new session page and remove the state.reset property
        return;
      }
      if (showEmptyChatInstructions) {
        if ([...searchParams].length > 0) {
          setSearchParams({});
        }
      } else {
        const appName = markdownArtifact ? 'write' : 'chat';
        if (searchParams.get('app') !== appName) {
          const newSearchParams = new URLSearchParams(searchParams);
          newSearchParams.set('app', appName);
          setSearchParams(newSearchParams);
        }
      }
    }, [
      showEmptyChatInstructions,
      markdownArtifact,
      setSearchParams,
      searchParams,
      newSession,
      location.state,
      navigate,
      location.pathname,
    ]);
    const isWriteApp = !hasCustomInstruction && markdownArtifact;
    const startingMessageNode = showEmptyChatInstructions ? (
      <ComposeEmptyChatInstructions chatWithDashEnabled={chatWithDashEnabled} />
    ) : (
      isWriteApp && (
        <ContextInputMessages
          key={currentSessionID} // reset inputs when session changes
          handleClickAdd={handleClickAdd}
          sources={sources}
          sourcesContentCache={sourcesContentCache}
          removeSource={removeSource}
          draftConfig={markdownArtifact?.draftConfig || {}}
          setDraftConfig={setDraftConfig}
          onCompleteInputs={() => {
            if (messagesHistory.length === 0) {
              handleCompleteContextInputs();
            }
          }}
          logComposeEvent={logComposeEvent}
        />
      )
    );
    const lastMessage =
      messagesHistory.length > 0 && messagesHistory[messagesHistory.length - 1];
    const isLastMessageFollowUpSuggestions = !!(
      lastMessage as ComposeAssistantConversationMessageMessage
    ).followUpSuggestions?.length;
    const renderSources = useCallback(() => {
      if (!isWriteApp) {
        return (
          <ConversationMessageSourceChips
            sources={sources}
            removeSource={removeSource}
            logComposeEvent={logComposeEvent}
          />
        );
      }
      return null;
    }, [isWriteApp, removeSource, sources, logComposeEvent]);
    return (
      <>
        <ConversationMessages
          variant={variant}
          renderSources={renderSources}
          isLastMessageFollowUpSuggestions={isLastMessageFollowUpSuggestions}
          startingMessageNode={startingMessageNode}
          messages={messagesToDisplay}
          isWaitingForResponse={isWaitingForResponse}
          progressString={progressString}
          onRemoveSource={removeSource}
          artifacts={artifacts}
          logComposeEvent={logComposeEvent}
          onOpenArtifact={onOpenArtifact}
        />
        {isLastMessageFollowUpSuggestions && (
          <ConversationFollowUpSuggestions
            rightPaneExpanded={rightPaneExpanded}
            message={messagesHistory[messagesHistory.length - 1]}
            onClickFollowUpSuggestion={(suggestion: string) => {
              postUserMessage({
                text: suggestion,
                isFollowUpSuggestion: true,
              });
            }}
          />
        )}
        <ConversationInput
          variant={variant}
          onSubmit={handleSubmitInput}
          inputContext={inputContext}
          setInputContext={setInputContext}
          logComposeEvent={logComposeEvent}
          handleClickAdd={handleClickAdd}
          sources={sources}
          messages={messagesHistory}
        />
        {isAddSourceModalOpen && (
          <AddSourcesModal
            sources={sources}
            sourcesContentCache={sourcesContentCache}
            addSource={addSource}
            removeSource={removeSource}
            onRequestClose={handleCloseAddSourcesModal}
            logComposeEvent={logComposeEvent}
          />
        )}
      </>
    );
  },
);
ComposeConversation.displayName = 'ComposeConversation';

interface ComposeEmptyChatInstructionsProps {
  chatWithDashEnabled: boolean;
}
export const ComposeEmptyChatInstructions = memo(
  ({ chatWithDashEnabled }: ComposeEmptyChatInstructionsProps) => {
    const { addArtifact, addRawMessage, currentSessionID } =
      useComposeCurrentSessionContext();
    const { logComposeEvent } = useComposeAnalyticsContext({
      actionSurfaceComponent: 'compose_chat_pane',
      currentSessionID,
    });
    const handleClickStartingOption = useCallback(
      (option: ClickableEmptyChatInstructionsOption) => {
        logComposeEvent(PAP_Click_AppType({ actionType: option }));
        switch (option) {
          case 'write':
            addArtifact({
              type: 'markdown_draft',
              id: uuidv4(),
              markdownContent: '',
              draftConfig: {},
            });
            return;
          case 'chat_with_dash':
            addRawMessage({
              type: 'instruction',
              role: 'assistant',
              title: i18n.t('assistant_initial_generic_prompt_message'),
            });
            return;
          case 'request_capabilities':
            openURL('https://forms.gle/8NrfeQ58mMAzYr916');
            return;
          default:
            option satisfies never;
            throw new Error(`Unknown option: ${option}`);
        }
      },
      [addArtifact, addRawMessage, logComposeEvent],
    );
    return (
      <EmptyChatInstructions
        chatWithDashEnabled={chatWithDashEnabled}
        onClickOption={handleClickStartingOption}
      />
    );
  },
);
ComposeEmptyChatInstructions.displayName = 'ComposeEmptyChatInstructions';

function getUserMessages(
  messages: ComposeAssistantConversationMessage[],
): ComposeAssistantConversationMessage[] {
  return messages.filter((message) => message.role === 'user');
}

function getTransientSourcesMessage(
  transientSources: TransientSource[] | undefined,
  messagesHistory: ComposeAssistantConversationMessage[],
): ComposeAssistantConversationMessage | undefined {
  // look for transient sources that were never referenced by a message
  const referencedSourcesUUIDs = new Set<string>();
  for (const message of messagesHistory) {
    if (message.type === 'message') {
      for (const source of message.referencingSources || []) {
        const uuid = getSourceUUID(source);
        if (uuid) {
          referencedSourcesUUIDs.add(uuid);
        }
      }
    }
  }
  const unreferencedSources = (transientSources || []).filter(
    (transientSource) => {
      const uuid = getSourceUUID(transientSource.source);
      return uuid && !referencedSourcesUUIDs.has(uuid);
    },
  );
  if (unreferencedSources.length === 0) {
    return undefined;
  }

  // if unreferenced sources exist, return a message to ask user if they want to ask about it
  const source = unreferencedSources[0].source;
  const title =
    getSourceTitle(source) ||
    i18n.t('compose_assistant_message_transient_source_generic_title');
  return {
    type: 'message',
    role: 'assistant',
    text: i18n.t('compose_assistant_message_transient_sources', {
      title,
    }),
    ts: Date.now(),
    referencingSources: [source],
    actionContext: {
      type: 'read_current_browser_tab',
      sourceTitle: title,
    },
  };
}

function getSourceTitle(source: ComposeSource): string | undefined {
  switch (source.type) {
    case 'dash_search_result':
      return source.searchResult.title;
    case 'recommendation':
      return source.recommendation.title;
    case 'transient':
      return source.title;
    default:
      source satisfies never;
      return undefined;
  }
}
