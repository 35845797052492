import { Avatar, AvatarSizes } from '@dropbox/dig-components/avatar';
import { Box } from '@dropbox/dig-foundations';
import classnames from 'classnames';
import styles from './AvatarCluster.module.css';

type AvatarClusterSize = 'small' | 'medium';

type AvatarProps = {
  src?: string;
  initials?: string;
  isGuest?: boolean;
  hasNoOutline?: boolean;
  className?: string;
};

type AvatarClusterProps = {
  // an list of up to 3 image sources to use in the avatar
  avatars: AvatarProps[];
  size?: AvatarClusterSize;
};

export const AvatarCluster = ({
  avatars,
  size = 'medium',
}: AvatarClusterProps) => {
  const sizeMap = sizesMap[size] || sizesMap.medium;
  const sizes = sizeMap[avatars.length];
  const countClassName = { 1: styles.one, 2: styles.two, 3: styles.three }[
    avatars.length
  ];

  const avatarPile = avatars.map((props, i) => {
    const size = sizes[i];
    const className = classnames(
      styles.avatar,
      countClassName,
      props.className,
    );
    const isGuest = props.isGuest || false;
    if (isGuest) {
      return (
        <Avatar
          key={i}
          size={size}
          className={className}
          hasNoOutline={!!props.hasNoOutline}
          isGuest
        ></Avatar>
      );
    }
    return (
      <Avatar
        key={i}
        size={size}
        className={className}
        src={props.src}
        hasNoOutline={!!props.hasNoOutline}
      >
        {props.initials}
      </Avatar>
    );
  });

  return (
    <Box className={classnames(styles.container, styles[`size-${size}`])}>
      {avatarPile}
    </Box>
  );
};

const sizesMap: Record<AvatarClusterSize, Record<number, AvatarSizes[]>> = {
  small: {
    1: ['medium'],
    2: ['small', 'small'],
    3: ['xsmall', 'xsmall', 'xsmall'],
  },
  medium: {
    1: ['large'],
    2: ['medium', 'medium'],
    3: ['small', 'xsmall', 'xsmall'],
  },
};
