import { Text } from '@dropbox/dig-components/typography';
import { useTheme } from '@dropbox/dig-foundations';
import { createUxaElementId } from '@mirage/analytics/uxa';
import { SEARCH_DASH_UUID } from '@mirage/shared/search/cache-result';
import tokenize from '@mirage/shared/search/tokenizers';
import classNames from 'classnames';
import {
  ForwardedRef,
  forwardRef,
  MouseEvent,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import { Highlighter } from './Highlighter';
import { LoadingResult } from './LoadingResult';
import styles from './TypeaheadResultRow.module.css';

import type { TypeaheadResult } from '@mirage/mosaics/SearchBarWithTypeahead/useConvertToTypeaheadResults';

type Props = {
  result: TypeaheadResult;
  disabled?: boolean;
  isFocused?: boolean;
  isSelected?: boolean;
  /** called when this item is hovered for use with the copy-url functionality */
  setIsHovered?: () => void;
  setIsUnhovered?: () => void;
  onClick: (e: MouseEvent<HTMLLIElement>) => void;
  query: string;
  observer?: IntersectionObserver;
  isMouseActive?: boolean;
  fullWidth?: boolean;
  resultRowIndex: number;
};

type MaybeLiEl = HTMLLIElement | null;

/**
 * TODO
 *   - Add search query highlighting
 */
const TypeaheadResultRow = forwardRef<HTMLLIElement, Props>(
  (
    {
      result,
      disabled = false,
      isFocused = false,
      isSelected = false,
      setIsHovered,
      setIsUnhovered,
      onClick,
      query,
      observer,
      resultRowIndex,
      isMouseActive = true,
      fullWidth = false,
    }: Props,
    liRef: ForwardedRef<HTMLLIElement>,
  ) => {
    const [wrapperEl, setWrapperEl] = useState<MaybeLiEl>(null);
    useImperativeHandle<MaybeLiEl, MaybeLiEl>(liRef, () => wrapperEl);
    const { title, subtitle, icon } = result;
    const isDarkMode = useTheme();

    // Observe and unobserve this paragraph
    useEffect(() => {
      if (observer) {
        if (wrapperEl) {
          observer.observe(wrapperEl);
        }
        return () => {
          if (wrapperEl) {
            observer.unobserve(wrapperEl);
          }
        };
      }
    }, [observer, wrapperEl]);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const onSetWrapperEl = (el: any) => {
      setWrapperEl(el);
      liRef = el;
    };

    return (
      // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions
      <li
        className={classNames(styles.resultContainer, {
          [styles.mouseActive]: isMouseActive,
          [styles.selected]: isSelected || isFocused,
          [styles.darkMode]: isDarkMode?.mode === 'dark',
          [styles.fullWidthSearchbar]: fullWidth,
        })}
        onClick={onClick}
        onMouseEnter={() => {
          setIsHovered?.();
        }}
        onMouseLeave={() => {
          setIsUnhovered?.();
        }}
        ref={onSetWrapperEl}
        data-testid="Typeahead-resultListItem"
        data-x-uuid={result.uuid}
        data-uxa-log={createUxaElementId(
          result.uuid === SEARCH_DASH_UUID
            ? 'default_typeahead_result_row'
            : 'typeahead_result_row',
          {
            actionSurface: 'search_typeahead',
            actionSurfaceComponent: 'search_dropdown',
            featureLine: 'search',
          },
        )}
        role="option"
        id={`typeahead-option-${resultRowIndex}`}
        aria-selected={isSelected}
      >
        <div
          className={classNames(styles.iconContainer, {
            [styles.fullWidthSearchbar]: fullWidth,
          })}
        >
          {icon}
        </div>
        <Text
          color={disabled ? 'faint' : 'standard'}
          className={styles.metadataTitleContainer}
        >
          {typeof title === 'string' ? (
            <Highlighter
              title={title}
              // always match the full default result to prevent flashing during fetch
              highlightWords={
                result.uuid === SEARCH_DASH_UUID
                  ? [title]
                  : [...tokenize(query)]
              }
            />
          ) : (
            title
          )}

          {subtitle && (
            <div>
              <Text color="faint">{subtitle}</Text>
            </div>
          )}
        </Text>
        <TypeaheadRowAccessoryItem
          isMouseActive={isMouseActive}
          result={result}
          isSelected={isSelected}
        />
      </li>
    );
  },
);
TypeaheadResultRow.displayName = 'TypeaheadResultRow';

function TypeaheadRowAccessoryItem({
  result,
  isSelected,
  isMouseActive,
}: {
  result: TypeaheadResult;
  isSelected: boolean;
  isMouseActive: boolean;
}) {
  const hasAccessory = Boolean(result.accessory);
  const hasHoverAccessory = Boolean(result.hoverAccessory);
  const isSelectedWithAccessory = isSelected && result.selectedAccessory;

  if (hasHoverAccessory && isMouseActive) {
    return (
      <>
        <div
          className={classNames(styles.rowAccessory, styles.onlyShowOnHover)}
        >
          {result.hoverAccessory}
        </div>

        {hasAccessory ? (
          <div
            className={classNames(
              styles.rowAccessory,
              styles.onlyShowNotHovered,
            )}
          >
            {isSelectedWithAccessory
              ? result.selectedAccessory
              : result.accessory}
          </div>
        ) : null}
      </>
    );
  } else if (isSelectedWithAccessory) {
    return (
      <div className={styles.rowAccessory}>{result.selectedAccessory}</div>
    );
  } else if (hasAccessory) {
    return <div className={styles.rowAccessory}>{result.accessory}</div>;
  }

  return null;
}

export default TypeaheadResultRow;

export const LoadingResultRow = () => (
  <div className={styles.resultContainer}>
    <LoadingResult />
  </div>
);
