/*
  Copied from rSERVER/metaserver/static/js/core/user_i18n.ts
  Modifications:
    - Custom `isCjkLocale` implementation
    - Removed punctuation from the `displayName` before processing
    - Added period after last initial in `getShortName`
*/

/**
 * See get_abbreviated_name_static in user_i18n.py
 * Simple native way to grab initials from display name
 */
export function getInitials(displayName: string): string {
  if (!displayName) {
    return '';
  }

  displayName = removePunctuation(displayName);

  // use all upper-case for initials
  displayName = displayName.toUpperCase();

  // Split the parts of the name by spaces.
  const nameParts = displayName.trim().split(' ');

  // Get the UTF 16 chars of the first and the last parts of the name.
  const nameFirstPart = nameParts[0];
  const nameFirstPartCharsArray =
    spliceStringToUtf16CodePointChars(nameFirstPart);
  const nameLastPart = nameParts[nameParts.length - 1];
  const nameLastPartCharsArray =
    spliceStringToUtf16CodePointChars(nameLastPart);

  if (nameParts.length >= 2) {
    // Get first character from the first and last parts
    if (!isCjkLocale(displayName)) {
      return nameFirstPartCharsArray[0] + nameLastPartCharsArray[0];
    } else {
      // Otherwise, the user is from a CJK locale. Reverse the initials since the family name appears first.
      return nameLastPartCharsArray[0] + nameFirstPartCharsArray[0];
    }
  }

  // The remaining cases deal with single part names
  // Get the UTF 16 chars of the name.
  const displayNameCharsArray = spliceStringToUtf16CodePointChars(displayName);

  // The user is from a CJK locale and has non-ascii characters in their name
  if (
    isCjkLocale(displayName) &&
    !isAsciiName(displayName) &&
    displayNameCharsArray.length > 1
  ) {
    return displayNameCharsArray[0] + displayNameCharsArray[1];
  } else {
    return displayNameCharsArray[0];
  }
}

/**
 * Returns the user's first name and last initial, grabbing it from displayName.
 * It is based closely on getInitials, which is based on get_abbreviated_name_static.
 */
export function getShortName(displayName: string): string {
  if (!displayName) {
    return '';
  }

  displayName = removePunctuation(displayName);

  const nameParts = displayName.trim().split(' ');
  if (nameParts.length < 2) {
    // For single-part names, we just return the whole thing.
    return displayName;
  }
  const nameFirstPart = nameParts[0];
  const nameLastPart = nameParts[nameParts.length - 1];
  const nameLastPartCharsArray =
    spliceStringToUtf16CodePointChars(nameLastPart);

  // Otherwise, we have a multi-part name.
  if (!isCjkLocale(displayName)) {
    return nameFirstPart + ' ' + nameLastPartCharsArray[0] + '.';
  } else {
    // A CJK locale; the family name comes first.
    return nameLastPartCharsArray[0] + ' ' + nameFirstPart;
  }
}

function isAsciiName(name: string): boolean {
  for (let i = 0; i < name.length; i++) {
    if (name.charCodeAt(i) >= 128) {
      return false;
    }
  }
  return true;
}

export function isCjkLocale(name: string): boolean {
  return /\p{Script=Han}/u.test(name);
}

/**
 * Splice a string into its correct UTF 16 Characters. It handles correctly UTF 16 chars in the
 * Unicode supplementary planes (having code points higher than 0xFFFF), like Emojis, that
 * consists of 2 UTF 16 words, called surrogate chars. It also supports correctly chars
 * in the regular Unicode Basic Multilingual Plane (BMP), consist of a single UTF 16 word.
 * https://mathiasbynens.be/notes/javascript-unicode
 */
function spliceStringToUtf16CodePointChars(str: string): string[] {
  const codePointCharsArray: string[] = [];

  const strLength = str.length;
  let currentUtf16WordIndex = 0;

  // Scan the UTF 16 words of the input string.
  while (currentUtf16WordIndex < strLength) {
    // Get the current UTF 16 word of the input string.
    const currentUtf16Word = str[currentUtf16WordIndex];

    // Check the type of the current UTF 16 word.
    if (currentUtf16Word >= '\uD800' && currentUtf16Word <= '\uDB7F') {
      // The current UTF 16 word is a high surrogate char in a surrogate pair.
      // The actual UTF 16 char consists of 2 UTF 16 words.
      if (currentUtf16WordIndex < strLength - 1) {
        // This is a safety check. If the current word is a high surrogate char, there must be a
        // second one, and it should be the low surrogate char.
        const nextUtf16Word = str[currentUtf16WordIndex + 1];
        const currentUtf16CodePointChar = currentUtf16Word + nextUtf16Word;
        codePointCharsArray.push(currentUtf16CodePointChar);
        currentUtf16WordIndex += 2;
      } else {
        // We should never get here for a well formatted strings.
        break;
      }
    } else {
      // The current UTF 16 word is a regular UTF 16 char in the Basic Multilingual Plane.
      codePointCharsArray.push(currentUtf16Word);
      currentUtf16WordIndex += 1;
    }
  }

  return codePointCharsArray;
}

function removePunctuation(displayName: string): string {
  const clean = displayName.replace(/[\p{P}\p{S}]/gu, '');
  return clean.trim().length ? clean : displayName;
}
