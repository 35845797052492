import { URLShortcut } from '@mirage/shared/search/url-shortcut';
import { managedShortcuts } from '../../utils/managed-shortcuts';

import type { MemoryCache } from '@mirage/service-typeahead-search/service/typeahead-cache/subcaches';

export const URL_SHORTCUTS_CACHE_LIMIT = managedShortcuts.length;

class URLShortcutsCache implements MemoryCache<URLShortcut> {
  private cache: URLShortcut[] = [];

  private static dedupeURLShortcuts(results: URLShortcut[]): URLShortcut[] {
    const seen = new Set();
    return results.filter(({ uuid }) => {
      if (seen.has(uuid)) return false;
      seen.add(uuid);
      return true;
    });
  }

  all() {
    return this.cache;
  }

  addItems(items: URLShortcut[] = []): Promise<URLShortcut[]> {
    this.cache = URLShortcutsCache.dedupeURLShortcuts([
      ...items,
      ...this.cache,
    ]).slice(0, URL_SHORTCUTS_CACHE_LIMIT);
    return Promise.resolve(this.cache);
  }

  removeItem(item: URLShortcut): Promise<URLShortcut[]> {
    this.cache = this.cache.filter((cacheItem) => cacheItem.uuid != item.uuid);
    return Promise.resolve(this.cache);
  }

  clear() {
    this.cache = [];
  }

  count() {
    return this.cache.length;
  }
}

export default URLShortcutsCache;
