import { Banner } from '@dropbox/dig-components/banner';
import { Button } from '@dropbox/dig-components/buttons';
import { Text, Title } from '@dropbox/dig-components/typography';
import { GlobalBannerProps } from '@mirage/mosaics/GlobalBanners/GlobalBannersProps';
import useDropboxAccount from '@mirage/service-auth/useDropboxAccount';
import { openURL } from '@mirage/service-platform-actions';
import { getMeticulousState } from '@mirage/shared/meticulous';
import i18n from '@mirage/translations';
import { useEffect, useState } from 'react';

export const dropboxMeticulousSlackURL =
  'https://dropbox.enterprise.slack.com/archives/C07U8GU0CNM';
export const localStorageDismissedAccountsKey =
  'meticulousRecordingBannerDismissedAccounts';
export const meticulousSessionsURL =
  'https://app.meticulous.ai/projects/Dropbox/dash?tab=sessions';

function getDismissedAccountsFromLocalStorage(): Set<string> {
  const dismissedAccounts =
    localStorage.getItem(localStorageDismissedAccountsKey) || '[]';
  return new Set<string>(JSON.parse(dismissedAccounts));
}

function setDismissedAccountsInLocalStorage(dismissedAccounts: Set<string>) {
  localStorage.setItem(
    localStorageDismissedAccountsKey,
    JSON.stringify(Array.from(dismissedAccounts)),
  );
}

export type MeticulousRecordingBannerProps = GlobalBannerProps & {
  getDismissedAccounts?: () => Set<string>;
  setDismissedAccounts?: (dismissedAccounts: Set<string>) => void;
};

type MeticulousRecordingBannerState = {
  accountId?: string;
  isRecording: boolean;
  isDismissed: boolean;
  interval: number | null;
};

export function MeticulousRecordingBanner({
  getDismissedAccounts = getDismissedAccountsFromLocalStorage,
  setDismissedAccounts = setDismissedAccountsInLocalStorage,
  ...props
}: MeticulousRecordingBannerProps) {
  const [state, setState] = useState<MeticulousRecordingBannerState>({
    accountId: undefined,
    isRecording: getMeticulousState().isEnabled,
    isDismissed: false,
    interval: null,
  });
  const account = useDropboxAccount();

  const handleDismissClick = () => {
    if (account?.public_account_id) {
      // Save public account id to localstorage to prevent banner from showing again.
      const dismissedAccounts = getDismissedAccounts();
      dismissedAccounts.add(account.public_account_id);
      setDismissedAccounts(dismissedAccounts);
    }

    if (state.interval) {
      // Stop polling whether meticulous is recording.
      window.clearInterval(state.interval);
    }

    setState((state) => ({
      ...state,
      isDismissed: true,
      interval: null,
    }));
  };

  useEffect(() => {
    // Start polling whether meticulous is recording.
    const interval = window.setInterval(() => {
      setState((state) => ({
        ...state,
        isRecording: getMeticulousState().isEnabled,
      }));
    }, 1000);

    // Save the interval to clear it on banner dismissal and component dismount.
    setState((state) => ({ ...state, interval }));

    return () => {
      if (state.interval) clearInterval(state.interval);
    };
  }, []);

  useEffect(() => {
    // Check whether banner has been dismissed for the account.
    const accountId = account?.public_account_id;
    const isDismissed = !!accountId && getDismissedAccounts().has(accountId);

    setState((state) => ({ ...state, accountId, isDismissed }));
  }, [account, getDismissedAccounts]);

  // Render when account id is available, meticulous is recording, and the banner is not dismissed.
  if (!state.accountId || !state.isRecording || state.isDismissed) {
    return null;
  }

  return (
    <div className={props.bannerClass}>
      <Banner variant="rich" type="alert" withLeftPictogram={null}>
        <Banner.Message>
          <Title>{i18n.t('meticulous_recording_banner_title')}</Title>
          <Text>{i18n.t('meticulous_recording_banner_text')}</Text>
        </Banner.Message>
        <Banner.Actions>
          <Button variant="outline" onClick={handleDismissClick}>
            {i18n.t('dismiss')}
          </Button>
          <Button
            onClick={() => openURL(meticulousSessionsURL)}
            variant="transparent"
          >
            {i18n.t('meticulous_view_sessions')}
          </Button>
          <Button
            onClick={() => openURL(dropboxMeticulousSlackURL)}
            variant="transparent"
          >
            {i18n.t('meticulous_request_deletions')}
          </Button>
        </Banner.Actions>
      </Banner>
    </div>
  );
}
