import { tagged } from '@mirage/service-logging';
import { copyToClipboard } from '@mirage/service-platform-actions';
import { typeahead } from '@mirage/service-typeahead-search/service/types';
import { getConnectorDisplayNameFromResult } from '@mirage/shared/connectors';
import {
  extractTrailingSearchFilter,
  SearchFilter,
  SearchFilterType,
} from '@mirage/shared/search/search-filters';
import { KeyCodes } from '@mirage/shared/util/constants';
import { usePrevious } from '@mirage/shared/util/hooks';
import i18n from '@mirage/translations';
import {
  type RefObject,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react';

import type { TypeaheadResult } from '@mirage/mosaics/SearchBarWithTypeahead/useConvertToTypeaheadResults';

const logger = tagged('useTypeaheadAutocomplete');

/** the max length we will autocomplete to and show in the CTA */
const MAX_AUTOCOMPLETE_LENGTH = 80;
/** if query is below this, we don't autocomplete or show a CTA or anything */
const MIN_QUERY_LENGTH = 1;

/**
 * Handles the logic for inline autocomplete and inline CTAs in the search input.
 *
 * This had to use some tricky data flow and some hacks to get around react 17 and
 * prevent infinite loops. A diagram of the data flow can be found at:
 * https://dropbox.atlassian.net/wiki/spaces/Dash/pages/1611794096/mirage+search+search+input+autocomplete
 */
export function useTypeaheadAutocomplete(
  /** The query as react knows it, this should never include the autocompleted text */
  query: string,
  searchResults: TypeaheadResult[] | null,
  selectedIdx: number,
  inputRef: RefObject<HTMLInputElement>,
  typeaheadIsOpen: boolean,
  wasAutocompleteRemoved: boolean,
  /**
   * Called when the user presses backspace to clear out the autocompleted portion
   * used to set the priority of the search dash result over the launch (until the user types again)
   *
   * This will be reset every time the user types a new character!
   */
  onAutocompleteRemoved: (autocompleteRemoved: boolean) => void,
  filters: SearchFilter[],
) {
  //
  // Hooks
  //

  const previousSelectedIdx = usePrevious(selectedIdx);

  //
  // State
  //

  const isSelectedResultSuggestedQuery =
    searchResults?.[selectedIdx]?.scoredResult?.type ===
    typeahead.ResultType.SuggestedQuery;
  const isSelectedResultSearchFilter =
    searchResults?.[selectedIdx]?.scoredResult?.type ===
    typeahead.ResultType.SearchFilter;

  //
  //// Build Autocomplete Text ////
  //

  const buildAutocompleteText = useCallback(() => {
    const getAutocompleteCandidateText = (
      targetResult?: TypeaheadResult,
    ): string | undefined => {
      if (!targetResult?.scoredResult) return;
      if (
        isSelectedResultSearchFilter &&
        !extractTrailingSearchFilter(query).filterType
      ) {
        return;
      }
      return getInlineAutocompleteText(targetResult.scoredResult);
    };

    const getInlineAutocompletePortion = (
      autocompleteCandidateText?: string,
    ): string | undefined => {
      if (!autocompleteCandidateText) return;

      const indexToSlice = isSelectedResultSearchFilter
        ? extractTrailingSearchFilter(query).query.length
        : query.length;

      return autocompleteCandidateText.slice(
        indexToSlice,
        MAX_AUTOCOMPLETE_LENGTH * 2, // double it here because we use css to apply a nice ellipsis so it takes up the right amount of space
      );
    };

    const checkIfAutocompleteShouldBeApplied = (
      autocompleteCandidateText?: string,
    ): boolean => {
      /** Typeahead must be open */
      if (!typeaheadIsOpen) return false;

      /** Don't autocomplete for queries that are too short */
      if (query.length < MIN_QUERY_LENGTH) return false;

      /** Don't autocomplete for search dash */
      if (isSelectedResultSuggestedQuery) return false;

      /** Autocomplete text does not exist */
      if (!autocompleteCandidateText) return false;

      /** Query is a prefix of the autocompleted text */
      if (
        !isSelectedResultSearchFilter &&
        !autocompleteCandidateText.toLowerCase().startsWith(query.toLowerCase())
      ) {
        return false;
      }

      /** Current query is shorter or equal to the autocomplete text (i.e. the user isn't typing past the autocompleted text) */
      if (
        !isSelectedResultSearchFilter &&
        query.length > autocompleteCandidateText.length
      ) {
        return false;
      }

      /** Selected idx is search filter but the trailing text is not a valid search filter type */
      if (
        isSelectedResultSearchFilter &&
        !extractTrailingSearchFilter(query).filterType
      )
        return false;

      /** Either the user is typing normally and not backspacing */
      if (
        wasAutocompleteRemoved &&
        selectedIdx === 0 &&
        previousSelectedIdx === 0
      ) {
        return false;
      }

      return true;
    };

    // if search is the item selected, we want to autocomplete to the second
    // item as that is what will be autocompleted-to if the user continues typing
    const targetResult = isSelectedResultSuggestedQuery
      ? searchResults?.[1]
      : searchResults?.[selectedIdx];

    // we need the string "unmodified" values, so we are using the scoredResult
    // rather than the typeahead result which can be all JSX and doesn't include
    // score info
    const autocompleteCandidateText =
      getAutocompleteCandidateText(targetResult);

    const inlineAutocompletePortion = getInlineAutocompletePortion(
      autocompleteCandidateText,
    );

    const shouldAutocomplete = checkIfAutocompleteShouldBeApplied(
      autocompleteCandidateText,
    );

    return {
      shouldAutocomplete,
      inlineAutocompletePortion,
      autocompleteCandidateText,
    };
  }, [
    previousSelectedIdx,
    query,
    searchResults,
    selectedIdx,
    isSelectedResultSearchFilter,
    isSelectedResultSuggestedQuery,
    typeaheadIsOpen,
    wasAutocompleteRemoved,
  ]);

  const {
    shouldAutocomplete,
    inlineAutocompletePortion,
    autocompleteCandidateText,
  } = buildAutocompleteText();

  //
  //// Append Autocomplete Text ////
  //

  let newFullInputValue = query;
  if (shouldAutocomplete) {
    newFullInputValue += inlineAutocompletePortion;
    logger.debug(`Autocompleting users query`);
  }

  //
  //// Apply Text Highlighting ////
  //

  useLayoutEffect(() => {
    if (inputRef.current && shouldAutocomplete) {
      inputRef.current.setSelectionRange(
        query.length,
        newFullInputValue.length,
      );
      logger.debug('Applied highlighting for Autocomplete');
    }
  }, [inputRef, newFullInputValue.length, query.length, shouldAutocomplete]);

  //
  //// Build InlineCTA ////
  //

  const buildInlineCTA = useCallback(
    function () {
      const ctaResult = searchResults?.[selectedIdx] ?? searchResults?.[0];

      const shouldCTACoverQuery =
        !shouldAutocomplete &&
        !isSelectedResultSuggestedQuery &&
        !isSelectedResultSearchFilter &&
        selectedIdx !== 0;

      const hasInlineCTAText =
        typeaheadIsOpen &&
        query.length >= MIN_QUERY_LENGTH &&
        !!ctaResult?.scoredResult;

      let inlineCTAText!: string;

      if (hasInlineCTAText) {
        inlineCTAText = getInlineCTAText(ctaResult!.scoredResult);
      }

      const shouldHaveFakeAutocompleteText =
        shouldCTACoverQuery && autocompleteCandidateText;

      const fakeAutocompleteText: string = shouldHaveFakeAutocompleteText
        ? sliceWithEllipsis(autocompleteCandidateText, MAX_AUTOCOMPLETE_LENGTH)
        : '';

      return { shouldCTACoverQuery, inlineCTAText, fakeAutocompleteText };
    },
    [
      autocompleteCandidateText,
      query.length,
      searchResults,
      selectedIdx,
      isSelectedResultSearchFilter,
      isSelectedResultSuggestedQuery,
      shouldAutocomplete,
      typeaheadIsOpen,
    ],
  );

  const { shouldCTACoverQuery, inlineCTAText, fakeAutocompleteText } =
    buildInlineCTA();

  //
  //// Calculate InlineCTA Position and Suffix Position ////
  //

  const [leftOffset, setLeftOffset] = useState(0);

  useLayoutEffect(() => {
    if (!inputRef.current) return;
    // Track left-offset when filters are updated
    setLeftOffset(inputRef.current.offsetLeft);
  }, [inputRef, filters]);

  const suffixPosition = useMemo(() => {
    if (!inlineCTAText || !inputRef.current) return null;

    if (shouldCTACoverQuery) {
      // just go ahead and cover the query with the CTA to simulate the effect
      // of replacing the whole input
      return leftOffset;
    }

    const textWidth = calculateTextWidth(newFullInputValue, inputRef.current);
    return textWidth + leftOffset + 1;
  }, [
    inlineCTAText,
    inputRef,
    leftOffset,
    newFullInputValue,
    shouldCTACoverQuery,
  ]);

  //
  //// Calculate Selected Icon ////
  //

  const getSearchResultIcon = () => {
    const result = searchResults?.[selectedIdx];
    if (!result) return null;

    if (
      result.type === typeahead.ResultType.SuggestedQuery ||
      result.type === typeahead.ResultType.PreviousQuery
    ) {
      return (
        searchResults.find(
          (result) => result.type === typeahead.ResultType.SuggestedQuery,
        )?.icon || null
      );
    }
    return result.icon;
  };

  //
  //// Handle calling onAutocompleteRemoved on Backspace ////
  //

  const onSearchInputKeyDown = useCallback(
    // this should stay as keydown event to prevent pressing and holding
    // backspace from acting weird
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      const { selectionStart, selectionEnd } = event.target as HTMLInputElement;
      if (
        !wasAutocompleteRemoved &&
        // this ensures the user hasn't changed selection. If they did, all
        // bets are off, and we shouldn't prevent the default behavior
        selectionStart === query.length &&
        selectionEnd === newFullInputValue.length
      ) {
        if (event.key === KeyCodes.backspace) {
          // one final check to ensure there is something actually highlighted
          if (selectionStart !== selectionEnd) {
            event.preventDefault();
          }

          logger.debug(
            'Autocomplete disabled and removed as the user typed backspace',
          );
          onAutocompleteRemoved(true);
        }
      } else if (
        (event.metaKey || event.ctrlKey) &&
        event.key === 'c' &&
        selectionStart != null &&
        selectionEnd != null &&
        selectionStart !== selectionEnd
      ) {
        // prevent the copy-url hotkey handler from firing if the user has highlighted text differently from the autocomplete
        event.preventDefault();
        event.stopPropagation();

        logger.debug(
          'User is not autocompleting and has text highlighted, copying just the highlighted text',
        );

        copyToClipboard(
          inputRef.current!.value.slice(selectionStart, selectionEnd),
        );
      }
    },
    [
      newFullInputValue,
      onAutocompleteRemoved,
      query.length,
      wasAutocompleteRemoved,
    ],
  );

  //
  //// Handle Re-enabling Autocomplete ////
  //

  const previousNewFullInputValue = usePrevious(newFullInputValue);
  useLayoutEffect(() => {
    if (
      (previousNewFullInputValue?.length ?? 0) < newFullInputValue.length &&
      newFullInputValue.length > MIN_QUERY_LENGTH &&
      wasAutocompleteRemoved
    ) {
      logger.debug('Autocompletion re-enabled as the user kept typing');
      onAutocompleteRemoved(false);
    }
  }, [
    onAutocompleteRemoved,
    previousNewFullInputValue?.length,
    newFullInputValue.length,
    wasAutocompleteRemoved,
  ]);

  const onInputMouseDown = useCallback(() => {
    if (
      inputRef.current &&
      fakeAutocompleteText.length &&
      inlineCTAText &&
      shouldCTACoverQuery
    ) {
      inputRef.current.value = fakeAutocompleteText;
    }
  }, [fakeAutocompleteText, inlineCTAText, shouldCTACoverQuery, inputRef]);

  useEffect(() => {
    if (!inputRef.current) return;

    // Ensures it will be available in cleanup
    const inputElement = inputRef.current;

    inputElement.addEventListener('mousedown', onInputMouseDown);

    return () => {
      inputElement.removeEventListener('mousedown', onInputMouseDown);
    };
  }, [onInputMouseDown, inputRef]);

  return {
    inputValue: newFullInputValue,
    onSearchInputKeyDown,
    inlineCTAText,
    suffixPosition,
    shouldAutocomplete,
    fakeAutocompleteText,
    selectedIcon: getSearchResultIcon(),
    coverCTA: shouldCTACoverQuery,
    shouldCTACoverQuery,
  };
}

/**
 * This should only return a string for types that support inline autocompletion
 */
function getInlineAutocompleteText(
  typeaheadResult: typeahead.ScoredResult,
): string {
  switch (typeaheadResult?.type) {
    case typeahead.ResultType.DesktopFile:
      return typeaheadResult.result.title;
    case typeahead.ResultType.DesktopApplication:
      return typeaheadResult.result.title;
    case typeahead.ResultType.Stack:
      return (
        typeaheadResult.result.stack_data?.name ||
        i18n.t('stacks_typeahead_default_title')
      );
    case typeahead.ResultType.StackItem:
      return typeaheadResult.result.name;
    case typeahead.ResultType.SearchResult:
      return typeaheadResult.result.title;
    case typeahead.ResultType.Recommendation:
      return typeaheadResult.result.title;
    case typeahead.ResultType.PreviousQuery:
      return typeaheadResult.result.query;
    case typeahead.ResultType.URLShortcut:
      return typeaheadResult.result.parameters.activeHotword;
    case typeahead.ResultType.SearchFilter:
      return typeaheadResult.result.id;
    default:
      typeaheadResult.type satisfies
        | typeahead.ResultType.MathCalculation
        | typeahead.ResultType.SuggestedQuery;
      return '';
  }
}

/**
 * This should only return a string for types that support an inline CTA
 */
function getInlineCTAText(typeaheadResult: typeahead.ScoredResult): string {
  switch (typeaheadResult.type) {
    case typeahead.ResultType.Recommendation:
    case typeahead.ResultType.SearchResult: {
      const appName = getConnectorDisplayNameFromResult(typeaheadResult.result);
      return ' — ' + i18n.t('search_inline_cta_search_result', { appName });
    }
    case typeahead.ResultType.DesktopFile:
      return ' — ' + i18n.t('search_inline_cta_local_file');
    case typeahead.ResultType.Stack:
      return ' — ' + i18n.t('search_inline_cta_stack');
    case typeahead.ResultType.StackItem:
      return ' — ' + i18n.t('search_inline_cta_stack_item');
    case typeahead.ResultType.DesktopApplication:
      return ' — ' + i18n.t('search_inline_cta_desktop_app');
    case typeahead.ResultType.SuggestedQuery:
    case typeahead.ResultType.PreviousQuery:
      return ' — ' + i18n.t('search_dash_byline');
    case typeahead.ResultType.URLShortcut:
      return ' — ' + i18n.t('search_inline_cta_shortcut');
    case typeahead.ResultType.MathCalculation:
      return ' = ' + typeaheadResult.result.answer;
    case typeahead.ResultType.SearchFilter:
      switch (typeaheadResult.result.type) {
        case SearchFilterType.Connector:
          return ' — ' + typeaheadResult.result.parameters.displayName;
        case SearchFilterType.ContentType:
          return ' — ' + typeaheadResult.result.parameters.label;
        case SearchFilterType.LastUpdated:
          return ' — ' + typeaheadResult.result.parameters.title;
        case SearchFilterType.Person:
          return ' — ' + typeaheadResult.result.parameters.displayName;
        default:
          typeaheadResult.result satisfies never;
          return '';
      }
    default:
      typeaheadResult satisfies never;
      return '';
  }
}

const canvas = new OffscreenCanvas(window.outerWidth, 200);
const context = canvas.getContext('2d')!;

/**
 * Used to calculate the width of text in the input box by using an offscreen canvas.
 *
 * I timed this and it took an average of 0.1ms to calculate the width of a 250 character string
 **/
function calculateTextWidth(text: string, input: HTMLInputElement) {
  context.font = window.getComputedStyle(input).font;
  return context.measureText(text).width;
}

function sliceWithEllipsis<T extends string | null | undefined>(
  text: T,
  maxLength: number,
) {
  if (!text || text.length <= maxLength) return text;
  return text.slice(0, maxLength).trim() + '...';
}
