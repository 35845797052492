import { TableCellNode, TableNode, TableRowNode } from '@lexical/table';
import { tagged } from '@mirage/service-logging';

const logger = tagged('tableUtils');

export const moveTableNodes = (
  table: TableNode,
  direction: 'row' | 'column',
  sourceIndex: number,
  targetIndex: number,
): void => {
  if (sourceIndex === targetIndex) {
    return;
  }

  if (direction === 'row') {
    const rows = table.getChildren();
    if (
      sourceIndex < 0 ||
      sourceIndex >= rows.length ||
      targetIndex < 0 ||
      targetIndex >= rows.length
    ) {
      const message = 'moveTableNodes: Invalid source or target row index';
      logger.error(message);
      throw new Error(message);
    }

    const sourceRow = rows[sourceIndex];
    if (!sourceRow || !(sourceRow instanceof TableRowNode)) {
      const message = 'moveTableNodes: Invalid source row';
      logger.error(message);
      throw new Error(message);
    }

    sourceRow.remove();
    const targetRow = rows[targetIndex];
    if (targetRow && targetRow instanceof TableRowNode) {
      if (targetIndex > sourceIndex) {
        targetRow.insertAfter(sourceRow);
      } else {
        targetRow.insertBefore(sourceRow);
      }
    } else {
      table.append(sourceRow);
    }
  } else {
    const firstRow = table.getFirstChild();
    if (!(firstRow instanceof TableRowNode)) {
      const message = 'moveTableNodes: Invalid first row';
      logger.error(message);
      throw new Error(message);
    }

    const columnCount = firstRow.getChildrenSize();
    if (
      sourceIndex < 0 ||
      sourceIndex >= columnCount ||
      targetIndex < 0 ||
      targetIndex >= columnCount
    ) {
      const message = 'moveTableNodes: Invalid source or target column index';
      logger.error(message);
      throw new Error(message);
    }

    for (const row of table.getChildren()) {
      if (!(row instanceof TableRowNode)) {
        const message = 'moveTableNodes: Invalid row';
        logger.error(message);
        throw new Error(message);
      }

      const cells = row.getChildren();
      const sourceCell = cells[sourceIndex];
      if (!sourceCell || !(sourceCell instanceof TableCellNode)) {
        const message = 'moveTableNodes: Invalid source cell';
        logger.error(message);
        throw new Error(message);
      }

      sourceCell.remove();
      const targetCell = cells[targetIndex];
      if (targetCell && targetCell instanceof TableCellNode) {
        if (targetIndex > sourceIndex) {
          targetCell.insertAfter(sourceCell);
        } else {
          targetCell.insertBefore(sourceCell);
        }
      } else {
        row.append(sourceCell);
      }
    }
  }
};

export const getRowsFromTable = (table: TableNode) =>
  Array.from(table.getChildren()).filter(
    (row): row is TableRowNode => row instanceof TableRowNode,
  );
