import { useCallback } from 'react';

type CopyCallback = (selection: string) => void;

/**
 * Returns a callback to be used with ref.current that attaches copy event listeners
 * and notifies the callback if the copied text comes from inside the element.
 */
export function useClipboardCopyListenerOnRef(onCopy: CopyCallback) {
  const handleClipboardCopy = useCallback(
    (container: HTMLElement) => {
      function listener() {
        const selection = document.getSelection()?.toString()?.trim();
        if (
          selection &&
          container?.contains(window.getSelection()?.focusNode as Node)
        ) {
          onCopy(selection);
        }
      }

      container.addEventListener('copy', listener);
      return () => container.removeEventListener('copy', listener);
    },
    [onCopy],
  );

  return handleClipboardCopy;
}
