import { Button } from '@dropbox/dig-components/buttons';
import { Modal } from '@dropbox/dig-components/modal';
import { Text } from '@dropbox/dig-components/typography';
import i18n, { Trans } from '@mirage/translations';

export const AddLinkToStackWithConfirmation: React.FC<{
  hideModal: () => void;
  url: string;
  addLinkToStackFn: () => void;
}> = ({ hideModal, url, addLinkToStackFn }) => {
  return (
    <Modal
      open
      width="small"
      withCloseButton="Close"
      onRequestClose={hideModal}
    >
      <Modal.Header hasBottomSpacing="title-small">
        <Modal.Title size="small">
          {i18n.t('stack_item_already_exists')}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Trans
          i18nKey="stack_item_already_exists_warning_modal_body"
          values={{ url }}
          components={{
            strong: <Text isBold style={{ wordBreak: 'break-all' }} />,
          }}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="opacity" onClick={hideModal}>
          {i18n.t('cancel')}
        </Button>
        <Button
          variant="primary"
          onClick={() => {
            addLinkToStackFn();
            hideModal();
          }}
        >
          {i18n.t('add')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
