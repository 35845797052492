import { Button } from '@dropbox/dig-components/buttons';
import { Text, Title } from '@dropbox/dig-components/typography';
import { ThemeContainer, ThemeProvider } from '@dropbox/dig-foundations';
import { WordmarkLogo } from '@dropbox/dig-logos';
import { createUxaElementId } from '@mirage/analytics/uxa';
import { Link } from '@mirage/link/Link';
import { getSupportUrl } from '@mirage/mosaics/Feedback/support-url';
import { RedirectVariant } from '@mirage/mosaics/FullPageRedirect/types';
import { DashWordmarkText } from '@mirage/mosaics/GlobalNav/DashWordmarkText';
import useDropboxAccount from '@mirage/service-auth/useDropboxAccount';
import { EnvCtx } from '@mirage/service-environment-context/global-env-ctx';
import { logout } from '@mirage/service-logout';
import { openURL } from '@mirage/service-platform-actions';
import { exit } from '@mirage/service-setting-actions';
import i18n from '@mirage/translations';
import React from 'react';
import styles from './RedirectModal.module.css';

export interface RedirectModalProps {
  asset?: React.ReactNode;
  cta?: string;
  description?: string;
  primaryCta?: {
    onClick?: () => void;
    label?: string;
  };
  title?: string;
  variant: RedirectVariant;
}

export const RedirectModal = ({
  asset,
  description,
  primaryCta,
  title,
  variant,
}: RedirectModalProps) => {
  const account = useDropboxAccount();
  const isDesktop = EnvCtx.surface == 'desktop';

  const onLogout = async () => {
    if (isDesktop) {
      exit('logout');
      return;
    }
    logout();
  };

  const onContactSupport = () => {
    openURL(getSupportUrl());
  };

  return (
    <ThemeProvider mode="bright">
      <ThemeContainer>
        <div
          className={styles.container}
          data-uxa-log={createUxaElementId(variant, {
            actionSurfaceComponent: 'eligibility_redirect_modal',
            featureLine: 'eligibility_redirect_modal',
          })}
          data-uxa-interactions="shown"
        >
          <div className={styles.content}>
            <div className={styles.header}>
              <WordmarkLogo size={32} src={DashWordmarkText} />
            </div>
            <div className={styles.assetContainer}>{asset}</div>
            <Title className={styles.noMargin} weightVariant="emphasized">
              {title}
            </Title>
            <Text color="subtle" size="large">
              {description}
            </Text>
            {primaryCta && (
              <div>
                <Button variant="primary" onClick={primaryCta?.onClick}>
                  {primaryCta?.label}
                </Button>
              </div>
            )}
            <hr className={styles.line} />
            <div>
              {account?.email ? (
                <>
                  <Text color="subtle">{i18n.t('logged_in_as')}</Text>
                  <Text isBold> {account?.email}</Text>
                </>
              ) : (
                <>
                  {/* Add nbsp in order to keep flow the same as the user logs out */}
                  &nbsp;
                </>
              )}
            </div>
            <Text color="subtle" className={styles.footer}>
              <Link
                onClick={onLogout}
                href="#"
                variant="primary"
                data-uxa-log={createUxaElementId('logout_link', {
                  actionSurfaceComponent: 'eligibility_redirect_modal',
                  featureLine: 'eligibility_redirect_modal',
                })}
              >
                {i18n.t('switch_accounts')}
              </Link>
              &#x2022;
              <Link
                onClick={onContactSupport}
                href="#"
                variant="primary"
                data-uxa-log={createUxaElementId('contact_support_link', {
                  actionSurfaceComponent: 'eligibility_redirect_modal',
                  featureLine: 'eligibility_redirect_modal',
                })}
              >
                {i18n.t('contact_support')}
              </Link>
            </Text>
          </div>
        </div>
      </ThemeContainer>
    </ThemeProvider>
  );
};
