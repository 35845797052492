type MeticulousState = {
  accountEmail?: string;
  isEnabled: boolean;
  stopRecording?: () => Promise<void>;
};

type WindowWithMeticulous = Window &
  typeof globalThis & {
    DASH_METICULOUS_STATE: MeticulousState;
  };

export const getMeticulousState = (): MeticulousState => {
  return ((window as WindowWithMeticulous).DASH_METICULOUS_STATE ||
    {}) as MeticulousState;
};

export const setMeticulousState = (state: MeticulousState) => {
  (window as WindowWithMeticulous).DASH_METICULOUS_STATE = state;
};
