import { dash_feed, stacks, users } from '@dropbox/api-v2-client';
import { getActors, getFirstAction } from '@mirage/service-feed/util/helpers';
import { Trans } from '@mirage/translations';
import { ActivityLabelLink } from '../ActivityLabel/ActivityLabelLink';
import { ActivityActorsLabel } from '../ActivityLabel/ActivityLabelSubject';

type StackActivityLabelProps = {
  activity: dash_feed.FeedItem;
  stack: stacks.StackData;
  currentAccount?: users.FullAccount;
};

export const StackActivityLabel = ({
  activity,
  stack,
}: StackActivityLabelProps) => {
  const action = getFirstAction(activity);
  switch (action?.['.tag']) {
    case 'edit':
      return (
        <EditStackActivityAction
          activity={activity}
          stack={stack}
          action={action}
        />
      );
    case 'share':
      return <ShareStackActivityAction activity={activity} stack={stack} />;
    case 'add_child':
      return (
        <AddStackItemActivityAction
          activity={activity}
          stack={stack}
          action={action}
        />
      );
    case 'remove_child':
      return (
        <RemoveStackItemActivityAction
          activity={activity}
          stack={stack}
          action={action}
        />
      );
    default:
      return null;
  }
};

type EditStackActivityActionProps = {
  action: dash_feed.action_unionEdit;
} & StackActivityLabelProps;

export const EditStackActivityAction = ({
  activity,
  stack,
  action,
}: EditStackActivityActionProps) => {
  if (!action.updates || action.updates.length == 0) {
    return null;
  }

  const firstUpdate = action.updates[0].update;
  if (firstUpdate?.['.tag'] == 'stack_update') {
    const updatedField = firstUpdate.field;
    switch (updatedField?.['.tag']) {
      case 'name':
        return (
          <EditStackNameActivityAction
            activity={activity}
            stack={stack}
            update={updatedField}
          />
        );
      case 'description':
        return (
          <EditStackDescriptionActivityAction
            activity={activity}
            stack={stack}
            update={updatedField}
          />
        );
      case 'is_archived':
        return (
          <ArchiveStackActivityAction
            activity={activity}
            stack={stack}
            update={updatedField}
          />
        );
      default:
        return null;
    }
  }

  return null;
};

type EditStackNameActivityActionProps = {
  update: dash_feed.field_unionName;
} & StackActivityLabelProps;

const EditStackNameActivityAction = ({
  activity,
}: EditStackNameActivityActionProps) => {
  const actors = getActors(activity);

  // John Doe changed the title
  return (
    <Trans
      i18nKey="activity_feed_expanded_label_edit_stack_name"
      components={{
        subject: <ActivityActorsLabel actors={actors} />,
      }}
    />
  );
};

type EditStackDescriptionActivityActionProps = {
  update: dash_feed.field_unionDescription;
} & StackActivityLabelProps;

const EditStackDescriptionActivityAction = ({
  activity,
}: EditStackDescriptionActivityActionProps) => {
  const actors = getActors(activity);

  // John Doe changed the description of your Project Planning stack from “ABC Description” to “XYZ Description”
  return (
    <Trans
      i18nKey="activity_feed_expanded_label_edit_stack_description"
      components={{
        subject: <ActivityActorsLabel actors={actors} />,
      }}
    />
  );
};

type ArchiveStackActivityActionProps = {
  update: dash_feed.field_unionIsArchived;
} & StackActivityLabelProps;

const ArchiveStackActivityAction = ({
  activity,
  update,
}: ArchiveStackActivityActionProps) => {
  const actors = getActors(activity);
  const isArchived = update.new_value;

  if (isArchived) {
    // John Doe changed the title of your “Old Title” stack to “New Title”
    return (
      <Trans
        i18nKey="activity_feed_expanded_label_archive_stack"
        components={{
          subject: <ActivityActorsLabel actors={actors} />,
        }}
      />
    );
  } else {
    // we currently don't display activities if they are not archived
    return null;
  }
};

type ShareStackActivityActionProps = {} & StackActivityLabelProps;

export const ShareStackActivityAction = ({
  activity,
}: ShareStackActivityActionProps) => {
  const actors = getActors(activity);

  // John Doe shared Project Planning stack with you
  return (
    <Trans
      i18nKey="activity_feed_expanded_label_share_stack"
      components={{
        subject: <ActivityActorsLabel actors={actors} />,
      }}
    />
  );
};

type AddStackItemActivityActionProps = {
  action: dash_feed.action_unionAddChild;
} & StackActivityLabelProps;

export const AddStackItemActivityAction = ({
  activity,
  action,
}: AddStackItemActivityActionProps) => {
  const actors = getActors(activity);
  const addedItem = action.added_object;
  const addedItemName = addedItem?.name;
  const addedItemUrl = addedItem?.url ?? '#';

  return (
    <Trans
      i18nKey="activity_feed_expanded_label_add_stack_item"
      values={{ addedItemName }}
      components={{
        subject: <ActivityActorsLabel actors={actors} />,
        addedItemLink: <ActivityLabelLink href={addedItemUrl} />,
      }}
    />
  );
};

type RemoveChildActivityActionProps = {
  action: dash_feed.action_unionRemoveChild;
} & StackActivityLabelProps;

export const RemoveStackItemActivityAction = ({
  activity,
  action,
}: RemoveChildActivityActionProps) => {
  const actors = getActors(activity);
  const removedItem = action.removed_object;
  const removedItemName = removedItem?.name;
  const removedItemUrl = removedItem?.url ?? '#';

  // John Doe removed Klien Project Planning from your Project Planning stack
  return (
    <Trans
      i18nKey="activity_feed_expanded_label_remove_stack_item"
      values={{ removedItemName }}
      components={{
        subject: <ActivityActorsLabel actors={actors} />,
        removedItemLink: <ActivityLabelLink href={removedItemUrl!} />,
      }}
    />
  );
};
