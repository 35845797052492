import {
  stopIntercepting,
  tryLoadAndStartRecorder,
} from "@alwaysmeticulous/recorder-loader";
import * as authService from "@mirage/service-auth";
import { tagged } from "@mirage/service-logging";
import {
  getMeticulousState,
  setMeticulousState,
} from "@mirage/shared/meticulous";

import { METICULOUS_REDACTION_MIDDLEWARE } from "./middleware";

const _logger = tagged("meticulous");

// This is a public token for Meticulous.
const METICULOUS_PUBLIC_RECORDING_TOKEN =
  "bpciwGxTTN4oudQ0wbT21NLDrPeRTdBZt3lnpV7b";

// Check if the environment is valid
export const isValidEnvironment = () => {
  return (
    process.env.REACT_APP_ENV === "devbox" ||
    process.env.REACT_APP_ENV === "devprod" ||
    process.env.REACT_APP_ENV === "canary"
  );
};

// initMeticulous starts recordings for logged-out users and dbx51.com accounts.
// It stops recordings for other accounts if a recording has already started.
export const initMeticulous = async (
  logger: ReturnType<typeof tagged> = _logger,
) => {
  authService.listenForAccount().subscribe(async (account) => {
    // Record for logged-out users and dbx51.com accounts
    const shouldEnable = !account || account.email?.endsWith("@dbx51.com");
    const meticulousState = getMeticulousState();

    if (isValidEnvironment() && shouldEnable) {
      if (
        meticulousState.isEnabled &&
        meticulousState.accountEmail === account?.email
      ) {
        // Avoid re-enabling if already enabled for this account.
        return;
      }

      try {
        // Start recording and update state.
        const result = await tryLoadAndStartRecorder({
          isProduction: false, // false because index.html only loads meticulous for devbox, devprod, or canary.
          middleware: METICULOUS_REDACTION_MIDDLEWARE,
          recordingToken: METICULOUS_PUBLIC_RECORDING_TOKEN,
          version: "1.1.0", // This needs to match the version of network-recorder.bundle.js set in index.html
        });
        setMeticulousState({
          accountEmail: account?.email,
          isEnabled: true,
          stopRecording: result.stopRecording,
        });
      } catch (err) {
        logger.error("Meticulous failed to initialize", err);
      }
    } else {
      // Meticulous should not record for this account. Stop recording and intercepting, and update state.
      // stopIntercepting() drops any network requests/responses stored in memory and deactivates Meticulous.
      try {
        await Promise.all([
          stopIntercepting(),
          meticulousState.stopRecording?.(),
        ]);
        setMeticulousState({
          accountEmail: account?.email,
          isEnabled: false,
          stopRecording: undefined,
        });
      } catch (err) {
        logger.error("Meticulous failed to stop recording", err);
      }
    }
  });
};
