import useDropboxAccount from '@mirage/service-auth/useDropboxAccount';
import useConnectors from '@mirage/service-connectors/useConnectors';
import { callApiV2 } from '@mirage/service-dbx-api';
import { EnvCtx } from '@mirage/service-environment-context/global-env-ctx';
import { isDropboxerEmail } from '@mirage/shared/account';
import Sentry from '@mirage/shared/sentry';
import { atom, useAtom } from 'jotai';

export const feedbackParamsAtom = atom<object>({});

// Enum string is used as the title of the resulting ticket
export enum FeedbackType {
  BugReport = 'Bug',
  Suggestion = 'Suggestion',
  Other = 'Other',
}

export type UserFeedback = {
  title: string;
  body: string;
  feedbackType: FeedbackType;
  screenCapture?: string;
  traceId?: string;
};

type SubmitFeedbackStatus = {
  success?: boolean;
};

export function useSubmitFeedback() {
  const [feedbackParams, setFeedbackParams] = useAtom(feedbackParamsAtom);
  const account = useDropboxAccount();
  const { connections } = useConnectors();
  const connectedConnectorsStr = connections
    .map((connection) => connection.branding?.display_name)
    .filter(Boolean)
    .join(', ');
  const extraFields = Object.entries(feedbackParams)
    .map(([key, value]) => `*${key}*: ${value}`)
    .join('\n');

  const addFeedbackParams = (params: object) => {
    setFeedbackParams({ ...feedbackParams, ...params });
  };

  const resetFeedbackParams = (params: object) => {
    setFeedbackParams(params);
  };

  const getDataDogUrl = () => {
    EnvCtx.buildChannel;
    let service;
    switch (EnvCtx.surface) {
      case 'desktop':
        if (EnvCtx.buildChannel === 'canary') {
          service = 'dash-desktop-canary';
        } else if (EnvCtx.buildChannel === 'development') {
          service = 'dash-desktop-development';
        } else {
          service = 'dash-desktop-production';
        }
        break;
      case 'extension':
        service = 'dash-extension';
        break;
      case 'web':
        service = 'dash-web';
        break;
    }
    const query = `%40uid%3A${account?.uid}%20service%3A${service}%20%40version%3A${EnvCtx.version}`;
    const to = Date.now();
    const from = to - 3600000; // 1 hour time frame
    return `https://app.datadoghq.com/logs?query=${query}&from_ts=${from}&to_ts=${to}&live=false`;
  };

  const submitFeedback = async (
    feedback: UserFeedback,
  ): Promise<SubmitFeedbackStatus> => {
    const { title, body, feedbackType, screenCapture, traceId } = feedback;
    try {
      await callApiV2('mobileLoggingFeedback', {
        title: `[DashFeedback | ${feedbackType}] ${title}`,
        feedback: `
          ${body}\n\n----\n\n
          This feedback was submitted directly from the Dash application\n
          *userId*: ${account?.uid}
          *accountId*: ${account?.account_id}
          *teamId*: ${account?.team?.id}
          ${traceId ? `trace_id: ${traceId}` : ''}
          *connectors*: ${connectedConnectorsStr}
          ${extraFields}

          *logs*: ${getDataDogUrl()}
        `,
        feedback_type: feedback.feedbackType,

        sys_model: EnvCtx.platform === 'win32' ? 'Windows' : 'MacOS',
        sys_version: EnvCtx.surface,
        app_version: EnvCtx.version,

        name: `${account?.name?.given_name} ${account?.name?.surname}`,
        email: account?.email,
        // if true, creates jira ticket isntead of zendesk
        is_internal: account?.email ? isDropboxerEmail(account?.email) : false,

        // TODO: Implement uploading screenshots and logs for bug reports
        // log_report: '',
        screenshot: screenCapture, // base64 encoded
      });
      return { success: true };
    } catch (e) {
      Sentry.captureException(e);
      return { success: false };
    }
  };

  return {
    submitFeedback,
    resetFeedbackParams,
    addFeedbackParams,
  };
}
