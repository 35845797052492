import * as React from "react";

import { LabelGroup } from "../label-group";
import { Card } from "../card";
import { Skeleton } from "@dropbox/dig-components/skeleton";
import { Box } from "@dropbox/dig-foundations";
import styles from "./Skeleton.module.css";
import { SkeletonStartAccessory } from "./StartAccessory";
import { ContentRowProps } from "./types";
import {
  convertPaddingSizeToBreakoutSize,
  convertPaddingSizeToPaddingX,
} from "./utils";

export const ContentRowSkeleton: React.FC<{
  paddingSize?: ContentRowProps["paddingSize"];
  startAccessorShape?: "square" | "circle";
}> = ({ paddingSize = "large", startAccessorShape = "circle" }) => {
  const breakoutSize = convertPaddingSizeToBreakoutSize(paddingSize);
  const paddingX = convertPaddingSizeToPaddingX(paddingSize);

  return (
    <Card.Module breakout={breakoutSize}>
      <Box
        as={LabelGroup}
        paddingX={paddingX}
        verticalAlignment="center"
        accessoryGap="Micro XLarge"
        withStartAccessory={
          <SkeletonStartAccessory shape={startAccessorShape} />
        }
        withLabel={
          <Skeleton.Text
            withBorderRadius="xsmall"
            className={styles.text}
            width={300}
          />
        }
        withMeta={
          <Skeleton.Text
            withBorderRadius="xsmall"
            className={styles.meta}
            width={150}
          />
        }
      />
    </Card.Module>
  );
};
