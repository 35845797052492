import { stacks, users } from '@dropbox/api-v2-client';
import { Menu, MenuPlacement } from '@dropbox/dig-components/menu';
import { Text } from '@dropbox/dig-components/typography';
import { UIIcon } from '@dropbox/dig-icons';
import {
  ArchiveLine,
  DeleteLine,
  DuplicateLine,
  EditLine,
  LinkLine,
  MoreHorizontalLine,
  PersonLeaveLine,
  PinLine,
  SettingsLine,
  UpgradeLine,
} from '@dropbox/dig-icons/assets';
import { useMirageAnalyticsContext } from '@mirage/analytics/AnalyticsProvider';
import { PAP_Cancel_DashMoreActions } from '@mirage/analytics/events/types/cancel_dash_more_actions';
import { PAP_Click_DashMoreActions } from '@mirage/analytics/events/types/click_dash_more_actions';
import { PAP_Update_StackIconColor } from '@mirage/analytics/events/types/update_stack_icon_color';
import { createUxaElementId } from '@mirage/analytics/uxa';
import {
  stackDerivePAPProps,
  upsertStack,
  upsertToggleStackIsPinned,
} from '@mirage/service-stacks';
import { IconButtonWithTooltip } from '@mirage/shared/icons/IconButtonWithTooltip';
import { showSnackbar } from '@mirage/shared/snackbar';
import { DigTooltip } from '@mirage/shared/util/DigTooltip';
import { nonNil } from '@mirage/shared/util/tiny-utils';
import { getDefaultColorIndex } from '@mirage/stacks/themes';
import i18n from '@mirage/translations';
import { useCallback, useMemo, useRef } from 'react';
import { ColorPicker } from './ColorPicker';
import { useCopyStackUrlToClipboard } from './CopyStackUrl';
import {
  isStackOwner,
  stackHasWritePermissions,
  stackIsArchived,
} from './Helpers/Utils';
import {
  useDeleteStackModal,
  useLeaveStack,
  useReportToggleStackIsPinned,
} from './hooks';
import { UnthemedWrapper } from './UnthemedWrapper';

const actionSurfaceComponent = 'stacks';
const featureLine = 'stacks';

export enum MenuItem {
  COPY_LINK = 'COPY_LINK',
  EDIT = 'EDIT',
  PIN = 'PIN',
  DELETE = 'DELETE',
  LEAVE = 'LEAVE',
  COLOR = 'COLOR',
  ARCHIVE = 'ARCHIVE',
  CLONE = 'CLONE',
}

export type SetMenuOpenForStackId = React.Dispatch<
  React.SetStateAction<string | undefined>
>;

export type ActionsMenuProps = {
  stack: stacks.Stack | null;
  currentAccount?: users.FullAccount;
  onEdit?: () => void;
  onArchive?: () => void;
  onClone?: () => void;
  setMenuOpenForStackId?: SetMenuOpenForStackId;
  iconColor?: string;
  menuPlacement?: MenuPlacement;
  showColorPicker?: boolean;
  showPinnedIcon?: boolean;
  mutationRequestId?: string;
  isCloning?: boolean;
  useGearIcon?: boolean;
};

export const ActionsMenu: React.FC<ActionsMenuProps> = ({
  stack,
  currentAccount,
  onEdit,
  onArchive,
  onClone,
  setMenuOpenForStackId,
  iconColor,
  menuPlacement,
  showColorPicker,
  mutationRequestId,
  isCloning,
  useGearIcon = false,
}) => {
  const { reportPapEvent } = useMirageAnalyticsContext();
  const isOwner = stack ? isStackOwner(stack) : true;
  const canEdit = stack ? stackHasWritePermissions(stack) : true;
  const stackNamespaceIdExists = !!stack?.namespace_id;
  const triggerRef = useRef<HTMLDivElement>(null);

  const isNewStack = !stack?.namespace_id;

  const { setShowDeleteConfirmationModal, setStackToDelete } =
    useDeleteStackModal();
  const { reportToggleStackIsPinned } = useReportToggleStackIsPinned();

  const onCopyLink = useCopyStackUrlToClipboard(stack);

  const showArchiveCloneMenuSegment = Boolean(
    (canEdit && onArchive) || onClone,
  );

  const isDirectMember = useMemo<boolean>(() => {
    if (!currentAccount?.email) {
      return false;
    }
    return (stack?.sharing_data?.members || []).some(
      (member) =>
        member.subject?.['.tag'] === 'user' &&
        member.subject.email === currentAccount.email,
    );
  }, [stack?.sharing_data?.members, currentAccount?.email]);

  const onDelete = () => {
    if (!stack) {
      return;
    }
    setShowDeleteConfirmationModal(true);
    setStackToDelete(stack);
  };

  const onPin = () => {
    if (stack?.namespace_id) {
      reportToggleStackIsPinned(stack);
    }
    upsertToggleStackIsPinned({
      type: stackNamespaceIdExists ? 'nsId' : 'requestId',
      id: stackNamespaceIdExists
        ? nonNil(stack.namespace_id, 'stack.namespace_id')
        : nonNil(mutationRequestId, 'mutationRequestId'),
    });
    showSnackbar({
      title: i18n.t(
        stack?.user_data?.is_pinned ? 'stack_unpinned' : 'stack_pinned',
      ),
    });
  };

  const onLeave = useLeaveStack(stack, currentAccount);

  const onColorChange = (colorIndex: number) => {
    upsertStack(
      {
        type: stackNamespaceIdExists ? 'nsId' : 'requestId',
        id: stackNamespaceIdExists
          ? nonNil(stack.namespace_id, 'stack.namespace_id')
          : nonNil(mutationRequestId, 'mutationRequestId'),
      },
      [
        {
          field: {
            '.tag': 'color_index_update',
            color_index_update: colorIndex,
          },
        },
      ],
    );
  };

  const handleToggle = useCallback(
    (isOpen: boolean) => {
      if (!stack || !stack?.namespace_id) return;
      const papFn = isOpen
        ? PAP_Click_DashMoreActions
        : PAP_Cancel_DashMoreActions;
      reportPapEvent(
        papFn({
          ...stackDerivePAPProps(stack),
          actionSurfaceComponent,
          featureLine,
        }),
      );
      setMenuOpenForStackId &&
        setMenuOpenForStackId(isOpen ? stack?.namespace_id : undefined);

      if (!isOpen) {
        // Focus the trigger button when the menu is closed via escape key,
        // so that tab focus order is maintained.
        triggerRef.current?.focus();
      }
    },
    [reportPapEvent, stack, setMenuOpenForStackId],
  );

  const handleMenuItemSelect = (menuItem: string) => {
    const [key, value] = menuItem.split('|');

    switch (menuItem) {
      case MenuItem.EDIT:
        onEdit?.();
        break;
      case MenuItem.COPY_LINK:
        onCopyLink();
        showSnackbar({ title: i18n.t('copied_link_to_clipboard') });
        break;
      case MenuItem.PIN:
        onPin();
        break;
      case MenuItem.DELETE:
        onDelete();
        break;
      case MenuItem.LEAVE:
        onLeave?.();
        break;
      case MenuItem.ARCHIVE:
        onArchive?.();
        break;
      case MenuItem.CLONE:
        onClone?.();
        break;
      default:
        if (key === MenuItem.COLOR) {
          onColorChange(Number(value));
          if (stack) {
            reportPapEvent(
              PAP_Update_StackIconColor({
                ...stackDerivePAPProps(stack),
                actionSurfaceComponent,
                featureLine,
              }),
            );
          }
        }
        break;
    }
  };

  return (
    <Menu.Wrapper
      onSelection={handleMenuItemSelect}
      onToggle={(event) => handleToggle(event.isOpen)}
    >
      {({ getContentProps, getTriggerProps }) => (
        <>
          {/* This div is needed to allow the icon button to receive focus via keyboard */}
          <div ref={triggerRef} tabIndex={-1}>
            <IconButtonWithTooltip
              tooltipProps={{
                title: i18n.t('more'),
              }}
              {...getTriggerProps()}
              variant="transparent"
              data-testid="StackOptions"
              data-uxa-log={createUxaElementId('more_actions_button', {
                actionSurfaceComponent,
                featureLine,
              })}
              disabled={!currentAccount}
              data-uxa-entity-id={stack?.namespace_id}
            >
              <UIIcon
                src={useGearIcon ? SettingsLine : MoreHorizontalLine}
                style={iconColor ? { color: iconColor } : {}}
              />
            </IconButtonWithTooltip>
          </div>
          {/* Remove <UnthemedWrapper> after dash_2024_07_04_stack_page_august_revision is removed */}
          <UnthemedWrapper>
            <Menu.Content {...getContentProps()} placement={menuPlacement}>
              {showColorPicker && canEdit && (
                <Menu.Segment withLabel={i18n.t('stack_theme')}>
                  <ColorPicker
                    menuKey={MenuItem.COLOR}
                    colorIndex={
                      stack?.stack_data?.color_index || getDefaultColorIndex()
                    }
                    stackNamespaceId={stack?.namespace_id}
                  />
                </Menu.Segment>
              )}
              <Menu.Segment>
                {onEdit && !isNewStack && (
                  <Menu.ActionItem
                    key={MenuItem.EDIT}
                    value={MenuItem.EDIT}
                    withLeftAccessory={<UIIcon src={EditLine} size="medium" />}
                    data-uxa-log={createUxaElementId(
                      'more_actions_edit_button',
                      {
                        actionSurfaceComponent,
                        featureLine,
                      },
                    )}
                    data-uxa-entity-id={stack?.namespace_id}
                  >
                    {i18n.t('edit_stack_details')}
                  </Menu.ActionItem>
                )}
                {!isNewStack && (
                  <Menu.ActionItem
                    key={MenuItem.COPY_LINK}
                    value={MenuItem.COPY_LINK}
                    withLeftAccessory={<UIIcon src={LinkLine} size="medium" />}
                    data-uxa-log={createUxaElementId(
                      'more_actions_copy_button',
                      {
                        actionSurfaceComponent,
                        featureLine,
                      },
                    )}
                    data-uxa-entity-id={stack?.namespace_id}
                  >
                    {i18n.t('copy_link_to_stack')}
                  </Menu.ActionItem>
                )}
                <Menu.ActionItem
                  data-testid="MenuItemPin"
                  key={MenuItem.PIN}
                  value={MenuItem.PIN}
                  withLeftAccessory={<UIIcon src={PinLine} size="medium" />}
                  data-uxa-log={createUxaElementId('more_actions_pin_button', {
                    actionSurfaceComponent,
                    featureLine,
                  })}
                  data-uxa-entity-id={stack?.namespace_id}
                >
                  {stack?.user_data?.is_pinned
                    ? i18n.t('unpin_stack')
                    : i18n.t('pin_stack')}
                </Menu.ActionItem>
              </Menu.Segment>
              {showArchiveCloneMenuSegment && (
                <Menu.Segment>
                  {onClone && (
                    <Menu.ActionItem
                      key={MenuItem.CLONE}
                      value={MenuItem.CLONE}
                      withLeftAccessory={
                        <UIIcon src={DuplicateLine} size="medium" />
                      }
                      data-uxa-log={createUxaElementId(
                        'more_actions_clone_button',
                        {
                          actionSurfaceComponent,
                          featureLine,
                        },
                      )}
                      data-uxa-entity-id={stack?.namespace_id}
                      disabled={isCloning}
                    >
                      {i18n.t('clone_stack')}
                    </Menu.ActionItem>
                  )}
                  {onArchive && canEdit && (
                    <Menu.ActionItem
                      key={MenuItem.ARCHIVE}
                      value={MenuItem.ARCHIVE}
                      withLeftAccessory={
                        <UIIcon
                          src={
                            stackIsArchived(stack) ? UpgradeLine : ArchiveLine
                          }
                          size="medium"
                        />
                      }
                      data-uxa-log={createUxaElementId(
                        'more_actions_archive_button',
                        {
                          actionSurfaceComponent,
                          featureLine,
                        },
                      )}
                      data-uxa-entity-id={stack?.namespace_id}
                    >
                      {i18n.t(
                        stackIsArchived(stack)
                          ? 'unarchive_stack'
                          : 'archive_stack',
                      )}
                    </Menu.ActionItem>
                  )}
                </Menu.Segment>
              )}
              {onLeave && !isOwner && !isNewStack && (
                <Menu.Segment>
                  <DigTooltip
                    title={i18n.t('leave_disallowed')}
                    placement="right"
                    disabled={isDirectMember}
                  >
                    <Menu.ActionItem
                      key={MenuItem.LEAVE}
                      value={MenuItem.LEAVE}
                      withLeftAccessory={
                        <UIIcon src={PersonLeaveLine} size="medium" />
                      }
                      disabled={!isDirectMember}
                      data-uxa-log={createUxaElementId(
                        'more_actions_leave_button',
                        {
                          actionSurfaceComponent,
                          featureLine,
                        },
                      )}
                      data-uxa-entity-id={stack?.namespace_id}
                    >
                      {i18n.t('leave')}
                    </Menu.ActionItem>
                  </DigTooltip>
                </Menu.Segment>
              )}
              {isOwner && !isNewStack && (
                <Menu.Segment>
                  <Menu.ActionItem
                    data-testid="menuItemDelete"
                    key={MenuItem.DELETE}
                    value={MenuItem.DELETE}
                    withLeftAccessory={
                      <Text color="error" style={{ lineHeight: 0 }}>
                        <UIIcon src={DeleteLine} size="medium" />
                      </Text>
                    }
                    data-uxa-log={createUxaElementId(
                      'more_actions_delete_button',
                      {
                        actionSurfaceComponent,
                        featureLine,
                      },
                    )}
                    data-uxa-entity-id={stack?.namespace_id}
                  >
                    <Text color="error">{i18n.t('delete_stack')}</Text>
                  </Menu.ActionItem>
                </Menu.Segment>
              )}
            </Menu.Content>
          </UnthemedWrapper>
        </>
      )}
    </Menu.Wrapper>
  );
};
