import { IconButton } from '@dropbox/dig-components/buttons';
import { UIIcon } from '@dropbox/dig-icons';
import { EditLine } from '@dropbox/dig-icons/assets';
import { AvatarSettings } from '@mirage/service-settings/service/types';
import { HotkeysType } from '@mirage/shared/hotkeys/hotkeysKeyMap';
import { PrettyShortcuts } from '@mirage/shared/keyboard-shortcuts/PrettyShortcuts';
import { DigTooltip } from '@mirage/shared/util/DigTooltip';
import i18n from '@mirage/translations';
import { useCallback, useState } from 'react';
import ListItem from '../ListItem';
import KeyboardInput from './KeyboardInput';
import styles from './KeyboardShortcutListItem.module.css';

type Props = Readonly<{
  shortcutItem: HotkeysType;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  shortcuts: any;
  platform: string;
  settings: AvatarSettings;
  setSetting: (
    settingName: keyof AvatarSettings,
    value?: number | string,
  ) => void;
}>;

export default function KeyboardShortcutListItem({
  shortcutItem,
  shortcuts,
  platform,
  settings,
  setSetting,
}: Props) {
  const [settingId, label, editType] = shortcutItem;
  const [editing, setEditing] = useState(false);
  const handleToggle = useCallback(() => {
    if (editType) {
      setEditing(!editing);
    }
  }, [editType, editing]);

  const currentKeyboardShortcut = settings[settingId] || shortcuts[settingId];

  const renderShortcut = useCallback(() => {
    if (!editing) {
      return (
        <>
          {!!editType && (
            <DigTooltip
              title={i18n.t('settings_keyboard_shortcuts_edit')}
              placement="bottom"
            >
              <IconButton
                className={styles.keyboardShortcutButton}
                variant="transparent"
                size="medium"
                onClick={handleToggle}
                aria-label={i18n.t('settings_keyboard_shortcuts_edit')}
              >
                <UIIcon src={EditLine} />
              </IconButton>
            </DigTooltip>
          )}
          <PrettyShortcuts
            shortcut={currentKeyboardShortcut}
            isDarwin={platform === 'darwin'}
            editable={!!editType}
          />
        </>
      );
    }

    return (
      <KeyboardInput
        setEditing={setEditing}
        settingId={settingId}
        settings={settings}
        handleToggle={handleToggle}
        label={label}
        platform={platform}
        setSetting={setSetting}
      />
    );
  }, [
    currentKeyboardShortcut,
    editType,
    editing,
    handleToggle,
    label,
    platform,
    setSetting,
    settingId,
    settings,
  ]);

  return (
    <ListItem
      key={settingId}
      title={label}
      componentType="button"
      titleIndent={!editType}
      displayActions={renderShortcut()}
      onClick={handleToggle}
      role="none"
    />
  );
}
