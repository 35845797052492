import { ContentIcon } from '@dropbox/dash-component-library';
import { UIIcon } from '@dropbox/dig-icons';
import {
  CalculatorLine,
  ClockLine,
  CloseLine,
  LinkLine,
  SearchLine,
} from '@dropbox/dig-icons/dist/mjs/assets';
import { ChatEntryPoint } from '@mirage/conversations/types';
import { DashStackIcon } from '@mirage/mosaics/DCLWrappers/DashStackIcon';
import { SuggestedQueryRowSubTitle } from '@mirage/mosaics/SearchBarWithTypeahead/SuggestedQueryRowSubTitle';
import { BASE_SEARCH_RESULTS_URL } from '@mirage/search/hooks/useQueryParams';
import { Highlighter } from '@mirage/search/SearchResults/Highlighter';
import { TypeaheadResultSubtitle } from '@mirage/search/SearchResults/Metadata/TypeaheadResultSubtitle';
import {
  ShortcutSubtitle,
  ShortcutTitle,
} from '@mirage/search/SearchResults/ShortcutTitle';
import { StackItemSubtitle } from '@mirage/search/SearchResults/StackItemTitle';
import { EnvCtx } from '@mirage/service-environment-context/global-env-ctx';
import { useFeatureFlagValue } from '@mirage/service-experimentation/useFeatureFlagValue';
import { convertFeatureValueToBool } from '@mirage/service-experimentation/util';
import { tagged } from '@mirage/service-logging';
import { useOnboardingValues } from '@mirage/service-onboarding/hooks';
import {
  copyToClipboard,
  openLocalPath,
  openURL,
  runLocalLaunchCommandForShortcut,
} from '@mirage/service-platform-actions';
import { openResult } from '@mirage/service-result-actions';
import useSettings from '@mirage/service-settings/useSettings';
import { stackGetShareId } from '@mirage/service-stacks/service/utils';
import {
  addURLShortcutToCache,
  registerHit,
} from '@mirage/service-typeahead-search';
import { typeahead } from '@mirage/service-typeahead-search/service/types';
import { calculatePapPositions } from '@mirage/shared/hooks/useResultPAPLogger';
import { getKeyMaps } from '@mirage/shared/hotkeys/hotkeysKeyMap';
import { URLShortcutIcon, UrlSrcIcon } from '@mirage/shared/icons';
import DesktopApplicationIcon from '@mirage/shared/icons/DesktopApplicationIcon';
import { FileContentIcon } from '@mirage/shared/icons/FileContentIcon';
import { IconButtonWithTooltip } from '@mirage/shared/icons/IconButtonWithTooltip';
import { PrettyShortcuts } from '@mirage/shared/keyboard-shortcuts/PrettyShortcuts';
import {
  mergePartialFilterText,
  SearchFilter,
  stripStringOfPotentialFilterBindings,
} from '@mirage/shared/search/search-filters';
import {
  CREATE_STACK_ACTION_UUID,
  type WellKnownShortcutUUID,
} from '@mirage/shared/search/url-shortcut';
import { showSnackbar } from '@mirage/shared/snackbar';
import { faviconSrcForSrcUrl } from '@mirage/shared/util/favicon';
import i18n from '@mirage/translations';
import { useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { SummarizeAction } from '../SummaryQnaPanel/SummarizeAction';
import { SummaryQnAEnum } from '../SummaryQnaPanel/utils';
import { getTypeaheadFilterDisplayProps } from './typeahead-search-filter-result';

import type { dash } from '@dropbox/api-v2-client';
import type { LaunchMethod } from '@mirage/analytics/events/enums/launch_method';
import type { useTypeaheadSearch } from '@mirage/service-typeahead-search/hooks/useTypeaheadSearch';

const logger = tagged('useConvertToTypeaheadResults');

export type TypeaheadResult = {
  type: typeahead.ResultType;
  uuid: string;
  scoredResult: typeahead.ScoredResult;
  icon: JSX.Element | null;
  title: JSX.Element | string;
  subtitle?: JSX.Element | string;
  launchResult?: (launchMethod: LaunchMethod) => void;
  removeResult?: () => void;
  copyResult?: () => void;
  priority?: number;
  accessory?: JSX.Element | null;
  /** overwrites accessory if it's defined */
  selectedAccessory?: JSX.Element | null;
  /** overwrites accessory or selectedAccessory if either is defined */
  hoverAccessory?: JSX.Element | null;
  summarizable?: dash.SummarizableType['.tag'] | null;
  keepTypeaheadOpen?: boolean;
};

type UseTypeaheadSearch = ReturnType<typeof useTypeaheadSearch>;

export function useConvertToTypeaheadResults({
  typeaheadQuery,
  selectedIndex,
  hoveredIndex,
  setTypeaheadQuery,
  shouldSearchBeBoosted,
  handleTaggedSearchSubmit,
  removePreviousQuery,
  onInteractedWithResult,
  papLogResultOpen,
  papLogResultCopy,
  allFilters,
}: {
  typeaheadQuery: string;
  selectedIndex: number;
  hoveredIndex: number | null;
  setTypeaheadQuery: (typeaheadQuery: string) => void;
  shouldSearchBeBoosted: boolean;
  handleTaggedSearchSubmit: (
    result: typeahead.ScoredPreviousQuery | typeahead.ScoredSuggestedQuery,
    isDefaultSearchDash: boolean,
  ) => void;
  removePreviousQuery: (result: typeahead.ScoredPreviousQuery) => void;
  /** Fired for either a copy or open on everything but "search dash" (SuggestedQuery) */
  onInteractedWithResult: (result: typeahead.ScoredResult) => void;
  papLogResultOpen: UseTypeaheadSearch['papLogResultOpen'];
  papLogResultCopy: UseTypeaheadSearch['papLogResultCopy'];
  allFilters: SearchFilter[];
}) {
  const platform = EnvCtx.platform as 'darwin' | 'win32';
  const isDesktop = EnvCtx.surface === 'desktop';
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { setOnboardingValue } = useOnboardingValues();

  const isSERP = pathname === BASE_SEARCH_RESULTS_URL;

  const enableTypeaheadCSummarizableRowButton =
    useFeatureFlagValue('dash_2024_09_19_typeahead_summary_row_button') ===
    'ON';
  const openFilesInDesktopAppsGb = convertFeatureValueToBool(
    useFeatureFlagValue('dash_2024_10_24_open_files_in_desktop_apps'),
  );
  const openFilesInDesktopAppsSetting = useSettings([
    'openFilesInDesktopAppsV2',
  ]).settings?.openFilesInDesktopAppsV2;
  const openFilesInDesktopApps =
    openFilesInDesktopAppsGb && !!openFilesInDesktopAppsSetting;

  return useCallback(
    (searchResults: typeahead.ScoredResult[]): TypeaheadResult[] =>
      searchResults
        .map((result, idx) => {
          const baseResult = {
            type: result.type,
            uuid: result.uuid,
            scoredResult: result,
          } satisfies Partial<TypeaheadResult>;

          switch (result.type) {
            case typeahead.ResultType.DesktopFile: {
              return {
                ...baseResult,
                icon: <FileContentIcon content={result.result} />,
                title: result.result.title,
                subtitle: i18n.t('search_result_recent_file'),
                launchResult: (launchMethod: LaunchMethod) => {
                  if (!result.result.url) return;
                  setOnboardingValue('dismissedQuerySuggestionBanner', false);
                  papLogResultOpen(result, launchMethod);
                  registerHit(result.uuid);
                  onInteractedWithResult(result);
                  openLocalPath(result.result.url);
                  if (!isSERP) {
                    setTypeaheadQuery('');
                    navigate('/');
                  }
                },
              };
            }
            case typeahead.ResultType.DesktopApplication:
              return {
                ...baseResult,
                icon: (
                  <DesktopApplicationIcon result={result.result} size={32} />
                ),
                title: result.result.title,
                subtitle: i18n.t('desktop_application_subtitle'),
                launchResult: (launchMethod: LaunchMethod) => {
                  if (!result.result.url) return;
                  setOnboardingValue('dismissedQuerySuggestionBanner', false);
                  papLogResultOpen(result, launchMethod);
                  registerHit(result.uuid);
                  onInteractedWithResult(result);
                  openLocalPath(result.result.url);
                  if (!isSERP) {
                    setTypeaheadQuery('');
                    navigate('/');
                  }
                },
              };
            case typeahead.ResultType.Stack:
              return {
                ...baseResult,
                icon: (
                  <DashStackIcon
                    colorIndex={result.result.stack_data?.color_index}
                    emoji={result.result.stack_data?.emoji}
                    size="medium"
                  />
                ),
                title:
                  result.result.stack_data?.name ||
                  i18n.t('stacks_typeahead_default_title'),
                subtitle: i18n.t('stacks_typeahead_subtitle'),
                launchResult: (launchMethod: LaunchMethod) => {
                  setOnboardingValue('dismissedQuerySuggestionBanner', false);
                  papLogResultOpen(result, launchMethod);
                  onInteractedWithResult(result);
                  navigate(`/stacks/${stackGetShareId(result.result) ?? ''}`);
                  if (!isSERP) {
                    setTypeaheadQuery('');
                  }
                },
                copyResult: () => {
                  if (!result.result.sharing_data?.shared_link?.url) return;
                  copyToClipboard(result.result.sharing_data.shared_link.url);
                  showSnackbar({ title: i18n.t('copied_link_to_clipboard') });
                  papLogResultCopy(result);
                  onInteractedWithResult(result);
                },
              };
            case typeahead.ResultType.StackItem:
              return {
                ...baseResult,
                icon: (
                  <ContentIcon
                    icon={
                      result.result.url ? (
                        <UrlSrcIcon
                          name={i18n.t('icon_for_item')}
                          size="100%"
                          iconUrl={faviconSrcForSrcUrl(result.result.url)}
                        />
                      ) : (
                        <UIIcon src={LinkLine} />
                      )
                    }
                    hasBackground={true}
                    size="medium"
                  />
                ),
                title: result.result.custom_name || result.result.name,
                subtitle: <StackItemSubtitle stackItem={result.result} />,
                launchResult: (launchMethod: LaunchMethod) => {
                  if (!result.result.url) return;
                  setOnboardingValue('dismissedQuerySuggestionBanner', false);
                  papLogResultOpen(result, launchMethod);
                  registerHit(result.uuid);
                  onInteractedWithResult(result);
                  openURL(result.result.url);
                  if (!isSERP) {
                    setTypeaheadQuery('');
                    navigate('/');
                  }
                },
                copyResult: () => {
                  if (!result.result.url) return;
                  copyToClipboard(result.result.url);
                  showSnackbar({ title: i18n.t('copied_link_to_clipboard') });
                  papLogResultCopy(result);
                  onInteractedWithResult(result);
                },
              };
            case typeahead.ResultType.SearchResult:
            case typeahead.ResultType.Recommendation: {
              const summaryResult = {
                ...baseResult,
                icon: <FileContentIcon content={result.result} size="medium" />,
                title: result.result.title,
                subtitle: <TypeaheadResultSubtitle result={result} />,
                summarizable: result?.result?.summarizable,
                launchResult: (launchMethod: LaunchMethod) => {
                  if (!result.result.url) return;
                  setOnboardingValue('dismissedQuerySuggestionBanner', false);
                  papLogResultOpen(result, launchMethod);
                  registerHit(result.uuid);
                  onInteractedWithResult(result);
                  openResult(result.result).catch((e: Error) => {
                    logger.error('Failed to open result', e);
                  });
                  if (!isSERP) {
                    setTypeaheadQuery('');
                    navigate('/');
                  }
                },
                copyResult: () => {
                  if (!result.result.url) return;
                  copyToClipboard(result.result.url);
                  showSnackbar({ title: i18n.t('copied_link_to_clipboard') });
                  papLogResultCopy(result);
                  onInteractedWithResult(result);
                },
              };

              const { resultPosition, resultPositionNoCta } =
                calculatePapPositions(
                  searchResults,
                  (r) => r.uuid === result.uuid,
                );

              return {
                ...summaryResult,
                ...(enableTypeaheadCSummarizableRowButton &&
                  summaryResult.summarizable === 'yes_summarizable' && {
                    accessory: (
                      <SummarizeAction
                        result={summaryResult}
                        summaryQnAType={SummaryQnAEnum.TYPEAHEAD}
                        entrypoint={ChatEntryPoint.search_bar_inline}
                        resultPosition={resultPosition}
                        resultPositionNoCta={resultPositionNoCta}
                        summarizable={summaryResult.summarizable || undefined}
                      />
                    ),
                  }),
              };
            }
            case typeahead.ResultType.PreviousQuery: {
              const accessory = (
                <IconButtonWithTooltip
                  aria-label={i18n.t('aria_remove_previous_query')}
                  variant="transparent"
                  onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                    e.stopPropagation();
                    removePreviousQuery(result);
                  }}
                  tooltipProps={{
                    title: i18n.t('tooltip_remove_previous_query'),
                  }}
                >
                  <UIIcon src={CloseLine} />
                </IconButtonWithTooltip>
              );
              return {
                ...baseResult,
                icon: <UIIcon src={ClockLine} />,
                title: result.result.query,
                subtitle: '',
                launchResult: (launchMethod: LaunchMethod) => {
                  setOnboardingValue('dismissedQuerySuggestionBanner', false);
                  papLogResultOpen(result, launchMethod);
                  onInteractedWithResult(result);
                  handleTaggedSearchSubmit(result, false);
                },
                removeResult: () => {
                  removePreviousQuery(result);
                },
                hoverAccessory: accessory,
              };
            }
            case typeahead.ResultType.SuggestedQuery:
              return {
                ...baseResult,
                icon: (
                  <UIIcon
                    src={SearchLine}
                    size="medium"
                    color={'var(--dig-color__text__base)'}
                  />
                ),
                title: stripStringOfPotentialFilterBindings(
                  result.result.query,
                ),
                subtitle: (
                  <SuggestedQueryRowSubTitle
                    query={typeaheadQuery}
                    allFilters={allFilters}
                  />
                ),
                launchResult: (launchMethod: LaunchMethod) => {
                  setOnboardingValue('dismissedQuerySuggestionBanner', false);
                  papLogResultOpen(result, launchMethod);
                  handleTaggedSearchSubmit(result, true);
                },
                priority:
                  isSERP || shouldSearchBeBoosted || typeaheadQuery.length < 3
                    ? 1
                    : 0,
                accessory: (
                  <PrettyShortcuts
                    shortcut={
                      getKeyMaps(platform, isDesktop).typeahead.searchQuery
                    }
                    isDarwin={platform === 'darwin'}
                  />
                ),
                selectedAccessory: <></>, // Don't show anything when selected
                copyResult: () => {
                  if (!result.result.query?.length) return;
                  copyToClipboard(result.result.query);
                },
              };
            case typeahead.ResultType.URLShortcut:
              return {
                ...baseResult,
                icon: <URLShortcutIcon result={result.result} />,
                title: (
                  <ShortcutTitle
                    title={result.result.parameters.title}
                    selected={idx === hoveredIndex || idx === selectedIndex}
                  />
                ),
                subtitle: (
                  <ShortcutSubtitle
                    subtitle={
                      <Highlighter
                        title={result.result.parameters.url}
                        highlightWords={result.result.parameters.highlightWords}
                      />
                    }
                  />
                ),
                launchResult: async (launchMethod: LaunchMethod) => {
                  setOnboardingValue('dismissedQuerySuggestionBanner', false);
                  addURLShortcutToCache(result.result);

                  papLogResultOpen(result, launchMethod);
                  onInteractedWithResult(result);

                  if (result.uuid === CREATE_STACK_ACTION_UUID) {
                    papLogResultOpen(result, launchMethod);
                    onInteractedWithResult(result);
                    // create stack
                    navigate('/stacks/new');
                    setTypeaheadQuery('');
                    return;
                  }

                  if (!result.result.parameters.url?.length) {
                    logger.warn(
                      'attempted to launch URLShortcut without a URL, ignoring',
                    );
                    return;
                  }

                  if (!isSERP) {
                    setTypeaheadQuery('');
                    navigate('/');
                  }

                  try {
                    let launchedLocally = false;
                    if (openFilesInDesktopApps) {
                      launchedLocally = await runLocalLaunchCommandForShortcut(
                        result.uuid as WellKnownShortcutUUID,
                      );
                    }

                    if (!launchedLocally) {
                      await openURL(result.result.parameters.url);
                    }
                  } catch (e) {
                    logger.error('Failed to launch URLShortcut', e);
                  }
                },
                copyResult: () => {
                  if (!result.result.parameters.url) return;
                  copyToClipboard(result.result.parameters.url);
                  papLogResultCopy(result);
                  onInteractedWithResult(result);
                  showSnackbar({ title: i18n.t('copied_link_to_clipboard') });
                },
                // Give this result priority over others. We want URL shortcuts appearing 1st in result list
                priority: 1,
              };
            case typeahead.ResultType.MathCalculation: {
              const copyResultCallback = () => {
                copyToClipboard(result.result.answer);
                showSnackbar({
                  title: i18n.t('copied_math_to_clipboard'),
                });
                papLogResultCopy(result);
                onInteractedWithResult(result);
              };

              return {
                ...baseResult,
                icon: <UIIcon src={CalculatorLine} />,
                title: (
                  <Highlighter
                    title={`${typeaheadQuery.trim().replace(/\s+/g, ' ')} = ${
                      result.result.answer
                    }`}
                    highlightWords={[`= ${result.result.answer}`]}
                  />
                ),
                subtitle: i18n.t('math_calculation_byline'),
                launchResult: () => {
                  setOnboardingValue('dismissedQuerySuggestionBanner', false);
                  copyResultCallback();
                  setTypeaheadQuery(result.result.answer);
                },
                copyResult: copyResultCallback,
                // Give this result priority over others. We want math appearing 1st in result list
                priority: 1,
              };
            }
            case typeahead.ResultType.SearchFilter: {
              return {
                ...baseResult,
                ...getTypeaheadFilterDisplayProps(
                  typeaheadQuery,
                  result.result,
                ),
                launchResult: (launchMethod: LaunchMethod) => {
                  setOnboardingValue('dismissedQuerySuggestionBanner', false);
                  papLogResultOpen(result, launchMethod);

                  const mergedFilterText = mergePartialFilterText(
                    typeaheadQuery,
                    result.result.id,
                  );

                  setTypeaheadQuery(mergedFilterText + ' ');
                },
                keepTypeaheadOpen: true,
                priority: 1,
              };
            }
            default:
              result satisfies never;
              logger.warn('Unrecognized typeahead result type');
              return result;
          }
        })
        .sort(handleSortByPriority)
        .filter((result) => !!result),
    [
      enableTypeaheadCSummarizableRowButton,
      handleTaggedSearchSubmit,
      isSERP,
      navigate,
      onInteractedWithResult,
      openFilesInDesktopApps,
      papLogResultCopy,
      papLogResultOpen,
      platform,
      removePreviousQuery,
      setTypeaheadQuery,
      shouldSearchBeBoosted,
      typeaheadQuery,
      isDesktop,
      allFilters,
      hoveredIndex,
      selectedIndex,
      setOnboardingValue,
    ],
  );
}

const handleSortByPriority = (a?: TypeaheadResult, b?: TypeaheadResult) => {
  // Assign 0 as default if 'priority' is undefined
  const aPriority = a?.priority ?? 0;
  const bPriority = b?.priority ?? 0;

  return bPriority - aPriority;
};
