import { useMirageAnalyticsContext } from '@mirage/analytics/AnalyticsProvider';
import { PAPActionSurface } from '@mirage/analytics/events/base/event';
import { PAP_Click_DashAvatarIcon } from '@mirage/analytics/events/types/click_dash_avatar_icon';
import { Dropdown } from '@mirage/search/General/Dropdown';
import { ReactElement } from 'react';

import type { MenuPlacement } from '@dropbox/dig-components/menu';
import type { DropdownConfig } from '@mirage/search/General/Dropdown';

type Props = {
  anchor: ReactElement;
  config: DropdownConfig;
  placement?: MenuPlacement;
  ariaRole?: string;
};

export default function AvatarDropdown({
  anchor,
  config,
  placement = 'top-start',
  ariaRole,
}: Props) {
  const { reportPapEvent } = useMirageAnalyticsContext();

  return (
    <Dropdown
      anchor={anchor}
      config={config}
      placement={placement}
      closeOnMouseLeave={false}
      onToggle={(open) => {
        if (open) {
          reportPapEvent(
            PAP_Click_DashAvatarIcon({
              actionSurface: PAPActionSurface.START_PAGE,
            }),
          );
        }
      }}
      ariaRole={ariaRole}
    />
  );
}
