import { getCachedOrFetchFeatureValue } from '@mirage/service-experimentation';
import {
  MultiAnswerResponse,
  QuestionAndAnswerResponse,
  QuestionAndAnswerSource,
} from '@mirage/shared/answers/multi-answer';
import { v4 as uuid } from 'uuid';
import { SearchResult } from '../search';

import type { APIv2CallableWithHeaders } from '..';
import type { context_engine, dash } from '@dropbox/api-v2-client';

const NUM_SOURCES_SHOWN = 3;

export type AnswerResponse = {
  answer: string;
  sources: SearchResult[];
  searchRequestId?: string;
  answerId?: string;
  conversationId?: string;
};

function getClientContext(): dash.ClientContext {
  return {
    timezone_string: Intl.DateTimeFormat().resolvedOptions().timeZone,
  };
}

export async function getAnswersForQuery(
  api: APIv2CallableWithHeaders,
  query: string,
  source?: string,
): Promise<MultiAnswerResponse> {
  const contextEngineExperimentSetting = await getCachedOrFetchFeatureValue(
    'context_engine_2024_08_19_experiment_setting',
  );
  const contextEngineExperimentSettingAsString =
    contextEngineExperimentSetting === undefined
      ? undefined
      : String(contextEngineExperimentSetting);
  const result = await api('contextEngineGetAnswersForQuery', {
    user_query: query,
    source: source ?? 'dash',
    client_context: getClientContext() as context_engine.ClientContext,
    experiment_setting: contextEngineExperimentSettingAsString || '',
  });

  const requestId = result.headers.get('x-dropbox-request-id');

  const answers: QuestionAndAnswerResponse[] =
    result.result.answers
      ?.filter((contextEngineAnswer: context_engine.Answer) => {
        const model = contextEngineAnswer.answer?.output?.model?.items?.[0];
        return model?.['.tag'] !== 'context_user_error';
      })
      ?.map((contextEngineAnswer: context_engine.Answer, index: number) => {
        const model = contextEngineAnswer.answer?.output?.model?.items?.[0];

        let answerText: string | undefined;
        let sources: QuestionAndAnswerSource[] = [];
        switch (model?.['.tag']) {
          case 'answer_info':
            answerText = model?.answer;
            sources =
              model.sources?.map((documentInfo) => {
                return {
                  id3p: documentInfo.third_party_id ?? '',
                  brandedType: documentInfo.branded_type ?? '',
                  title: documentInfo.title ?? '',
                  url: documentInfo.url ?? '',
                  connectorName:
                    documentInfo.connector_info?.connector_name ?? '',
                  iconUrl: documentInfo.connector_info?.connector_icon_url,
                  connectorInfo: documentInfo.connector_info,
                  uuid: documentInfo.uuid,
                  lastUpdatedMs: documentInfo.last_updated_ms,
                };
              }) ?? [];
            break;
          case 'string_value':
            answerText = model?.string_value;
            // TODO: waiting for context engine API to return sources in this case
            break;
        }
        const answerId = contextEngineAnswer?.answer_id;

        const conversationId =
          contextEngineAnswer.conversation_id &&
          contextEngineAnswer.conversation_id !== ''
            ? contextEngineAnswer.conversation_id
            : uuid();
        return {
          position: index,
          question: contextEngineAnswer.query ?? '',
          answer: answerText ?? '',
          sources: sources.slice(0, NUM_SOURCES_SHOWN),
          conversationId,
          answerId,
        };
      })
      .filter((item): item is QuestionAndAnswerResponse => !!item) || [];
  return { answers, requestId };
}
