import { stacks } from '@dropbox/api-v2-client';
import { Button, IconButton } from '@dropbox/dig-components/buttons';
import { Menu } from '@dropbox/dig-components/menu';
import { Truncate } from '@dropbox/dig-components/truncate';
import { Text, Title } from '@dropbox/dig-components/typography';
import { UIIcon } from '@dropbox/dig-icons';
import {
  ChevronDownLine,
  ChevronUpLine,
  DeleteLine,
  EditLine,
  MoreHorizontalLine,
} from '@dropbox/dig-icons/dist/mjs/assets';
import { useMirageAnalyticsContext } from '@mirage/analytics/AnalyticsProvider';
import { PAP_Cancel_DashMoreActions } from '@mirage/analytics/events/types/cancel_dash_more_actions';
import { PAP_Click_DashMoreActions } from '@mirage/analytics/events/types/click_dash_more_actions';
import { PAP_Delete_StackSection } from '@mirage/analytics/events/types/delete_stack_section';
import { PAP_Rename_StackSection } from '@mirage/analytics/events/types/rename_stack_section';
import { useStackPageAugustRevisionEnabled } from '@mirage/august-revision-hook';
import { upsertStack } from '@mirage/service-stacks';
import { stackDerivePAPProps } from '@mirage/service-stacks/service/utils';
import { DigTooltip } from '@mirage/shared/util/DigTooltip';
import { nonNil } from '@mirage/shared/util/tiny-utils';
import {
  activeStackActionAtom,
  activeStackAtom,
  activeStackHasWritePermissionsAtom,
  activeStackMutationRequestIdAtom,
  activeStackSectionIdAtom,
  activeStackSectionsAtom,
} from '@mirage/stacks/ActiveStack/atoms';
import i18n from '@mirage/translations';
import classnames from 'classnames';
import { useAtom, useAtomValue } from 'jotai';
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { SectionsActionType } from '../types';
import { asStackUpsertId } from '../utils';
import { FancyInlineEdit } from './FancyInlineEdit';
import styles from './Header.module.css';
import { useSectionsNavigation } from './hooks';

export const Header = ({
  section,
  isCollapsed = false,
  setIsCollapsed,
  linkCount,
}: {
  section: stacks.Section;
  isCollapsed?: boolean;
  setIsCollapsed?: Dispatch<SetStateAction<boolean>>;
  linkCount: number;
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const sectionId = nonNil(section.id, 'section.id');
  const { reportPapEvent } = useMirageAnalyticsContext();
  const { getSectionRef } = useSectionsNavigation();
  const sectionRef = getSectionRef(sectionId);
  const mutationId = useAtomValue(activeStackMutationRequestIdAtom);

  const [currentAction, setCurrentAction] = useAtom(activeStackActionAtom);
  const stack = useAtomValue(activeStackAtom);
  const [currentSectionId, setCurrentSectionId] = useAtom(
    activeStackSectionIdAtom,
  );
  const hasWritePermissions = useAtomValue(activeStackHasWritePermissionsAtom);
  const [{ allSections: sections }, setSections] = useAtom(
    activeStackSectionsAtom,
  );
  const augustRevision = useStackPageAugustRevisionEnabled();

  const onDeleteSection = useCallback(
    async (sectionId: string) => {
      if (!sections) return;

      const newSections = sections.filter(
        (section) => section.id !== sectionId,
      );
      if (stack && stack.namespace_id) {
        reportPapEvent(
          PAP_Delete_StackSection({
            ...stackDerivePAPProps(stack),
            featureLine: 'stacks',
          }),
        );
      }
      const update: stacks.StackDataUpdate = {
        field: {
          '.tag': 'section_data_update',
          sections: newSections,
        },
      };
      await upsertStack(asStackUpsertId(stack?.namespace_id, mutationId), [
        update,
      ]);
      setSections(newSections);
    },
    [reportPapEvent, sections, setSections, stack, mutationId],
  );

  const onRenameSection = useCallback(
    async (newName: string, sectionId: string) => {
      if (!sections) return;

      const newSections = sections.map((section) => {
        if (section.id === sectionId) {
          return {
            ...section,
            title: newName,
          };
        }
        return section;
      });
      if (stack && stack.namespace_id) {
        reportPapEvent(
          PAP_Rename_StackSection({
            ...stackDerivePAPProps(stack),
            featureLine: 'stacks',
          }),
        );
      }
      const update: stacks.StackDataUpdate = {
        field: {
          '.tag': 'section_data_update',
          sections: newSections,
        },
      };
      await upsertStack(asStackUpsertId(stack?.namespace_id, mutationId), [
        update,
      ]);
      setSections(newSections);
    },
    [reportPapEvent, sections, setSections, stack, mutationId],
  );

  const handleSubmit = useCallback(
    (title: string) => {
      setIsEditing(false);
      setCurrentAction(null);
      onRenameSection(title, sectionId);
    },
    [onRenameSection, sectionId, setCurrentAction],
  );

  const handleStartEditing = useCallback(() => {
    setIsEditing(true);
    setCurrentAction(SectionsActionType.EDIT_SECTION);
    setCurrentSectionId(sectionId);
  }, [setCurrentAction, setCurrentSectionId, sectionId]);

  useEffect(() => {
    if (currentAction !== SectionsActionType.EDIT_SECTION) {
      setIsEditing(false);
    }
  }, [currentAction]);

  useEffect(() => {
    sectionRef?.current?.addEventListener('edit', handleStartEditing);
  }, [handleStartEditing, sectionRef]);

  return (
    // This ultimately seemed easier (copying over) rather than trying to fit into
    // the TwoColumnGridCard component due to the special case inline title editing
    // functionality, along with the HeaderMenu props that were already well
    // defined in this component
    <div
      className={classnames(
        styles.headerContainer,
        augustRevision && styles.augustHeaderContainer,
      )}
      ref={sectionRef}
    >
      <div className={styles.titleContainer}>
        <Title
          className={classnames(
            styles.primaryTitle,
            styles.titleSizeXSmall,
            augustRevision && styles.augustRevisionTitle,
          )}
          weightVariant="emphasized"
        >
          {isEditing && section.id === currentSectionId ? (
            <FancyInlineEdit
              title={section.title ?? ''}
              onSubmit={handleSubmit}
              onCancel={() => {
                setIsEditing(false);
                setCurrentAction(null);
                setCurrentSectionId(null);
              }}
            />
          ) : (
            <DigTooltip title={section.title ?? ''}>
              <span>
                <Truncate>{section.title}</Truncate>
              </span>
            </DigTooltip>
          )}
        </Title>
        {isCollapsed && (
          <>
            <Text color="faint">{'·'}</Text>
            <Text color="faint">
              {i18n.t('num_items', {
                count: linkCount,
              })}
            </Text>
          </>
        )}
        {augustRevision && (
          <ExpandCollapseButton
            linkCount={linkCount}
            isCollapsed={isCollapsed}
            setIsCollapsed={setIsCollapsed}
            iconOnly
          />
        )}
      </div>
      <div className={styles.actionsContainer} data-testid="actionsContainer">
        {!augustRevision && (
          <ExpandCollapseButton
            linkCount={linkCount}
            isCollapsed={isCollapsed}
            setIsCollapsed={setIsCollapsed}
          />
        )}
        {!isEditing && hasWritePermissions && (
          <HeaderMenu
            stack={stack}
            onDeleteSection={() => onDeleteSection(sectionId)}
            onEditSection={() => {
              setIsEditing(true);
              setCurrentAction(SectionsActionType.EDIT_SECTION);
              setCurrentSectionId(sectionId);
            }}
            isHoveringSection={true}
          />
        )}
      </div>
    </div>
  );
};

const ExpandCollapseButton = ({
  linkCount,
  isCollapsed,
  setIsCollapsed,
  iconOnly,
}: {
  linkCount: number;
  isCollapsed: boolean;
  setIsCollapsed?: (x: boolean) => void;
  iconOnly?: boolean;
}) => {
  if (linkCount === 0) {
    return null;
  }
  if (iconOnly) {
    return (
      <IconButton
        variant="transparent"
        onClick={() => {
          setIsCollapsed?.(!isCollapsed);
        }}
        aria-label={isCollapsed ? i18n.t('expand') : i18n.t('collapse')}
        style={{ color: 'var(--dig-color__text__subtle)' }}
        size="small"
      >
        <UIIcon
          src={isCollapsed ? ChevronDownLine : ChevronUpLine}
          size="small"
        />
      </IconButton>
    );
  }

  const text = isCollapsed ? i18n.t('expand') : i18n.t('collapse');
  return (
    <Button
      variant="borderless"
      style={{ color: 'var(--dig-color__text__subtle)' }}
      onClick={() => {
        setIsCollapsed?.(!isCollapsed);
      }}
      className={isCollapsed ? styles.collapseBtn : styles.expandBtn}
      withDropdownIcon
      size="medium"
    >
      <Text color="faint" size="small">
        {text}
      </Text>
    </Button>
  );
};

export const HeaderMenu = ({
  stack,
  onEditSection,
  onDeleteSection,
  isHoveringSection,
  setIsHoveringOnMenu,
}: {
  stack: stacks.Stack | null;
  onEditSection: () => void;
  onDeleteSection: () => void;
  isHoveringSection?: boolean;
  setIsHoveringOnMenu?: (value: boolean) => void;
}) => {
  const { reportPapEvent } = useMirageAnalyticsContext();

  const handleClickDashMore = useCallback(
    (isOpen: boolean) => {
      if (!stack) return;
      const papFn = isOpen
        ? PAP_Click_DashMoreActions
        : PAP_Cancel_DashMoreActions;
      reportPapEvent(
        papFn({
          ...stackDerivePAPProps(stack),
          featureLine: 'stacks',
        }),
      );
    },
    [stack, reportPapEvent],
  );

  return (
    <Menu.Wrapper
      className={classnames(
        styles.sectionHeaderMenu,
        isHoveringSection && styles.sectionHeaderVisible,
      )}
      onToggle={({ isOpen }) => {
        handleClickDashMore(isOpen);
      }}
      onMouseEnter={() => setIsHoveringOnMenu?.(true)}
      onMouseLeave={() => setIsHoveringOnMenu?.(false)}
    >
      {({ getContentProps, getTriggerProps }) => (
        <>
          <DigTooltip title={i18n.t('more')}>
            <div>
              <IconButton variant="transparent" {...getTriggerProps()}>
                <UIIcon
                  src={MoreHorizontalLine}
                  style={{ color: 'var(--dig-color__text__subtle)' }}
                />
              </IconButton>
            </div>
          </DigTooltip>
          <Menu.Content {...getContentProps()} placement="bottom-end">
            <Menu.Segment>
              <Menu.ActionItem
                withLeftAccessory={<UIIcon src={EditLine} />}
                onClick={onEditSection}
                data-testid="renameSection"
              >
                {i18n.t('rename_section')}
              </Menu.ActionItem>
              <Menu.ActionItem
                withLeftAccessory={<UIIcon src={DeleteLine} />}
                onClick={onDeleteSection}
              >
                {i18n.t('remove_section')}
              </Menu.ActionItem>
            </Menu.Segment>
          </Menu.Content>
        </>
      )}
    </Menu.Wrapper>
  );
};
