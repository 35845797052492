// @generated - Do not edit: this file is autogenerated by:
//   //dropbox/pap/codegen
//
// Event definition: https://pap.pp.dropbox.com/#/events/3621
import type { PAPEventGeneric } from "../base/event";
import type { DashRoute } from "../enums/dash_route";

// Event_name: 'eng.dash_webpage_error'
// Description: Logged by Error boundary at route level
// Owner: dash-web
export type Eng_DashWebpageError = PAPEventGeneric<
  'dash',
  'eng',
  'dash_webpage_error',
  {
    // Route name where unhandled exception is thrown and caught by Error boundary
    dashRoute?: DashRoute;
  }
>;

export function PAP_Eng_DashWebpageError(properties?: Eng_DashWebpageError['properties']): Eng_DashWebpageError {
  return <Eng_DashWebpageError>{
    class: 'dash',
    action: 'eng',
    object: 'dash_webpage_error',
    properties,
  };
}
