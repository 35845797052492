import { dash_feed } from '@dropbox/api-v2-client';
import { Connector } from '@mirage/service-dbx-api/service';
import { ConnectorName } from '@mirage/shared/connectors';
import { DEFAULT_FILTERS } from '../service';
import { ActivityFeedFilters } from '../types';

// Returns true if activity feed filters are set, false otherwise
export const areFiltersSet = (filters: ActivityFeedFilters): boolean => {
  if (filters.isOnlyMine != DEFAULT_FILTERS.isOnlyMine) {
    return true;
  }

  if (filters.applications != DEFAULT_FILTERS.applications) {
    return true;
  }

  if (filters.actor != DEFAULT_FILTERS.actor) {
    return true;
  }

  return false;
};

const SUPPORTED_FEED_CONNECTORS: ConnectorName[] = [
  'dropbox',
  'confluence_cloud',
  'googledrive',
  'sharepoint',
  'onedrive',
];

export const isSupportedActivityFeedConnector = (connector: Connector) => {
  const isValid =
    connector.id_attrs?.type &&
    connector.branding?.display_name &&
    connector.branding?.icon_src;

  if (!isValid) {
    return false;
  }

  const isSupported = SUPPORTED_FEED_CONNECTORS.includes(
    (connector.id_attrs?.type || '') as ConnectorName,
  );

  return isSupported;
};

export const getFirstActivity = (item: dash_feed.FeedItem) => {
  return item.activities?.[0];
};

export const getFirstActor = (item: dash_feed.FeedItem) => {
  return getFirstActivity(item)?.actor;
};

export const getActors = (item: dash_feed.FeedItem) => {
  const actors =
    item.activities
      ?.map((activity) => activity.actor)
      .filter((activity) => !!activity) || [];

  return uniqueActivityUsers(actors);
};

const uniqueActivityUsers = (actors: dash_feed.ActivityUser[]) => {
  const actorIds = new Set<string>();
  const uniqueActors: dash_feed.ActivityUser[] = [];
  for (const actor of actors) {
    const id = String(
      actor.user_id || actor.email || actor.name || '',
    ).toLocaleLowerCase();
    if (id && !actorIds.has(id)) {
      actorIds.add(id);
      uniqueActors.push(actor);
    }
  }

  return uniqueActors;
};

export const getFirstAction = (item: dash_feed.FeedItem) => {
  return getFirstActivity(item)?.action?.action;
};
