import { Button } from '@dropbox/dig-components/buttons';
import { Title } from '@dropbox/dig-components/typography';
import { UIIcon } from '@dropbox/dig-icons';
import {
  CreateStackLine,
  CustomizeLine,
} from '@dropbox/dig-icons/dist/mjs/assets';
import { useMirageAnalyticsContext } from '@mirage/analytics/AnalyticsProvider';
import { PAP_Click_DashNewStack } from '@mirage/analytics/events/types/click_dash_new_stack';
import { PAP_Close_CustomizeLayoutModal } from '@mirage/analytics/events/types/close_customize_layout_modal';
import { PAP_Open_CustomizeLayoutModal } from '@mirage/analytics/events/types/open_customize_layout_modal';
import { CustomizeHomePageModal } from '@mirage/mosaics/CustomizeHomePage/CustomizeHomePageModal';
import { useCustomizableModulesInternalSettings } from '@mirage/service-settings/useCustomizableModuleSettings';
import { IconButtonWithTooltip } from '@mirage/shared/icons/IconButtonWithTooltip';
import {
  CardHeaderType,
  TwoColumnGridCard,
} from '@mirage/shared/two-column-grid/TwoColumnGridCard';
import { transparentTheme } from '@mirage/stacks/themes/themeDefinitions';
import i18n from '@mirage/translations';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './ForYouModule.module.css';

export const ForYouModule = ({ hasNoStacks }: { hasNoStacks: boolean }) => {
  const navigate = useNavigate();
  const { reportPapEvent } = useMirageAnalyticsContext();
  const customizableModulesInternalSettings =
    useCustomizableModulesInternalSettings();
  const [openCustomizeModal, setOpenCustomizeModal] = useState(false);

  const handleOpenCustomizeModal = () => {
    setOpenCustomizeModal(true);
    reportPapEvent(
      PAP_Open_CustomizeLayoutModal({
        featureLine: 'startpage_customization',
      }),
    );
  };

  const handleCloseCustomizeModal = () => {
    setOpenCustomizeModal(false);
    reportPapEvent(
      PAP_Close_CustomizeLayoutModal({
        featureLine: 'startpage_customization',
      }),
    );
  };

  const customHeader = (
    <div className={styles.headerContainer}>
      <Title weightVariant="emphasized" size="small">
        {i18n.t('for_you')}
      </Title>
      <div className={styles.buttonContainer}>
        <IconButtonWithTooltip
          tooltipProps={{
            title: i18n.t('customize_modal_title'),
          }}
          data-testid="ForYou-customize"
          variant="outline"
          onClick={handleOpenCustomizeModal}
        >
          <UIIcon src={CustomizeLine} />
        </IconButtonWithTooltip>
        <Button
          variant="primary"
          data-testid="ForYou-createStack"
          withIconStart={<UIIcon src={CreateStackLine} />}
          onClick={() => {
            reportPapEvent(
              PAP_Click_DashNewStack({
                stacksEmptyState: hasNoStacks,
                featureLine: 'stacks',
              }),
            );
            navigate('/stacks/new');
          }}
        >
          {i18n.t('create_stack')}
        </Button>
      </div>
      {/* Need to put inside react DOM so that it can pick up settings changes.
          `showModal` will only capture a frozen snapshot of the settings. */}
      <CustomizeHomePageModal
        open={openCustomizeModal}
        onClose={handleCloseCustomizeModal}
        internalSettings={customizableModulesInternalSettings}
      />
    </div>
  );

  return (
    <TwoColumnGridCard
      settingId="for_you"
      cardTypeProps={{
        cardType: CardHeaderType.CUSTOM,
        customHeader,
      }}
      isAlwaysCollapsed={false}
      showDividerLine={false}
      className={styles.container}
      theme={transparentTheme}
    />
  );
};
