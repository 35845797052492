import { RadioButton } from '@dropbox/dig-components/controls';
import { Text } from '@dropbox/dig-components/typography';
import * as React from 'react';
import BaseListItem from '../BaseListItem';
import styles from './RadioListItem.module.css';

export { default as createRadios } from './createRadios';

export type RadioProps = Readonly<{
  /** left hand side icon */
  icon?: React.ReactNode;
  /** accepts and returns boolean or 0 and 1 */
  value?: number;

  onClick: (val: string | number | void) => void;
  /** default title */
  title: React.ReactNode;
  /** optional aria label */
  ariaLabel?: string;
}>;

export default function RadioListItem({
  icon,
  onClick,
  value,
  title = '',
  ariaLabel,
}: RadioProps) {
  const checked = !!value;
  return (
    <BaseListItem
      icon={icon}
      onClick={onClick}
      title={<Text htmlFor={`radio-${title}`}>{title}</Text>}
      role="radio"
      ariaLabel={ariaLabel}
      ariaChecked={checked}
    >
      <div className={styles.radioListItem}>
        <RadioButton
          id={`radio-${title}`}
          className={styles.radioButton}
          checked={checked}
          readOnly={true}
          aria-hidden={true}
        />
      </div>
    </BaseListItem>
  );
}
