import { ActionSurfaceComponent } from '@mirage/analytics/events/enums/action_surface_component';
import { FeatureLine } from '@mirage/analytics/events/enums/feature_line';
import { PAP_Open_DashLink } from '@mirage/analytics/events/types/open_dash_link';
import { LinkComponent, LinkList } from '@mirage/link-list';
import {
  Link,
  LinkExposurePapEvent,
  LinkOpenPAPEvent,
  ListItemSize,
} from '@mirage/link-list/types';
import {
  CardHeaderType,
  TwoColumnGridCard,
  TwoColumnGridCardProps,
} from '@mirage/shared/two-column-grid/TwoColumnGridCard';
import { StackTheme } from '@mirage/stacks/themes';

export type LinkWithRenderData = Link & {
  accessoryComponent?: React.ReactNode;
  isInternalLink?: { state: unknown };
  icon?: JSX.Element;
  iconTheme?: StackTheme;
  exposurePapEvents?: Array<LinkExposurePapEvent>;
};

export const AsyncLinkListCard = ({
  title,
  links,
  showLoading = true,
  loadingRowCount,
  actionSurfaceComponent,
  featureLine,
  dashConnectorId,
  openPapEvents,
  ...cardProps
}: {
  title: string;
  links: LinkWithRenderData[] | undefined;
  showLoading?: boolean;
  loadingRowCount: number;
  actionSurfaceComponent: ActionSurfaceComponent;
  featureLine: FeatureLine;
  dashConnectorId?: string;
  openPapEvents?: LinkOpenPAPEvent[];
} & Omit<TwoColumnGridCardProps, 'cardTypeProps'>) => {
  return (
    <TwoColumnGridCard
      cardTypeProps={{
        cardType: CardHeaderType.INLINE_SUBTITLE,
        actionSurfaceComponent,
        featureLine,
        title,
      }}
      {...cardProps}
    >
      <LinkList listItemSize={ListItemSize.Large}>
        {links && links.length > 0
          ? links.map((link, index) => (
              <LinkComponent
                key={link.id ?? index}
                link={link}
                listItemSize={ListItemSize.Large}
                accessoryComponent={link.accessoryComponent}
                icon={link.icon}
                iconTheme={link.iconTheme}
                isInternalLink={link.isInternalLink}
                exposurePapEvents={link.exposurePapEvents}
                openPapEvents={
                  openPapEvents ?? [
                    PAP_Open_DashLink({
                      actionSurfaceComponent,
                      featureLine,
                      dashConnectorId,
                    }),
                  ]
                }
              />
            ))
          : showLoading
            ? Array.from({ length: loadingRowCount }, () => ({
                isLoading: true,
                url: '',
                title: '',
              })).map((link, index) => (
                <LinkComponent
                  key={index}
                  listItemSize={ListItemSize.Large}
                  link={link}
                  isLoading={true}
                />
              ))
            : undefined}
      </LinkList>
    </TwoColumnGridCard>
  );
};
