import { ComputerLine } from '@dropbox/dig-icons/assets';
import { useMirageAnalyticsContext } from '@mirage/analytics/AnalyticsProvider';
import { PAP_Select_DownloadDashUpsell } from '@mirage/analytics/events/types/select_download_dash_upsell';
import { EnvCtx } from '@mirage/service-environment-context/global-env-ctx';
import { getFirstLoginTime } from '@mirage/service-onboarding';
import { useOnboardingValues } from '@mirage/service-onboarding/hooks';
import { openURL } from '@mirage/service-platform-actions';
import useAvailableExtensions from '@mirage/settings/utils/useAvailableExtensions';
import useIsMobileDevice from '@mirage/settings/utils/useIsMobileDevice';
import {
  logoForCurrentBrowser,
  openExtensionLinkForCurrentBrowser,
  useIsExtensionInstalled,
} from '@mirage/settings/utils/webExtensionsHelpers';
import { DESKTOP_DOWNLOAD_URL } from '@mirage/shared/urls';
import i18n from '@mirage/translations';
import { SVGProps, useCallback, useEffect, useState } from 'react';

export type DownloadDashEntry = {
  title: string;
  content: string;
  callToAction: string;
  completeCallToAction: string;
  complete: boolean;
  onAction: () => void;
  icon?: (props: SVGProps<SVGSVGElement>) => React.ReactElement;
};

const SEEN_DOWNLOAD_DASH_UPSELL = 'seenDownloadDashUpsell';
const DISMISSED_DOWNLOAD_DASH_UPSELL = 'dismissedDownloadDashUpsell';

const DOWNLOAD_DESKTOP: DownloadDashEntry = {
  icon: ComputerLine,
  title: i18n.t('desktop_app'),
  content: i18n.t('search_your_local_files'),
  callToAction: i18n.t('install_upsell'),
  completeCallToAction: i18n.t('install_again'),
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onAction: () => {},
  complete: false,
};

const DOWNLOAD_EXTENSION: DownloadDashEntry = {
  title: i18n.t('tab_manager_browser_extension'),
  content: i18n.t('search_and_organize_your_tabs'),
  callToAction: i18n.t('add_extension'),
  completeCallToAction: i18n.t('add_again'),
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onAction: () => {},
  complete: false,
};

export default function useDownloadDashUpsell() {
  const [open, setOpen] = useState(false);
  const [seen, setSeen] = useState(false);
  const [dismissed, setDismissed] = useState(false);
  const extensionInstalled = useIsExtensionInstalled();
  const isMobileDevice = useIsMobileDevice();
  const { reportPapEvent } = useMirageAnalyticsContext();
  const [installers, setInstallers] = useState<DownloadDashEntry[]>([]);
  const [appsInstalled, setAppsInstalled] = useState(0);
  const { extensionAvailableForBrowser } = useAvailableExtensions();

  const { getOnboardingValue, setOnboardingValue } = useOnboardingValues();

  const addBrowserExtension = useCallback(() => {
    reportPapEvent(PAP_Select_DownloadDashUpsell());
    openExtensionLinkForCurrentBrowser();
  }, [reportPapEvent]);

  const installDesktopApp = useCallback(() => {
    reportPapEvent(PAP_Select_DownloadDashUpsell());
    openURL(DESKTOP_DOWNLOAD_URL);
  }, [reportPapEvent]);

  const handleDismissed = () => {
    setDismissed(true);
    setOnboardingValue(DISMISSED_DOWNLOAD_DASH_UPSELL, true);
  };

  useEffect(() => {
    const fetchUpsellSeen = async () => {
      const seen = await getOnboardingValue(SEEN_DOWNLOAD_DASH_UPSELL);
      setSeen(seen);
    };

    fetchUpsellSeen();
  }, [getOnboardingValue, setSeen]);

  useEffect(() => {
    const getDismissed = async () => {
      const isDismissed = await getOnboardingValue(
        DISMISSED_DOWNLOAD_DASH_UPSELL,
      );
      setDismissed(isDismissed);
    };
    getDismissed();
  }, [getOnboardingValue]);

  useEffect(() => {
    const fetchInstallers = async () => {
      const installers: DownloadDashEntry[] = [];
      const hasInstalledDesktop = await getFirstLoginTime('desktop');

      installers.push({
        ...DOWNLOAD_DESKTOP,
        onAction: installDesktopApp,
        complete: !!hasInstalledDesktop,
      });
      if (EnvCtx.surface !== 'extension' && extensionAvailableForBrowser) {
        const icon = logoForCurrentBrowser(false);
        installers.push({
          ...DOWNLOAD_EXTENSION,
          icon,
          onAction: addBrowserExtension,
          complete: extensionInstalled,
        });
      }
      setInstallers(installers);
      setAppsInstalled(installers.filter((i) => i.complete).length);
    };
    fetchInstallers();
  }, [
    addBrowserExtension,
    installDesktopApp,
    extensionInstalled,
    extensionAvailableForBrowser,
  ]);

  return {
    open,
    enabled: appsInstalled !== installers.length && !isMobileDevice,
    dismissed,
    seen,
    installers,
    onOpen: setOpen,
    onSeen: setSeen,
    onDismissed: handleDismissed,
  };
}
