import { Text } from '@dropbox/dig-components/typography';
import { PrettyShortcuts } from '@mirage/shared/keyboard-shortcuts/PrettyShortcuts';
import { useIsMobileSize } from '@mirage/shared/responsive/mobile';
import i18n from '@mirage/translations';
import styles from './TypeaheadShortcutBanner.module.css';

type TypeaheadKeyboardShortcutBannerProps = {
  isDarwin: boolean;
  showAddToStackShortcut?: boolean;
  showSummarizeShortcut?: boolean;
};

export const TypeaheadKeyboardShortcutBanner = ({
  isDarwin,
  showAddToStackShortcut = false,
  showSummarizeShortcut = false,
}: TypeaheadKeyboardShortcutBannerProps) => {
  const isMobileSize = useIsMobileSize();

  return (
    <div className={styles.container}>
      {!isMobileSize && showSummarizeShortcut && (
        <div className={styles.shortcut}>
          <PrettyShortcuts
            shortcut={['commandOrControl', 'S']}
            isDarwin={isDarwin}
          />
          <Text size="small" monospace className={styles.text}>
            {i18n.t('summarizable_reason_yes_summarizable')}
          </Text>
        </div>
      )}
      {!isMobileSize && showAddToStackShortcut && (
        <div className={styles.shortcut}>
          <PrettyShortcuts
            shortcut={['commandOrControl', 'G']}
            isDarwin={isDarwin}
          />
          <Text size="small" monospace className={styles.text}>
            {i18n.t('add_to_stack')}
          </Text>
        </div>
      )}
      {!isMobileSize && (
        <div className={styles.shortcut}>
          <PrettyShortcuts
            shortcut={['commandOrControl', 'C']}
            isDarwin={isDarwin}
          />
          <Text size="small" monospace className={styles.text}>
            {i18n.t('copy_link')}
          </Text>
        </div>
      )}
      <div className={styles.shortcut}>
        <PrettyShortcuts
          shortcut={['/new']}
          isDarwin={isDarwin}
          casingOverride="lowercase"
        />
        <Text size="small" monospace className={styles.text}>
          {i18n.t('typeahead_shortcut_create')}
        </Text>
      </div>
    </div>
  );
};
