import "./App.css";
// CSS imports do not affect JS tree shaking.
// eslint-disable-next-line no-restricted-imports
import "@dropbox/dig-components/dist/index.web.css";
import "@dropbox/dig-fonts/dist/index.min.css";
import "@dropbox/dig-fonts/dist/sharp_grotesk_23.min.css";
import "@dropbox/dig-foundations/dist/index.css";

import { useEffect } from "react";
import { BrowserRouter, useLocation } from "react-router-dom";

import { OnboardingChecklistEnabledAtom } from "@mirage/growth";
import { FullPageSpinner } from "@mirage/mosaics/FullPageSpinner";
import {
  FeatureFlagsStatus,
  useInitFeatureFlags,
} from "@mirage/service-experimentation/useInitFeatureFlags";
import { tagged } from "@mirage/service-logging";
import { logPageLoadMilestoneOnce } from "@mirage/service-operational-metrics/page-load";
import { useSetCurrentLocation } from "@mirage/shared/location/atomHooks";
import { SmoothShow } from "@mirage/shared/smooth-show/SmoothShow";
import { AppShell } from "@mirage/webapp/components/AppShell";
import {
  useInitPriorityAuthedJobs,
  useInitUxaPublisher,
} from "@mirage/webapp/hooks/useInitAuthedJobs";
import * as Sentry from "@sentry/react";
import { useSetAtom } from "jotai";

import { useRoute } from "./Routes";
import { AuthedContainer } from "./components/AuthedContainer";
import { ExtensionConnector } from "./components/ExtensionConnector";
import { useAuthCheck } from "./hooks/useAuthCheck";
import { useFullstory } from "./hooks/useFullstory";
import { useInitSentryReplay } from "./hooks/useInitSentryReplay";
import { useRedirectFromAmplifyIfNeeded } from "./hooks/useRedirectFromAmplifyIfNeeded";
import { useRedirectToStagingIfNeeded } from "./hooks/useRedirectToStagingIfNeeded";

const logger = tagged("App.tsx");

const AppContent = () => {
  logPageLoadMilestoneOnce("AppContent start");

  const setOnboardingChecklistEnabled = useSetAtom(
    OnboardingChecklistEnabledAtom,
  );
  useFullstory();
  const route = useRoute();
  const { loggedIn } = useAuthCheck(route);
  const featureFlagsStatus = useInitFeatureFlags(loggedIn);
  const location = useLocation();

  useEffect(() => {
    setOnboardingChecklistEnabled(true);
  }, [setOnboardingChecklistEnabled]);

  useEffect(() => {
    // This is used by the extension to remember the last location of the app.
    logger.info(`App - posting location changes ${location.pathname}`);
    window.parent.postMessage({ locationChanged: location.pathname }, "*");
  }, [location.pathname]);

  useEffect(() => {
    if (loggedIn && window.opener) {
      logger.info("App - in embed mode since window.opener is populated");
      window.opener?.postMessage("oauth-complete");
    }
  }, [loggedIn]);

  // We set this here in addition to setting it in Mirage code because
  // the Mirage code call is only hit if the user is logged in.
  // TODO: https://jira.dropboxer.net/browse/DASHWEB-4309
  useSetCurrentLocation();

  if (loggedIn) {
    logger.debug(`AppContent loggedIn`);
    return <PostAuthCheckContainer featureFlagsStatus={featureFlagsStatus} />;
  }

  if (
    route.requiresAuthentication ||
    featureFlagsStatus !== FeatureFlagsStatus.LOGGED_OUT_READY
  ) {
    // Wait for useAuthCheck to take us to the login page
    // or for feature flags to be ready before mounting the
    // "public" components.
    logger.info(
      `AppContent spinner: ` +
        `requiresAuthentication=${route.requiresAuthentication}, ` +
        `featureFlagsStatus=${featureFlagsStatus}`,
    );
    return <FullPageSpinner spinnerId="App_notLoggedIn" />;
  }

  return (
    <SmoothShow>
      <route.Component />
    </SmoothShow>
  );
};

const PostAuthCheckContainer: React.FC<{
  featureFlagsStatus: FeatureFlagsStatus;
}> = ({ featureFlagsStatus }) => {
  logPageLoadMilestoneOnce("PostAuthCheckContainer start");

  useEffect(() => {
    if (featureFlagsStatus !== FeatureFlagsStatus.LOGGED_IN_READY) {
      Sentry.captureMessage(
        `[App] PostAuthCheckContainer showing spinner due to flags status: ${featureFlagsStatus}`,
      );
    }
  }, [featureFlagsStatus]);

  useRedirectToStagingIfNeeded();
  useRedirectFromAmplifyIfNeeded();
  useInitSentryReplay();
  useInitPriorityAuthedJobs();
  useInitUxaPublisher();

  return featureFlagsStatus === FeatureFlagsStatus.LOGGED_IN_READY ? (
    <AuthedContainer />
  ) : (
    <FullPageSpinner spinnerId="App_initAuth" />
  );
};

export const App: React.FC = () => {
  logPageLoadMilestoneOnce("App start");

  return (
    <BrowserRouter>
      <AppShell>
        {/* WARNING: This component must be loaded after the auth state of
            mirage has stabilized. Otherwise there will be a race condition
            of detecting whether the webapp is currently logged in or not. */}
        <ExtensionConnector />
        <AppContent />
      </AppShell>
    </BrowserRouter>
  );
};
