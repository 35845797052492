import { Text } from '@dropbox/dig-components/typography';
import {
  QuestionAndAnswerResponse,
  QuestionAndAnswerSource,
} from '@mirage/shared/answers/multi-answer';
import { KeyCodes } from '@mirage/shared/util/constants';
import { DigTooltip } from '@mirage/shared/util/DigTooltip';
import i18n from '@mirage/translations';
import { useEffect, useRef } from 'react';
import styles from './Source.module.css';

type SourceProps = {
  source: QuestionAndAnswerSource;
  answer: QuestionAndAnswerResponse;
  onClick: (
    answer: QuestionAndAnswerResponse,
    source: QuestionAndAnswerSource,
  ) => void;
  onShown: (
    answer: QuestionAndAnswerResponse,
    source: QuestionAndAnswerSource,
  ) => void;
};

export const Source = ({ source, answer, onClick, onShown }: SourceProps) => {
  const hasFiredShownEvent = useRef(false);

  useEffect(() => {
    if (hasFiredShownEvent.current) return;
    onShown(answer, source);
    hasFiredShownEvent.current = true;

    return () => {
      hasFiredShownEvent.current = false;
    };
  }, [onShown, answer, source]);

  return (
    <DigTooltip title={source.title}>
      <button
        className={styles.sourceButton}
        onClick={() => {
          onClick(answer, source);
        }}
        onKeyDown={(e) => {
          if (e.key === KeyCodes.enter) {
            onClick(answer, source);
          }
        }}
      >
        <img
          className={styles.sourceIcon}
          alt={i18n.t('answers_source_logo_alt', {
            connectorName: source.connectorName,
          })}
          src={source.iconUrl}
        />
        <Text className={styles.sourceTitle} size="small" isBold>
          {source.title}
        </Text>
      </button>
    </DigTooltip>
  );
};
