import { Avatar, AvatarSizes } from '@dropbox/dig-components/avatar';
import { Text } from '@dropbox/dig-components/typography';
import { getInitials } from '@mirage/shared/account';
import i18n from '@mirage/translations';
import classNames from 'classnames';
import styles from './Avatar.module.css';
import { SharingMember } from './Types';

function getAbbreviation(member: SharingMember): string {
  switch (member['.tag']) {
    case 'user':
      return getInitials(member.displayName);
    case 'group':
      return member.memberCount < 99 ? member.memberCount.toString() : '99+';
    default:
      return '';
  }
}

export const AvatarMemberOption: React.FC<{
  member: SharingMember;
  onSelection?: () => void;
  avatarSize?: AvatarSizes;
  subtitleSuffix?: string;
}> = ({ member, onSelection, avatarSize, subtitleSuffix }) => {
  const subtitlePrefix =
    member['.tag'] === 'user'
      ? member.email
      : `${i18n.t('company_accesslevel')} · ${i18n.t('num_members', {
          count: member.memberCount,
        })}`;
  const subtitle = subtitleSuffix
    ? `${subtitlePrefix} · ${subtitleSuffix}`
    : subtitlePrefix;
  return (
    <div
      tabIndex={0}
      role="button"
      className={classNames(
        styles.sharingContact,
        onSelection !== undefined ? styles.sharingContactHover : '',
      )}
      onMouseDown={onSelection}
    >
      <AvatarMember member={member} avatarSize={avatarSize} />
      <div className={styles.sharingContactTextContainer}>
        <Text isBold size="medium" className={styles.standardText}>
          {member.displayName}
        </Text>
        <Text className={styles.faintText} size="small">
          {subtitle}
        </Text>
      </div>
    </div>
  );
};

export const AvatarMember: React.FC<{
  member: SharingMember;
  avatarSize?: AvatarSizes;
  hasNoOutline?: boolean;
}> = ({ member, avatarSize = 'standard', hasNoOutline }) => {
  return (
    <Avatar
      src={member['.tag'] === 'user' ? member.profilePhotoUrl : undefined}
      size={avatarSize}
      hasNoOutline={
        hasNoOutline !== undefined ? hasNoOutline : avatarSize === 'small'
      }
    >
      {getAbbreviation(member)}
    </Avatar>
  );
};
