import { stacks } from '@dropbox/api-v2-client';
import { useMirageAnalyticsContext } from '@mirage/analytics/AnalyticsProvider';
import { PAP_Add_DashSearchToStack } from '@mirage/analytics/events/types/add_dash_search_to_stack';
import { PAP_Copy_DashSearch } from '@mirage/analytics/events/types/copy_dash_search';
import { PAP_Open_DashSearchResult } from '@mirage/analytics/events/types/open_dash_search_result';
import { PAP_Shown_DashSearchResult } from '@mirage/analytics/events/types/shown_dash_search_result';
import { getTypeaheadFilterTitle } from '@mirage/mosaics/SearchBarWithTypeahead/typeahead-search-filter-result';
import { stackDerivePAPProps } from '@mirage/service-stacks';
import { typeahead } from '@mirage/service-typeahead-search/service/types';
import {
  SearchFilter,
  SearchFilterType,
  typeToKeyword,
} from '@mirage/shared/search/search-filters';
import { useCallback } from 'react';
import { isResultVerified } from '../curations';
import useSearchQueryId from './useSearchQueryId';

import type { Recommendation } from '../search/recommendation';
import type { StackItem } from '../search/stack-item';
import type { PAPEvent } from '@mirage/analytics/events/base/event';
import type { ActionSurfaceComponent } from '@mirage/analytics/events/enums/action_surface_component';
import type { DashSourceType } from '@mirage/analytics/events/enums/dash_source_type';
import type { LaunchMethod } from '@mirage/analytics/events/enums/launch_method';
import type { TypeaheadResultType } from '@mirage/analytics/events/enums/typeahead_result_type';
import type { Open_DashSearchResult } from '@mirage/analytics/events/types/open_dash_search_result';
import type { TypeaheadResult } from '@mirage/mosaics/SearchBarWithTypeahead/useConvertToTypeaheadResults';
import type { SearchResult } from '@mirage/service-dbx-api';
import type { MathCalculation } from '@mirage/service-typeahead-search/service/types';
import type { PreviousQuery } from '@mirage/shared/search/cache-result';
import type { URLShortcut } from '@mirage/shared/search/url-shortcut';

type DefaultProps = {
  query: string;
  filters: Array<SearchFilter>;
  results: SearchResult[] | typeahead.ScoredResult[];

  // Optional Params
  actionSurfaceComponent?: ActionSurfaceComponent;
};

export type SearchResultDefaultProps = DefaultProps & {
  searchResult: SearchResult;
};

export type TypeaheadResultDefaultProps = DefaultProps & {
  result: typeahead.ScoredResult;
};

export type StackResultDefaultProps = DefaultProps & {
  uuid: string;
  stack: stacks.Stack;
};

export type StackItemResultDefaultProps = DefaultProps & {
  stackItem: StackItem;
};

export type PreviousQueryDefaultProps = DefaultProps & {
  previousQuery: PreviousQuery;
};

export type RecommendationDefaultProps = DefaultProps & {
  recommendation: Recommendation;
};

export type URLShortcutDefaultProps = DefaultProps & {
  urlShortcut: URLShortcut;
};

export type MathCalculationDefaultProps = DefaultProps & {
  mathCalculation: MathCalculation;
};

export type DesktopFileDefaultProps = DefaultProps & {
  desktopFile: SearchResult;
};

export type DesktopApplicationDefaultProps = DefaultProps & {
  desktopApplication: SearchResult;
};

export type SearchFilterDefaultProps = DefaultProps & {
  searchFilter: SearchFilter;
};

type DashResultDefaultParams = {
  resultPosition: number;
  resultPositionNoCta: number;
  rightRailOpened: boolean;
  resultCount: number;
  launchMethod?: LaunchMethod;
  dashSourceType?: DashSourceType;

  // Typeahead-specific (at least these are known to be, others may be as well!)
  isTypeahead: boolean;
  typeaheadResultType?: TypeaheadResultType;
  typeaheadScore?: number;
  typeaheadScoringNotes?: typeahead.ScoringNotes;
};

type DashSearchResultParams = SearchResultDefaultProps &
  DashResultDefaultParams;
type StackResultParams = StackResultDefaultProps & DashResultDefaultParams;
type StackItemResultParams = StackItemResultDefaultProps &
  DashResultDefaultParams;
type PreviousQueryParams = PreviousQueryDefaultProps & DashResultDefaultParams;
type RecommendationResultParams = RecommendationDefaultProps &
  DashResultDefaultParams;
type URLShortcutResultParams = URLShortcutDefaultProps &
  DashResultDefaultParams;
type MathCalculationParams = MathCalculationDefaultProps &
  DashResultDefaultParams;
type DesktopFileParams = DesktopFileDefaultProps & DashResultDefaultParams;
type DesktopApplicationParams = DesktopApplicationDefaultProps &
  DashResultDefaultParams;
type SearchFilterParams = SearchFilterDefaultProps & DashResultDefaultParams;

export function useResultPAPLogger() {
  const { reportPapEvent, searchAttemptSessionManager, searchSessionManager } =
    useMirageAnalyticsContext();
  const { searchQueryUuid } = useSearchQueryId();

  const concatActiveFilterBindings = useCallback(
    (filters: Array<SearchFilter>) => {
      if (!filters) return '';

      return filters
        .map((filter) => {
          if (filter.type === SearchFilterType.LastUpdated) {
            return `${filter.type}:${filter.id.replace(/-/g, '_')}`;
          }

          return `${typeToKeyword(filter.type)}:${filter.id}`;
        })
        .join(',');
    },
    [],
  );
  const reportEvent = useCallback(
    (event: PAPEvent) => {
      if (!event) return;
      const defaultProperties = {
        searchSessionId: searchSessionManager.getSessionIdOrUndefined(),
        searchAttemptId: searchAttemptSessionManager.getSessionIdOrUndefined(),
      };

      const combinedProperties = {
        ...defaultProperties,
        ...event.properties,
      };

      reportPapEvent({ ...event, properties: combinedProperties });
    },
    [reportPapEvent, searchAttemptSessionManager, searchSessionManager],
  );

  const getOpenDashSearchResult = useCallback(
    (params: DashSearchResultParams) => {
      const {
        query,
        filters,
        resultPosition,
        resultPositionNoCta,
        rightRailOpened,
        resultCount,
        launchMethod,
        actionSurfaceComponent,
        dashSourceType,
        // Typeahead-specific
        isTypeahead,
        typeaheadResultType,
        typeaheadScore,
        typeaheadScoringNotes,
      } = params;
      const searchResult = params.searchResult;
      const activeFilters = concatActiveFilterBindings(filters);
      const hasQuery = query.length > 0;

      return PAP_Open_DashSearchResult({
        resultUuid: searchResult.uuid,
        connector: searchResult?.connectorInfo?.connectorName,
        activeFilters,
        queryString: query,
        title: searchResult.title ? searchResult.title : '',
        dashSearchResultType: searchResult.recordType?.['.tag'],
        searchRequestId: searchResult.searchRequestId,
        resultPosition: resultPosition >= 0 ? resultPosition : undefined,
        resultPositionNoCta:
          resultPositionNoCta >= 0 ? resultPositionNoCta : resultPosition,
        dashSourceType,
        resultUpdatedAtMs: searchResult.updatedAtMs ?? undefined,
        rightRailOpened,
        totalScore: isTypeahead ? typeaheadScore : searchResult.score,
        hitCount: undefined,
        resultCount,
        searchResultSource: searchResult.searchResultSource,
        fromUpstream: searchResult.searchResultSource === 'upstream',
        isPinned: false,
        latency: searchResult.latency ?? undefined,
        hasQuery,
        launchMethod,
        featureLine: 'search',
        actionSurfaceComponent,
        searchQueryUuid,
        serpFinalRanking: searchResult.serpFinalRanking,
        dashIsResultVerified: isResultVerified(searchResult),
        // Typeahead-specific
        isTypeahead,
        typeaheadResultType: isTypeahead ? typeaheadResultType : undefined,
        ...getTypeaheadScoringNotesPAPProperties(typeaheadScoringNotes),
      });
    },
    [concatActiveFilterBindings, searchQueryUuid],
  );
  const logSearchResultOpen = useCallback(
    (
      defaultProps: SearchResultDefaultProps,
      rightRailOpened: boolean,
      isTypeahead: boolean,
      launchMethod: LaunchMethod,
      actionSurfaceComponent?: ActionSurfaceComponent,
      typeaheadResultType?: TypeaheadResultType,
      typeaheadScore?: number,
      typeaheadScoringNotes?: typeahead.ScoringNotes,
      dashSourceType: DashSourceType = 'connector',
    ) => {
      const { results } = defaultProps;
      const resultCount = results.length;
      const { resultPosition, resultPositionNoCta } = calculatePapPositions(
        results,
        (result) => result.uuid === defaultProps.searchResult.uuid,
      );
      const params: DashSearchResultParams = {
        ...defaultProps,
        resultPosition,
        resultPositionNoCta,
        rightRailOpened,
        resultCount,
        launchMethod,
        actionSurfaceComponent,
        dashSourceType,
        // Typeahead-specific
        isTypeahead,
        typeaheadScore,
        typeaheadResultType,
        typeaheadScoringNotes,
      };

      const event = getOpenDashSearchResult(params);

      reportEvent(event);
    },
    [getOpenDashSearchResult, reportEvent],
  );

  const getOpenStackResult = useCallback(
    (params: StackResultParams) => {
      const {
        uuid,
        stack,
        query,
        filters,
        resultPosition,
        resultPositionNoCta,
        rightRailOpened,
        resultCount,
        actionSurfaceComponent,
        // Typeahead-specific
        isTypeahead,
        typeaheadResultType,
        typeaheadScore,
        typeaheadScoringNotes,
      } = params;
      const hasQuery = query.length > 0;
      const activeFilters = concatActiveFilterBindings(filters);

      return PAP_Open_DashSearchResult({
        title: stack?.stack_data?.name,
        resultUuid: uuid,
        activeFilters,
        queryString: query,
        resultPosition: resultPosition >= 0 ? resultPosition : undefined,
        resultPositionNoCta:
          resultPositionNoCta >= 0 ? resultPositionNoCta : resultPosition,
        dashSourceType: 'connector',
        searchResultSource: 'local_cache',
        rightRailOpened,
        hitCount: undefined,
        resultCount,
        fromUpstream: false,
        totalScore: isTypeahead ? typeaheadScore : undefined,
        isPinned: false,
        hasQuery,
        actionSurfaceComponent,
        searchQueryUuid,
        featureLine: 'search',
        // Typeahead-specific
        isTypeahead,
        typeaheadResultType: isTypeahead ? typeaheadResultType : undefined,
        ...getTypeaheadScoringNotesPAPProperties(typeaheadScoringNotes),
      });
    },
    [concatActiveFilterBindings, searchQueryUuid],
  );
  const logStackResultOpen = useCallback(
    (
      defaultProps: StackResultDefaultProps,
      isTypeahead: boolean,
      rightRailOpened: boolean,
      launchMethod: LaunchMethod,
      actionSurfaceComponent: ActionSurfaceComponent,
      typeaheadResultType?: TypeaheadResultType,
      typeaheadScore?: number,
      typeaheadScoringNotes?: typeahead.ScoringNotes,
    ) => {
      const { results } = defaultProps;
      const resultCount = results.length;

      // The core Stack type (`defaultProps.result.result`) doesn't have uuid,
      // only namespace_id. Use locally-generated uuid for finding
      // resultPosition.
      const { resultPosition, resultPositionNoCta } = calculatePapPositions(
        results,
        (result) => result?.uuid === defaultProps.uuid,
      );

      const params: StackResultParams = {
        ...defaultProps,
        resultPosition,
        resultPositionNoCta,
        rightRailOpened,
        resultCount,
        launchMethod,
        actionSurfaceComponent,
        // Typeahead-specific
        isTypeahead,
        typeaheadResultType,
        typeaheadScore,
        typeaheadScoringNotes,
      };

      const event = getOpenStackResult(params);

      reportEvent(event);
    },
    [getOpenStackResult, reportEvent],
  );

  const getOpenStackItemResult = useCallback(
    (params: StackItemResultParams) => {
      const {
        stackItem,
        query,
        filters,
        resultPosition,
        resultPositionNoCta,
        rightRailOpened,
        resultCount,
        actionSurfaceComponent,
        // Typeahead-specific
        isTypeahead,
        typeaheadResultType,
        typeaheadScore,
        typeaheadScoringNotes,
      } = params;
      const hasQuery = query.length > 0;
      const activeFilters = concatActiveFilterBindings(filters);

      return PAP_Open_DashSearchResult({
        title: stackItem.name,
        resultUuid: stackItem.uuid,
        activeFilters,
        queryString: query,
        resultPosition: resultPosition >= 0 ? resultPosition : undefined,
        resultPositionNoCta:
          resultPositionNoCta >= 0 ? resultPositionNoCta : resultPosition,
        dashSourceType: 'stack',
        searchResultSource: 'local_cache',
        rightRailOpened,
        hitCount: undefined,
        resultCount,
        fromUpstream: false,
        totalScore: isTypeahead ? typeaheadScore : undefined,
        isPinned: false,
        hasQuery,
        actionSurfaceComponent,
        searchQueryUuid,
        featureLine: 'search',
        // Typeheaed-specific
        isTypeahead,
        typeaheadResultType: isTypeahead ? typeaheadResultType : undefined,
        ...getTypeaheadScoringNotesPAPProperties(typeaheadScoringNotes),
      });
    },
    [concatActiveFilterBindings, searchQueryUuid],
  );
  const logStackItemResultOpen = useCallback(
    (
      defaultProps: StackItemResultDefaultProps,
      isTypeahead: boolean,
      rightRailOpened: boolean,
      launchMethod: LaunchMethod,
      actionSurfaceComponent: ActionSurfaceComponent,
      typeaheadResultType?: TypeaheadResultType,
      typeaheadScore?: number,
      typeaheadScoringNotes?: typeahead.ScoringNotes,
    ) => {
      const { results } = defaultProps;
      const resultCount = results.length;
      const { resultPosition, resultPositionNoCta } = calculatePapPositions(
        results,
        (result) => result?.uuid === defaultProps.stackItem.uuid,
      );

      const params: StackItemResultParams = {
        ...defaultProps,
        resultPosition,
        resultPositionNoCta,
        rightRailOpened,
        resultCount,
        launchMethod,
        actionSurfaceComponent,
        // Typeahead-specific
        isTypeahead,
        typeaheadResultType,
        typeaheadScore,
        typeaheadScoringNotes,
      };

      const event = getOpenStackItemResult(params);

      reportEvent(event);
    },
    [getOpenStackItemResult, reportEvent],
  );

  const getOpenPreviousResult = useCallback(
    (params: PreviousQueryParams) => {
      const {
        previousQuery,
        query,
        filters,
        resultPosition,
        resultPositionNoCta,
        rightRailOpened,
        resultCount,
        actionSurfaceComponent,
        // Typeahead-specific
        isTypeahead,
        typeaheadResultType,
        typeaheadScore,
        typeaheadScoringNotes,
      } = params;

      const hasQuery = query.length > 0;
      const activeFilters = concatActiveFilterBindings(filters);

      return PAP_Open_DashSearchResult({
        title: previousQuery.query,
        activeFilters,
        queryString: query,
        resultPosition: resultPosition >= 0 ? resultPosition : undefined,
        resultPositionNoCta:
          resultPositionNoCta >= 0 ? resultPositionNoCta : resultPosition,
        dashSourceType: 'connector',
        searchResultSource: 'local_cache',
        rightRailOpened,
        hitCount: undefined,
        resultCount,
        fromUpstream: false,
        isPinned: false,
        totalScore: isTypeahead ? typeaheadScore : undefined,
        hasQuery,
        actionSurfaceComponent,
        searchQueryUuid,
        featureLine: 'search',
        // Typeahead-specific
        isTypeahead,
        typeaheadResultType: isTypeahead ? typeaheadResultType : undefined,
        ...getTypeaheadScoringNotesPAPProperties(typeaheadScoringNotes),
      });
    },
    [concatActiveFilterBindings, searchQueryUuid],
  );
  const logPreviousQueryOpen = useCallback(
    (
      defaultProps: PreviousQueryDefaultProps,
      isTypeahead: boolean,
      rightRailOpened: boolean,
      launchMethod: LaunchMethod,
      actionSurfaceComponent: ActionSurfaceComponent,
      typeaheadResultType?: TypeaheadResultType,
      typeaheadScore?: number,
      typeaheadScoringNotes?: typeahead.ScoringNotes,
    ) => {
      const { results } = defaultProps;
      const resultCount = results.length;
      const { resultPosition, resultPositionNoCta } = calculatePapPositions(
        results,
        (result) => result.uuid === defaultProps.previousQuery.uuid,
      );
      const params: PreviousQueryParams = {
        ...defaultProps,
        resultPosition,
        resultPositionNoCta,
        rightRailOpened,
        resultCount,
        launchMethod,
        actionSurfaceComponent,
        // Typeahead-specific
        isTypeahead,
        typeaheadResultType,
        typeaheadScore,
        typeaheadScoringNotes,
      };

      const event = getOpenPreviousResult(params);

      reportEvent(event);
    },
    [getOpenPreviousResult, reportEvent],
  );

  const getOpenRecommendationResult = useCallback(
    (params: RecommendationResultParams) => {
      const {
        recommendation,
        query,
        filters,
        resultPosition,
        resultPositionNoCta,
        rightRailOpened,
        resultCount,
        launchMethod,
        actionSurfaceComponent,
        dashSourceType,
        // Typeahead-specific
        isTypeahead,
        typeaheadResultType,
        typeaheadScore,
        typeaheadScoringNotes,
      } = params;
      const activeFilters = concatActiveFilterBindings(filters);
      const hasQuery = query.length > 0;

      return PAP_Open_DashSearchResult({
        resultUuid: recommendation.uuid,
        connector: recommendation?.connectorInfo?.connectorName,
        activeFilters,
        queryString: query,
        title: recommendation.title ? recommendation.title : '',
        dashSearchResultType: recommendation.recordType?.['.tag'],
        searchRequestId: undefined,
        resultPosition: resultPosition >= 0 ? resultPosition : undefined,
        resultPositionNoCta:
          resultPositionNoCta >= 0 ? resultPositionNoCta : resultPosition,
        dashSourceType,
        resultUpdatedAtMs: undefined,
        rightRailOpened,
        totalScore: isTypeahead ? typeaheadScore : undefined,
        hitCount: undefined,
        resultCount,
        searchResultSource: 'local_cache',
        fromUpstream: false,
        isPinned: false,
        latency: undefined,
        hasQuery,
        launchMethod,
        featureLine: 'search',
        actionSurfaceComponent,
        searchQueryUuid,
        // Typeahead-specific
        isTypeahead,
        typeaheadResultType: isTypeahead ? typeaheadResultType : undefined,
        ...getTypeaheadScoringNotesPAPProperties(typeaheadScoringNotes),
      });
    },
    [concatActiveFilterBindings, searchQueryUuid],
  );
  const logRecommendationOpen = useCallback(
    (
      defaultProps: RecommendationDefaultProps,
      isTypeahead: boolean,
      rightRailOpened: boolean,
      launchMethod: LaunchMethod,
      actionSurfaceComponent?: ActionSurfaceComponent,
      typeaheadResultType?: TypeaheadResultType,
      typeaheadScore?: number,
      typeaheadScoringNotes?: typeahead.ScoringNotes,
      dashSourceType: DashSourceType = 'connector',
    ) => {
      const { results } = defaultProps;
      const resultCount = results.length;
      const { resultPosition, resultPositionNoCta } = calculatePapPositions(
        results,
        (result) => result.uuid === defaultProps.recommendation.uuid,
      );
      const params: RecommendationResultParams = {
        ...defaultProps,
        resultPosition,
        resultPositionNoCta,
        rightRailOpened,
        resultCount,
        launchMethod,
        actionSurfaceComponent,
        dashSourceType,
        // Typeahead-specific
        isTypeahead,
        typeaheadResultType,
        typeaheadScore,
        typeaheadScoringNotes,
      };

      const event = getOpenRecommendationResult(params);

      reportEvent(event);
    },
    [getOpenRecommendationResult, reportEvent],
  );

  const getOpenOpenURLShortcutResult = useCallback(
    (params: URLShortcutResultParams) => {
      const {
        urlShortcut,
        query,
        filters,
        resultPosition,
        resultPositionNoCta,
        rightRailOpened,
        resultCount,
        launchMethod,
        actionSurfaceComponent,
        // Typeahead-specific
        isTypeahead,
        typeaheadScore,
        typeaheadResultType,
        typeaheadScoringNotes,
      } = params;
      const activeFilters = concatActiveFilterBindings(filters);
      const hasQuery = query.length > 0;

      return PAP_Open_DashSearchResult({
        resultUuid: urlShortcut.uuid,
        connector: undefined,
        activeFilters,
        queryString: query,
        title: urlShortcut.parameters.activeHotword,
        dashSearchResultType: 'url_shortcut',
        searchRequestId: undefined,
        resultPosition: resultPosition >= 0 ? resultPosition : undefined,
        resultPositionNoCta:
          resultPositionNoCta >= 0 ? resultPositionNoCta : resultPosition,
        dashSourceType: 'url_shortcut',
        resultUpdatedAtMs: undefined,
        rightRailOpened,
        totalScore: isTypeahead ? typeaheadScore : undefined,
        hitCount: undefined,
        resultCount,
        searchResultSource: 'local_cache',
        fromUpstream: false,
        isPinned: false,
        latency: undefined,
        hasQuery,
        launchMethod,
        featureLine: 'search',
        actionSurfaceComponent,
        searchQueryUuid,
        // Typeahead-specific
        isTypeahead,
        typeaheadResultType: isTypeahead ? typeaheadResultType : undefined,
        ...getTypeaheadScoringNotesPAPProperties(typeaheadScoringNotes),
      });
    },
    [concatActiveFilterBindings, searchQueryUuid],
  );
  const logURLShortcutOpen = useCallback(
    (
      defaultProps: URLShortcutDefaultProps,
      rightRailOpened: boolean,
      isTypeahead: boolean,
      launchMethod: LaunchMethod,
      actionSurfaceComponent?: ActionSurfaceComponent,
      typeaheadResultType?: TypeaheadResultType,
      typeaheadScore?: number,
      typeaheadScoringNotes?: typeahead.ScoringNotes,
      dashSourceType: DashSourceType = 'connector',
    ) => {
      const { results } = defaultProps;
      const resultCount = results.length;
      const { resultPosition, resultPositionNoCta } = calculatePapPositions(
        results,
        (result) => result.uuid === defaultProps.urlShortcut.uuid,
      );
      const params: URLShortcutResultParams = {
        ...defaultProps,
        resultPosition,
        resultPositionNoCta,
        rightRailOpened,
        resultCount,
        launchMethod,
        actionSurfaceComponent,
        dashSourceType,
        // Typeahead-specific
        isTypeahead,
        typeaheadResultType,
        typeaheadScore,
        typeaheadScoringNotes,
      };

      const event = getOpenOpenURLShortcutResult(params);

      reportEvent(event);
    },
    [getOpenOpenURLShortcutResult, reportEvent],
  );

  const getOpenMathCalculationResult = useCallback(
    (params: MathCalculationParams) => {
      const {
        mathCalculation,
        query,
        filters,
        resultPosition,
        resultPositionNoCta,
        rightRailOpened,
        resultCount,
        launchMethod,
        actionSurfaceComponent,
        // Typeahead-specific
        isTypeahead,
        typeaheadResultType,
        typeaheadScore,
        typeaheadScoringNotes,
      } = params;
      const activeFilters = concatActiveFilterBindings(filters);
      const hasQuery = query.length > 0;

      return PAP_Open_DashSearchResult({
        resultUuid: mathCalculation.uuid,
        connector: undefined,
        activeFilters,
        queryString: query,
        title: mathCalculation.answer,
        dashSearchResultType: 'math',
        searchRequestId: undefined,
        resultPosition: resultPosition >= 0 ? resultPosition : undefined,
        resultPositionNoCta:
          resultPositionNoCta >= 0 ? resultPositionNoCta : resultPosition,
        dashSourceType: 'local',
        resultUpdatedAtMs: undefined,
        rightRailOpened,
        totalScore: isTypeahead ? typeaheadScore : undefined,
        hitCount: undefined,
        resultCount,
        searchResultSource: 'local_cache',
        fromUpstream: false,
        isPinned: false,
        latency: undefined,
        hasQuery,
        launchMethod,
        featureLine: 'search',
        actionSurfaceComponent,
        searchQueryUuid,
        // Typeahead-specific
        isTypeahead,
        typeaheadResultType: isTypeahead ? typeaheadResultType : undefined,
        ...getTypeaheadScoringNotesPAPProperties(typeaheadScoringNotes),
      });
    },
    [concatActiveFilterBindings, searchQueryUuid],
  );
  const logMathCalculationOpen = useCallback(
    (
      defaultProps: MathCalculationDefaultProps,
      rightRailOpened: boolean,
      isTypeahead: boolean,
      launchMethod: LaunchMethod,
      actionSurfaceComponent?: ActionSurfaceComponent,
      typeaheadResultType?: TypeaheadResultType,
      typeaheadScore?: number,
      typeaheadScoringNotes?: typeahead.ScoringNotes,
      dashSourceType: DashSourceType = 'local',
    ) => {
      const { results } = defaultProps;
      const resultCount = results.length;
      const { resultPosition, resultPositionNoCta } = calculatePapPositions(
        results,
        (result) => result.uuid === defaultProps.mathCalculation.uuid,
      );
      const params: MathCalculationParams = {
        ...defaultProps,
        resultPosition,
        resultPositionNoCta,
        rightRailOpened,
        resultCount,
        launchMethod,
        actionSurfaceComponent,
        dashSourceType,
        // Typeahead-specific
        isTypeahead,
        typeaheadResultType,
        typeaheadScore,
        typeaheadScoringNotes,
      };

      const event = getOpenMathCalculationResult(params);

      reportEvent(event);
    },
    [getOpenMathCalculationResult, reportEvent],
  );

  const getOpenSearchFilterResult = useCallback(
    (params: SearchFilterParams) => {
      const {
        searchFilter,
        query,
        filters,
        resultPosition,
        resultPositionNoCta,
        rightRailOpened,
        resultCount,
        launchMethod,
        actionSurfaceComponent,
        // Typeahead-specific
        isTypeahead,
        typeaheadScore,
        typeaheadResultType,
        typeaheadScoringNotes,
      } = params;
      const activeFilters = concatActiveFilterBindings(filters);
      const title = getTypeaheadFilterTitle(searchFilter);
      const hasQuery = query.length > 0;

      return PAP_Open_DashSearchResult({
        connector: undefined,
        activeFilters,
        queryString: query,
        title: title,
        sourceType: searchFilter.type,
        dashSearchResultType: 'filter',
        searchRequestId: undefined,
        resultPosition: resultPosition >= 0 ? resultPosition : undefined,
        resultPositionNoCta:
          resultPositionNoCta >= 0 ? resultPositionNoCta : resultPosition,
        dashSourceType: 'local',
        resultUpdatedAtMs: undefined,
        rightRailOpened,
        totalScore: isTypeahead ? typeaheadScore : undefined,
        hitCount: undefined,
        resultCount,
        searchResultSource: 'local_cache',
        fromUpstream: false,
        isPinned: false,
        latency: undefined,
        hasQuery,
        launchMethod,
        featureLine: 'search',
        actionSurfaceComponent,
        searchQueryUuid: searchQueryUuid.length ? searchQueryUuid : undefined,
        // Typeahead-specific
        isTypeahead,
        typeaheadResultType: isTypeahead ? typeaheadResultType : undefined,
        ...getTypeaheadScoringNotesPAPProperties(typeaheadScoringNotes),
      });
    },
    [concatActiveFilterBindings, searchQueryUuid],
  );
  const logSearchFilterOpen = useCallback(
    (
      defaultProps: SearchFilterDefaultProps,
      rightRailOpened: boolean,
      isTypeahead: boolean,
      launchMethod: LaunchMethod,
      actionSurfaceComponent?: ActionSurfaceComponent,
      typeaheadResultType?: TypeaheadResultType,
      typeaheadScore?: number,
      typeaheadScoringNotes?: typeahead.ScoringNotes,
      dashSourceType: DashSourceType = 'local',
    ) => {
      const { results } = defaultProps;
      const resultCount = results.length;
      const { resultPosition, resultPositionNoCta } = calculatePapPositions(
        results,
        (result) => result.uuid === defaultProps.searchFilter.id,
      );
      const params: SearchFilterParams = {
        ...defaultProps,
        resultPosition,
        resultPositionNoCta,
        rightRailOpened,
        resultCount,
        launchMethod,
        actionSurfaceComponent,
        dashSourceType,
        // Typeahead-specific
        isTypeahead,
        typeaheadResultType,
        typeaheadScore,
        typeaheadScoringNotes,
      };

      const event = getOpenSearchFilterResult(params);

      reportEvent(event);
    },
    [getOpenSearchFilterResult, reportEvent],
  );

  const getShownDashSearchResult = useCallback(
    (params: DashSearchResultParams) => {
      const {
        query,
        filters,
        resultPosition,
        resultPositionNoCta,
        resultCount,
        rightRailOpened,
        actionSurfaceComponent,
        // Typeahead-specific
        isTypeahead,
        typeaheadResultType,
        typeaheadScore,
        typeaheadScoringNotes,
      } = params;
      const searchResult = params.searchResult;
      const activeFilters = concatActiveFilterBindings(filters);
      const hasQuery = query.length > 0;

      return PAP_Shown_DashSearchResult({
        activeFilters,
        queryString: query,
        title: searchResult.title,
        resultUuid: searchResult.uuid,
        connector: searchResult?.connectorInfo?.connectorName,
        totalScore: isTypeahead ? typeaheadScore : searchResult.score,
        sourceScore: searchResult.score ?? 0,
        searchResultSource: searchResult.searchResultSource,
        fromUpstream: searchResult.searchResultSource === 'upstream',
        resultUpdatedAtMs: searchResult.providerUpdateAtMs || undefined,
        dashSearchResultType: searchResult.recordType?.['.tag'],
        searchRequestId: searchResult.searchRequestId,
        resultCount,
        rightRailOpened,
        resultPosition,
        resultPositionNoCta:
          resultPositionNoCta >= 0 ? resultPositionNoCta : resultPosition,
        dashSourceType: 'connector',
        hasQuery,
        actionSurfaceComponent,
        featureLine: 'search',
        searchQueryUuid,
        serpFinalRanking: searchResult.serpFinalRanking,
        dashIsResultVerified: isResultVerified(searchResult),
        // Typeahead-specific
        isTypeahead,
        typeaheadResultType: isTypeahead ? typeaheadResultType : undefined,
        ...getTypeaheadScoringNotesPAPProperties(typeaheadScoringNotes),
      });
    },
    [concatActiveFilterBindings, searchQueryUuid],
  );
  const logSearchResultShown = useCallback(
    (
      defaultProps: SearchResultDefaultProps,
      isTypeahead: boolean,
      actionSurfaceComponent?: ActionSurfaceComponent,
      typeaheadResultType?: TypeaheadResultType,
      typeaheadScore?: number,
      typeaheadScoringNotes?: typeahead.ScoringNotes,
    ) => {
      const { results } = defaultProps;
      const resultCount = results.length;
      const { resultPosition, resultPositionNoCta } = calculatePapPositions(
        results,
        (result) => result.uuid === defaultProps.searchResult.uuid,
      );
      const params: DashSearchResultParams = {
        ...defaultProps,
        resultPosition,
        resultPositionNoCta,
        rightRailOpened: false,
        resultCount,
        actionSurfaceComponent,
        // Typeahead-specific
        isTypeahead,
        typeaheadResultType,
        typeaheadScore,
        typeaheadScoringNotes,
      };

      const event = getShownDashSearchResult(params);

      reportEvent(event);
    },
    [reportEvent, getShownDashSearchResult],
  );

  const getShownRecommendationResult = useCallback(
    (params: RecommendationResultParams) => {
      const {
        recommendation,
        query,
        filters,
        resultPosition,
        resultPositionNoCta,
        resultCount,
        rightRailOpened,
        actionSurfaceComponent,
        dashSourceType,
        // Typeahead-specific
        isTypeahead,
        typeaheadResultType,
        typeaheadScore,
        typeaheadScoringNotes,
      } = params;
      const activeFilters = concatActiveFilterBindings(filters);
      const hasQuery = query.length > 0;

      return PAP_Shown_DashSearchResult({
        activeFilters,
        queryString: query,
        title: recommendation.title ? recommendation.title : '',
        resultUuid: recommendation.uuid,
        connector: recommendation?.connectorInfo?.connectorName,
        searchResultSource: 'local_cache',
        fromUpstream: false,
        isPinned: false,
        totalScore: isTypeahead ? typeaheadScore : undefined,
        resultUpdatedAtMs: undefined,
        dashSearchResultType: recommendation.recordType?.['.tag'],
        resultCount,
        rightRailOpened,
        resultPosition,
        resultPositionNoCta:
          resultPositionNoCta >= 0 ? resultPositionNoCta : resultPosition,
        dashSourceType,
        hasQuery,
        actionSurfaceComponent,
        featureLine: 'search',
        searchQueryUuid,
        searchRequestId: undefined,
        // Typeahead-specific
        isTypeahead,
        typeaheadResultType: isTypeahead ? typeaheadResultType : undefined,
        ...getTypeaheadScoringNotesPAPProperties(typeaheadScoringNotes),
      });
    },
    [concatActiveFilterBindings, searchQueryUuid],
  );
  const logRecommendationShown = useCallback(
    (
      defaultProps: RecommendationDefaultProps,
      isTypeahead: boolean,
      actionSurfaceComponent?: ActionSurfaceComponent,
      typeaheadResultType?: TypeaheadResultType,
      typeaheadScore?: number,
      typeaheadScoringNotes?: typeahead.ScoringNotes,
    ) => {
      const { results } = defaultProps;
      const resultCount = results.length;
      const { resultPosition, resultPositionNoCta } = calculatePapPositions(
        results,
        (result) => result.uuid === defaultProps.recommendation.uuid,
      );
      const params: RecommendationResultParams = {
        ...defaultProps,
        resultPosition,
        resultPositionNoCta,
        rightRailOpened: false,
        resultCount,
        actionSurfaceComponent,
        // Typeahead-specific
        isTypeahead,
        typeaheadResultType,
        typeaheadScore,
        typeaheadScoringNotes,
      };

      const event = getShownRecommendationResult(params);

      reportEvent(event);
    },
    [getShownRecommendationResult, reportEvent],
  );

  const logSearchResultCopy = useCallback(
    (defaultProps: SearchResultDefaultProps) => {
      const searchResult = defaultProps.searchResult as SearchResult;
      const { query, results } = defaultProps;
      const resultCount = results.length;
      const { resultPosition, resultPositionNoCta } = calculatePapPositions(
        results,
        (result) => result.uuid === defaultProps.searchResult.uuid,
      );
      const dashConnectorId = searchResult.connectorInfo.connectorId;
      const event = PAP_Copy_DashSearch({
        queryString: query,
        resultRank: resultPosition,
        resultPositionNoCta,
        dashConnectorId,
        resultCount,
        dashSearchResultType: searchResult.recordType?.['.tag'],
        searchResultSource: searchResult.searchResultSource,
        searchRequestId: searchResult.searchRequestId,
        resultUuid: searchResult.uuid,
        searchQueryUuid,
        featureLine: 'search',
        dashIsResultVerified: isResultVerified(searchResult),
      });

      reportEvent(event);
    },
    [reportEvent, searchQueryUuid],
  );
  const logTypeaheadResultCopy = useCallback(
    (
      defaultProps: TypeaheadResultDefaultProps,
      actionSurfaceComponent?: ActionSurfaceComponent,
      typeaheadResultType?: TypeaheadResultType,
      _typeaheadScore?: number,
      typeaheadScoringNotes?: typeahead.ScoringNotes,
    ) => {
      const { result, query, results } = defaultProps;
      const resultCount = results.length;
      const { resultPosition, resultPositionNoCta } = calculatePapPositions(
        results,
        (result) => result.uuid === defaultProps.result.uuid,
      );

      // NOTE: There's not a helper function like the open/shown events, this
      // is getting and reporting a raw PAP event
      const event = PAP_Copy_DashSearch({
        queryString: query,
        resultRank: resultPosition,
        resultPositionNoCta,
        resultCount,
        resultUuid: result.uuid,
        searchQueryUuid,
        featureLine: 'search',
        actionSurfaceComponent,
        // NOTE: We would track `totalScore` here, but that PAP property isn't on the copy event!
        // Typeahead-specific
        isTypeahead: true,
        typeaheadResultType,
        ...getTypeaheadScoringNotesPAPProperties(typeaheadScoringNotes),
      });
      reportEvent(event);
    },
    [reportEvent, searchQueryUuid],
  );

  const logSearchResultAddedToStack_SERP = useCallback(
    (
      defaultProps: SearchResultDefaultProps,
      stack?: stacks.Stack,
      actionSurfaceComponent?: ActionSurfaceComponent,
    ) => {
      const { query, filters, results } = defaultProps;
      const searchResult = defaultProps.searchResult as SearchResult;
      const resultCount = results.length;
      const { resultPosition, resultPositionNoCta } = calculatePapPositions(
        results,
        (result) => result.uuid === searchResult.uuid,
      );
      const dashConnectorId = searchResult.connectorInfo.connectorId;
      const stackProps = stack ? { ...stackDerivePAPProps(stack) } : {};
      const activeFilters = concatActiveFilterBindings(filters);

      const event = PAP_Add_DashSearchToStack({
        queryString: query,
        resultPosition,
        resultPositionNoCta,
        dashConnectorId,
        resultUuid: searchResult.uuid,
        connector: searchResult.connectorInfo?.connectorName,
        dashSearchResultType: searchResult.recordType?.['.tag'],
        searchResultSource: searchResult.searchResultSource,
        searchRequestId: searchResult.searchRequestId,
        resultCount,
        activeFilters,
        featureLine: 'search',
        dashSourceType: 'connector',
        isSuccess: !!stack,
        actionSurfaceComponent,
        searchQueryUuid,
        dashIsResultVerified: isResultVerified(searchResult),
        ...stackProps,
      });

      reportEvent(event);
    },
    [concatActiveFilterBindings, reportEvent, searchQueryUuid],
  );
  const logSearchResultAddedToStack_Typeahead = useCallback(
    (
      defaultProps: TypeaheadResultDefaultProps,
      stack?: stacks.Stack,
      actionSurfaceComponent?: ActionSurfaceComponent,
    ) => {
      const { query, filters, results, result: scoredResult } = defaultProps;
      const resultCount = results.length;
      const { resultPosition, resultPositionNoCta } = calculatePapPositions(
        results,
        (result) => result.uuid === scoredResult.uuid,
      );
      let dashConnectorId, connector, dashSearchResultType;

      if (
        scoredResult.type === typeahead.ResultType.SearchResult ||
        scoredResult.type === typeahead.ResultType.Recommendation
      ) {
        dashConnectorId = scoredResult.result.connectorInfo.connectorId;
        connector = scoredResult.result.connectorInfo.connectorName;
        dashSearchResultType = scoredResult.result.recordType?.['.tag'];
      }

      const stackProps = stack ? { ...stackDerivePAPProps(stack) } : {};
      const activeFilters = concatActiveFilterBindings(filters);

      const event = PAP_Add_DashSearchToStack({
        queryString: query,
        resultPosition,
        resultPositionNoCta,
        dashConnectorId,
        resultUuid: scoredResult.uuid,
        connector,
        dashSearchResultType,
        resultCount,
        activeFilters,
        featureLine: 'search',
        dashSourceType: 'connector',
        isSuccess: !!stack,
        actionSurfaceComponent,
        searchQueryUuid,
        ...stackProps,
      });

      reportEvent(event);
    },
    [concatActiveFilterBindings, reportEvent, searchQueryUuid],
  );

  const getShownStackResult = useCallback(
    (params: StackResultParams) => {
      const {
        stack,
        query,
        filters,
        resultPosition,
        resultPositionNoCta,
        resultCount,
        rightRailOpened,
        actionSurfaceComponent,
        // Typeahead-specific
        isTypeahead,
        typeaheadResultType,
        typeaheadScore,
        typeaheadScoringNotes,
      } = params;

      const hasQuery = query.length > 0;
      const activeFilters = concatActiveFilterBindings(filters);

      return PAP_Shown_DashSearchResult({
        activeFilters,
        queryString: query,
        title: stack.stack_data?.name,
        resultCount,
        totalScore: isTypeahead ? typeaheadScore : undefined,
        rightRailOpened,
        resultPosition,
        resultPositionNoCta:
          resultPositionNoCta >= 0 ? resultPositionNoCta : resultPosition,
        dashSourceType: 'stack',
        searchResultSource: 'local_cache',
        fromUpstream: false,
        hasQuery,
        featureLine: 'search',
        actionSurfaceComponent,
        searchQueryUuid,
        // Typeahead-specific
        isTypeahead,
        typeaheadResultType: isTypeahead ? typeaheadResultType : undefined,
        ...getTypeaheadScoringNotesPAPProperties(typeaheadScoringNotes),
      });
    },
    [concatActiveFilterBindings, searchQueryUuid],
  );
  const logStackResultShown = useCallback(
    (
      defaultProps: StackResultDefaultProps,
      isTypeahead: boolean,
      actionSurfaceComponent?: ActionSurfaceComponent,
      typeaheadResultType?: TypeaheadResultType,
      typeaheadScore?: number,
      typeaheadScoringNotes?: typeahead.ScoringNotes,
    ) => {
      const { results, uuid } = defaultProps;
      const resultCount = results.length;
      const { resultPosition, resultPositionNoCta } = calculatePapPositions(
        results,
        (result) => result.uuid === uuid,
      );
      const params: StackResultParams = {
        ...defaultProps,
        resultPosition,
        resultPositionNoCta,
        rightRailOpened: false,
        resultCount,
        actionSurfaceComponent,
        // Typeahead-specific
        isTypeahead,
        typeaheadResultType,
        typeaheadScore,
        typeaheadScoringNotes,
      };

      const event = getShownStackResult(params);
      reportEvent(event);
    },
    [getShownStackResult, reportEvent],
  );

  const getShownStackItemResult = useCallback(
    (params: StackItemResultParams) => {
      const {
        stackItem,
        query,
        filters,
        resultPosition,
        resultPositionNoCta,
        resultCount,
        rightRailOpened,
        actionSurfaceComponent,
        // Typeahead-specific
        isTypeahead,
        typeaheadResultType,
        typeaheadScore,
        typeaheadScoringNotes,
      } = params;

      const hasQuery = query.length > 0;
      const activeFilters = concatActiveFilterBindings(filters);

      return PAP_Shown_DashSearchResult({
        activeFilters,
        queryString: query,
        title: stackItem.name,
        resultCount,
        totalScore: isTypeahead ? typeaheadScore : undefined,
        rightRailOpened,
        resultPosition,
        resultPositionNoCta:
          resultPositionNoCta >= 0 ? resultPositionNoCta : resultPosition,
        dashSourceType: 'stack',
        searchResultSource: 'local_cache',
        fromUpstream: false,
        hasQuery,
        featureLine: 'search',
        actionSurfaceComponent,
        searchQueryUuid,
        // Typeahead-specific
        isTypeahead,
        typeaheadResultType: isTypeahead ? typeaheadResultType : undefined,
        ...getTypeaheadScoringNotesPAPProperties(typeaheadScoringNotes),
      });
    },
    [concatActiveFilterBindings, searchQueryUuid],
  );
  const logStackItemResultShown = useCallback(
    (
      defaultProps: StackItemResultDefaultProps,
      isTypeahead: boolean,
      actionSurfaceComponent?: ActionSurfaceComponent,
      typeaheadResultType?: TypeaheadResultType,
      typeaheadScore?: number,
      typeaheadScoringNotes?: typeahead.ScoringNotes,
    ) => {
      const { results, stackItem } = defaultProps;
      const resultCount = results.length;
      const { resultPosition, resultPositionNoCta } = calculatePapPositions(
        results,
        (result) => result.uuid === stackItem.uuid,
      );
      const params: StackItemResultParams = {
        ...defaultProps,
        resultPosition,
        resultPositionNoCta,
        rightRailOpened: false,
        resultCount,
        actionSurfaceComponent,
        // Typeahead-specific
        isTypeahead,
        typeaheadResultType,
        typeaheadScore,
        typeaheadScoringNotes,
      };

      const event = getShownStackItemResult(params);
      reportEvent(event);
    },
    [getShownStackItemResult, reportEvent],
  );

  const getShownPreviousQuery = useCallback(
    (params: PreviousQueryParams) => {
      const {
        previousQuery,
        query,
        filters,
        resultPosition,
        resultPositionNoCta,
        resultCount,
        rightRailOpened,
        actionSurfaceComponent,
        // Typeahead-specific
        isTypeahead,
        typeaheadResultType,
        typeaheadScore,
        typeaheadScoringNotes,
      } = params;

      const hasQuery = query.length > 0;
      const activeFilters = concatActiveFilterBindings(filters);

      return PAP_Shown_DashSearchResult({
        activeFilters,
        queryString: query,
        title: previousQuery.query,
        resultCount,
        totalScore: isTypeahead ? typeaheadScore : undefined,
        rightRailOpened,
        resultPosition,
        resultPositionNoCta:
          resultPositionNoCta >= 0 ? resultPositionNoCta : resultPosition,
        dashSourceType: 'connector',
        searchResultSource: 'local_cache',
        fromUpstream: false,
        hasQuery,
        featureLine: 'search',
        actionSurfaceComponent,
        searchQueryUuid,
        // Typeahead-specific
        isTypeahead,
        typeaheadResultType: isTypeahead ? typeaheadResultType : undefined,
        ...getTypeaheadScoringNotesPAPProperties(typeaheadScoringNotes),
      });
    },
    [concatActiveFilterBindings, searchQueryUuid],
  );
  const logPreviousQueryShown = useCallback(
    (
      defaultProps: PreviousQueryDefaultProps,
      isTypeahead: boolean,
      actionSurfaceComponent?: ActionSurfaceComponent,
      typeaheadResultType?: TypeaheadResultType,
      typeaheadScore?: number,
      typeaheadScoringNotes?: typeahead.ScoringNotes,
    ) => {
      const { results, previousQuery } = defaultProps;
      const resultCount = results.length;
      const { resultPosition, resultPositionNoCta } = calculatePapPositions(
        results,
        (result) => result.uuid === previousQuery.uuid,
      );
      const params: PreviousQueryParams = {
        ...defaultProps,
        resultPosition,
        resultPositionNoCta,
        rightRailOpened: false,
        resultCount,
        actionSurfaceComponent,
        // Typeahead-specific
        isTypeahead,
        typeaheadResultType,
        typeaheadScore,
        typeaheadScoringNotes,
      };

      const event = getShownPreviousQuery(params);
      reportEvent(event);
    },
    [getShownPreviousQuery, reportEvent],
  );

  const getShownURLShortcut = useCallback(
    (params: URLShortcutResultParams) => {
      const {
        urlShortcut,
        query,
        filters,
        resultPosition,
        resultPositionNoCta,
        resultCount,
        rightRailOpened,
        actionSurfaceComponent,
        // Typeahead-specific
        isTypeahead,
        typeaheadResultType,
        typeaheadScore,
        typeaheadScoringNotes,
      } = params;

      const hasQuery = query.length > 0;
      const activeFilters = concatActiveFilterBindings(filters);

      return PAP_Shown_DashSearchResult({
        activeFilters,
        queryString: query,
        title: urlShortcut.parameters.activeHotword,
        resultUuid: urlShortcut.uuid,
        resultCount,
        totalScore: isTypeahead ? typeaheadScore : undefined,
        rightRailOpened,
        resultPosition,
        resultPositionNoCta:
          resultPositionNoCta >= 0 ? resultPositionNoCta : resultPosition,
        dashSourceType: 'connector',
        searchResultSource: 'local_cache',
        fromUpstream: false,
        hasQuery,
        featureLine: 'search',
        actionSurfaceComponent,
        searchQueryUuid,
        // Typeahead-specific
        isTypeahead,
        typeaheadResultType: isTypeahead ? typeaheadResultType : undefined,
        ...getTypeaheadScoringNotesPAPProperties(typeaheadScoringNotes),
      });
    },
    [concatActiveFilterBindings, searchQueryUuid],
  );
  const logURLShortcutShown = useCallback(
    (
      defaultProps: URLShortcutDefaultProps,
      isTypeahead: boolean,
      actionSurfaceComponent?: ActionSurfaceComponent,
      typeaheadResultType?: TypeaheadResultType,
      typeaheadScore?: number,
      typeaheadScoringNotes?: typeahead.ScoringNotes,
    ) => {
      const { results, urlShortcut } = defaultProps;
      const resultCount = results.length;
      const { resultPosition, resultPositionNoCta } = calculatePapPositions(
        results,
        (result) => result.uuid === urlShortcut.uuid,
      );
      const params: URLShortcutResultParams = {
        ...defaultProps,
        resultPosition,
        resultPositionNoCta,
        rightRailOpened: false,
        resultCount,
        actionSurfaceComponent,
        // Typeahead-specific
        isTypeahead: true,
        typeaheadResultType,
        typeaheadScore,
        typeaheadScoringNotes,
      };

      const event = getShownURLShortcut(params);
      reportEvent(event);
    },
    [getShownURLShortcut, reportEvent],
  );

  const getShownMathCalculation = useCallback(
    (params: MathCalculationParams) => {
      const {
        mathCalculation,
        query,
        filters,
        resultPosition,
        resultPositionNoCta,
        resultCount,
        rightRailOpened,
        actionSurfaceComponent,
        // Typeahead-specific
        isTypeahead,
        typeaheadResultType,
        typeaheadScore,
        typeaheadScoringNotes,
      } = params;

      const hasQuery = query.length > 0;
      const activeFilters = concatActiveFilterBindings(filters);

      return PAP_Shown_DashSearchResult({
        activeFilters,
        queryString: query,
        title: mathCalculation.answer,
        resultUuid: mathCalculation.uuid,
        resultCount,
        totalScore: isTypeahead ? typeaheadScore : undefined,
        rightRailOpened,
        resultPosition,
        resultPositionNoCta:
          resultPositionNoCta >= 0 ? resultPositionNoCta : resultPosition,
        fromUpstream: false,
        hasQuery,
        featureLine: 'search',
        actionSurfaceComponent,
        searchQueryUuid,
        // Typeahead-specific
        isTypeahead,
        typeaheadResultType: isTypeahead ? typeaheadResultType : undefined,
        ...getTypeaheadScoringNotesPAPProperties(typeaheadScoringNotes),
      });
    },
    [concatActiveFilterBindings, searchQueryUuid],
  );
  const logMathCalculationShown = useCallback(
    (
      defaultProps: MathCalculationDefaultProps,
      isTypeahead: boolean,
      actionSurfaceComponent?: ActionSurfaceComponent,
      typeaheadResultType?: TypeaheadResultType,
      typeaheadScore?: number,
      typeaheadScoringNotes?: typeahead.ScoringNotes,
    ) => {
      const { results, mathCalculation } = defaultProps;
      const resultCount = results.length;
      const { resultPosition, resultPositionNoCta } = calculatePapPositions(
        results,
        (result) => result.uuid === mathCalculation.uuid,
      );

      const params: MathCalculationParams = {
        ...defaultProps,
        resultPosition,
        resultPositionNoCta,
        rightRailOpened: false,
        resultCount,
        actionSurfaceComponent,
        // Typeahead-specific
        isTypeahead,
        typeaheadResultType,
        typeaheadScore,
        typeaheadScoringNotes,
      };

      const event = getShownMathCalculation(params);
      reportEvent(event);
    },
    [getShownMathCalculation, reportEvent],
  );

  const getShownDesktopFile = useCallback(
    (params: DesktopFileParams) => {
      const {
        desktopFile,
        query,
        filters,
        resultPosition,
        resultPositionNoCta,
        resultCount,
        rightRailOpened,
        actionSurfaceComponent,
        // Typeahead-specific
        isTypeahead,
        typeaheadResultType,
        typeaheadScore,
        typeaheadScoringNotes,
      } = params;

      const hasQuery = query.length > 0;
      const activeFilters = concatActiveFilterBindings(filters);

      return PAP_Shown_DashSearchResult({
        activeFilters,
        queryString: query,
        title: desktopFile.title,
        resultUuid: desktopFile.uuid,
        resultCount,
        totalScore: isTypeahead ? typeaheadScore : undefined,
        rightRailOpened,
        resultPosition,
        resultPositionNoCta:
          resultPositionNoCta >= 0 ? resultPositionNoCta : resultPosition,
        fromUpstream: false,
        hasQuery,
        featureLine: 'search',
        actionSurfaceComponent,
        searchQueryUuid,
        // Typeahead-specific
        isTypeahead,
        typeaheadResultType: isTypeahead ? typeaheadResultType : undefined,
        ...getTypeaheadScoringNotesPAPProperties(typeaheadScoringNotes),
      });
    },
    [concatActiveFilterBindings, searchQueryUuid],
  );
  const logDesktopFileShown = useCallback(
    (
      defaultProps: DesktopFileDefaultProps,
      isTypeahead: boolean,
      actionSurfaceComponent?: ActionSurfaceComponent,
      typeaheadResultType?: TypeaheadResultType,
      typeaheadScore?: number,
      typeaheadScoringNotes?: typeahead.ScoringNotes,
    ) => {
      const { results, desktopFile } = defaultProps;
      const resultCount = results.length;
      const { resultPosition, resultPositionNoCta } = calculatePapPositions(
        results,
        (result) => result.uuid === desktopFile.uuid,
      );

      const params: DesktopFileParams = {
        ...defaultProps,
        resultPosition,
        resultPositionNoCta,
        rightRailOpened: false,
        resultCount,
        actionSurfaceComponent,
        // Typeahead-specific
        isTypeahead,
        typeaheadResultType,
        typeaheadScore,
        typeaheadScoringNotes,
      };

      const event = getShownDesktopFile(params);
      reportEvent(event);
    },
    [getShownDesktopFile, reportEvent],
  );

  const getShownSearchFilter = useCallback(
    (params: SearchFilterParams) => {
      const {
        searchFilter,
        query,
        filters,
        resultPosition,
        resultPositionNoCta,
        resultCount,
        rightRailOpened,
        actionSurfaceComponent,
        // Typeahead-specific
        isTypeahead,
        typeaheadResultType,
        typeaheadScore,
        typeaheadScoringNotes,
      } = params;

      const hasQuery = query.length > 0;
      const activeFilters = concatActiveFilterBindings(filters);
      const title = getTypeaheadFilterTitle(searchFilter);

      return PAP_Shown_DashSearchResult({
        activeFilters,
        queryString: query,
        title: title,
        sourceType: searchFilter.type,
        resultCount,
        totalScore: isTypeahead ? typeaheadScore : undefined,
        rightRailOpened,
        resultPosition,
        resultPositionNoCta:
          resultPositionNoCta >= 0 ? resultPositionNoCta : resultPosition,
        fromUpstream: false,
        hasQuery,
        featureLine: 'search',
        actionSurfaceComponent,
        searchQueryUuid: searchQueryUuid.length ? searchQueryUuid : undefined,
        // Typeahead-specific
        isTypeahead,
        typeaheadResultType: isTypeahead ? typeaheadResultType : undefined,
        ...getTypeaheadScoringNotesPAPProperties(typeaheadScoringNotes),
      });
    },
    [concatActiveFilterBindings, searchQueryUuid],
  );
  const logSearchFilterShown = useCallback(
    (
      defaultProps: SearchFilterDefaultProps,
      isTypeahead: boolean,
      actionSurfaceComponent?: ActionSurfaceComponent,
      typeaheadResultType?: TypeaheadResultType,
      typeaheadScore?: number,
      typeaheadScoringNotes?: typeahead.ScoringNotes,
    ) => {
      const { results, searchFilter } = defaultProps;
      const resultCount = results.length;
      const { resultPosition, resultPositionNoCta } = calculatePapPositions(
        results,
        (result) => result.uuid === searchFilter.id,
      );

      const params: SearchFilterParams = {
        ...defaultProps,
        resultPosition,
        resultPositionNoCta,
        rightRailOpened: false,
        resultCount,
        actionSurfaceComponent,
        // Typeahead-specific
        isTypeahead,
        typeaheadResultType,
        typeaheadScore,
        typeaheadScoringNotes,
      };

      const event = getShownSearchFilter(params);
      reportEvent(event);
    },
    [getShownSearchFilter, reportEvent],
  );

  const getShownDesktopApplication = useCallback(
    (params: DesktopApplicationParams) => {
      const {
        desktopApplication,
        query,
        filters,
        resultPosition,
        resultPositionNoCta,
        resultCount,
        rightRailOpened,
        actionSurfaceComponent,
        // Typeahead-specific
        isTypeahead,
        typeaheadResultType,
        typeaheadScore,
        typeaheadScoringNotes,
      } = params;

      const hasQuery = query.length > 0;
      const activeFilters = concatActiveFilterBindings(filters);

      return PAP_Shown_DashSearchResult({
        activeFilters,
        queryString: query,
        title: desktopApplication.title,
        resultUuid: desktopApplication.uuid,
        resultCount,
        totalScore: isTypeahead ? typeaheadScore : undefined,
        rightRailOpened,
        resultPosition,
        resultPositionNoCta:
          resultPositionNoCta >= 0 ? resultPositionNoCta : resultPosition,
        fromUpstream: false,
        hasQuery,
        featureLine: 'search',
        actionSurfaceComponent,
        searchQueryUuid,
        // Typeahead-specific
        isTypeahead,
        typeaheadResultType: isTypeahead ? typeaheadResultType : undefined,
        ...getTypeaheadScoringNotesPAPProperties(typeaheadScoringNotes),
      });
    },
    [concatActiveFilterBindings, searchQueryUuid],
  );
  const logDesktopApplicationShown = useCallback(
    (
      defaultProps: DesktopApplicationDefaultProps,
      isTypeahead: boolean,
      actionSurfaceComponent?: ActionSurfaceComponent,
      typeaheadResultType?: TypeaheadResultType,
      typeaheadScore?: number,
      typeaheadScoringNotes?: typeahead.ScoringNotes,
    ) => {
      const { results, desktopApplication } = defaultProps;
      const resultCount = results.length;
      const { resultPosition, resultPositionNoCta } = calculatePapPositions(
        results,
        (result) => result.uuid === desktopApplication.uuid,
      );

      const params: DesktopApplicationParams = {
        ...defaultProps,
        resultPosition,
        resultPositionNoCta,
        rightRailOpened: false,
        resultCount,
        actionSurfaceComponent,
        // Typeahead-specific
        isTypeahead,
        typeaheadResultType,
        typeaheadScore,
        typeaheadScoringNotes,
      };

      const event = getShownDesktopApplication(params);
      reportEvent(event);
    },
    [getShownDesktopApplication, reportEvent],
  );

  return {
    // Open
    logSearchResultOpen,
    logRecommendationOpen,
    logURLShortcutOpen,
    logStackResultOpen,
    logStackItemResultOpen,
    logPreviousQueryOpen,
    logMathCalculationOpen,
    logSearchFilterOpen,
    // Shown
    logStackResultShown,
    logStackItemResultShown,
    logSearchResultShown,
    logRecommendationShown,
    logPreviousQueryShown,
    logMathCalculationShown,
    logDesktopFileShown,
    logDesktopApplicationShown,
    logTypeaheadResultCopy,
    logURLShortcutShown,
    logSearchFilterShown,
    // Copy
    logSearchResultCopy,
    // Misc
    logSearchResultAddedToStack_SERP,
    logSearchResultAddedToStack_Typeahead,
  };
}

export function calculatePapPositions<
  T extends DefaultProps['results'] | (TypeaheadResult | undefined)[],
>(
  results: T,
  findCb: (result: T[number]) => boolean,
): { resultPosition: number; resultPositionNoCta: number } {
  const resultsWithoutCta = results.filter((result) =>
    result && 'type' in result // typeahead.ScoredResult
      ? result.type !== typeahead.ResultType.SuggestedQuery
      : // SearchResult
        true,
  );

  return {
    resultPosition: results.findIndex(findCb),
    resultPositionNoCta: resultsWithoutCta.findIndex(findCb),
  };
}

type TypeaheadScoringNotesPAPProperties = Pick<
  NonNullable<Open_DashSearchResult['properties']>,
  // Title Match Score
  | 'typeaheadScoringTitleMatchScoreUnweightedScore'
  | 'typeaheadScoringTitleMatchScoreWeightedScore'
  | 'typeaheadScoringTitleMatchScoreConfiguredWeight'
  | 'typeaheadScoringTitleMatchScoreAppliedWeight'
  // Last Clicked Score
  | 'typeaheadScoringLastClickedScoreUnweightedScore'
  | 'typeaheadScoringLastClickedScoreWeightedScore'
  | 'typeaheadScoringLastClickedScoreConfiguredWeight'
  | 'typeaheadScoringLastClickedScoreAppliedWeight'
  // Frequently Clicked Score
  | 'typeaheadScoringFrequentlyClickedScoreUnweightedScore'
  | 'typeaheadScoringFrequentlyClickedScoreWeightedScore'
  | 'typeaheadScoringFrequentlyClickedScoreConfiguredWeight'
  | 'typeaheadScoringFrequentlyClickedScoreAppliedWeight'
  // Last Browser Viewed Score
  | 'typeaheadScoringLastBrowserViewedScoreUnweightedScore'
  | 'typeaheadScoringLastBrowserViewedScoreWeightedScore'
  | 'typeaheadScoringLastBrowserViewedScoreConfiguredWeight'
  | 'typeaheadScoringLastBrowserViewedScoreAppliedWeight'
  // Frequently Browser Viewed Score
  | 'typeaheadScoringFrequentlyBrowserViewedScoreUnweightedScore'
  | 'typeaheadScoringFrequentlyBrowserViewedScoreWeightedScore'
  | 'typeaheadScoringFrequentlyBrowserViewedScoreConfiguredWeight'
  | 'typeaheadScoringFrequentlyBrowserViewedScoreAppliedWeight'
  // File Type Score
  | 'typeaheadScoringFileTypeScoreUnweightedScore'
  | 'typeaheadScoringFileTypeScoreWeightedScore'
  | 'typeaheadScoringFileTypeScoreConfiguredWeight'
  | 'typeaheadScoringFileTypeScoreAppliedWeight'
  // Pinned
  | 'typeaheadScoringPinned'
>;

type EmptyObject = Record<string, never>;

function getTypeaheadScoringNotesPAPProperties(
  scoringNotes?: typeahead.ScoringNotes,
): TypeaheadScoringNotesPAPProperties | EmptyObject {
  if (scoringNotes === undefined) return {};
  return typeaheadScoringNotesToPAPProperties(scoringNotes);
}
// Export for testing only
export { getTypeaheadScoringNotesPAPProperties };

function typeaheadScoringNotesToPAPProperties(
  scoringNotes: typeahead.ScoringNotes,
): TypeaheadScoringNotesPAPProperties {
  const { pinned, scoringComponents } = scoringNotes;

  const {
    titleMatchScore,
    lastClickedScore,
    frequentlyClickedScore,
    lastBrowserViewedScore,
    frequentlyBrowserViewedScore,
    fileTypeScore,
  } = scoringComponents;

  // Scoring system uses `null` for non-scores, but PAP wants `undefined`
  // Use nullish coalescing operator (??) to convert
  // NOTE: `??` will NOT inadvertently convert 0 scores to undefined

  return {
    // Title Match Score
    typeaheadScoringTitleMatchScoreUnweightedScore:
      titleMatchScore.unweightedScore ?? undefined,
    typeaheadScoringTitleMatchScoreWeightedScore:
      titleMatchScore.weightedScore ?? undefined,
    typeaheadScoringTitleMatchScoreConfiguredWeight:
      titleMatchScore.configuredWeight ?? undefined,
    typeaheadScoringTitleMatchScoreAppliedWeight:
      titleMatchScore.appliedWeight ?? undefined,

    // Last Clicked Score
    typeaheadScoringLastClickedScoreUnweightedScore:
      lastClickedScore.unweightedScore ?? undefined,
    typeaheadScoringLastClickedScoreWeightedScore:
      lastClickedScore.weightedScore ?? undefined,
    typeaheadScoringLastClickedScoreConfiguredWeight:
      lastClickedScore.configuredWeight ?? undefined,
    typeaheadScoringLastClickedScoreAppliedWeight:
      lastClickedScore.appliedWeight ?? undefined,

    // Frequently Clicked Score
    typeaheadScoringFrequentlyClickedScoreUnweightedScore:
      frequentlyClickedScore.unweightedScore ?? undefined,
    typeaheadScoringFrequentlyClickedScoreWeightedScore:
      frequentlyClickedScore.weightedScore ?? undefined,
    typeaheadScoringFrequentlyClickedScoreConfiguredWeight:
      frequentlyClickedScore.configuredWeight ?? undefined,
    typeaheadScoringFrequentlyClickedScoreAppliedWeight:
      frequentlyClickedScore.appliedWeight ?? undefined,

    // Last Browser Viewed Score
    typeaheadScoringLastBrowserViewedScoreUnweightedScore:
      lastBrowserViewedScore.unweightedScore ?? undefined,
    typeaheadScoringLastBrowserViewedScoreWeightedScore:
      lastBrowserViewedScore.weightedScore ?? undefined,
    typeaheadScoringLastBrowserViewedScoreConfiguredWeight:
      lastBrowserViewedScore.configuredWeight ?? undefined,
    typeaheadScoringLastBrowserViewedScoreAppliedWeight:
      lastBrowserViewedScore.appliedWeight ?? undefined,

    // Frequently Browser Viewed Score
    typeaheadScoringFrequentlyBrowserViewedScoreUnweightedScore:
      frequentlyBrowserViewedScore.unweightedScore ?? undefined,
    typeaheadScoringFrequentlyBrowserViewedScoreWeightedScore:
      frequentlyBrowserViewedScore.weightedScore ?? undefined,
    typeaheadScoringFrequentlyBrowserViewedScoreConfiguredWeight:
      frequentlyBrowserViewedScore.configuredWeight ?? undefined,
    typeaheadScoringFrequentlyBrowserViewedScoreAppliedWeight:
      frequentlyBrowserViewedScore.appliedWeight ?? undefined,

    // File Type Score
    typeaheadScoringFileTypeScoreUnweightedScore:
      fileTypeScore.unweightedScore ?? undefined,
    typeaheadScoringFileTypeScoreWeightedScore:
      fileTypeScore.weightedScore ?? undefined,
    typeaheadScoringFileTypeScoreConfiguredWeight:
      fileTypeScore.configuredWeight ?? undefined,
    typeaheadScoringFileTypeScoreAppliedWeight:
      fileTypeScore.appliedWeight ?? undefined,

    // Pinned
    typeaheadScoringPinned: pinned,
  };
}
// Export for testing only
export { typeaheadScoringNotesToPAPProperties };
