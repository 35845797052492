import { PAP_Click_AddSource } from '@mirage/analytics/events/types/click_add_source';
import { PAP_Click_RemoveSource } from '@mirage/analytics/events/types/click_remove_source';
import { PAP_Edit_EditorTextArea } from '@mirage/analytics/events/types/edit_editor_text_area';
import {
  areSessionsDataEqual,
  useComposeSessionsContext,
} from '@mirage/mosaics/ComposeAssistant/data/ComposeSessionsContext';
import {
  SourcesContentCache,
  useComposeSourcesCache,
} from '@mirage/mosaics/ComposeAssistant/data/ComposeSourcesCache';
import { useComposeVoicesContext } from '@mirage/mosaics/ComposeAssistant/data/ComposeVoicesContext';
import {
  PostUserMessageParams,
  usePostMessageHandlers,
} from '@mirage/mosaics/ComposeAssistant/data/current-session/ComposeCurrentSessionPostMessage';
import {
  composeCurrentSessionReducer,
  newSession,
} from '@mirage/mosaics/ComposeAssistant/data/current-session/ComposeCurrentSessionStates';
import { TransientSource } from '@mirage/mosaics/ComposeAssistant/data/TransientSources';
import {
  ComposeArtifact,
  ComposeAssistantConversationMessage,
  ComposeAssistantDraftConfig,
  ComposeSession,
  ComposeSource,
  InputContext,
} from '@mirage/shared/compose/compose-session';
import { useDebounce } from '@mirage/shared/hooks/useDebounce';
import { usePrevious } from '@mirage/shared/util/hooks';
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from 'react';
import { useComposeAnalyticsContext } from '../ComposeAnalyticsContext';

export interface ComposeCurrentSessionContextInterface {
  // persisted
  messagesHistory: ComposeAssistantConversationMessage[];
  sources: ComposeSource[];
  artifacts: ComposeArtifact[];

  // transient
  sourcesContentCache: SourcesContentCache;
  inputContext: InputContext | undefined;
  isWaitingForResponse: boolean;
  progressString: string | undefined;
  currentSessionID: string;
  transientSources?: TransientSource[];

  // methods
  postUserMessage: (messageParams: PostUserMessageParams) => void;
  postUpdateDraftWithVoice: (voiceID: string) => void;
  addRawMessage: (message: ComposeAssistantConversationMessage) => void;
  addSource: (source: ComposeSource) => void;
  removeSource: (source: ComposeSource) => void;
  setMarkdownContent: (markdownContent: string) => void;
  setDraftConfig: (config: ComposeAssistantDraftConfig) => void;
  setInputContext: (context: InputContext | undefined) => void;
  updateCurrentSession: (session: ComposeSession) => void;
  addArtifact: (artifact: ComposeArtifact) => void;
  newSession: (history: ComposeAssistantConversationMessage[]) => void;
  loadSession: (sessionID: string) => void;
}
export const ComposeCurrentSessionContext =
  createContext<ComposeCurrentSessionContextInterface | null>(null);
export const useComposeCurrentSessionContext = () => {
  const context = useContext(ComposeCurrentSessionContext);
  if (!context) {
    throw new Error(
      'useComposeCurrentSessionContext must be used within a ComposeCurrentSessionContextProvider',
    );
  }
  return context;
};

interface ComposeCurrentSessionContextProviderProps {
  children: React.ReactNode;
  transientSources?: TransientSource[];
}
export const ComposeCurrentSessionContextProvider = ({
  children,
  transientSources,
}: ComposeCurrentSessionContextProviderProps) => {
  const [hasAddedSource, setHasAddedSource] = useState(false);
  const { voices } = useComposeVoicesContext();
  const { sessions, saveSession } = useComposeSessionsContext();
  const [state, dispatch] = useReducer(
    composeCurrentSessionReducer,
    undefined,
    () => {
      return {
        currentSession: newSession([]),
        inputContext: undefined,
        isWaitingForResponse: false,
        progressString: undefined,
      };
    },
  );
  const { logComposeEvent } = useComposeAnalyticsContext({
    actionSurfaceComponent: 'compose_source_modal',
    currentSessionID: state.currentSession.id,
  });
  const debouncedLogKeystroke = useDebounce(() => {
    logComposeEvent(
      PAP_Edit_EditorTextArea({
        isFirstAction: !hasAddedSource,
      }),
      {
        actionSurfaceComponent: 'compose_editor_pane',
      },
    );
  }, 1000);
  const sourcesContents = useComposeSourcesCache(state.currentSession.sources);
  const markdownArtifact = useMemo(
    () =>
      state.currentSession.artifacts.find((a) => a.type === 'markdown_draft'),
    [state.currentSession.artifacts],
  );
  const currentVoice = useMemo(() => {
    return voices?.find((v) => v.id === markdownArtifact?.draftConfig.voiceID);
  }, [markdownArtifact?.draftConfig.voiceID, voices]);
  const voiceSourceContents = useComposeSourcesCache(
    currentVoice?.sources || [],
  );
  const updateCurrentSession = useCallback(async (session: ComposeSession) => {
    dispatch({
      type: 'updateCurrentSession',
      session,
    });
  }, []);
  const prevSession = usePrevious(state.currentSession);
  useEffect(() => {
    //  clicked on an old session from page load
    if (prevSession?.dataId === '' && state.currentSession.dataId) {
      return;
    }
    // data hasn't changed return early
    if (
      prevSession &&
      areSessionsDataEqual(prevSession, state.currentSession)
    ) {
      return;
    }
    // save current session when modified, but only if there is user-entered data
    if (isEmptySession(state.currentSession)) {
      return;
    }
    // return early if clicking from session to session
    if (
      prevSession?.dataId &&
      state.currentSession.dataId &&
      prevSession.dataId !== state.currentSession.dataId
    ) {
      return;
    }
    saveSession(state.currentSession, updateCurrentSession);
  }, [state.currentSession, prevSession, saveSession, updateCurrentSession]);

  const prevSessions = usePrevious(sessions);
  useEffect(() => {
    // if current session is deleted, reset to a new session
    if (isEmptySession(state.currentSession)) {
      return; // ignore unsaved sessions
    }
    if (prevSessions === sessions) {
      return; // ignore cases where sessions didn't change
    }
    const deletedSessions = prevSessions?.filter(
      (s) => !sessions?.find((ss) => ss.id === s.id),
    );

    if (deletedSessions?.find((s) => s.id === state.currentSession.id)) {
      dispatch({
        type: 'newSession',
        history: [],
      });
    }
  }, [sessions, prevSessions, state.currentSession]);

  const { postUserMessage, postUpdateDraftWithVoice } = usePostMessageHandlers(
    state,
    dispatch,
    voices,
    sourcesContents,
    transientSources,
    voiceSourceContents,
    logComposeEvent,
    markdownArtifact,
  );

  const context: ComposeCurrentSessionContextInterface = {
    messagesHistory: state.currentSession.messagesHistory,
    sources: state.currentSession.sources,
    artifacts: state.currentSession.artifacts,
    sourcesContentCache: sourcesContents,
    inputContext: state.inputContext,
    isWaitingForResponse: state.isWaitingForResponse,
    progressString: state.progressString,
    currentSessionID: state.currentSession.id,
    transientSources,

    postUserMessage,
    postUpdateDraftWithVoice,
    addRawMessage: useCallback(
      (message: ComposeAssistantConversationMessage) => {
        dispatch({
          type: 'addMessage',
          message,
          requiresResponse: false,
        });
      },
      [],
    ),
    addSource: useCallback(
      (source: ComposeSource) => {
        dispatch({
          type: 'addSource',
          source,
        });
        logComposeEvent(PAP_Click_AddSource());
        setHasAddedSource(true);
      },
      [logComposeEvent],
    ),
    removeSource: useCallback(
      (source: ComposeSource) => {
        dispatch({
          type: 'removeSource',
          source,
        });
        logComposeEvent(PAP_Click_RemoveSource());
      },
      [logComposeEvent],
    ),
    setMarkdownContent: useCallback(
      (markdownContent: string) => {
        dispatch({
          type: 'setMarkdownContent',
          content: markdownContent,
        });
        debouncedLogKeystroke();
      },
      [debouncedLogKeystroke],
    ),
    setDraftConfig: useCallback((config: ComposeAssistantDraftConfig) => {
      dispatch({
        type: 'setDraftConfig',
        config,
      });
    }, []),
    setInputContext: useCallback((context: InputContext | undefined) => {
      dispatch({
        type: 'setInputContext',
        context,
      });
    }, []),
    updateCurrentSession: useCallback((session: ComposeSession) => {
      dispatch({
        type: 'updateCurrentSession',
        session,
      });
    }, []),
    addArtifact: useCallback((artifact: ComposeArtifact) => {
      dispatch({
        type: 'addArtifact',
        artifact,
      });
    }, []),
    newSession: useCallback(
      (history: ComposeAssistantConversationMessage[]) => {
        dispatch({
          type: 'newSession',
          history,
        });
      },
      [],
    ),
    loadSession: useCallback(
      (sessionID: string) => {
        const session = sessions?.find((s) => s.id === sessionID);
        if (!session) {
          return;
        }
        dispatch({
          type: 'loadSession',
          session,
        });
      },
      [sessions],
    ),
  };
  return (
    <ComposeCurrentSessionContext.Provider value={context}>
      {children}
    </ComposeCurrentSessionContext.Provider>
  );
};

function isEmptySession(session: Omit<ComposeSession, 'lastUpdated'>): boolean {
  return (
    session.messagesHistory.filter((m) => m.role === 'user').length === 0 &&
    session.sources.length === 0 &&
    !hasNonEmptyArtifact(session.artifacts)
  );
}

function hasNonEmptyArtifact(artifacts: ComposeArtifact[]): boolean {
  return artifacts.some(
    (a) =>
      (a.draftConfig.contentDescription || '').trim().length > 0 ||
      a.markdownContent.trim().length > 0,
  );
}
