import { dash } from '@dropbox/api-v2-client';
import { transformDashResultToMirage } from '@mirage/service-dbx-api/service/utils';
import { SearchResultIcon } from '@mirage/shared/icons';
import EventIcon from '@mirage/shared/icons/EventIcon';
import { RESULT_BADGE_ICON_SIZE } from './util';

import type { ContentIconProps } from '@dropbox/dash-component-library';

type EventResultIconProps = {
  event: dash.SearchResult;
  size?: ContentIconProps['size'];
};

export const EventResultIcon = ({
  event,
  size = 'medium',
}: EventResultIconProps) => {
  const eventDate = new Date(event.start_time || 0);
  const mirageResult = transformDashResultToMirage(event);
  // checking if the date is valid here prevents EventIcon from throwing on invalid dates
  if (
    !!mirageResult &&
    eventDate instanceof Date &&
    !isNaN(eventDate.getTime())
  ) {
    return (
      <EventIcon
        eventDate={eventDate}
        size={size}
        sourceIcon={
          <SearchResultIcon
            result={mirageResult}
            size={RESULT_BADGE_ICON_SIZE}
          />
        }
      />
    );
  } else {
    return null;
  }
};
