import { stacks } from '@dropbox/api-v2-client';
import { LinkComponent, LinkComponentProps } from '@mirage/link-list/Link/Link';
import { DraggableStackLink } from '@mirage/stacks/FullScreenStack/DragAndDrop/DraggableStackLink';
import { DroppableItemizedSection } from './DroppableItemizedSection';

interface DragDroppableSectionLinkComponentProps {
  sectionId: string;
  index: number;
  item: stacks.StackItemShortcut;
}

export const DragDroppableSectionLinkComponent: React.FC<
  LinkComponentProps & DragDroppableSectionLinkComponentProps
> = (props) => {
  return (
    <DroppableItemizedSection {...props}>
      <DraggableStackLink {...props}>
        <LinkComponent {...props} enableDrag />
      </DraggableStackLink>
    </DroppableItemizedSection>
  );
};
