import { IconButton } from '@dropbox/dig-components/buttons';
import { UIIcon } from '@dropbox/dig-icons';
import {
  AiChatLine,
  ShuffleLine,
  ZoomInLine,
  ZoomOutLine,
} from '@dropbox/dig-icons/assets';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { PAPEvent } from '@mirage/analytics/events/base/event';
import { ActionSurfaceComponent } from '@mirage/analytics/events/enums/action_surface_component';
import { PAP_Shown_TextSelectionMenu } from '@mirage/analytics/events/types/shown_text_selection_menu';
import {
  FormatAction,
  FormatToolbar,
} from '@mirage/mosaics/ComposeAssistant/components/editor/FormatToolbar';
import { tagged } from '@mirage/service-logging';
import { DigTooltip } from '@mirage/shared/util/DigTooltip';
import i18n from '@mirage/translations';
import classNames from 'classnames';
import { $getSelection, $isRangeSelection } from 'lexical';
import {
  ComponentType,
  CSSProperties,
  memo,
  SVGAttributes,
  useEffect,
  useRef,
  useState,
} from 'react';
import styles from './SelectionToolbar.module.css';

const logger = tagged('ComposeEditor/SelectionToolbar');

const TOOLBAR_WIDTH = 435;
const TOOLBAR_LEFT_MIN = 5;

export type SelectionAction = {
  type: 'rewrite' | 'make-longer' | 'make-shorter' | 'prompt';
  selectedText: string;
};
export interface SelectionToolbarPluginProps {
  supportsTables: boolean;
  offsetTop?: number;
  onTriggerSelectionAction: (action: SelectionAction) => void;
  logComposeEvent: (
    event: PAPEvent,
    overrides?: { actionSurfaceComponent?: ActionSurfaceComponent },
  ) => void;
}
export const SelectionToolbarPlugin = memo(
  ({
    supportsTables,
    onTriggerSelectionAction,
    offsetTop = 0,
    logComposeEvent,
  }: SelectionToolbarPluginProps) => {
    const { selectionStates: editorSelectionStates, editorHasFocus } =
      useEditorSelectionStates();
    const [hasLoggedEvent, setHasLoggedEvent] = useState(false);
    const [toolbarVisible, setToolbarVisible] = useState(false);
    const timerIdRef = useRef<NodeJS.Timeout | null>(null);
    // hide toolbar after 1s when editor loses focus
    useEffect(() => {
      if (!editorHasFocus) {
        timerIdRef.current = setTimeout(() => {
          setToolbarVisible(false);
        }, 1000);
      } else {
        if (timerIdRef.current) {
          clearTimeout(timerIdRef.current);
          timerIdRef.current = null;
        }
        setToolbarVisible(true);
      }
      return () => {
        if (timerIdRef.current) {
          clearTimeout(timerIdRef.current);
          timerIdRef.current = null;
        }
      };
    }, [editorHasFocus]);
    useEffect(() => {
      if (editorSelectionStates && !hasLoggedEvent) {
        logComposeEvent(PAP_Shown_TextSelectionMenu());
        setHasLoggedEvent(true);
      } else if (!editorSelectionStates) {
        setHasLoggedEvent(false);
      }
    }, [editorSelectionStates, hasLoggedEvent, logComposeEvent]);
    if (!editorSelectionStates) {
      const noSelectionToolbarClass = classNames(styles.NoSelectionToolbar, {
        [styles.SelectionHidden]: !toolbarVisible,
      });
      return (
        <div className={noSelectionToolbarClass}>
          <FormatToolbar
            inverse={true}
            formatActions={getNoSelectionFormatActions(supportsTables)}
            logComposeEvent={logComposeEvent}
          />
        </div>
      );
    }
    const positionStyles = getPositionStyles(editorSelectionStates, offsetTop);
    const actions = getActions(editorSelectionStates.selectedText);
    const selectionToolbarClass = classNames(styles.SelectionToolbar, {
      [styles.SelectionHidden]: !toolbarVisible,
    });
    return (
      <div className={selectionToolbarClass} style={positionStyles}>
        {actions.map((actionInfo, i) =>
          actionInfo.type === 'divider' ? (
            <div key={`divider-${i}`} className={styles.ActionsDivider} />
          ) : (
            <DigTooltip
              title={actionInfo.title}
              key={actionInfo.action.type}
              openDelay={0}
            >
              <IconButton
                variant="borderless"
                className={styles.SelectionActionButton}
                onClick={() => onTriggerSelectionAction(actionInfo.action)}
              >
                <UIIcon src={actionInfo.icon} />
              </IconButton>
            </DigTooltip>
          ),
        )}
        <div className={styles.ActionsDivider} />
        <FormatToolbar
          inverse={true}
          logComposeEvent={logComposeEvent}
          formatActions={['bold', 'link', 'divider', 'h1', 'h2', 'list']}
        />
      </div>
    );
  },
);
SelectionToolbarPlugin.displayName = 'SelectionToolbarPlugin';

function getNoSelectionFormatActions(supportsTables: boolean): FormatAction[] {
  return supportsTables
    ? [
        'bold',
        'underline',
        'italic',
        'divider',
        'list',
        'table',
        'divider',
        'h1',
        'h2',
      ]
    : ['bold', 'underline', 'italic', 'divider', 'list', 'divider', 'h1', 'h2'];
}

interface EditorSelectionStates {
  selection: DOMRect; // relative to root element
  rootElement: DOMRect;
  selectedText: string;
}
function useEditorSelectionStates(): {
  selectionStates: EditorSelectionStates | null;
  editorHasFocus: boolean;
} {
  const [editor] = useLexicalComposerContext();
  const [selectionStates, setSelectionStates] =
    useState<EditorSelectionStates | null>(null);
  const [editorHasFocus, setEditorHasFocus] = useState(false);
  useEffect(() => {
    function handleSelectionChange() {
      editor.getEditorState().read(() => {
        const selection = $getSelection();
        if (
          !selection ||
          !$isRangeSelection(selection) ||
          selection.isCollapsed()
        ) {
          setSelectionStates(null);
          return;
        }

        const selectionPoints = selection.anchor.isBefore(selection.focus)
          ? [selection.anchor, selection.focus]
          : [selection.focus, selection.anchor];
        const domRange = document.createRange();
        const selectionElements = [
          editor.getElementByKey(selectionPoints[0].getNode().getKey()),
          editor.getElementByKey(selectionPoints[1].getNode().getKey()),
        ];
        if (!selectionElements[0] || !selectionElements[1]) {
          logger.error('selectionElements not found');
          return;
        }
        domRange.setStart(
          selectionElements[0].childNodes[0],
          selectionPoints[0].offset,
        );
        domRange.setEnd(
          selectionElements[1].childNodes[0],
          selectionPoints[1].offset,
        );
        const selectionBoundingRect = domRange.getBoundingClientRect();
        const rootElementBoundingRect = editor
          .getRootElement()!
          .parentElement!.getBoundingClientRect();
        const relativeRect = new DOMRect(
          selectionBoundingRect.x - rootElementBoundingRect.x,
          selectionBoundingRect.y - rootElementBoundingRect.y,
          selectionBoundingRect.width,
          selectionBoundingRect.height,
        );
        setSelectionStates({
          selection: relativeRect,
          rootElement: rootElementBoundingRect,
          selectedText: selection.getTextContent(),
        });
      });
    }
    function onFocus() {
      setEditorHasFocus(true);
    }
    function onBlur() {
      setEditorHasFocus(false);
    }
    const rootElement = editor.getRootElement();
    if (rootElement) {
      rootElement.addEventListener('focusin', onFocus);
      rootElement.addEventListener('focusout', onBlur);
    }
    document.addEventListener('selectionchange', handleSelectionChange);
    return () => {
      document.removeEventListener('selectionchange', handleSelectionChange);
      if (rootElement) {
        rootElement.removeEventListener('focusin', onFocus);
        rootElement.removeEventListener('focusout', onBlur);
      }
    };
  }, [editor]);
  return { selectionStates, editorHasFocus };
}

type ActionInfo =
  | {
      type: 'action';
      icon: ComponentType<SVGAttributes<SVGElement>>;
      title: string;
      action: SelectionAction;
    }
  | { type: 'divider' };
function getActions(selectedText: string): ActionInfo[] {
  return [
    {
      type: 'action',
      icon: ShuffleLine,
      title: i18n.t('compose_editor_selection_action_rewrite'),
      action: { type: 'rewrite', selectedText },
    },
    {
      type: 'action',
      icon: ZoomInLine,
      title: i18n.t('compose_editor_selection_action_make_longer'),
      action: { type: 'make-longer', selectedText },
    },
    {
      type: 'action',
      icon: ZoomOutLine,
      title: i18n.t('compose_editor_selection_action_make_shorter'),
      action: { type: 'make-shorter', selectedText },
    },
    {
      type: 'divider',
    },
    {
      type: 'action',
      icon: AiChatLine,
      title: i18n.t('compose_editor_selection_action_prompt'),
      action: { type: 'prompt', selectedText },
    },
  ];
}

const MARGIN_TOP = 8;

function getPositionStyles(
  editorSelectionStates: EditorSelectionStates,
  offsetTop: number,
): CSSProperties {
  const positionStyles: CSSProperties = {
    top:
      editorSelectionStates.selection.top +
      editorSelectionStates.selection.height +
      MARGIN_TOP +
      offsetTop,
  };
  if (
    editorSelectionStates.selection.left + TOOLBAR_WIDTH <
    editorSelectionStates.rootElement.width
  ) {
    positionStyles.left = editorSelectionStates.selection.left;
  } else if (
    editorSelectionStates.selection.left +
      editorSelectionStates.selection.width >
    TOOLBAR_WIDTH
  ) {
    positionStyles.right =
      editorSelectionStates.rootElement.width -
      (editorSelectionStates.selection.left +
        editorSelectionStates.selection.width);
  } else {
    positionStyles.left = TOOLBAR_LEFT_MIN;
  }
  return positionStyles;
}
