import { useEffect, useState } from 'react';

export type LocalNavigator = Navigator & {
  standalone: boolean;
  userAgentData: {
    mobile: boolean;
  };
};

export default function useIsMobileDevice() {
  const [isMobileDevice, setIsMobileDevice] = useState(false);

  useEffect(() => {
    const winNavigator = window?.navigator as LocalNavigator;

    const detectMobile = () => {
      // Default to false if userAgentData or standalone is not available
      const isMobileUserAgentData = !!winNavigator.userAgentData?.mobile;

      // Detect iOS Safari with standalone
      const isiOSStandalone = !!(
        typeof winNavigator.standalone !== 'undefined' &&
        winNavigator.maxTouchPoints &&
        winNavigator.maxTouchPoints > 2
      );

      return isMobileUserAgentData || isiOSStandalone;
    };

    setIsMobileDevice(detectMobile());
  }, []);

  return isMobileDevice;
}
