import { dash_feed } from '@dropbox/api-v2-client';
import { Box, Stack } from '@dropbox/dig-foundations';
import useDropboxAccount from '@mirage/service-auth/useDropboxAccount';
import useConnectors from '@mirage/service-connectors/useConnectors';
import { ActivityFeedFilters } from '@mirage/service-feed/types';
import {
  areFiltersSet,
  isSupportedActivityFeedConnector,
} from '@mirage/service-feed/util/helpers';
import { ActivityListEmptyState } from '../ActivityListEmptyState/ActivityListEmptyState';
import { ActivityListEmptyStateAddApps } from '../ActivityListEmptyState/ActivityListEmptyStateAddApps';
import { ActivityListEmptyStateResetFilters } from '../ActivityListEmptyState/ActivityListEmptyStateResetFilters';
import { InfiniteScroll } from '../InfiniteScroll/InfiniteScroll';
import { ActivityListItem, ActivityListItemSkeleton } from './ActivityListItem';
import { NoMoreActivityItems } from './NoMoreActivityItems';

type ActivityListProps = {
  activities: dash_feed.FeedItem[];
  clearFilters: () => void;
  filters: ActivityFeedFilters;
  hasMore: boolean;
  onViewItem: (activity: dash_feed.FeedItem, position: number) => void;
  onFeedScroll?: (activity: dash_feed.FeedItem, position: number) => void;
  onReachEnd?: () => void;
  showLoadingPlaceholders: boolean;
};

const NUM_LOADER_ITEMS = 10;

export const ActivityList = ({
  activities,
  clearFilters,
  filters,
  hasMore,
  onViewItem,
  onFeedScroll,
  onReachEnd,
  showLoadingPlaceholders,
}: ActivityListProps) => {
  const useInfiniteScroll = !!onReachEnd && hasMore && !showLoadingPlaceholders;
  const currentAccount = useDropboxAccount();
  const showEndOfFeed = !hasMore && !showLoadingPlaceholders;
  const { connectors } = useConnectors();
  const activeFeedConnectors = connectors.filter(
    isSupportedActivityFeedConnector,
  );

  if (activities.length === 0 && !showLoadingPlaceholders) {
    if (activeFeedConnectors.length === 0) {
      return <ActivityListEmptyStateAddApps />;
    } else if (areFiltersSet(filters)) {
      return <ActivityListEmptyStateResetFilters clearFilters={clearFilters} />;
    } else {
      return <ActivityListEmptyState />;
    }
  }

  return (
    <Box>
      <Stack gap="Micro Small">
        {activities.map((activity, index) => (
          <ActivityListItem
            key={index}
            activity={activity}
            currentAccount={currentAccount}
            index={index}
            onInView={onViewItem}
            onScrollIntoView={onFeedScroll}
            className="meticulous-redact-recording"
          />
        ))}

        {showLoadingPlaceholders &&
          Array.from({ length: NUM_LOADER_ITEMS }).map((_, index) => (
            <ActivityListItemSkeleton key={index} />
          ))}

        {useInfiniteScroll && <InfiniteScroll onTrigger={onReachEnd} />}
      </Stack>

      {showEndOfFeed && <NoMoreActivityItems />}
    </Box>
  );
};
