// @generated - Do not edit: this file is autogenerated by:
//   //dropbox/pap/codegen
//
// Event definition: https://pap.pp.dropbox.com/#/events/3625
import type { PAPEventGeneric } from "../base/event";
import type { Environment } from "../enums/environment";
import type { OperatingSystem } from "../enums/operating_system";
import type { DashSurface } from "../enums/dash_surface";
import type { DashActionSurface } from "../enums/dash_action_surface";
import type { DashEntrypoint } from "../enums/dash_entrypoint";
import type { FeatureLine } from "../enums/feature_line";
import type { DashSurfaceBuild } from "../enums/dash_surface_build";
import type { ActionSurfaceComponent } from "../enums/action_surface_component";

// Event_name: 'shown.dash_answers_source'
// Description: Impression event on dash answers source
// Owner: dash-exp-find-and-discover
export type Shown_DashAnswersSource = PAPEventGeneric<
  'dash',
  'shown',
  'dash_answers_source',
  {
    // An entry point string, used to describe how users are triggering the event as relevant (e.g. browse_file_row_context_menu)
    entryPoint?: string;
    // id that groups all queries in a single "session" for logging
    searchSessionId?: string;
    // Whether the app dispatching the event is Prod, Test, or Dev (run locally by the developer)
    environment?: Environment;
    // The operating system platform the user is running on.
    operatingSystem?: OperatingSystem;
    // Which Dash surface (web, desktop, or browser extension) the event originated from.
    dashSurface?: DashSurface;
    // generic property representing build version of whatever is logging this event.
    buildVersion?: string;
    // The ID of the Dash session that was in progress when the result was shown. This is a randomly generated UUID string.
    dashSessionId?: string;
    // The ID of the Search Attempt that was in progress while the result was shown. This is a randomly-generated UUID string.
    searchAttemptId?: string;
    // Dash Answers - this will be a randomly generated ID associated with each answer, used for logging purposes to track success/dwell time on answers page.
    answerId?: string;
    // unique id for a /dash/search query. Used by the ML team to get feedback on search result ranking
    searchRequestId?: string;
    // The surface in which an action was taken on Dash.
    // Note: serp and search_result_page are the same thing, but we're only using serp moving forward.
    dashActionSurface?: DashActionSurface;
    // The entry point for a dash action. How a user got to a specific surface to perform an action.
    dashEntrypoint?: DashEntrypoint;
    // Feature Line for dash
    featureLine?: FeatureLine;
    // Defines different types of dash surfaces
    dashSurfaceBuild?: DashSurfaceBuild;
    // The component within a surface in which an action was taken on Dash.
    actionSurfaceComponent?: ActionSurfaceComponent;
    // unique id for a /dash/conversation query. Used by the ML team to get feedback on answer quality. This is used to associate backend events with user actions on the front end. This is an opaque ID that is just used for correlating actions and has no other significance.
    dashAnswerRequestId?: string;
    // Identifier for a search query
    // Identifier is a v4 uuid that is generated on the fly during a search.
    searchQueryUuid?: string;
    // A zero-indexed position of an answer in the multi-answer card
    answerPositionIndex?: number;
    // LLM Generated query string for suggested questions. Returned from the context engine api and passed directly to the PAP event for model training data
    generatedQueryString?: string;
    // Legacy: dash_connector_id in Desktop
    // Note, this should have been a enum :(
    connector?: string;
    // Timestamp that encodes last time the search result was updated (in milliseconds).
    // i.e Document.upstreamUpdatedAt, Event.upstreamUpdatedAt, etc.
    // 1680559512000, 1680569512000, etc.
    resultUpdatedAtMs?: number;
    // Unique identifier (UUIDv5 ) of the search result
    resultUuid?: string;
  }
>;

export function PAP_Shown_DashAnswersSource(properties?: Shown_DashAnswersSource['properties']): Shown_DashAnswersSource {
  return <Shown_DashAnswersSource>{
    class: 'dash',
    action: 'shown',
    object: 'dash_answers_source',
    properties,
  };
}
