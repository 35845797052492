import { dash_feed } from '@dropbox/api-v2-client/types';
import { DashActivityAction } from '@mirage/analytics/events/enums/dash_activity_action';
import { getDashActivityActionFromString } from '@mirage/analytics/events/enums/dash_activity_action-utils';
import { DashObjectType } from '@mirage/analytics/events/enums/dash_object_type';
import { getFirstAction } from './helpers';

type CommonActivityProps = {
  dashActivityAction?: DashActivityAction;
  dashObjectType?: DashObjectType;
  dashObjectId?: string;
  actorUserId?: number;
  dashConnectorId?: string;
  itemPosition?: number;
};

export const activityDerivePAPProps = (
  activity?: dash_feed.FeedItem,
  position?: number,
): CommonActivityProps => {
  if (!activity) {
    return {};
  }

  return {
    dashActivityAction: toAction(activity),
    dashObjectType: toObjectType(activity),
    dashObjectId: toObjectId(activity),
    actorUserId: toActorUserId(activity),
    dashConnectorId: toConnectorId(activity),
    itemPosition: position,
  };
};

const toAction = (activity: dash_feed.FeedItem) => {
  return getDashActivityActionFromString(
    getFirstAction(activity)?.['.tag'] || 'other',
  );
};

const toObjectType = (activity: dash_feed.FeedItem) => {
  const type = activity.target?.details?.object_details?.['.tag'];
  switch (type) {
    case 'full_stack':
      return 'stack';
    default:
      return type;
  }
};

const toObjectId = (activity: dash_feed.FeedItem) => {
  const type = activity.target?.details?.object_details?.['.tag'];
  switch (type) {
    case 'full_stack':
      return activity.target?.details?.object_details?.namespace_id;
    case 'document':
      return activity.target?.details?.object_details?.uuid;
  }
};

const toActorUserId = (activity: dash_feed.FeedItem) => {
  return activity.activities?.[0]?.actor?.user_id;
};

const toConnectorId = (activity: dash_feed.FeedItem) => {
  const type = activity.target?.details?.object_details?.['.tag'];
  switch (type) {
    case 'document':
      return activity.target?.details?.object_details?.connector_type?.['.tag'];
  }
};
