import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

export function useListenForTypeaheadReset(resetTypeaheadState: VoidFunction) {
  const [params, setSearchParams] = useSearchParams();
  const resetTypeahead = params.get('resetTypeahead');
  useEffect(() => {
    if (resetTypeahead !== 'true') return;
    resetTypeaheadState();
    setSearchParams((params) => {
      params.delete('resetTypeahead');
      return params;
    });
  }, [resetTypeahead, setSearchParams, resetTypeaheadState]);
}

export function useListenForTypeaheadFocus(focusTypeaheadFn: VoidFunction) {
  const [params, setSearchParams] = useSearchParams();
  const focusTypeahead = params.get('focusTypeahead');
  useEffect(() => {
    if (focusTypeahead !== 'true') return;
    focusTypeaheadFn();
    setSearchParams((params) => {
      params.delete('focusTypeahead');
      return params;
    });
  }, [focusTypeahead, focusTypeaheadFn, setSearchParams]);
}
