import { tagged } from '@mirage/service-logging';
import { isDefined } from '@mirage/shared/util/tiny-utils';
import { UrlMetadata, UrlMetadataParams } from '../types';

import type { dcs } from '@dropbox/api-v2-client/types';
import type { APIv2Callable } from '@mirage/service-dbx-api/service';

const logger = tagged('url-metadata-api');

const isBlobContent = (
  content: dcs.blob_content_union,
): content is dcs.blob_content_unionRawContent => {
  return content?.['.tag'] === 'raw_content';
};

export const getMetadataForUrls = async (
  urlsParams: UrlMetadataParams[],
  callApiV2: APIv2Callable,
): Promise<UrlMetadata[] | undefined> => {
  const now = Date.now();
  const results: UrlMetadata[] = [];
  const urls = urlsParams.map((urlParams) => urlParams.url);

  const dcsResponse = await callApiV2('dcsGetLinkMetadata', {
    url_or_uuids: urlsParams.map((urlParams) => {
      return {
        link_type:
          urlParams.brandedType || urlParams.id3p
            ? {
                '.tag': 'override',
                override: {
                  url: urlParams.url,
                  branded_type: urlParams.brandedType,
                  id3p: urlParams.id3p,
                },
              }
            : { '.tag': 'url', url: urlParams.url },
      };
    }),
  });

  let nonConnectorUrls = urls; // Responses where the error_code field is populated
  if (dcsResponse.results) {
    const dcsResponseValid = dcsResponse.results
      .filter((response) => response.error_code === '' && response.link)
      .map((response): UrlMetadata => {
        let thumbnailUrl = '';
        if (
          response?.body?.blob_content &&
          isBlobContent(response.body.blob_content)
        ) {
          thumbnailUrl = response.body.blob_content.raw || '';
        }

        const contentLastModifiedMs = response.last_modified_time
          ? new Date(response.last_modified_time).getTime()
          : null;

        return {
          url: response.link as string, // this cast is safe since it's checked in the filter
          title: response.title,
          thumbnailUrl,
          lastFetched: now,
          uuid: response.uuid || '',
          summarizableTag: response.summarizable?.['.tag'],
          faviconUrl: '', // No favicon for DCS links
          contentLastModifiedMs, // Last modified time of the underlying content according to DCS
        };
      });

    const connectorUrls = dcsResponseValid.map((response) => response.url);
    nonConnectorUrls = urls.filter((url) => !connectorUrls.includes(url));

    // Collect results
    results.splice(results.length, 0, ...dcsResponseValid);
  }

  // Fall back to stacks API for URLs not serviced by dcsGetLinkMetadata
  if (nonConnectorUrls.length) {
    try {
      const stacksResponse = await callApiV2('stacksGetMetadataForUrls', {
        urls: nonConnectorUrls, // Call normal endpoint if not a connector
      });

      const stacksMetadata =
        stacksResponse.url_metadata
          ?.map((metadata) =>
            metadata.url
              ? {
                  url: metadata.url,
                  title: metadata.title,
                  faviconUrl: metadata.favicon_url,
                  thumbnailUrl: metadata.thumbnail_url,
                  uuid: undefined,
                  summarizableTag: undefined,
                  lastFetched: now,
                  // stacks/get_metadata_for_urls does not return contentLastModified currently
                  contentLastModifiedMs: null,
                }
              : undefined,
          )
          .filter(isDefined) || [];

      results.splice(results.length, 0, ...stacksMetadata);
    } catch {
      // Ignore errors from stacks API
      logger.warn('Error calling stacksGetMetadataForUrls');
    }
  }

  return results;
};
