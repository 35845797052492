import { DashSpinner } from '@mirage/shared/dash-spinner';
import styles from './SpinnerSystemMessage.module.css';

export const SpinnerSystemMessage: React.FC = () => {
  return (
    <div className={styles.spinnerContainer}>
      <div className={styles.spinnerMessage}>
        <DashSpinner size={32} />
      </div>
    </div>
  );
};
