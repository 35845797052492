import { Menu } from '@dropbox/dig-components/menu';
import styled from '@emotion/styled';
import { cloneElement } from 'react';

import type { MenuPlacement } from '@dropbox/dig-components/menu';
import type { AnalyticsHtmlAttributes } from '@mirage/analytics/uxa';

export type DropdownItem = {
  title: React.ReactNode;
  subtitle?: React.ReactNode;
  leftAccessory?: React.ReactNode;
  rightAccessory?: React.ReactNode;
  selected?: boolean;
  onClick?: () => void;
  key: string;
  analyticsAttr?: AnalyticsHtmlAttributes;
  subItems?: DropdownItem[];
};

export type DropdownConfig = {
  sections: Array<Array<DropdownItem>>;
  footer?: React.ReactNode;
};

type Props = {
  anchor: React.ReactElement;
  placement?: MenuPlacement;
  config: DropdownConfig;
  onToggle?: (open: boolean) => void;
  closeOnMouseLeave?: boolean;
  minWidth?: number;
  onClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
  className?: string;
  ariaRole?: string;
};

type MenuItemProps = {
  item: DropdownItem;
};

function DropdownMenuItem({ item }: MenuItemProps) {
  if (!item.subItems?.length) {
    return (
      <MenuItem
        withTitle={item.title}
        withSubtitle={item.subtitle}
        withLeftAccessory={item.leftAccessory}
        withRightAccessory={item.rightAccessory}
        value={item}
        interactive={!!item.onClick}
        selected={item.selected}
        {...(item.analyticsAttr || {})}
      />
    );
  }
  return (
    <Menu.Submenu
      withTriggerContent={item.title}
      withLeftAccessory={item.leftAccessory}
      withRightAccessory={item.rightAccessory}
      {...(item.analyticsAttr || {})}
    >
      <Menu.Segment>
        {item.subItems.map((subItem) => (
          <Menu.ActionItem
            key={subItem.key}
            withTitle={subItem.title}
            withSubtitle={subItem.subtitle}
            withLeftAccessory={subItem.leftAccessory}
            withRightAccessory={subItem.rightAccessory}
            value={subItem}
            interactive={!!subItem.onClick}
            {...(subItem.analyticsAttr || {})}
          />
        ))}
      </Menu.Segment>
    </Menu.Submenu>
  );
}

export default function Dropdown({
  anchor,
  placement = 'bottom-end',
  config,
  onToggle,
  closeOnMouseLeave = false,
  minWidth = 240,
  onClick = () => {},
  className,
  ariaRole,
}: Props) {
  return (
    <Menu.Wrapper
      {...(ariaRole ? { role: ariaRole } : {})}
      onClick={onClick}
      className={className}
      shouldPropagateClickOutsideMouseEvents={true}
      closeOnBlur={true}
      onToggle={onToggle && ((event) => onToggle(event.isOpen))}
      onSelection={(item) => item?.onClick?.()}
    >
      {({ getContentProps, getTriggerProps, closeMenu }) => (
        <>
          {cloneElement(anchor, { ...getTriggerProps() })}
          {config && (
            <DropdownWrapper
              {...getContentProps()}
              placement={placement}
              onMouseLeave={() => closeOnMouseLeave && closeMenu({})}
              minWidth={minWidth}
            >
              {config.sections.map((segment, i) => (
                <Menu.Segment key={`dropdow_section_${i}`}>
                  {segment.map((item) => (
                    <DropdownMenuItem item={item} key={item.key} />
                  ))}
                </Menu.Segment>
              ))}
              {config.footer && <>{config.footer}</>}
            </DropdownWrapper>
          )}
        </>
      )}
    </Menu.Wrapper>
  );
}

const DropdownWrapper = styled(Menu.Content)<{ minWidth: number }>`
  ${({ minWidth }) => `min-width: ${minWidth}px;`}
  background: var(--dig-color__background__base);
  border-radius: var(--dig-spacing__micro__small);

  .dig-Menu-canvas {
    border-radius: var(--dig-spacing__micro__small);
  }

  .dig-Menu-segment {
    display: flex;
    flex-direction: column;
    align-items: stretch;
  }

  .dig-Menu-row {
    padding-top: 2px;
    padding-bottom: 2px;
  }
`;

const MenuItem = styled(Menu.ActionItem)<{
  selected?: boolean;
  interactive?: boolean;
}>`
  outline: none;

  ${({ selected }) =>
    selected && 'background: var(--dig-color__opacity__surface--state-1);'}

  &:hover {
    ${({ selected, interactive }) =>
      !selected &&
      interactive &&
      'background: var(--dig-color__opacity__surface);'}
  }
`;
