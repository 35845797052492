import { dash_feed } from '@dropbox/api-v2-client';
import { Text } from '@dropbox/dig-components/typography';
import { Trans } from '@mirage/translations';

export const ActivityLabelSubject = ({
  children,
}: {
  children?: React.ReactNode;
}) => {
  return (
    <Text variant="label" color="standard" isBold>
      {children}
    </Text>
  );
};

// Creates a label for the actors in an activity
// If there are more than 3 actors, only the first 2 actors are displayed, along with the count of the rest of the actors
// EX: "John, Jane, and 3 others"
export const ActivityActorsLabel = ({
  actors,
}: {
  actors: dash_feed.ActivityUser[];
}) => {
  let actorNamesToDisplay = actors
    .map((actor) => actor.name)
    .filter((name) => !!name) as string[];
  let extraActorsCount = 0;
  if (actors.length > 3) {
    // display 2 actors + rest of the count
    extraActorsCount = actors.length - 2;
    actorNamesToDisplay = actorNamesToDisplay.slice(0, 2);
  }

  if (actorNamesToDisplay.length === 0) {
    return (
      <Trans
        i18nKey="activity_feed_expanded_label_actors_no_actor"
        components={{ subject: <ActivityLabelSubject /> }}
      />
    );
  } else if (actorNamesToDisplay.length === 1) {
    return (
      <Trans
        i18nKey="activity_feed_expanded_label_actors_one_actor"
        values={{
          actorName1: actorNamesToDisplay[0],
        }}
        components={{ subject: <ActivityLabelSubject /> }}
      />
    );
  } else if (actorNamesToDisplay.length === 2) {
    return (
      <Trans
        i18nKey="activity_feed_expanded_label_actors_two_actors"
        values={{
          actorName1: actorNamesToDisplay[0],
          actorName2: actorNamesToDisplay[1],
          count: extraActorsCount,
        }}
        components={{ subject: <ActivityLabelSubject /> }}
      />
    );
  } else {
    return (
      <Trans
        i18nKey="activity_feed_expanded_label_actors_three_actors"
        values={{
          actorName1: actorNamesToDisplay[0],
          actorName2: actorNamesToDisplay[1],
          actorName3: actorNamesToDisplay[2],
        }}
        components={{ subject: <ActivityLabelSubject /> }}
      />
    );
  }
};
