/*
  NOTE: this code is copied from the Scout repo.

  Ideally, this code might be extracted into a shared, internally-hosted
  node module for both Tesla and Scout to consume.

  However, we expect to do another revision on how Dash generates ids
  (and normalizes URLs into ids) in the very near future.  In this case,
  the effort to extract this code into a separate module, updated references
  throughout the codebases, etc. is likely not worth the incremental effort.
*/

/* eslint-disable no-useless-escape */
const REGEX_URL_SUPPORTED_PROTOCOLS =
  '(?:https?|sftp|ftps?|ssh|ircs?|file|gopher|telnet|nntp|worldwind|chrome|chrome-extension|svn|git|mms|smb|afp|nfs|(x-)?man|txmt|x-hackpad-image-upload)';

const URI_PROTOCOLS_WITHOUT_SLASH = [
  'mailto',
  'xmpp',
  'sips?',
  'news',
  'bitcoin',
  'magnet',
  'urn',
  'geo',
];

const REGEX_URLCHAR =
  /[-:@_.,~%+\/\\?=&#;()!'$\*\u0030-\u0039\u0041-\u005A\u0061-\u007A\u00C0-\u00D6\u00D8-\u00DE\u00E0-\u00F6\u00F8-\u00FF\u0100-\u1FFF\u3040-\u9FFF\uF900-\uFDFF\uFE70-\uFEFE\uFF10-\uFF19\uFF21-\uFF3A\uFF41-\uFF5A\uFF66-\uFFDC]/;

export const REGEX_URL = new RegExp(
  `(?:${REGEX_URL_SUPPORTED_PROTOCOLS}://|${URI_PROTOCOLS_WITHOUT_SLASH.join(
    ':|',
  )}:)${REGEX_URLCHAR.source}*(?![:.,;\\)'])${REGEX_URLCHAR.source}`,
  'gi',
);

export const PAPER_REGEX_URL = new RegExp(
  `https://www.dropbox.com/${REGEX_URLCHAR.source}*.paper${REGEX_URLCHAR.source}*`,
);

// Copied from /paper/etherpad/src/client/ace/editor/render/constants.tsx
export const DROPBOX_SHARING_SUFFIX = '(?:\\?([^#]*))?(?:(.*))?';
export const DROPBOX_SCL_CLOUD_DOCS_LINKS_REGEX = new RegExp(
  `https://(?:[a-zA-Z0-9-]+[.])*dropbox[.]com/scl/fi/([a-zA-Z0-9]+)/([^#\\?\\s]+)\\.([^#\\?\\s]*)${DROPBOX_SHARING_SUFFIX}`,
  'g',
);

// Modified from https://github.com/dropbox/cmde-tesla/blob/1ca4769b2aed62b879add8d286292b9fcd7f85c1/app/connectors/parser/parserConfigs/confluence.js
// overview, e.g. https://dropbox-kms.atlassian.net/wiki/spaces/CMDE/overview?homepageId=575015401
export const CONFLUENCE_OVERVIEW_RE = new RegExp(
  /^([\w-]+)\.(?:atlassian|jira)\.(?:net|com)\/wiki\/spaces\/([^\/]+)\/overview/,
);
// page, e.g. https://dropbox-kms.atlassian.net/wiki/spaces/CMDE/pages/588842241/How+to+add+users+to+the+alpha
export const CONFLUENCE_PAGE_RE = new RegExp(
  /^([\w-]+)\.(?:atlassian|jira)\.(?:net|com)\/wiki\/spaces\/([^\/]+)\/pages\/([^\/]+)/,
);

function genericSuffixMapper(urlPrefix: string) {
  return (url: string): string => {
    return url.split('?')[0].split('#')[0].substring(urlPrefix.length);
  };
}

function regexSuffixMapper(urlPrefix: string, regex: string) {
  return (url: string): string | undefined => {
    const matches = url
      .split('?')[0]
      .split('#')[0]
      .substring(urlPrefix.length)
      .match(regex);
    return matches ? matches[1] : undefined;
  };
}

function regexURLMapper(regex: RegExp) {
  return (url: string): string | undefined => {
    const matches = regex.exec(url);
    if (matches) {
      return matches.slice(1).join(':');
    }
    return undefined;
  };
}

// references https://github.com/dropbox/twine/blob/0118a5e58d365c52adb657fa50506e0289adef70/twine/sync/external_stores/url.py#L6
export interface CanonUrlMapRule {
  urlPrefix: string;
  canonPrefix: string;
  mapperFn: (url: string) => string | undefined;
  serviceName: string;
  entityType?: string;
}
export const CANON_URL_MAPPERS: CanonUrlMapRule[] = [
  {
    urlPrefix: 'airtable.com/',
    canonPrefix: 'airtable',
    mapperFn: genericSuffixMapper('airtable.com/'),
    serviceName: 'Airtable',
  },
  {
    urlPrefix: 'coda.io/d/',
    canonPrefix: 'coda',
    mapperFn: genericSuffixMapper('coda.io/d/'),
    serviceName: 'Coda',
  },
  {
    urlPrefix: 'docsend.com/documents/',
    canonPrefix: 'docsend',
    mapperFn: genericSuffixMapper('docsend.com/documents/'),
    serviceName: 'Docsend',
  },
  {
    urlPrefix: 'paper.dropbox.com/doc/',
    canonPrefix: 'paper:doc',
    mapperFn: regexSuffixMapper('paper.dropbox.com/doc/', '.+-(.+)$'),
    serviceName: 'Paper',
  },
  {
    urlPrefix: 'paper.dropbox.com/doc/',
    canonPrefix: 'paper:doc',
    mapperFn: regexSuffixMapper('paper.dropbox.com/doc/', '([A-Za-z0-9]+)$'),
    serviceName: 'Paper',
  },
  {
    urlPrefix: 'paper.dropbox.com/doc/print/',
    canonPrefix: 'paper:doc',
    mapperFn: regexSuffixMapper(
      'paper.dropbox.com/doc/print/',
      '([A-Za-z0-9]+)$',
    ),
    serviceName: 'Paper',
  },
  {
    urlPrefix: 'www.dropbox.com/scl/fi/',
    canonPrefix: 'paper:scl',
    mapperFn: regexSuffixMapper(
      'www.dropbox.com/scl/fi/',
      '([A-Za-z0-9]+)/.+\\.paper',
    ),
    serviceName: 'Paper',
  },
  {
    urlPrefix: 'www.dropbox.com/scl/fi/',
    canonPrefix: 'paper:scl',
    mapperFn: regexSuffixMapper(
      'www.dropbox.com/scl/fi/',
      '([A-Za-z0-9]+)/.+\\.paper',
    ),
    serviceName: 'Paper',
  },
  {
    urlPrefix: 'www.dropbox.com/scl/fi/',
    canonPrefix: 'dropbox:scl',
    mapperFn: regexSuffixMapper(
      'www.dropbox.com/scl/fi/',
      '([A-Za-z0-9]+)/.+(?!\\.paper)',
    ),
    serviceName: 'Dropbox',
  },
  {
    urlPrefix: 'www.dropbox.com/s/',
    canonPrefix: 'dropbox:s',
    mapperFn: regexSuffixMapper('www.dropbox.com/s/', '([A-Za-z0-9]+)/.+'),
    serviceName: 'Dropbox',
  },
  {
    urlPrefix: 'figma.com/proto/',
    canonPrefix: 'figma',
    mapperFn: regexSuffixMapper('figma.com/proto/', '(.+)/.+'),
    serviceName: 'Figma',
  },
  {
    urlPrefix: 'www.figma.com/proto/',
    canonPrefix: 'figma',
    mapperFn: regexSuffixMapper('www.figma.com/proto/', '(.+)/.+'),
    serviceName: 'Figma',
  },
  {
    urlPrefix: 'figma.com/file/',
    canonPrefix: 'figma',
    mapperFn: regexSuffixMapper('figma.com/file/', '(.+)/.+'),
    serviceName: 'Figma',
  },
  {
    urlPrefix: 'www.figma.com/file/',
    canonPrefix: 'figma',
    mapperFn: regexSuffixMapper('figma.com/file/', '(.+)/.+'),
    serviceName: 'Figma',
  },
  {
    urlPrefix: 'docs.google.com/spreadsheets/d/',
    canonPrefix: 'gsheet',
    mapperFn: regexSuffixMapper(
      'docs.google.com/spreadsheets/d/',
      '(.+)/edit.*',
    ),
    serviceName: 'Google Sheets',
    entityType: 'gsheet',
  },
  {
    urlPrefix: 'docs.google.com/a/',
    canonPrefix: 'gsheet',
    mapperFn: regexSuffixMapper(
      'docs.google.com/a/',
      '.+/spreadsheets/d/(.+)/edit.*',
    ),
    serviceName: 'Google Sheets',
    entityType: 'gsheet',
  },
  {
    urlPrefix: 'docs.google.com/spreadsheets/u/',
    canonPrefix: 'gsheet',
    mapperFn: regexSuffixMapper(
      'docs.google.com/spreadsheets/u/',
      '.+/d/(.+)/edit.*',
    ),
    serviceName: 'Google Sheets',
    entityType: 'gsheet',
  },
  {
    urlPrefix: 'docs.google.com/presentation/d/',
    canonPrefix: 'gslide',
    mapperFn: regexSuffixMapper(
      'docs.google.com/presentation/d/',
      '(.+)/edit.*',
    ),
    serviceName: 'Google Slides',
    entityType: 'gslide',
  },
  {
    urlPrefix: 'docs.google.com/a/',
    canonPrefix: 'gslide',
    mapperFn: regexSuffixMapper(
      'docs.google.com/a/',
      '.+/presentation/d/(.+)/edit.*',
    ),
    serviceName: 'Google Slides',
    entityType: 'gslide',
  },
  {
    urlPrefix: 'docs.google.com/presentation/u/',
    canonPrefix: 'gslide',
    mapperFn: regexSuffixMapper(
      'docs.google.com/presentation/u/',
      '.+/d/(.+)/edit.*',
    ),
    serviceName: 'Google Slides',
    entityType: 'gslide',
  },
  {
    urlPrefix: 'docs.google.com/document/d/',
    canonPrefix: 'gdoc',
    mapperFn: regexSuffixMapper('docs.google.com/document/d/', '(.+)/edit.*'),
    serviceName: 'Google Docs',
    entityType: 'gdoc',
  },
  {
    urlPrefix: 'docs.google.com/a/',
    canonPrefix: 'gdoc',
    mapperFn: regexSuffixMapper(
      'docs.google.com/a/',
      '.+/document/d/(.+)/edit.*',
    ),
    serviceName: 'Google Docs',
    entityType: 'gdoc',
  },
  {
    urlPrefix: 'docs.google.com/document/u/',
    canonPrefix: 'gdoc',
    mapperFn: regexSuffixMapper(
      'docs.google.com/document/u/',
      '.+/d/(.+)/edit.*',
    ),
    serviceName: 'Google Docs',
    entityType: 'gdoc',
  },
  {
    urlPrefix: '',
    canonPrefix: 'confluence:overview',
    mapperFn: regexURLMapper(CONFLUENCE_OVERVIEW_RE),
    serviceName: 'Confluence',
  },
  {
    urlPrefix: '',
    canonPrefix: 'confluence:page',
    mapperFn: regexURLMapper(CONFLUENCE_PAGE_RE),
    serviceName: 'Confluence',
  },
  {
    urlPrefix: 'twitter.com/',
    canonPrefix: 'twitter',
    mapperFn: regexURLMapper(
      new RegExp(/^twitter\.com\/([\w-_]+)\/status\/(\d+)/),
    ),
    serviceName: 'Twitter',
  },
  {
    urlPrefix: 'twitter.com/',
    canonPrefix: 'twitter:profile',
    mapperFn: regexURLMapper(new RegExp(/^twitter\.com\/([\w-_]+)/)),
    serviceName: 'Twitter',
  },
  {
    urlPrefix: 'stackoverflow.com/questions/',
    canonPrefix: 'stackoverflow',
    mapperFn: regexSuffixMapper(
      'stackoverflow.com/questions/',
      '([A-Za-z0-9]+)/',
    ),
    serviceName: 'Stack Overflow',
  },
  {
    urlPrefix: 'open.spotify.com/album/',
    canonPrefix: 'spotify:album',
    mapperFn: regexSuffixMapper('open.spotify.com/album/', '([A-Za-z0-9]+)'),
    serviceName: 'Spotify',
  },
  {
    urlPrefix: 'open.spotify.com/track/',
    canonPrefix: 'spotify:track',
    mapperFn: regexSuffixMapper('open.spotify.com/track/', '([A-Za-z0-9]+)'),
    serviceName: 'Spotify',
  },
  {
    urlPrefix: 'calendar.google.com/calendar',
    canonPrefix: 'gcal',
    mapperFn: regexURLMapper(new RegExp('[?&]eid=([^&#]*)')),
    serviceName: 'Google Calendar',
  },
  {
    urlPrefix: 'onedrive.live.com/',
    canonPrefix: 'onedrive',
    // Matches set of characters after the resID param or id param
    // https://onedrive.live.com/edit.aspx?resid=54740FFA4B34779A!354 will match 54740FFA4B34779A!354
    mapperFn: regexURLMapper(new RegExp('[?&](?:resid|id)=([^&#]*)')),
    serviceName: 'OneDrive',
  },
];

export function getCanonUrlMapRule(
  contentID: string,
): CanonUrlMapRule | undefined {
  for (const rule of CANON_URL_MAPPERS) {
    if (contentID.startsWith(`${rule.canonPrefix}:`)) {
      return rule;
    }
  }
  return undefined;
}

export function urlToContentID(url: string, lowercase: boolean = true): string {
  const cleanURL = (lowercase ? url.toLowerCase() : url).replace(
    /https?:\/\//i,
    '',
  );
  for (const rule of CANON_URL_MAPPERS) {
    if (cleanURL.startsWith(rule.urlPrefix)) {
      const res = rule.mapperFn(cleanURL);
      if (res) {
        return `${rule.canonPrefix}:${res}`;
      }
    }
  }
  const urlWithoutHash = cleanURL.split('#')[0];
  return `web:${urlWithoutHash}`;
}

export function urlToEntityType(url: string): string {
  const cleanURL = url.toLowerCase().replace(/https?:\/\//i, '');
  for (const rule of CANON_URL_MAPPERS) {
    if (cleanURL.startsWith(rule.urlPrefix) && rule.entityType) {
      return rule.entityType;
    }
  }
  return `unknown`;
}

export function urlToService(url: string): string {
  const cleanURL = url.toLowerCase().replace(/https?:\/\//i, '');
  for (const rule of CANON_URL_MAPPERS) {
    if (cleanURL.startsWith(rule.urlPrefix)) {
      const res = rule.mapperFn(cleanURL);
      if (res) {
        return `${rule.serviceName}`;
      }
    }
  }

  return '';
}

export function urlToDomainName(url: string): string {
  try {
    const domain = new URL(url).hostname;
    return domain;
  } catch (e) {
    return '';
  }
}
