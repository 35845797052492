import { Button } from '@dropbox/dig-components/buttons';
import { Title } from '@dropbox/dig-components/typography';
import { useMirageAnalyticsContext } from '@mirage/analytics/AnalyticsProvider';
import { PAP_Eng_DashWebpageError } from '@mirage/analytics/events/types/eng_dash_webpage_error';
import { t } from 'i18next';
import { useEffect } from 'react';
import styles from './StackErrorBoundary.module.css';

export const StackErrorBoundary = () => {
  const { reportPapEvent } = useMirageAnalyticsContext();

  useEffect(() => {
    reportPapEvent(
      PAP_Eng_DashWebpageError({
        dashRoute: 'stack',
      }),
    );
  }, [reportPapEvent]);

  return (
    <div className={styles.errorBoundaryContainer}>
      <Title size="medium">{t('generic_error_message')}</Title>
      <div>
        <Button variant="primary" onClick={() => window.location.reload()}>
          {t('reload_window_button')}
        </Button>
      </div>
    </div>
  );
};
