import { FolderLine } from '@dropbox/dig-icons/assets';
import { getConnectorDisplayNameFromResult } from '@mirage/shared/connectors';
import { useIsMobileSize } from '@mirage/shared/responsive/mobile';
import { Recommendation } from '@mirage/shared/search/recommendation';
import { PersonObject } from '@mirage/shared/search/search-filters';
import { RefObject } from 'react';
import {
  getLocalFileTimestamp,
  getPersonForMetadata,
} from '../util/resultUtil';
import { MetadataItem } from './MetadataItem';
import { PersonMetadata } from './PersonMetadata';
import { TruncatedFilePath } from './TruncatedFilePath';

import type { SearchResult } from '@mirage/service-dbx-api';

type FilePathMetadataProps = {
  result: SearchResult | Recommendation;
  pathSegments: string[];
  isOverflowing: boolean;
  isTooltipContent?: boolean;
  triggerRef?: RefObject<HTMLDivElement>;
  onClickPerson?: (person: PersonObject) => void;
};

export const FilePathMetadata = ({
  result,
  pathSegments,
  isOverflowing,
  isTooltipContent,
  triggerRef,
  onClickPerson,
}: FilePathMetadataProps) => {
  const { sender, creator, lastModifier } = result;
  const isMobileSize = useIsMobileSize();

  const appName = getConnectorDisplayNameFromResult(result);
  const timestamp = getLocalFileTimestamp(result);
  const parentFolder = pathSegments.at(-1) || '';

  const metadataTitle = isTooltipContent ? (
    pathSegments.join(' > ')
  ) : (
    <TruncatedFilePath
      isOverflowing={isOverflowing}
      pathSegments={[parentFolder]}
    />
  );

  const renderPersonMetadata = () => {
    const { person, i18nKey } = getPersonForMetadata({
      sender,
      creator,
      lastModifier,
    });

    if (!i18nKey || !person) {
      return null;
    }

    return (
      <MetadataItem
        title={
          <PersonMetadata
            i18nKey={i18nKey}
            person={person}
            onClickPerson={onClickPerson}
          />
        }
      />
    );
  };

  return (
    <>
      {!isTooltipContent && appName && <MetadataItem title={appName} />}
      {pathSegments.length > 0 && (
        <MetadataItem
          title={metadataTitle}
          iconSrc={FolderLine}
          triggerRef={triggerRef}
        />
      )}
      {!isTooltipContent && !isMobileSize && renderPersonMetadata()}
      {!isTooltipContent && timestamp && <MetadataItem title={timestamp} />}
    </>
  );
};
