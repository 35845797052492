import { useFeatureFlagValue } from '@mirage/service-experimentation/useFeatureFlagValue';
import { convertFeatureValueToBool } from '@mirage/service-experimentation/util';
import { useCallback, useEffect, useState } from 'react';
import { activityFeed, loadMoreActivities, setActivityFeedFilters } from '../';
import { DEFAULT_FILTERS } from '../service';
import { UserActivityFeed } from '../types';

export const useActivityFeed = () => {
  // if true, we'll render "mock" data to be used during roadshow demos by Morgan/Umesh
  const shouldUseDemoData = convertFeatureValueToBool(
    // note: this flag controls all demo data right now, not just recents like it's named
    useFeatureFlagValue('dash_2024_10_04_mock_recents_data'),
  );

  const [userActivityFeed, setUserActivityFeed] = useState<UserActivityFeed>({
    activities: undefined,
    isRefreshing: true,
    isLoadingMore: false,
    hasMore: false,
    filters: DEFAULT_FILTERS,
  });

  useEffect(() => {
    const sub = activityFeed().subscribe((feed) => {
      if (shouldUseDemoData) {
        setDemoTimestamps(feed);
      }
      setUserActivityFeed(feed);
    });

    return () => {
      sub.unsubscribe();
    };
  }, [shouldUseDemoData]);

  const clearFilters = useCallback(() => {
    setActivityFeedFilters(DEFAULT_FILTERS);
  }, []);

  return {
    setFilters: setActivityFeedFilters,
    clearFilters,
    loadMore: loadMoreActivities,
    ...userActivityFeed,
  };
};

//
function setDemoTimestamps(feed: UserActivityFeed) {
  if (!feed.activities) {
    return;
  }

  const timeDecayMinutes = [
    10, // 10 mins ago
    25, // 25 mins ago
    60 * 2, // 2 hours ago
    60 * 2, // 2 hours ago
    60 * 6, // 6 hours ago
    60 * 8, // 8 hours ago
    60 * 24 * 1, // 1 day ago
    60 * 24 * 1, // 1 day ago
    60 * 24 * 1, // 1 day ago
    60 * 24 * 1, // 1 day ago
    60 * 24 * 1, // 1 day ago
    60 * 24 * 1, // 1 day ago
    60 * 24 * 1, // 1 day ago
    60 * 24 * 1, // 1 day ago
    60 * 24 * 2, // 2 days ago
    60 * 24 * 2, // 2 days ago
    60 * 24 * 2, // 2 days ago
    60 * 24 * 2, // 2 days ago
    60 * 24 * 3, // 3 days ago
  ];

  for (const item of feed.activities) {
    const timeOffsetMinutes = timeDecayMinutes.shift() || 60 * 24 * 3;
    const mockTimestamp = new Date(Date.now() - timeOffsetMinutes * 60000);
    const activity = item.activities?.[0];
    if (activity) {
      activity.ts = mockTimestamp.getTime();
      switch (item.target?.details?.object_details?.['.tag']) {
        case 'full_stack':
          item.target.details.object_details.last_modified_time_utc_sec =
            mockTimestamp.getTime() / 1000;
          break;
        case 'document':
          item.target.details.object_details.provider_updated_at_ms =
            mockTimestamp.getTime();
      }
    }
  }
}
