import { Chip } from '@dropbox/dig-components/chip';
import { Text } from '@dropbox/dig-components/typography';
import { UIIcon } from '@dropbox/dig-icons';
import { AddCircleLine, MagicWandLine } from '@dropbox/dig-icons/assets';
import { PAPEvent } from '@mirage/analytics/events/base/event';
import { ActionSurfaceComponent } from '@mirage/analytics/events/enums/action_surface_component';
import { PAP_Click_AddLaterButton } from '@mirage/analytics/events/types/click_add_later_button';
import { SourcesContentCache } from '@mirage/mosaics/ComposeAssistant/data/ComposeSourcesCache';
import {
  ComposeSource,
  ContentTypeOption,
} from '@mirage/shared/compose/compose-session';
import i18n from '@mirage/translations';
import classnames from 'classnames';
import { memo, useEffect, useRef, useState } from 'react';
import styles from './ContextInputMessage.module.css';
import { ConversationMessageSourceChips } from './ConversationMessageSourceChips';

interface ContextInputMessageWrapperProps {
  stepIndex: number;
  instruction: string;
  children: React.ReactNode;
  didSelectContentType?: boolean;
}
export const ContextInputMessageWrapper = memo(
  ({
    stepIndex,
    instruction,
    children,
    didSelectContentType,
  }: ContextInputMessageWrapperProps) => {
    // Use ref to recapture focus on text options for screen reader
    const wrapperRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
      wrapperRef.current?.focus();
    }, []);
    return (
      <div
        className={styles.ContextInputMessageWrapper}
        ref={wrapperRef}
        tabIndex={-1}
      >
        <div className={styles.ContextInputMessageIconContainer}>
          <UIIcon
            src={MagicWandLine}
            size="small"
            className={styles.ContextInputMessageIcon}
          />
        </div>
        <div className={styles.ContextInputMessageWrapperContent}>
          <Text size="small" color="subtle" tagName="div">
            {i18n.t('compose_step_index', { stepIndex })}
          </Text>
          <Text size="medium" tagName="div">
            {instruction}
          </Text>
          <div
            className={
              (didSelectContentType &&
                styles.ContextInputMessageWrapperDisabled) ||
              ''
            }
          >
            {children}
          </div>
        </div>
      </div>
    );
  },
);
ContextInputMessageWrapper.displayName = 'ContextInputMessageWrapper';

interface ContextInputContentTypeMessageProps {
  stepIndex: number;
  currentContentType: ContentTypeOption | undefined;
  onContentTypeChange: (contentType: ContentTypeOption | undefined) => void;
  didSelectContentType: boolean;
}
export const ContextInputContentTypeMessage = memo(
  ({
    stepIndex,
    currentContentType,
    onContentTypeChange,
    didSelectContentType,
  }: ContextInputContentTypeMessageProps) => {
    return (
      <ContextInputMessageWrapper
        stepIndex={stepIndex}
        instruction={i18n.t('compose_content_type_question')}
        didSelectContentType={didSelectContentType}
      >
        {CONTENT_TYPES_CHOICES.map((option) => (
          <Chip
            className={classnames(styles.ContextInputContentTypeMessageChip, {
              [styles.ContextInputContentTypeMessageChipSelected]:
                option === currentContentType,
            })}
            key={option}
            variant="transparent"
            onClick={() => onContentTypeChange(option)}
            isSelected={option === currentContentType}
          >
            <Chip.Content>{contentTypeOptionToString(option)}</Chip.Content>
          </Chip>
        ))}
        <Chip
          className={classnames(styles.ContextInputContentTypeMessageChip, {
            [styles.ContextInputContentTypeMessageChipSelected]:
              didSelectContentType && currentContentType === undefined,
          })}
          key="other"
          variant="transparent"
          onClick={() => onContentTypeChange(undefined)}
          isSelected={didSelectContentType && currentContentType === undefined}
        >
          <Chip.Content>
            {i18n.t('compose_context_form_content_type_none')}
          </Chip.Content>
        </Chip>
      </ContextInputMessageWrapper>
    );
  },
);
ContextInputContentTypeMessage.displayName = 'ContextInputContentTypeMessage';

const CONTENT_TYPES_CHOICES: ContentTypeOption[] = [
  'email',
  'report',
  'proposal',
];

interface ContextInputSourcesMessageProps {
  stepIndex: number;
  sources: ComposeSource[];
  sourcesContentCache: SourcesContentCache;
  handleClickAdd: () => void;
  removeSource: (source: ComposeSource) => void;
  onDone?: () => void;
  logComposeEvent: (
    event: PAPEvent,
    overrides?: {
      actionSurfaceComponent?: ActionSurfaceComponent;
    },
  ) => void;
}
export const ContextInputSourcesMessage = memo(
  ({
    stepIndex,
    sources,
    handleClickAdd,
    removeSource,
    onDone,
    logComposeEvent,
  }: ContextInputSourcesMessageProps) => {
    const [showAddLater, setShowAddLater] = useState(true);
    return (
      <ContextInputMessageWrapper
        stepIndex={stepIndex}
        instruction={i18n.t('compose_sources_question')}
      >
        <div className={styles.ContextInputSourcesActions}>
          <Chip
            variant="transparent"
            size="medium"
            onClick={handleClickAdd}
            className={styles.ContextInputSourceActionChip}
          >
            <Chip.IconAccessory>
              <UIIcon src={AddCircleLine} />
            </Chip.IconAccessory>
            <Chip.Content>{i18n.t('compose_add_source_action')}</Chip.Content>
          </Chip>
          {showAddLater && onDone && sources.length === 0 && (
            <Chip
              className={styles.ContextInputSourceActionChip}
              variant="transparent"
              onClick={() => {
                logComposeEvent(PAP_Click_AddLaterButton());
                setShowAddLater(false);
                onDone();
              }}
            >
              <Chip.Content>{i18n.t('skip_button')}</Chip.Content>
            </Chip>
          )}
        </div>
        <ConversationMessageSourceChips
          sources={sources}
          removeSource={removeSource}
          logComposeEvent={logComposeEvent}
        />
      </ContextInputMessageWrapper>
    );
  },
);
ContextInputSourcesMessage.displayName = 'ContextInputSourcesMessage';

export function contentTypeOptionToString(option: ContentTypeOption): string {
  switch (option) {
    case 'email':
      return i18n.t('compose_context_form_content_type_email');
    case 'message':
      return i18n.t('compose_context_form_content_type_message');
    case 'report':
      return i18n.t('compose_context_form_content_type_report');
    case 'proposal':
      return i18n.t('compose_context_form_content_type_proposal');
    case 'social_media_post':
      return i18n.t('compose_context_form_content_type_social_media_post');
    case 'meeting_agenda':
      return i18n.t('compose_context_form_content_type_meeting_agenda');
    default:
      option satisfies never;
      throw new Error(`Unknown content type option: ${option}`);
  }
}
