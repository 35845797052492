// @generated - Do not edit: this file is autogenerated by:
//   //dropbox/pap/codegen
//
// Event Property Definition: https://pap.pp.dropbox.com/#/event_property/2686

import type { DashActivityAction } from "./dash_activity_action";

const DashActivityActionValues: DashActivityAction[] = [
    "edit",
    "share",
    "comment",
    "other",
    "add_child",
    "remove_child",
    "view",
    "create",
];

export function getDashActivityActionFromString(s: string): DashActivityAction | undefined {
    return DashActivityActionValues.find(e => e === s?.toLowerCase());
}
