import { stacks } from '@dropbox/api-v2-client';
import { useMirageAnalyticsContext } from '@mirage/analytics/AnalyticsProvider';
import { DashNewLinkType } from '@mirage/analytics/events/enums/dash_new_link_type';
import { PAP_Add_DashNewLink } from '@mirage/analytics/events/types/add_dash_new_link';
import { PAP_Add_StackSection } from '@mirage/analytics/events/types/add_stack_section';
import { PAP_Archive_DashStacksArchive } from '@mirage/analytics/events/types/archive_dash_stacks_archive';
import { PAP_Create_DashNewStack } from '@mirage/analytics/events/types/create_dash_new_stack';
import { PAP_Undo_DashStacksArchive } from '@mirage/analytics/events/types/undo_dash_stacks_archive';
import { Link } from '@mirage/link-list/types';
import { useFeatureFlagValue } from '@mirage/service-experimentation/useFeatureFlagValue';
import { convertFeatureValueToBool } from '@mirage/service-experimentation/util';
import { tagged } from '@mirage/service-logging';
import { useOperationalMetrics } from '@mirage/service-operational-metrics/hooks';
import {
  createStack,
  createStackItemsBatch,
  ensureStackAndCreateItem,
  updateStack,
  upsertStack,
} from '@mirage/service-stacks';
import {
  createNewSection,
  DEFAULT_SECTION_ID,
  isStackItemShortcut,
  stackDerivePAPProps,
  stackGetShareId,
} from '@mirage/service-stacks/service/utils';
import { StackItemCreationFields } from '@mirage/service-stacks/types';
import Sentry from '@mirage/shared/sentry';
import { showSnackbar } from '@mirage/shared/snackbar';
import { coerceSortKey } from '@mirage/shared/util/tiny-utils';
import i18n from '@mirage/translations';
import { generateKeyBetween, generateNKeysBetween } from 'fractional-indexing';
import { useAtom, useAtomValue } from 'jotai';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  activeStackAtom,
  activeStackItemShortcutsAtom,
  activeStackLinkSectionsMapAtom,
  activeStackMutationRequestIdAtom,
  activeStackSectionsAtom,
  activeStackSessionIdAtom,
} from '../ActiveStack/atoms';
import { stackIsArchived } from '../Helpers/Utils';
import { AddLinkToStack } from '../types';
import {
  asStackUpsertId,
  hasItemWithUrlInSection,
  newStackPapEvent,
} from './utils';

export const useAddLinkToStack = (): AddLinkToStack => {
  const createItemTimer = useRef<{
    startMs: number;
    url: string;
    sectionId: string;
  }>();
  const stack = useAtomValue(activeStackAtom);
  const items = useAtomValue(activeStackItemShortcutsAtom);
  const sessionId = useAtomValue(activeStackSessionIdAtom);
  const mutationId = useAtomValue(activeStackMutationRequestIdAtom);
  const linkSectionMap = useAtomValue(activeStackLinkSectionsMapAtom);
  const { reportPapEvent } = useMirageAnalyticsContext();

  const { stats: createStackItemStats } =
    useOperationalMetrics('createStackItem');

  useEffect(() => {
    if (!items) return;

    const timer = createItemTimer.current;
    if (!timer) return;

    // New stack item has not appeared into the UI yet.
    if (!hasItemWithUrlInSection(items, timer)) return;

    // Publish metric on duration of creation.
    const durationMs = performance.now() - timer.startMs;
    createStackItemStats('e2e', durationMs);

    // Clear the timer after we consume it.
    createItemTimer.current = undefined;
  }, [createStackItemStats, items]);

  return useCallback(
    async (
      sectionId: string,
      itemToAdd: Link,
      dashNewLinkSessionId?: string,
      dashLinkSearchSessionId?: string,
      dashNewLinkType?: DashNewLinkType,
    ): Promise<boolean> => {
      if (!items) {
        Sentry.captureMessage(
          'Failed to add link to stack via useAddLinkToStack: items is null',
          'error',
          { data: { items } },
        );
        showSnackbar({ title: i18n.t('failed_to_create_stack_item') });
        return false;
      }
      if (sectionId === '') {
        sectionId = DEFAULT_SECTION_ID;
      }

      // Check that no duplicate item already exists before starting timer.
      const timer = {
        startMs: performance.now(),
        url: itemToAdd.url,
        sectionId,
      };

      if (!hasItemWithUrlInSection(items, timer)) {
        // Note that this will override the last create item, but since this
        // action is usually quick to complete (< 2s), trying to handle for
        // concurrent creates is probably an overkill.
        createItemTimer.current = timer;
      }

      if (!itemToAdd.sortKey) {
        const dstSectionLinks = linkSectionMap?.get(sectionId) || [];
        const dstFirstLink = dstSectionLinks[0];
        itemToAdd.sortKey = generateKeyBetween(
          null,
          coerceSortKey(dstFirstLink?.sort_key),
        );
      }

      let createdNsId: string | undefined;
      try {
        const res = await ensureStackAndCreateItem(
          asStackUpsertId(stack?.namespace_id, mutationId),
          itemToAdd.url,
          itemToAdd.title,
          sectionId,
          itemToAdd.sortKey,
          itemToAdd.id3p,
          itemToAdd.brandedType,
        );
        createdNsId = res.nsId;
      } catch (e) {
        logger.error('Failed to create stack item via useAddLinkToStack', e);
        Sentry.captureException(e, {
          data: {
            message: 'Failed to create stack item via useAddLinkToStack',
            itemToAdd,
            stack,
            sectionId,
            mutationId,
          },
        });

        showSnackbar({ title: i18n.t('failed_to_create_stack_item') });
        return false;
      }

      if (stack?.namespace_id) {
        reportPapEvent(
          PAP_Add_DashNewLink({
            ...stackDerivePAPProps(stack),
            itemIdHash: itemToAdd.itemIdHash,
            predictionIdHash: itemToAdd.predictionIdHash,
            dashRequestId: itemToAdd.dashRequestId,
            stackSize: items.length,
            numberOfLinks: items.length,
            featureLine: itemToAdd.dashRequestId
              ? 'content_suggestions'
              : 'stacks',
            createStackSessionId: sessionId ?? undefined,
            dashNewLinkSessionId,
            dashLinkSearchSessionId,
            dashNewLinkType,
          }),
        );
      } else {
        reportPapEvent(
          newStackPapEvent(createdNsId, sessionId ?? undefined, 1),
        );
      }

      showSnackbar({ title: i18n.t('new_stack_item_added') });
      return true;
    },
    [items, reportPapEvent, stack, mutationId, sessionId, linkSectionMap],
  );
};

export const useCreateStackSection = () => {
  const { reportPapEvent } = useMirageAnalyticsContext();

  const stack = useAtomValue(activeStackAtom);
  const [{ allSections: sections }, setSections] = useAtom(
    activeStackSectionsAtom,
  );
  const mutationId = useAtomValue(activeStackMutationRequestIdAtom);

  return useCallback(
    async (title: string, afterSectionId?: string) => {
      if (!sections) return;

      const newSection = createNewSection(title);
      let insertPosition = sections.findIndex(
        (section) => section.id === afterSectionId,
      );
      if (insertPosition === -1) {
        insertPosition = sections.length;
      } else {
        insertPosition += 1;
      }
      if (stack && stack.namespace_id) {
        reportPapEvent(
          PAP_Add_StackSection({
            ...stackDerivePAPProps(stack),
            featureLine: 'stacks',
          }),
        );
      }

      const newSections = [
        ...sections.slice(0, insertPosition),
        newSection,
        ...sections.slice(insertPosition),
      ];
      const update: stacks.StackDataUpdate = {
        field: {
          '.tag': 'section_data_update',
          sections: newSections,
        },
      };
      await upsertStack(asStackUpsertId(stack?.namespace_id, mutationId), [
        update,
      ]);
      setSections(newSections);
      return newSection.id;
    },
    [sections, stack, mutationId, setSections, reportPapEvent],
  );
};

export const useEditStates = (
  count: number,
): [boolean[], (index: number) => void] => {
  const initialEditStates = Array.from({ length: count }, () => false);

  const [editStates, setEditStates] = useState<boolean[]>(initialEditStates);

  useEffect(() => {
    setEditStates(Array.from({ length: count }, () => false));
  }, [count]);

  const toggleEditState = (index: number) => {
    setEditStates((prevStates) =>
      prevStates.map((state, idx) => (idx === index ? !state : state)),
    );
  };

  return [editStates, toggleEditState];
};

interface ToggleArchiveStackProps {
  forceShouldArchive?: boolean;
  isUndo?: boolean;
}

export const useIsArchiveStackEnabled = () => {
  return convertFeatureValueToBool(
    useFeatureFlagValue('dash_web_2024_05_23_archive_stacks', false),
  );
};

export const useArchiveStack = (stack: stacks.Stack | null) => {
  const isStackArchived = stackIsArchived(stack);
  const [isArchiveModalOpen, setIsArchiveModalOpen] = useState(false);
  const isArchiveStacksEnabled =
    useIsArchiveStackEnabled() && stack?.namespace_id;
  const { reportPapEvent } = useMirageAnalyticsContext();

  const toggleArchiveStackStatus = useCallback(
    async ({ forceShouldArchive, isUndo }: ToggleArchiveStackProps) => {
      const namespaceId = stack?.namespace_id;
      if (!namespaceId) {
        return;
      }
      const shouldArchive = forceShouldArchive ?? !isStackArchived;
      const archiveUpdate: stacks.StackDataUpdate = {
        field: {
          is_archived: shouldArchive,
          '.tag': 'archive_update',
        },
      };
      const papEvent = shouldArchive
        ? PAP_Archive_DashStacksArchive
        : PAP_Undo_DashStacksArchive;
      reportPapEvent(papEvent(stackDerivePAPProps(stack)));

      await updateStack(namespaceId, [archiveUpdate]);
      showSnackbar({
        title: i18n.t(
          shouldArchive
            ? 'archived_stack_snackbar'
            : 'unarchived_stack_snackbar',
          { stackName: stack?.stack_data?.name },
        ),
        buttons: isUndo
          ? []
          : [
              {
                label: i18n.t('undo'),
                onClick: () =>
                  toggleArchiveStackStatus({
                    forceShouldArchive: !shouldArchive,
                    isUndo: true,
                  }),
              },
            ],
      });
    },
    [stack, reportPapEvent, isStackArchived],
  );

  return {
    isArchiveStacksEnabled,
    isArchiveModalOpen,
    setIsArchiveModalOpen,
    toggleArchiveStackStatus,
  };
};

const logger = tagged('create-stack-flow');

export const useCreateStackAndNav = (sessionId: string) => {
  const { reportPapEvent } = useMirageAnalyticsContext();
  const navigate = useNavigate();
  const commonCreatePAPProps = useMemo(
    () => ({
      createStackSessionId: sessionId,
    }),
    [sessionId],
  );

  const createStackAndNavigate = async (
    stackData: stacks.StackData,
    itemData: {
      items: stacks.StackItem[];
      predictionIdHash?: number;
    },
    creationType: 'manual' | 'suggested_stack',
    onAfterCreate?: () => void,
  ): Promise<boolean> => {
    const { name, emoji, color_index, description } = stackData;
    if (name) {
      const { items: itemsToAdd, predictionIdHash } = itemData;

      const sortKeys = generateNKeysBetween(null, null, itemsToAdd.length);

      const stackItems: StackItemCreationFields[] = [...itemsToAdd]
        .filter(isStackItemShortcut)
        .map((item, index): StackItemCreationFields => {
          return {
            url: item.url ?? '',
            metadata_title: item.metadata_title
              ? item.metadata_title
              : (item.name ?? ''),
            sort_key: sortKeys[index],
            branded_type: item.branded_type,
            id_3p: item.id_3p,
          };
        });

      const stack = await createStack(
        name,
        description ?? '',
        emoji,
        color_index,
        false,
        creationType,
      );
      if (!stack.namespace_id) {
        return false;
      }

      for (let i = 0; i < stackItems.length; i += 4) {
        const slice = stackItems.slice(i, i + 4);
        await createStackItemsBatch(stack.namespace_id, slice);
      }

      const sharedPapProperties = {
        ...commonCreatePAPProps,
        isOwner: true,
        isShared: false,
        stackId: stack.namespace_id,
        numSuggestions: itemsToAdd.length,
        predictionIdHashes: predictionIdHash?.toString() ?? '',
        numberOfLinks: itemsToAdd.length,
      };

      reportPapEvent(
        PAP_Create_DashNewStack({
          ...sharedPapProperties,
          ...(creationType === 'suggested_stack'
            ? {
                actionSurfaceComponent: 'suggested_auto_stacks',
                featureLine: 'suggested_auto_stacks',
              }
            : { actionSurfaceComponent: 'sidebar', featureLine: 'stacks' }),
        }),
      );

      const shareId = stackGetShareId(stack);
      onAfterCreate && onAfterCreate();
      if (shareId) {
        showSnackbar({
          title: i18n.t('stack_successfully_created', {
            stackName: name,
          }),
        });
        navigate(`/stacks/${shareId}`, { replace: true });
        return true;
      }
    }
    return false;
  };
  return async (...args: Parameters<typeof createStackAndNavigate>) => {
    let success = false;
    try {
      success = await createStackAndNavigate(...args);

      if (!success) {
        logger.error('Failed to create stack');
      }
    } catch (e) {
      logger.error('Failed to create stack due to exception', e);
      success = false;
    }

    if (!success) {
      showSnackbar({ title: i18n.t('failed_to_create_stack') });
      navigate('/');
    }
  };
};
