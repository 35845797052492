import {
  mapConnectorFilterToQueryFilter,
  mapContentTypeFilterToQueryFilter,
  mapLastUpdatedFilterToQueryFilter,
  mapPeopleFilterToQueryFilter,
  transformDashResultToMirage,
} from '@mirage/service-dbx-api/service/utils';
import {
  cacheLastSearchRequestArguments,
  cacheLastSearchRequestId,
} from '@mirage/service-relevance-snapshot/index';
import { DESKTOP_LOCAL_FILE_CONNECTOR_ID } from '@mirage/shared/connectors';
import {
  ConnectorFilter,
  ContentTypeFilter,
  isConnectorFilter,
  isContentTypeFilter,
  isLastUpdatedFilter,
  isPersonFilter,
  LastUpdatedFilter,
  PersonFilter,
  SearchFilter,
} from '@mirage/shared/search/search-filters';

import type { dcs } from '@dropbox/api-v2-client';
import type {
  AuthorInfo,
  BrandedSiteInfo,
  ConnectorInfo,
  DataSource,
  FileTypeInfo,
  IconResource,
  ItemLocation,
  SearchResult,
} from '@mirage/shared/search/search-result';

// TODO: remove this and update all imports to use shared if we like the strategy
// having it here was causing circular dependencies, and boundary import issues
export {
  SearchResult,
  ConnectorInfo,
  IconResource,
  FileTypeInfo,
  BrandedSiteInfo,
  DataSource,
  ItemLocation,
  AuthorInfo,
};

import type { APIv2CallableWithHeaders } from '..';

export async function getSearchResults(
  api: APIv2CallableWithHeaders,
  query: string,
  filters: SearchFilter[] = [],
): Promise<SearchResult[]> {
  const connectorFilters: Array<dcs.QueryFilter> = filters
    .filter(
      (filter): filter is ConnectorFilter =>
        isConnectorFilter(filter) &&
        filter.id !== DESKTOP_LOCAL_FILE_CONNECTOR_ID,
    )
    .map(mapConnectorFilterToQueryFilter);

  const lastUpdatedFilters: Array<dcs.QueryFilter> = filters
    .filter((filter): filter is LastUpdatedFilter =>
      isLastUpdatedFilter(filter),
    )
    .map(mapLastUpdatedFilterToQueryFilter);

  const peopleFilters: Array<dcs.QueryFilter> = filters
    .filter((filter): filter is PersonFilter => isPersonFilter(filter))
    .map(mapPeopleFilterToQueryFilter);

  const contentTypeFilters: Array<dcs.QueryFilter> = filters
    .filter((filter): filter is ContentTypeFilter =>
      isContentTypeFilter(filter),
    )
    .map(mapContentTypeFilterToQueryFilter);

  const appliedFilters = [
    ...connectorFilters,
    ...lastUpdatedFilters,
    ...peopleFilters,
    ...contentTypeFilters,
  ];

  cacheLastSearchRequestArguments({
    query_text: query,
    filters: appliedFilters,
  });

  // TODO: API error handling. Should probably define this at the boundary and return predictable errors
  // currently just bubbling up to the caller

  // TODO - remove after #sev-speedy-carnelian-sandpiper
  const response = await api('dcsSearch', {
    query_text: query,
    filters: appliedFilters,
  });

  const { headers, result } = response;

  if (result.results === undefined) {
    return [];
  }

  const searchRequestId = result?.search_request_id || '';
  const analyticsTraceId = headers.get('x-dropbox-request-id') || '';

  // save the last search request payload and id in case of relevance snapshot
  cacheLastSearchRequestId(searchRequestId);

  return (result.results || [])
    .filter((r) => r.query_result && r.query_result['.tag'] === 'search_result')
    .map((result) =>
      transformDashResultToMirage(
        { ...result.query_result, relevance_score: result.relevance_score },
        searchRequestId,
        analyticsTraceId,
      ),
    )
    .filter((msr): msr is SearchResult => Boolean(msr));
}
