import { Avatar } from '@dropbox/dig-components/avatar';
import { Button, IconButton } from '@dropbox/dig-components/buttons';
import { TextInput } from '@dropbox/dig-components/text_fields';
import { Text } from '@dropbox/dig-components/typography';
import { Box } from '@dropbox/dig-foundations';
import { UIIcon } from '@dropbox/dig-icons';
import {
  BlockQuoteLine,
  ChevronDownLine,
  ChevronUpLine,
  HideLine,
  RedoLine,
  SendLine,
  SummarizeLine,
} from '@dropbox/dig-icons/assets';
import { FssGlyph, GlyphLogo } from '@dropbox/dig-logos';
import { DashSpinner } from '@mirage/shared/dash-spinner/DashSpinner';
import { IconButtonWithTooltip } from '@mirage/shared/icons/IconButtonWithTooltip';
import {
  useLinkSummary,
  useQuestionHistory,
} from '@mirage/shared/stack-item-summary/linkSummarizer';
import {
  AnswerResponse,
  LinkSummaryError,
  LinkSummarySuccess,
  Question,
} from '@mirage/shared/stack-item-summary/types';
import { getTimeAgoString } from '@mirage/shared/util/time';
import i18n from '@mirage/translations';
import classnames from 'classnames';
import { memo, useMemo, useState } from 'react';
import Markdown from 'react-markdown';
import styles from './StackItemSummary.module.css';

interface StackItemSummaryButtonProps {
  apiFileId: string | undefined;
  isSummaryCollapsed: boolean;
  setCollapseSummary: (apiFileId: string, state?: boolean) => void;
}

export const StackItemSummaryButton: React.FC<StackItemSummaryButtonProps> = ({
  apiFileId,
  isSummaryCollapsed,
  setCollapseSummary,
}) => {
  return (
    <IconButtonWithTooltip
      variant="borderless"
      size="standard"
      onClick={() => {
        if (apiFileId) {
          setCollapseSummary(apiFileId, !isSummaryCollapsed);
        }
      }}
      className={classnames(
        styles.roundedBorder,
        styles.pointerEventReset,
        styles.buttonContainer,
      )}
      tooltipProps={{
        title: isSummaryCollapsed
          ? i18n.t('expand_stack_item_summary')
          : i18n.t('collapse_stack_item_summary'),
      }}
    >
      <UIIcon src={SummarizeLine} />
      {isSummaryCollapsed ? (
        <UIIcon src={ChevronDownLine} className={styles.sideIcon} />
      ) : (
        <UIIcon src={ChevronUpLine} className={styles.sideIcon} />
      )}
    </IconButtonWithTooltip>
  );
};
StackItemSummaryButton.displayName = 'StackItemSummaryButton';

interface StackItemSummaryProps {
  url: string;
  userProfilePhotoUrl: string | undefined;
  userGivenName: string;
}

interface CollapsibleBlockquoteProps
  extends React.BlockquoteHTMLAttributes<HTMLQuoteElement> {
  children?: React.ReactNode;
}

function CollapsibleBlockquote({
  children,
  ...props
}: CollapsibleBlockquoteProps) {
  const [isVisible, setIsVisible] = useState(false);
  return (
    <div>
      <IconButton
        size="small"
        shape="circular"
        variant="opacity"
        onClick={() => setIsVisible(!isVisible)}
      >
        <UIIcon src={BlockQuoteLine} />
      </IconButton>
      {isVisible && (
        <blockquote className={styles.summaryQuote} {...props}>
          <div className={styles.summaryQuoteText}>{children}</div>
        </blockquote>
      )}
    </div>
  );
}

export const StackItemSummary: React.FC<StackItemSummaryProps> = ({
  url,
  userProfilePhotoUrl,
  userGivenName,
}) => {
  const { summary, refreshSummary } = useLinkSummary(url);
  const { questionAnswers, onSubmitQuestion } = useQuestionHistory(
    summary,
    url,
  );

  if (!summary) {
    return (
      <div className={styles.outerWrapper}>
        <div className={styles.descriptionContainer}>
          <Box display="flex" alignItems="center">
            <DashSpinner size={26} />
            <Text className={styles.contentText}>
              {i18n.t('stack_item_summary_loading_summary')}
            </Text>
          </Box>
        </div>
      </div>
    );
  }

  if (summary && summary.type === 'error') {
    const summaryError = summary as LinkSummaryError;
    // TODO: Add proper error strings
    return (
      <div className={styles.outerWrapper}>
        <div className={styles.descriptionContainer}>
          <Text className={styles.contentText}>{summaryError.description}</Text>
        </div>
      </div>
    );
  }
  const summaryLoaded = summary as LinkSummarySuccess;
  if (!summaryLoaded || !summaryLoaded.response) {
    return null;
  }
  const summaryText = summaryLoaded.response.short_summary;
  const summaryCreatedTime = summaryLoaded.created;
  const hasSummary = summaryText !== '';
  const timeAgoText = summaryCreatedTime
    ? ` · ${getTimeAgoString(summaryCreatedTime, false, true)}`
    : '';
  const showCreatorName = summaryText.length > timeAgoText.length + 20; // 20 is the buffer for avatar and edit/remove buttons on hover
  if (!hasSummary) {
    return null;
  }
  return (
    <>
      {
        <div className={styles.outerWrapper}>
          <div className={styles.descriptionContainer}>
            <Text className={styles.contentText}>
              <Markdown components={{ blockquote: CollapsibleBlockquote }}>
                {summaryText}
              </Markdown>
            </Text>
            <div className={styles.lowerContainer}>
              <StackItemSummaryFootnote
                showSource={showCreatorName}
                createdTime={summaryCreatedTime}
                timeAgoText={timeAgoText}
              />
              {
                <div className={styles.actionButtonsContainer}>
                  <Button
                    variant="transparent"
                    onClick={refreshSummary}
                    size="small"
                    withIconStart={<UIIcon src={RedoLine} size="small" />}
                  >
                    {i18n.t('stack_item_summary_regenerate')}
                  </Button>
                </div>
              }
            </div>
          </div>
        </div>
      }
      {questionAnswers.map((qa, i) => (
        <SummaryContentQuestionAnswer
          key={`qa-${i}`}
          question={qa.question}
          answer={qa.answer}
          userProfilePhotoUrl={userProfilePhotoUrl}
          userGivenName={userGivenName}
        />
      ))}
      <SummaryQuestionInput
        summary={summary}
        onSubmit={onSubmitQuestion}
        userProfilePhotoUrl={userProfilePhotoUrl}
      />
    </>
  );
};

interface SummaryContentQuestionAnswerProps {
  question: Question;
  answer?: AnswerResponse;
  userProfilePhotoUrl: string | undefined;
  userGivenName: string;
}
export const SummaryContentQuestionAnswer = memo(
  ({
    question,
    answer,
    userProfilePhotoUrl,
    userGivenName,
  }: SummaryContentQuestionAnswerProps) => {
    const userAvatar = useMemo(() => {
      return userProfilePhotoUrl ? (
        <Avatar size="xsmall" hasNoOutline src={userProfilePhotoUrl} />
      ) : null;
    }, [userProfilePhotoUrl]);

    return (
      <>
        <div className={styles.outerWrapper}>
          <div className={styles.descriptionContainer}>
            <Text className={styles.contentText} isBold>
              {question.text}
            </Text>
            <div className={styles.lowerContainer}>
              <div className={styles.footnoteContainer}>
                {userAvatar}
                {question.text && (
                  <Text color="subtle" size="small">
                    {i18n.t('stack_item_qna_question_source_prefix', {
                      name: userGivenName,
                    })}
                  </Text>
                )}
                {
                  <IconButtonWithTooltip
                    variant="borderless"
                    size="small"
                    disabled={true}
                    tooltipProps={{
                      title: i18n.t('stack_item_summary_visibility_user_only'),
                    }}
                  >
                    <UIIcon src={HideLine} size="small" />
                  </IconButtonWithTooltip>
                }
                {question.timestamp && (
                  <Text color="subtle" size="small">
                    {getTimeAgoString(question.timestamp, false, true)}
                  </Text>
                )}
              </div>
            </div>
            {answer && answer?.text ? (
              <Text className={styles.contentText}>
                <Markdown components={{ blockquote: CollapsibleBlockquote }}>
                  {answer.text}
                </Markdown>
              </Text>
            ) : (
              <Box display="flex" alignItems="center">
                <DashSpinner size={26} />
                <Text className={styles.contentText}>
                  {i18n.t('stack_item_qna_loading_answer')}
                </Text>
              </Box>
            )}
            <div className={styles.lowerContainer}>
              <StackItemSummaryFootnote
                showSource={!!answer}
                createdTime={answer?.timestamp}
              />
            </div>
          </div>
        </div>
      </>
    );
  },
);
SummaryContentQuestionAnswer.displayName = 'SummaryContentQuestionAnswer';

interface SummaryQuestionInputProps {
  summary: LinkSummarySuccess;
  onSubmit: (question: Question) => void;
  userProfilePhotoUrl: string | undefined;
}
export const SummaryQuestionInput = memo(
  ({ onSubmit, userProfilePhotoUrl }: SummaryQuestionInputProps) => {
    const [promptText, setPromptText] = useState('');
    const userAvatar = useMemo(() => {
      return userProfilePhotoUrl ? (
        <Avatar size="small" hasNoOutline src={userProfilePhotoUrl} />
      ) : null;
    }, [userProfilePhotoUrl]);

    return (
      <div className={styles.outerWrapper}>
        <TextInput
          value={promptText}
          onChange={(event) => {
            event.preventDefault();
            setPromptText(event.currentTarget.value);
          }}
          onKeyDown={(event) => {
            if (event.key === 'Enter' && promptText.trim().length > 0) {
              if (!event.shiftKey) {
                // shift-enter should create newline as normal
                event.preventDefault();
                onSubmit({ text: promptText, timestamp: Date.now() });
                setPromptText('');
              }
            }
          }}
          isTransparent
          placeholder={i18n.t('stack_item_qna_input_placeholder')}
          size="standard"
          withLeftAccessory={userAvatar}
          withRightAccessory={
            <IconButtonWithTooltip
              variant="borderless"
              size="standard"
              onMouseDown={(e: React.MouseEvent) => {
                // This is to prevent the input from losing focus after clicking
                e.preventDefault();
              }}
              onClick={() => {
                onSubmit({ text: promptText, timestamp: Date.now() });
                setPromptText('');
              }}
              className={styles.pointerEventReset}
              tooltipProps={{
                title: i18n.t('stack_item_qna_submit_tooltip'),
              }}
            >
              <UIIcon src={SendLine} />
            </IconButtonWithTooltip>
          }
          wrapperProps={{ className: styles.textInputWrapper }}
        />
      </div>
    );
  },
);
SummaryQuestionInput.displayName = 'SummaryQuestionInput';

interface StackItemSummaryFootnoteProps {
  showSource: boolean;
  createdTime: number | undefined;
  timeAgoText?: string;
}
export const StackItemSummaryFootnote: React.FC<
  StackItemSummaryFootnoteProps
> = ({ showSource, createdTime, timeAgoText }) => (
  <div className={styles.footnoteContainer}>
    <Avatar
      size="xsmall"
      colorPalette={{ background: 'white', foreground: 'white' }}
    >
      <GlyphLogo src={FssGlyph} size={32} />
    </Avatar>
    {showSource && (
      <Text color="subtle" size="small">
        {i18n.t('stack_item_summary_source')}
      </Text>
    )}
    <IconButtonWithTooltip
      variant="borderless"
      size="small"
      disabled={true}
      tooltipProps={{
        title: i18n.t('stack_item_summary_visibility_user_only'),
      }}
    >
      <UIIcon src={HideLine} size="small" />
    </IconButtonWithTooltip>
    {createdTime && (
      <Text color="subtle" size="small">
        {timeAgoText || getTimeAgoString(createdTime, false, true)}
      </Text>
    )}
  </div>
);
StackItemSummaryFootnote.displayName = 'StackItemSummaryFootnote';
