// @generated - Do not edit: this file is autogenerated by:
//   //dropbox/pap/codegen
//
// Event definition: https://pap.pp.dropbox.com/#/events/3635
import type { PAPEventGeneric } from "../base/event";
import type { StackSendShareType } from "../enums/stack_send_share_type";
import type { FeatureLine } from "../enums/feature_line";
import type { ActionSurfaceComponent } from "../enums/action_surface_component";
import type { StackAccessLevel } from "../enums/stack_access_level";
import type { Environment } from "../enums/environment";
import type { OperatingSystem } from "../enums/operating_system";
import type { DashSurface } from "../enums/dash_surface";

// Event_name: 'select.dash_share_recipient'
// Description: Log how a user was added to the stack share input in Dash
// Owner: dash-web
export type Select_DashShareRecipient = PAPEventGeneric<
  'stacks',
  'select',
  'dash_share_recipient',
  {
    // Property to indicate how a user is entered for sharing contact
    stackSendShareType?: StackSendShareType;
    // User_id associated with the creator of the content being acted upon
    creatorId?: string;
    // The stack id that represents the stack (without extra hashing on the client side)
    unhashedStackId?: string;
    // True if public sharing is allowed, false otherwise
    isPublicSharingAllowed?: boolean;
    // Feature Line for dash
    featureLine?: FeatureLine;
    // The component within a surface in which an action was taken on Dash.
    actionSurfaceComponent?: ActionSurfaceComponent;
    // Used to identify if a stack is a published stack
    isPublished?: boolean;
    // Whether the user is interacting with their welcome stack
    isWelcomeStack?: boolean;
    // The access level settings for a stack
    stackAccessLevel?: StackAccessLevel;
    // Bool that indicates if the item acted upon is pinned
    isPinned?: boolean;
    // marks if the content is shared content
    isShared?: boolean;
    // Answers if the event actor on the stack is the same as the creator of the stack
    isOwner?: boolean;
    // The stack id that represents the stack
    stackId?: string;
    // Whether the app dispatching the event is Prod, Test, or Dev (run locally by the developer)
    environment?: Environment;
    // The operating system platform the user is running on.
    operatingSystem?: OperatingSystem;
    // Which Dash surface (web, desktop, or browser extension) the event originated from.
    dashSurface?: DashSurface;
    // generic property representing build version of whatever is logging this event.
    buildVersion?: string;
    // The number of emails a user pastes in the sharing modal search box
    numDashShareEmailPasted?: number;
  }
>;

export function PAP_Select_DashShareRecipient(properties?: Select_DashShareRecipient['properties']): Select_DashShareRecipient {
  return <Select_DashShareRecipient>{
    class: 'stacks',
    action: 'select',
    object: 'dash_share_recipient',
    properties,
  };
}
