import {
  getTooltipInCardProps,
  Metadata,
} from '@dropbox/dash-component-library';
import { Box } from '@dropbox/dig-foundations';
import { UIIcon } from '@dropbox/dig-icons';
import { GlobeLine } from '@dropbox/dig-icons/assets';
import { DashStackIcon } from '@mirage/mosaics/DCLWrappers/DashStackIcon';
import { TileCard } from '@mirage/mosaics/TileCard';
import { DashTruncateWithTooltip } from '@mirage/shared/util/DashTruncateWithTooltip';
import { getTimeAgoString } from '@mirage/shared/util/time';
import i18n from '@mirage/translations';
import { WorkingSetCardData } from '@mirage/working-set';
import { useState } from 'react';

// Improvement: consolidate this logic for stack cards on stack pages & refactor WorkingCardSetData
// so data (model) is separate from view layer (react render fn). This will make it easier to create this
// component in storybook
// https://jira.dropboxer.net/browse/DASHWEB-4910
const MetadataForStackCard = ({
  stackCard,
  lastViewedInfo,
}: {
  stackCard: WorkingSetCardData;
  lastViewedInfo?: number;
}) => {
  const [isTruncated, setIsTruncated] = useState(false);
  const { accessLevelIcon, accessLevel } = stackCard;
  let metaLabel = '';
  if (lastViewedInfo) {
    metaLabel = i18n.t('viewed_ago', {
      timeAgoString: getTimeAgoString(lastViewedInfo),
    });
  } else {
    metaLabel = stackCard.accessLevel;
  }

  // Not ideal: workaround to set "public" stacks to use global icon; would rather this get
  // set in accessLevelIconSrcForMemberCount, but don't want to break current implementation on stacks page
  let uiIconSrc = accessLevelIcon;
  if (stackCard.accessLevel === i18n.t('public_accesslevel')) {
    uiIconSrc = GlobeLine;
  }

  // Not ideal to rely on i18n text and should hanlde on the convert stack to card data fn
  if (accessLevel === i18n.t('private_accesslevel_multiple_users')) {
    return (
      <Metadata.Item>
        {/* padding left is used to align FacePile component since left edge bleeds out of the container */}
        {/* https://jira.dropboxer.net/browse/DSYS-4699 */}
        <Box style={{ paddingLeft: '8px' }}>
          {stackCard.getMembersComponent('xsmall')}
        </Box>
      </Metadata.Item>
    );
  }

  return (
    <>
      <Metadata.Item>
        <UIIcon src={uiIconSrc} isOpticallyAligned />
      </Metadata.Item>
      {metaLabel && (
        <Metadata.Item {...getTooltipInCardProps(isTruncated)}>
          <DashTruncateWithTooltip
            lines={1}
            tooltipProps={{
              title: metaLabel,
            }}
            onBeforeTruncate={setIsTruncated}
          >
            {metaLabel}
          </DashTruncateWithTooltip>
        </Metadata.Item>
      )}
    </>
  );
};

type StackCardProps = {
  stackData: WorkingSetCardData;
  selectorClassname: string;
  hidden: boolean;
  lastViewedInfo?: number;
};

const METADATA_MIN_HEIGHT = 24; // used to ensure recent tile card and stack tile cards are the same height
export const StackTileCard = ({
  stackData,
  selectorClassname,
  hidden,
  lastViewedInfo,
}: StackCardProps) => {
  return (
    <TileCard
      className={selectorClassname}
      hidden={hidden}
      onClick={stackData.onClickCard}
      visibleUrl={`/stacks/${stackData.id}`}
      titleLinesBeforeTruncate={2}
      icon={
        <DashStackIcon
          size="medium"
          colorIndex={stackData.colorIndex}
          emoji={stackData.emoji}
        />
      }
      title={stackData.title}
      metadata={
        <Box style={{ minHeight: METADATA_MIN_HEIGHT }}>
          <Metadata size="medium" withDividers="blank">
            <MetadataForStackCard
              stackCard={stackData}
              lastViewedInfo={lastViewedInfo}
            />
          </Metadata>
        </Box>
      }
    />
  );
};
