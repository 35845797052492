import { PAPEvent } from '@mirage/analytics/events/base/event';
import { ActionSurfaceComponent } from '@mirage/analytics/events/enums/action_surface_component';
import {
  ComposeSource,
  getSourceUUID,
} from '@mirage/shared/compose/compose-session';
import { memo } from 'react';
import { ConversationMessageRowMessageSourceRow } from './ConversationMessages';
import styles from './ConversationMessageSourceChips.module.css';

interface ConversationMessageSourceChipsProps {
  sources: ComposeSource[];
  logComposeEvent: (
    event: PAPEvent,
    overrides?: { actionSurfaceComponent?: ActionSurfaceComponent },
  ) => void;
  removeSource: (source: ComposeSource) => void;
}
export const ConversationMessageSourceChips = memo(
  ({
    sources,
    logComposeEvent,
    removeSource,
  }: ConversationMessageSourceChipsProps) => {
    return (
      <div>
        {sources.map((source, i) => (
          <div
            className={styles.ContextInputSourcesChipRow}
            key={getSourceUUID(source) || i}
          >
            <ConversationMessageRowMessageSourceRow
              source={source}
              onRemoveSource={removeSource}
              logComposeEvent={logComposeEvent}
            />
          </div>
        ))}
      </div>
    );
  },
);
ConversationMessageSourceChips.displayName = 'ConversationMessageSourceChips';
