import { ServiceId } from '@mirage/discovery/id';
import * as services from '@mirage/discovery/services/index';
import WithDefaults from '@mirage/storage/with-defaults';

import type { dcs } from '@dropbox/api-v2-client';
import type { APIv2Callable } from '@mirage/service-dbx-api/service/index';
import type { KVStorage } from '@mirage/storage';

export type Service = {
  cacheLastSearchRequestArguments(args: SearchRequestArguments): void;
  cacheLastSearchRequestId(id: string): Promise<void>;

  saveRelevanceSnapshot(trace_id: string): Promise<void>;
};

export interface SearchRequestArguments {
  query_text: string;
  filters?: dcs.QueryFilter[];
}

export type PersistentCacheShape = {
  lastSearchRequestArguments: SearchRequestArguments | undefined;
  lastSearchRequestId: string | undefined;
};

type DbxApiServiceContract = {
  callApiV2: APIv2Callable;
};

export default function provideRelevanceSnapshotService(
  storage: KVStorage<PersistentCacheShape>,
  dbxApiService: DbxApiServiceContract,
) {
  const LAST_SEARCH_REQUEST_ARGUMENTS_KEY = 'lastSearchRequestArguments';
  const LAST_SEARCH_REQUEST_ID_KEY = 'lastSearchRequestId';

  const adapter = new WithDefaults(storage, {
    lastSearchRequestArguments: undefined,
    lastSearchRequestId: undefined,
  });

  async function cacheLastSearchRequestArguments(args: SearchRequestArguments) {
    await adapter.set(LAST_SEARCH_REQUEST_ARGUMENTS_KEY, args);
  }

  async function cacheLastSearchRequestId(id: string) {
    await adapter.set(LAST_SEARCH_REQUEST_ID_KEY, id);
  }

  async function saveRelevanceSnapshot(traceId: string) {
    const [lastSearchRequestArgs, lastSearchRequestId] = await Promise.all([
      adapter.get(LAST_SEARCH_REQUEST_ARGUMENTS_KEY),
      adapter.get(LAST_SEARCH_REQUEST_ID_KEY),
    ]);

    await dbxApiService.callApiV2('dcsSaveRelevanceSnapshot', {
      trace_id: traceId,
      search_request_id: lastSearchRequestId,
      search_arguments: lastSearchRequestArgs,
    });
  }

  return services.provide<Service>(
    ServiceId.RELEVANCE_SNAPSHOT,
    {
      cacheLastSearchRequestArguments,
      cacheLastSearchRequestId,
      saveRelevanceSnapshot,
    },
    [ServiceId.DBX_API],
  );
}
