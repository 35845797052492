// @generated - Do not edit: this file is autogenerated by:
//   //dropbox/pap/codegen
//
// Event definition: https://pap.pp.dropbox.com/#/events/3515
import type { PAPEventGeneric } from "../base/event";
import type { ActionSurfaceComponent } from "../enums/action_surface_component";
import type { BrowserName } from "../enums/browser_name";
import type { DashActionSurface } from "../enums/dash_action_surface";
import type { Environment } from "../enums/environment";
import type { FeatureLine } from "../enums/feature_line";
import type { OperatingSystem } from "../enums/operating_system";

// Event_name: 'click.message_action'
// Description: Clicking on a message reaction for an action completed by assistant (copy is only visible for the Chat app)
// Owner: dash-web
export type Click_MessageAction = PAPEventGeneric<
  'dash_assist',
  'click',
  'message_action',
  {
    // The component within a surface in which an action was taken on Dash.
    actionSurfaceComponent?: ActionSurfaceComponent;
    // This indicates the user's current browser
    browserName?: BrowserName;
    // generic property representing build version of whatever is logging this event.
    buildVersion?: string;
    // The surface in which an action was taken on Dash.
    // Note: serp and search_result_page are the same thing, but we're only using serp moving forward.
    dashActionSurface?: DashActionSurface;
    // Whether the app dispatching the event is Prod, Test, or Dev (run locally by the developer)
    environment?: Environment;
    // Feature Line for dash
    featureLine?: FeatureLine;
    // Boolean event property that is set to true for Dash for Business (dfb) users.
    isDfbUser?: boolean;
    // The operating system platform the user is running on.
    operatingSystem?: OperatingSystem;
    // the start time of the event in ms
    startTimeMs?: number;
    // A list of actions users can do on the web
    actionType?: string;
    // LLM Generated query string for suggested questions. Returned from the context engine api and passed directly to the PAP event for model training data
    generatedQueryString?: string;
    // The length of a query
    queryLength?: number;
    // fresh id that gets generated for every new session or instance of the chat window
    chatSessionId?: string;
    // An entry point string, used to describe how users are triggering the event as relevant (e.g. browse_file_row_context_menu)
    entryPoint?: string;
  }
>;

export function PAP_Click_MessageAction(properties?: Click_MessageAction['properties']): Click_MessageAction {
  return <Click_MessageAction>{
    class: 'dash_assist',
    action: 'click',
    object: 'message_action',
    properties,
  };
}
