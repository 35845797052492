import { doNotRedact, redactString } from "@alwaysmeticulous/redaction";
import { dash_feed } from "@dropbox/api-v2-client";
import { StoredActivityFeed } from "@mirage/service-feed/service";

import { createRedactedApiV2Endpoint } from "../utils";

export const dashFeedListFeedItemsRedacted = createRedactedApiV2Endpoint<
  StoredActivityFeed,
  dash_feed.ListFeedItemsResponse
>({
  name: "dashFeedListFeedItems",
  api: "dash_feed",
  endpoint: "list_feed_items",
  indexedDbKey: "activity-feed",
  stringRedactors: {
    exact_queries: doNotRedact,
    new_value: redactString,
    old_value: redactString,
    applications: doNotRedact,
  },
});
