import { ContentRow, Metadata } from '@dropbox/dash-component-library';
import { useMirageAnalyticsContext } from '@mirage/analytics/AnalyticsProvider';
import { PAP_Open_DashLink } from '@mirage/analytics/events/types/open_dash_link';
import { activityDerivePAPProps } from '@mirage/service-feed/util/analytics';
import { openURL } from '@mirage/service-platform-actions';
import { FileContentIcon } from '@mirage/shared/icons/FileContentIcon';
import { useIsMobileSize } from '@mirage/shared/responsive/mobile';
import { SearchResult } from '@mirage/shared/search/search-result';
import { getTimeAgoString } from '@mirage/shared/util/time';
import i18n from '@mirage/translations';
import { useCallback } from 'react';
import { ActivityActionBar } from '../ActivityActionBar';
import { type ActivityObjectProps } from '../ActivityObject';
import { PersonMetadata } from '../PersonMetadata';

type DocumentActivityObjectProps = {
  document: SearchResult;
} & ActivityObjectProps;

export const DocumentActivityObject = ({
  activity,
  index,
  document,
}: DocumentActivityObjectProps) => {
  const { reportPapEvent } = useMirageAnalyticsContext();
  const isMobileSize = useIsMobileSize();
  const onClickRow = useCallback(() => {
    reportPapEvent(
      PAP_Open_DashLink({
        ...activityDerivePAPProps(activity, index),
        actionSurfaceComponent: 'activity_feed',
        featureLine: 'activity_feed',
      }),
    );
    openURL(document.url!);
  }, [document.url, reportPapEvent, activity, index]);

  return (
    <ContentRow
      isMobile={isMobileSize}
      paddingSize="medium"
      onClick={onClickRow}
      visibleUrl={document.url!}
      withStartAccessory={
        <DocumentActivityObjectIcon activity={activity} document={document} />
      }
      withLabel={document.title}
      withMeta={
        <DocumentActivityObjectMetadata
          activity={activity}
          document={document}
        />
      }
      withActions={<ActivityActionBar activity={activity} index={index} />}
    />
  );
};

const DocumentActivityObjectMetadata = ({
  document,
}: DocumentActivityObjectProps) => {
  return (
    <Metadata size="small">
      <Metadata.Item>
        <Metadata.Label>
          {i18n.t('updated_ago', {
            timeAgo: getTimeAgoString(document.providerUpdateAtMs!),
          })}
        </Metadata.Label>
      </Metadata.Item>
      {document.creator && (
        <Metadata.Item>
          <Metadata.Label>
            <PersonMetadata
              i18nKey="result_created_by"
              person={document.creator}
            />
          </Metadata.Label>
        </Metadata.Item>
      )}
    </Metadata>
  );
};

const DocumentActivityObjectIcon = ({
  document,
}: DocumentActivityObjectProps) => {
  return <FileContentIcon size="large" content={document} />;
};
