import { DashNewLinkType } from '@mirage/analytics/events/enums/dash_new_link_type';
import { Link } from '@mirage/link-list/types';
import { useFeatureFlagValue } from '@mirage/service-experimentation/useFeatureFlagValue';
import { convertFeatureValueToBool } from '@mirage/service-experimentation/util';
import { useModal } from '@mirage/shared/util/showModal';
import { useAtomValue } from 'jotai';
import { useCallback } from 'react';
import { activeStackItemShortcutsAtom } from '../ActiveStack/atoms';
import { AddLinkToStack } from '../types';
import { AddLinkToStackWithConfirmation } from './AddLinkToStackWithConfirmationModal';
import { useAddLinkToStack } from './hooks';

export const useAddLinkToStackWithConfirmation = (): AddLinkToStack => {
  const { showModal, hideModal } = useModal();
  const items = useAtomValue(activeStackItemShortcutsAtom);
  const addLinkToStack = useAddLinkToStack();
  const isWarnOnAddingDuplicateLinks = convertFeatureValueToBool(
    useFeatureFlagValue(
      'dash_2024_12_19_stacks_warn_on_adding_duplicate_links',
    ),
  );

  return useCallback(
    async (
      sectionId: string,
      itemToAdd: Link,
      dashNewLinkSessionId?: string,
      dashLinkSearchSessionId?: string,
      dashNewLinkType?: DashNewLinkType,
    ): Promise<boolean> => {
      const addLinkToStackFn = () =>
        addLinkToStack(
          sectionId,
          itemToAdd,
          dashNewLinkSessionId,
          dashLinkSearchSessionId,
          dashNewLinkType,
        );

      if (!isWarnOnAddingDuplicateLinks) {
        return addLinkToStackFn();
      }

      const isItemInStack = (items || []).some(
        (item) => item.url === itemToAdd.url,
      );

      if (isItemInStack) {
        showModal(
          <AddLinkToStackWithConfirmation
            hideModal={hideModal}
            url={itemToAdd.url}
            addLinkToStackFn={addLinkToStackFn}
          />,
        );
        return true;
      }
      return addLinkToStackFn();
    },
    [items, isWarnOnAddingDuplicateLinks, addLinkToStack, showModal, hideModal],
  );
};
