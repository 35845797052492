import { dash_feed, users } from '@dropbox/api-v2-client';
import { Text } from '@dropbox/dig-components/typography';
import { transformDashResultToMirage } from '@mirage/service-dbx-api/service/utils';
import { DocumentActivityLabel } from '../document/DocumentActivityLabel';
import { StackActivityLabel } from '../stack/StackActivityLabel';

type ActivityLabelProps = {
  activity: dash_feed.FeedItem;
  currentAccount?: users.FullAccount;
};

export const ActivityLabel = ({
  activity,
  currentAccount,
}: ActivityLabelProps) => {
  const label = (
    <ActivityLabelDetails activity={activity} currentAccount={currentAccount} />
  );
  if (!label) {
    return null;
  }

  return (
    <Text variant="label" isBold color="subtle">
      {label}
    </Text>
  );
};

const ActivityLabelDetails = ({
  activity,
  currentAccount,
}: ActivityLabelProps) => {
  const objectDetails = activity.target?.details?.object_details;
  switch (objectDetails?.['.tag']) {
    case 'full_stack':
      return (
        <StackActivityLabel
          activity={activity}
          currentAccount={currentAccount}
          stack={objectDetails.stack_data!}
        />
      );
    case 'document':
      return (
        <DocumentActivityLabel
          activity={activity}
          currentAccount={currentAccount}
          document={transformDashResultToMirage(objectDetails)!}
        />
      );
    default:
      return null;
  }
};
