import type { SearchResult } from '@mirage/service-dbx-api/service/search';

/**
 * This file should be removed once the data model is updated to support structured data for the new fields.
 *
 * Slack stopgap for Q4 2024 connectors work. Currently the connectors team is just putting extra metadata into the
 * title content of a search result. This function will parse that data out and return it as a separate object.
 *
 * In Q1 2025 the data model will be updated to support structured data for the new fields, until then
 * we have to use this hack... :(
 */
export function formatSlackResult(searchResult: SearchResult): SearchResult {
  if (searchResult.connectorInfo?.connectorName !== 'slack') {
    return searchResult;
  }

  if (searchResult.recordType?.['.tag'] !== 'file')
    searchResult.title = `#${searchResult.title}`;

  return searchResult;
}
