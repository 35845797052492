import { CSSProperties } from 'react';

/**
 * When we want to render a tooltip within a clickable Card, we need it to sit
 * above the Card's Link. As a result, we need to explicitly set the trigger
 * element's z-index
 *
 * Sample usage:
 * ```tsx
 * const [isTruncated, setIsTruncated] = useState(false);
 * const tooltipTextProps = getTooltipInCardProps(isTruncated);
 *
 * return (
 *   <Card.Module onClick={onClick}>
 *    <Text {...tooltipTextProps}>
 *      <DashTruncateWithTooltip onBeforeTruncate={setIsTruncated}>
 *       {text}
 *      </DashTruncateWithTooltip>
 *     </Text>
 *   </Card.Module>
 * );
 * ```
 *
 * @param isTruncated State value passed by DashTruncateWithTooltip identifying
 *  whether the text is truncated
 * @param styles Optional styles to be applied to the text
 * @returns
 */
export const getTooltipInCardProps = (
  isTruncated: boolean,
  styles?: CSSProperties,
) => {
  if (isTruncated) {
    return {
      style: {
        ...styles,
        position: 'relative',
        zIndex: 2,
        cursor: 'pointer',
      } as CSSProperties,
    };
  }
  return {};
};
