import { getTitleOfUrl } from '@mirage/service-stacks/service/url-title';
import { DEFAULT_SECTION_ID } from '@mirage/service-stacks/service/utils';
import { usePasteAnywhere } from '@mirage/shared/hooks/usePasteAnywhere';
import { showSnackbar } from '@mirage/shared/snackbar';
import { isUrl } from '@mirage/shared/util/tiny-utils';
import { constructAbsoluteURL } from '@mirage/shared/util/urls';
import i18n from '@mirage/translations';
import debounce from 'lodash.debounce';
import { useAddLinkToStackWithConfirmation } from './useAddLinkToStackWithConfirmation';

const PASTE_DEBOUNCE_MS = 2000;

/**
 * Hook that allows pasting a link anywhere in the document and creating a stack item from it.
 */
export const usePasteStackItemAnywhere = () => {
  const addLinkToStack = useAddLinkToStackWithConfirmation();

  const handlePaste = (e: ClipboardEvent) => {
    const link = e.clipboardData?.getData('text') || '';
    const url = link.trim();
    if (!isUrl(url)) {
      return;
    }

    const item = {
      url: constructAbsoluteURL(url),
      title: getTitleOfUrl(url),
    };

    addLinkToStack(DEFAULT_SECTION_ID, item, undefined, undefined, 'pasted');
    showSnackbar({ title: i18n.t('new_stack_item_added') });
  };

  const onPaste = debounce(handlePaste, PASTE_DEBOUNCE_MS, { leading: true });
  usePasteAnywhere({ onPaste });
};
