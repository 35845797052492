import { Text, Title } from '@dropbox/dig-components/typography';
import { Link } from '@mirage/link/Link';
import { BetaBadge } from '@mirage/shared/badges/BetaBadge';
import { AI_LEARN_MORE_URL } from '@mirage/shared/urls';
import i18n from '@mirage/translations';
import styles from './FullPageAnswersEmptyState.module.css';

export const FullPageAnswersEmptyState = () => {
  return (
    <div className={styles.container}>
      <div className={styles.textContainer}>
        <div className={styles.titleContainer}>
          <Title className={styles.text}>{i18n.t('answers_empty_title')}</Title>
          <BetaBadge />
        </div>

        <Text className={styles.text} size="large">
          {i18n.t('answers_empty_subtitle')}
        </Text>

        <Text className={styles.text} color="faint">
          {i18n.t('answers_disclaimer_text')}
          &nbsp;
          <Link variant="monochromatic" href={AI_LEARN_MORE_URL}>
            {i18n.t('learn_more')}
          </Link>
        </Text>
      </div>
    </div>
  );
};
