import { Button } from '@dropbox/dig-components/buttons';
import { Split } from '@dropbox/dig-foundations';
import { useMirageAnalyticsContext } from '@mirage/analytics/AnalyticsProvider';
import { PAP_Collapse_DashCard } from '@mirage/analytics/events/types/collapse_dash_card';
import { PAP_Expand_DashCard } from '@mirage/analytics/events/types/expand_dash_card';
import { PAP_Open_DashLink } from '@mirage/analytics/events/types/open_dash_link';
import { RecentTileCard } from '@mirage/mosaics/StartPage/TabbedModule/TabbedPanels/RecentTileCard';
import { TileCardGrid } from '@mirage/mosaics/TileCard';
import useConnectors from '@mirage/service-connectors/useConnectors';
import { useIsActivityFeedEnabled } from '@mirage/service-feed/hooks/features';
import { openURL } from '@mirage/service-platform-actions';
import {
  filterRecentContents,
  useCombinedRecents,
} from '@mirage/service-recent-content/hooks';
import { RecentContent } from '@mirage/service-recent-content/types';
import { useMetadataForUrls } from '@mirage/service-url-metadata/hooks';
import { privacyModeEnabledAtom } from '@mirage/shared/atoms/privacyMode';
import { Blur } from '@mirage/shared/blur/Blur';
import { ModuleHeader } from '@mirage/shared/two-column-grid/ModuleHeader';
import { faviconSrcForSrcUrl } from '@mirage/shared/util/favicon';
import { useDynamicExpandableContent } from '@mirage/stacks/FullScreenStack/useDynamicExpandableContent';
import i18n from '@mirage/translations';
import { useAtomValue } from 'jotai';
import { forwardRef, useCallback, useMemo } from 'react';
import { RecentsFeed } from './RecentsFeed';
import styles from './RecentsModule.module.css';

const MAX_VISIBLE_COUNT = 6;
const EMPTY_ARRAY: RecentContent[] = [];

export const RecentsModule = () => {
  const { reportPapEvent } = useMirageAnalyticsContext();
  const { content, isLoading } = useCombinedRecents();
  const { connectors } = useConnectors();
  const { expanded, setExpanded, showMore, numVisible, wrapperRef } =
    useDynamicExpandableContent(`div.${styles.recentCard}`);
  const showRecentsFeed = !useIsActivityFeedEnabled();
  const privacyModeEnabled = useAtomValue(privacyModeEnabledAtom);

  const recentContent = useMemo(() => {
    return isLoading ? EMPTY_ARRAY : filterRecentContents(content, connectors);
  }, [connectors, content, isLoading]);

  const recentContentForDisplay = useMemo(() => {
    if (showRecentsFeed) {
      return recentContent;
    }

    return recentContent.slice(0, 2 * MAX_VISIBLE_COUNT);
  }, [recentContent, showRecentsFeed]);

  // If recents have loaded, but there are none, hide the recents module
  if (!isLoading && !showRecentsFeed && recentContent.length === 0) return null;

  const handleToggle = () => {
    if (expanded) {
      reportPapEvent(
        PAP_Collapse_DashCard({
          featureLine: 'recents',
          actionSurfaceComponent: 'recents',
          dashCardType: 'recents',
        }),
      );
    } else {
      reportPapEvent(
        PAP_Expand_DashCard({
          featureLine: 'recents',
          actionSurfaceComponent: 'recents',
          dashCardType: 'recents',
        }),
      );
    }
    setExpanded(!expanded);
  };

  return (
    <Split direction="vertical" gap="Micro Small">
      <Split.Item>
        <RecentsHeader
          showToggle={!showRecentsFeed && showMore}
          expanded={expanded}
          onToggle={handleToggle}
        />
      </Split.Item>
      <Split.Item>
        <Blur isBlurred={privacyModeEnabled} blurAmount="high">
          {showRecentsFeed ? (
            <RecentsFeed
              isLoading={isLoading}
              contentList={recentContentForDisplay}
            />
          ) : (
            <RecentsList
              isLoading={isLoading}
              expanded={expanded}
              contentList={recentContentForDisplay}
              visibleCount={numVisible}
              ref={wrapperRef}
            />
          )}
        </Blur>
      </Split.Item>
    </Split>
  );
};

const RecentsHeader = ({
  expanded,
  showToggle,
  onToggle,
}: {
  expanded: boolean;
  showToggle: boolean;
  onToggle: () => void;
}) => {
  return (
    <ModuleHeader
      title={i18n.t('recents')}
      actions={
        showToggle && (
          <Button variant="transparent" size="small" onClick={onToggle}>
            {i18n.t(expanded ? 'show_less_answer' : 'show_more_answer')}
          </Button>
        )
      }
    />
  );
};

const RecentsList = forwardRef<
  HTMLDivElement,
  {
    isLoading: boolean;
    expanded: boolean;
    contentList: RecentContent[];
    visibleCount: number;
  }
>(({ isLoading, expanded, contentList, visibleCount }, ref) => {
  const recentContentUrls = contentList.map((c) => c.url);
  const urlMetadata = useMetadataForUrls(recentContentUrls) || {};
  const { reportPapEvent } = useMirageAnalyticsContext();
  const handleOnClick = useCallback(
    (content: RecentContent) => {
      if (content.url) {
        openURL(content.url);
        reportPapEvent(
          PAP_Open_DashLink({
            featureLine: 'recents',
            actionSurfaceComponent: 'recents',
            dashConnectorId:
              'connectorName' in content.connectorInfo
                ? content.connectorInfo.connectorName
                : undefined,
          }),
        );
      }
    },
    [reportPapEvent],
  );

  return (
    <TileCardGrid
      expanded={expanded}
      className={styles.recentsWrapper}
      tileCardClassName={styles.recentCard}
      isLoading={isLoading}
      loadingTileCount={visibleCount || MAX_VISIBLE_COUNT}
      ref={ref}
    >
      {contentList.map((content, i) => (
        <RecentTileCard
          key={content.uuid}
          // Need to hide card so we aren't able to tab to cards hidden by overflow
          hidden={i >= visibleCount}
          content={content}
          iconSrc={
            urlMetadata[content.url]?.faviconUrl ||
            faviconSrcForSrcUrl(content.url)
          }
          handleOnClick={handleOnClick}
          selectorClassname={styles.recentCard}
        />
      ))}
    </TileCardGrid>
  );
});
RecentsList.displayName = 'RecentsList';
