import { PAP_Click_FormatButton } from '@mirage/analytics/events/types/click_format_button';
import { PAP_Click_VoiceDeleteButton } from '@mirage/analytics/events/types/click_voice_delete_button';
import { PAP_Click_VoiceMenuOption } from '@mirage/analytics/events/types/click_voice_menu_option';
import { PAP_Click_VoiceSaveButton } from '@mirage/analytics/events/types/click_voice_save_button';
import { ComposeEditor } from '@mirage/mosaics/ComposeAssistant/components/editor/ComposeEditor';
import { SelectionAction } from '@mirage/mosaics/ComposeAssistant/components/editor/SelectionToolbar';
import { VoiceSettingsModal } from '@mirage/mosaics/ComposeAssistant/components/settings/VoiceSettingsModal';
import { useComposeAnalyticsContext } from '@mirage/mosaics/ComposeAssistant/data/ComposeAnalyticsContext';
import { useComposeVoicesContext } from '@mirage/mosaics/ComposeAssistant/data/ComposeVoicesContext';
import { useComposeCurrentSessionContext } from '@mirage/mosaics/ComposeAssistant/data/current-session/ComposeCurrentSessionContext';
import {
  getSelectionActionPromptString,
  getSelectionPromptString,
} from '@mirage/mosaics/ComposeAssistant/data/llm/llm-prompts';
import {
  DEFAULT_PRECONFIGURED_VOICE_ID,
  getFirstMarkdownArtifact,
} from '@mirage/shared/compose/compose-session';
import { ComposeVoice } from '@mirage/shared/compose/compose-voice';
import i18n from '@mirage/translations';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';

interface VoiceSettingsModalConfig {
  type: 'open';
  voiceID?: string;
}

interface ComposeEditorPaneProps {
  additionalToolbarButtons?: React.ReactNode;
}
export const ComposeEditorPane = memo(
  ({ additionalToolbarButtons }: ComposeEditorPaneProps) => {
    const {
      isWaitingForResponse,
      artifacts,
      setMarkdownContent,
      setDraftConfig,
      postUpdateDraftWithVoice,
      messagesHistory,
      currentSessionID,
    } = useComposeCurrentSessionContext();
    const { logComposeEvent: logEditorPaneEvent } = useComposeAnalyticsContext({
      actionSurfaceComponent: 'compose_editor_pane',
      currentSessionID,
    });
    const { logComposeEvent: logVoicesPaneEvent } = useComposeAnalyticsContext({
      actionSurfaceComponent: 'compose_voices_pane',
      currentSessionID,
    });
    const { voices, saveVoice, deleteVoice } = useComposeVoicesContext();
    const [pendingVoiceID, setPendingVoiceID] = useState<string | undefined>();
    const [openVoiceSettingsConfig, setOpenVoiceSettingsConfig] = useState<
      VoiceSettingsModalConfig | undefined
    >();
    const markdownArtifact = useMemo(
      () => getFirstMarkdownArtifact(artifacts),
      [artifacts],
    );
    const handleTriggerSelectionAction = useHandleTriggerSelectionAction();
    const handleChangeVoiceID = useCallback(
      (voiceID: string) => {
        postUpdateDraftWithVoice(voiceID);
      },
      [postUpdateDraftWithVoice],
    );
    const handleDeleteVoice = useCallback(
      (voiceId: string) => {
        if (markdownArtifact?.draftConfig.voiceID === voiceId) {
          handleChangeVoiceID(DEFAULT_PRECONFIGURED_VOICE_ID);
        }
        deleteVoice(voiceId);
        logVoicesPaneEvent(PAP_Click_VoiceDeleteButton(), {
          actionSurfaceComponent: 'compose_voices_pane',
        });
        setOpenVoiceSettingsConfig(undefined);
      },
      [deleteVoice, logVoicesPaneEvent, handleChangeVoiceID, markdownArtifact],
    );
    const handleCreateNewVoice = useCallback(() => {
      setOpenVoiceSettingsConfig({ type: 'open', voiceID: undefined });
    }, []);
    const handleSaveVoice = useCallback(
      async (voice: ComposeVoice) => {
        await saveVoice(voice);
        setDraftConfig({
          ...markdownArtifact?.draftConfig,
          voiceID: voice.id,
        });
        setPendingVoiceID(voice.id);
        logVoicesPaneEvent(PAP_Click_VoiceSaveButton());
      },
      [
        saveVoice,
        logVoicesPaneEvent,
        markdownArtifact?.draftConfig,
        setDraftConfig,
      ],
    );
    useEffect(() => {
      // Additional state update prevents using stale voice cache to post draft with new voice
      if (pendingVoiceID && voices?.some((v) => v.id === pendingVoiceID)) {
        postUpdateDraftWithVoice(pendingVoiceID);
        setPendingVoiceID(undefined);
      }
    }, [pendingVoiceID, voices, postUpdateDraftWithVoice]);
    const handleOpenVoiceSettings = useCallback(
      (voiceID: string | undefined) => {
        logEditorPaneEvent(
          PAP_Click_VoiceMenuOption({
            actionType: 'edit',
          }),
        );
        setOpenVoiceSettingsConfig({ type: 'open', voiceID });
      },
      [logEditorPaneEvent],
    );
    const handleCloseVoicesSettings = useCallback(() => {
      setOpenVoiceSettingsConfig(undefined);
    }, []);
    return (
      <>
        <ComposeEditor
          isWaitingForResponse={isWaitingForResponse}
          markdownContent={markdownArtifact?.markdownContent || ''}
          onChangeContent={setMarkdownContent}
          onTriggerSelectionAction={handleTriggerSelectionAction}
          currentVoiceID={markdownArtifact?.draftConfig.voiceID}
          customVoices={voices}
          onChangeVoiceID={handleChangeVoiceID}
          onCreateNewVoice={handleCreateNewVoice}
          onOpenVoiceSettings={handleOpenVoiceSettings}
          logComposeEvent={logEditorPaneEvent}
          additionalToolbarButtons={additionalToolbarButtons}
          disabled={messagesHistory.length === 0}
        />
        {openVoiceSettingsConfig && (
          <VoiceSettingsModal
            voices={voices || []}
            saveVoice={handleSaveVoice}
            onDeleteVoice={handleDeleteVoice}
            initialVoiceID={openVoiceSettingsConfig.voiceID}
            onRequestClose={handleCloseVoicesSettings}
            logComposeEvent={logVoicesPaneEvent}
          />
        )}
      </>
    );
  },
);
ComposeEditorPane.displayName = 'ComposeEditorPane';

function useHandleTriggerSelectionAction() {
  const { setInputContext, postUserMessage, currentSessionID } =
    useComposeCurrentSessionContext();
  const { logComposeEvent } = useComposeAnalyticsContext({
    actionSurfaceComponent: 'compose_editor_pane',
    currentSessionID,
  });
  return useCallback(
    (action: SelectionAction) => {
      logComposeEvent(
        PAP_Click_FormatButton({
          actionType: action.type,
        }),
      );
      switch (action.type) {
        case 'make-longer':
        case 'make-shorter':
        case 'rewrite':
          {
            const actionUserText = getSelectionActionUserText(action.type);
            const actionPromptString = getSelectionActionPromptString(
              action.type,
            );
            postUserMessage({
              text: actionUserText,
              rawPromptText: getSelectionPromptString(
                actionPromptString,
                action.selectedText,
              ),
              mustGenerateDraft: false,
              mustIncludeSourceContents: false,
              isTextSelectionMenuAction: true,
              actionContext: {
                type: 'compose_selection_edit',
                selectedText: action.selectedText,
              },
            });
          }
          break;
        case 'prompt':
          setInputContext({
            type: 'selection',
            selectedText: action.selectedText,
          });
          break;
        default:
          action.type satisfies never;
          throw new Error(`Unknown action type: ${action}`);
      }
    },
    [postUserMessage, setInputContext, logComposeEvent],
  );
}

function getSelectionActionUserText(
  actionType: 'rewrite' | 'make-longer' | 'make-shorter',
) {
  switch (actionType) {
    case 'make-longer':
      return i18n.t('compose_selection_action_make_longer');
    case 'make-shorter':
      return i18n.t('compose_selection_action_make_shorter');
    case 'rewrite':
      return i18n.t('compose_selection_action_rewrite');
    default:
      actionType satisfies never;
      throw new Error(`Unknown selection action type: ${actionType}`);
  }
}
