/* eslint @typescript-eslint/no-explicit-any: 0 */

import { ServiceId } from '@mirage/discovery/id';
import * as services from '@mirage/discovery/services';
import WithDefaults from '@mirage/storage/with-defaults';
import WithOverrides from '@mirage/storage/with-overrides';
import * as rx from 'rxjs';
import * as op from 'rxjs/operators';
import { Theme } from '../theming/types';
import {
  defaultLocalFileSettings,
  defaultStackPageSortPreference,
  Settings,
  StackFilterOption,
} from './types';

import type { KVStorage } from '@mirage/storage';
import type { Observable } from 'rxjs';

export const defaultSettings: Settings = {
  // Avatar Settings
  enableDarkMode: Theme.Auto,
  locale: 'auto',
  hideAppOnStartup: 0,
  hideAppOnStartupBecauseJustUpdated: 0,
  appToNearestWindow: 0,
  showDashInDock: 1,
  openFilesInDesktopApps: 0,
  openFilesInDesktopAppsV2: 1,
  enableTips: 1,
  appShortcut: '',
  isDevTools: 0,
  appDebug: 0,
  annotationMode: 0,
  disableAutoUpdate: '',

  // UI Settings
  stackPageSortPreference: defaultStackPageSortPreference,
  stackPageFilterPreference: StackFilterOption.ALL,
  collapsedCards: {},
  stackByIdSortPreference: {},
  stackItemByIdCollapseDescription: {},
  stackItemByIdCollapseSummary: {},
  startPageSelectedTab: 'recents',

  // local files
  localFiles: defaultLocalFileSettings,

  // onboarding
  disableGlobalShortcut: false,
};

type SettingsConfiguration = {
  overrides: Partial<Settings>;
};

export type SettingsKeyValue<K extends keyof Settings> = [K, Settings[K]];

export type SettingsPartial<K extends keyof Settings> = {
  [P in K]: Settings[P];
};

export type Service = ReturnType<typeof settings>;

export default function settings(
  adapter: KVStorage<Settings>,
  config: SettingsConfiguration,
) {
  // TODO (Matt): apply default settings to storage during init and figure out
  // how we want to manage migrations
  // ... if we do migrations and all that jazz we'll have to manage a similar
  // setup to block until they're complete
  // ... probably also need to figure out a way to overwrite existing settings
  // in a type-friendly manner if they are removed/renamed
  const wrapped = new WithDefaults<Settings>(
    new WithOverrides(adapter, config.overrides),
    defaultSettings,
  );
  // TODO (Matt): fix this typing
  const emitter$ = new rx.Subject<[any, any]>();

  async function get<K extends keyof Settings>(key: K): Promise<Settings[K]> {
    return wrapped.get(key);
  }

  async function getAll(): Promise<Settings> {
    return wrapped.getAll();
  }

  async function set<K extends keyof Settings>(key: K, value: Settings[K]) {
    await adapter.set(key, value);
    emitter$.next([key, value]);
  }

  function listen<K extends keyof Settings>(
    keys: K[] | Readonly<K[]>,
  ): Observable<SettingsPartial<K>> {
    // capture current values
    const values$ = keys.map(
      (key): Observable<SettingsKeyValue<K>> =>
        rx.from(wrapped.get(key)).pipe(op.map((value) => [key, value])),
    );
    // coerce into a settings partial for specified keys
    const current$: Observable<SettingsPartial<K>> = rx.zip(
      ...values$,
      (...partials) => {
        return partials.reduce((partial, [key, value]) => {
          return (partial[key] = value), partial;
        }, {} as SettingsPartial<K>);
      },
    );

    return current$.pipe(
      op.mergeMap((seed) => {
        // listen to changing settings values for our list of keys
        const changes$ = rx.from(keys).pipe(
          op.mergeMap((key: K) => {
            // this is fucking obnoxious but typing the partial emissions is
            // more tricky than I had originally thought it would be
            // TODO (Matt): unfuck this duplicate subscription approach when
            // typing is sorted on the subject
            return emitter$
              .pipe(op.filter(([k]) => key === k))
              .pipe(
                op.map(
                  ([, value]): SettingsKeyValue<K> => [
                    key,
                    value as Settings[K],
                  ],
                ),
              );
          }),
        );

        // merge state changes into our seed state
        const state$ = changes$.pipe(
          op.scan((state, [key, value]) => {
            return (state[key] = value), state;
          }, seed),
        );

        return rx.merge(rx.of(seed), state$);
      }),
    );
  }

  async function clear() {
    await adapter.clear();
  }

  return services.provide(
    ServiceId.SETTINGS,
    {
      get,
      getAll,
      set,
      listen,
      clear,
    },
    [],
  );
}
