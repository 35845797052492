/*
 * Note: This is non-end-user facing code so no need for i18n strings.
 */

import { Button } from '@dropbox/dig-components/buttons';
import { Text } from '@dropbox/dig-components/typography';
import { Box } from '@dropbox/dig-foundations';
import { saveRelevanceSnapshot } from '@mirage/service-relevance-snapshot/index';
import { useState } from 'react';
import { v4 as uuid } from 'uuid';
import styles from './RelevanceTools.module.css';

export const RelevanceTools: React.FC = () => {
  const [traceId, setTraceId] = useState<string | undefined>(undefined);

  const snapshotSearchRequest = async () => {
    const traceId = uuid();
    saveRelevanceSnapshot(traceId);
    setTraceId(traceId);
  };

  return (
    <div>
      <Box display="block" paddingBottom="16">
        {/* Header */}
        <Text size="medium" isBold>
          Relevance Tools
        </Text>
        <hr className={styles.horizontalSeparator} />
      </Box>

      <div className={styles.grid}>
        <div>
          <Text isBold>Test</Text>
        </div>
        <div>
          <Text isBold>Description</Text>
        </div>
        {/* Relevance snapshot */}
        <Button variant="opacity" onClick={snapshotSearchRequest}>
          Snapshot last search request
        </Button>
        <div>
          {traceId ? (
            <div>
              <Text>Trace ID: {traceId}</Text>
              <Button
                variant="opacity"
                onClick={() => {
                  navigator.clipboard.writeText(traceId);
                }}
              >
                Copy
              </Button>
            </div>
          ) : (
            <div>
              <Text>
                Submit a relevance debug dump of your last search query for
                analysis
              </Text>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
