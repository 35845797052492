import { PersonObject } from '../search/search-filters';
import * as UserI18n from './user_i18n';

import type { users } from '@dropbox/api-v2-client';

export const enum IsDropboxerMode {
  WorkAccountOnly,
  WorkOrPersonalAccount,
}

export function getAbbreviatedFullName(displayName?: string) {
  return UserI18n.getShortName(displayName || '');
}

export function getInitials(displayName: string): string {
  return UserI18n.getInitials(displayName);
}

export function getInitialsFromPerson(person?: PersonObject): string {
  // If display name exists, grab initials from that
  // Otherwise, grab the first letter of the email
  return getInitials(person?.displayName || person?.email || '');
}

export function getPersonTitle(person?: PersonObject) {
  return person?.displayName || person?.email || '';
}

export function isDropboxerEmail(email: string) {
  return Boolean(email.endsWith('@dropbox.com'));
}

function isTestAccountEmail(email: string) {
  return Boolean(email.endsWith('@dbx51.com'));
}

export function isDropboxerAccount(
  account: users.FullAccount | undefined,
  mode = IsDropboxerMode.WorkOrPersonalAccount,
) {
  if (!account) {
    return undefined;
  }

  // Dropboxer's personal account is true when mode=WorkOrPersonalAccount.
  if (
    mode === IsDropboxerMode.WorkOrPersonalAccount &&
    account.team?.id === 'dbtid:AAClREzwomnhuCpPK6axEJMQInDiteg4OSg'
  ) {
    return true;
  }

  return isDropboxerEmail(account.email);
}

export function isTestAccount(account: users.FullAccount | undefined) {
  if (!account) {
    return undefined;
  }

  return isTestAccountEmail(account.email);
}
