// @generated - Do not edit: this file is autogenerated by:
//   //dropbox/pap/codegen
//
// Event definition: https://pap.pp.dropbox.com/#/events/1297
import type { PAPEventGeneric } from '../base/event';
import type { DashNewLinkType } from '../enums/dash_new_link_type';
import type { DashSurface } from '../enums/dash_surface';
import type { Environment } from '../enums/environment';
import type { FeatureLine } from '../enums/feature_line';
import type { OperatingSystem } from '../enums/operating_system';
import type { TabGroupType } from '../enums/tab_group_type';

// Event_name: 'add.dash_new_link'
// Description: Within a stack, the event fires when a link is confirmed to be added to a Dash stack after the user clicks the +Add button
// Owner: content-ingest
export type Add_DashNewLink = PAPEventGeneric<
  'stacks',
  'add',
  'dash_new_link',
  {
    // Which Dash surface (web, desktop, or browser extension) the event originated from.
    dashSurface?: DashSurface;
    // Whether the app dispatching the event is Prod, Test, or Dev (run locally by the developer)
    environment?: Environment;
    // The operating system platform the user is running on.
    operatingSystem?: OperatingSystem;
    // the start time of the event in ms
    startTimeMs?: number;
    // generic property representing build version of whatever is logging this event.
    buildVersion?: string;
    // stormcrow feature name
    feature?: string;
    // Identifier for item being suggested by Dash ML
    itemId?: string;
    // Identifier for Dash predictions
    predictionId?: string;
    // Rank of the individual suggestion within the group
    predictionPosition?: number;
    // The zero-index returned position of search result in a list of search results.
    resultPosition?: number;
    // Number of items in a Dash stack
    stackSize?: number;
    // The stack id that represents the stack
    stackId?: string;
    // Generated random request id for tracking suggestions in Dash
    dashRequestId?: string;
    // A hashed integer for unique predictions recommended in Dash
    predictionIdHash?: number;
    // A hashed integer for items/content recommended in Dash
    itemIdHash?: number;
    // User_id associated with the creator of the content being acted upon
    creatorId?: string;
    // Answers if the event actor on the stack is the same as the creator of the stack
    isOwner?: boolean;
    // marks if the content is shared content
    isShared?: boolean;
    // Meeting IDs that are uuidv4s as generated by neo4j. Used to tie together Dash meetings and related content.
    meetingId?: string;
    // zero-indexed position of a calendar item
    itemPosition?: number;
    // 0 indexed position of the calendar event/item
    calendarItemPosition?: number;
    // The number of links in the stack
    numberOfLinks?: number;
    // Type of Tab groups provided by dash
    tabGroupType?: TabGroupType;
    // An entry point string, used to describe how users are triggering the event as relevant (e.g. browse_file_row_context_menu)
    entryPoint?: string;
    // Hashed URLs accessed by Dash. These are pre-hashed.
    dashUrlHash?: string;
    // The stack id that represents the stack (without extra hashing on the client side)
    unhashedStackId?: string;
    // Whether the user is interacting with their welcome stack
    isWelcomeStack?: boolean;
    // unique session id for creating stacks on dash
    createStackSessionId?: string;
    // Feature Line for dash
    featureLine?: FeatureLine;
    // This is a session that persists when the Add to Stack button is currently being displayed and ends when a link has been added, or the process is cancelled by clicking away, or page is closed.
    dashNewLinkSessionId?: string;
    // Session id for starting a search for stack links to add
    dashLinkSearchSessionId?: string;
    // Type of the new link being created
    dashNewLinkType?: DashNewLinkType;
    // A stack section Id of a stack
    stackSectionId?: string;
  }
>;

export function PAP_Add_DashNewLink(
  properties?: Add_DashNewLink['properties'],
): Add_DashNewLink {
  return <Add_DashNewLink>{
    class: 'stacks',
    action: 'add',
    object: 'dash_new_link',
    properties,
  };
}
