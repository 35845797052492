import { combine } from '@atlaskit/pragmatic-drag-and-drop/combine';
import { dropTargetForElements } from '@atlaskit/pragmatic-drag-and-drop/element/adapter';
import cx from 'classnames';
import { useEffect, useRef, useState } from 'react';
import styles from './DragAndDrop.module.css';
import { useDnDContext } from './Provider';
import { isDraggableData } from './utils';

/**
 * SectionDropTargetWrapper is a wrapper component that provides a drop target
 * for sections to be used with pragmatic-drag-and-drop.
 *
 */
export const SectionDropTargetWrapper = ({
  children,
  sectionId,
}: {
  children: React.ReactNode;
  sectionId: string;
}) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const element = ref?.current;
  const [isHovering, setIsHovering] = useState(false);
  const { moveStackItemIntoSection, moveSuggestionIntoSection } =
    useDnDContext();

  useEffect(() => {
    if (!element) return;

    return combine(
      dropTargetForElements({
        element,
        onDragEnter: () => {
          setIsHovering(true);
        },
        onDragLeave: () => {
          setIsHovering(false);
        },
        onDrop: ({ source }) => {
          setIsHovering(false);
          if (!isDraggableData(source.data)) {
            return;
          }

          if (source.data.type === 'stackitem') {
            moveStackItemIntoSection(sectionId, source.data.id, 0);
          } else if (source.data.type === 'suggestion') {
            moveSuggestionIntoSection(sectionId, source.data.payload, 0);
          }
        },
      }),
    );
  }, [element, moveStackItemIntoSection, moveSuggestionIntoSection, sectionId]);

  return (
    <div
      ref={ref}
      className={cx({
        [styles.sectionDropTargetHovering]: isHovering,
      })}
    >
      {children}
    </div>
  );
};
