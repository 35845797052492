import { stacks } from '@dropbox/api-v2-client';
import { Card, Metadata } from '@dropbox/dash-component-library';
import { Button } from '@dropbox/dig-components/buttons';
import { List } from '@dropbox/dig-components/list';
import { Truncate } from '@dropbox/dig-components/truncate';
import { Text } from '@dropbox/dig-components/typography';
import { UIIcon } from '@dropbox/dig-icons';
import { AddCircleLine, AddLine } from '@dropbox/dig-icons/assets';
import { useMirageAnalyticsContext } from '@mirage/analytics/AnalyticsProvider';
import { PAP_Cancel_AddStackSection } from '@mirage/analytics/events/types/cancel_add_stack_section';
import { PAP_Delete_StackSection } from '@mirage/analytics/events/types/delete_stack_section';
import { PAP_Initiate_AddStackSection } from '@mirage/analytics/events/types/initiate_add_stack_section';
import { PAP_Rename_StackSection } from '@mirage/analytics/events/types/rename_stack_section';
import { PAP_Select_StackSection } from '@mirage/analytics/events/types/select_stack_section';
import { createUxaElementId } from '@mirage/analytics/uxa';
import { useStackPageAugustRevisionEnabled } from '@mirage/august-revision-hook';
import { DragDropList } from '@mirage/drag-and-drop/DragDropList';
import { upsertStack } from '@mirage/service-stacks';
import {
  DEFAULT_SECTION_ID,
  stackDerivePAPProps,
} from '@mirage/service-stacks/service/utils';
import { IconButtonWithTooltip } from '@mirage/shared/icons/IconButtonWithTooltip';
import {
  CardHeaderType,
  TwoColumnGridCard,
} from '@mirage/shared/two-column-grid/TwoColumnGridCard';
import { MAX_STACK_SECTION_COUNT } from '@mirage/shared/util/constants';
import { DigTooltip } from '@mirage/shared/util/DigTooltip';
import { onKeyDownCommitFn } from '@mirage/shared/util/on-key-down';
import {
  activeStackActionAtom,
  activeStackAtom,
  activeStackHasWritePermissionsAtom,
  activeStackLinkSectionsMapAtom,
  activeStackMutationRequestIdAtom,
  activeStackSectionIdAtom,
  activeStackSectionsAtom,
} from '@mirage/stacks/ActiveStack/atoms';
import { SectionDropTargetWrapper } from '@mirage/stacks/FullScreenStack/DragAndDrop/SectionDropTargetWrapper';
import { getTheme } from '@mirage/stacks/themes';
import i18n from '@mirage/translations';
import classNames from 'classnames';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import {
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useCreateStackSection } from '../hooks';
import { SectionsActionType } from '../types';
import { asStackUpsertId } from '../utils';
import { useSectionsDragDrop } from './DragDrop';
import { FancyInlineEdit } from './FancyInlineEdit';
import { HeaderMenu } from './Header';
import styles from './Navigation.module.css';

interface NavigationProps {
  scrollToSection: (sectionId: string) => void;
  publicPreview?: boolean;
  // this override flag is used to display the dfb august revision styles
  // when we want to render the new styles but can't feature gate users
  // ex. public view of the stacks page using (dfi styles, and we wanted to display dfb styles)
  alwaysUseAugustRevisionDfBStyles?: boolean;
}

export const Navigation: React.FC<NavigationProps> = ({
  scrollToSection,
  alwaysUseAugustRevisionDfBStyles,
}) => {
  const { reportPapEvent } = useMirageAnalyticsContext();
  const isAugustRevisionEnabled =
    useStackPageAugustRevisionEnabled() || alwaysUseAugustRevisionDfBStyles;

  const stack = useAtomValue(activeStackAtom);
  const [{ allSections: sections }, setSections] = useAtom(
    activeStackSectionsAtom,
  );
  const hasWritePermissions = useAtomValue(activeStackHasWritePermissionsAtom);
  const [currentAction, setCurrentAction] = useAtom(activeStackActionAtom);
  const currentSectionId = useAtomValue(activeStackSectionIdAtom);

  const onCreateSection = useCreateStackSection();
  const { otherSections, onDrop } = useSectionsDragDrop();
  const mutationId = useAtomValue(activeStackMutationRequestIdAtom);

  // There's a strange issue where the value of `currentSectionId` gets frozen
  // inside the drag-and-drop component. Therefore, use a ref to make sure the
  // value is always updated.
  const currentSectionIdRef = useRef<string | null>(null);
  const [, forceRefresh] = useState(false);

  useEffect(() => {
    currentSectionIdRef.current = currentSectionId;
    forceRefresh((v) => !v);
  }, [currentSectionId]);

  const [isEditing, setIsEditing] = useState(false);

  const handleCreateSection = useCallback(
    (title: string) => {
      setIsEditing(false);
      setCurrentAction(null);
      onCreateSection(title);
    },
    [onCreateSection, setCurrentAction],
  );

  const handleScrollToSection = useCallback(
    (sectionId: string) => {
      scrollToSection(sectionId);
      if (stack) {
        reportPapEvent(
          PAP_Select_StackSection({
            ...stackDerivePAPProps(stack),
            featureLine: 'stacks',
          }),
        );
      }
    },
    [reportPapEvent, scrollToSection, stack],
  );

  const upsertStackFn = useCallback(
    async (newSections: stacks.Section[]) => {
      const update: stacks.StackDataUpdate = {
        field: {
          '.tag': 'section_data_update',
          sections: newSections,
        },
      };
      await upsertStack(asStackUpsertId(stack?.namespace_id, mutationId), [
        update,
      ]);
      setSections(newSections);
    },
    [upsertStack, stack, mutationId, setSections],
  );

  const onRenameSection = useCallback(
    async (newName: string, sectionId: string) => {
      if (!sections) return;

      const newSections = sections.map((section) => {
        if (section.id === sectionId) {
          return {
            ...section,
            title: newName,
          };
        }
        return section;
      });
      if (stack && stack.namespace_id) {
        reportPapEvent(
          PAP_Rename_StackSection({
            ...stackDerivePAPProps(stack),
            featureLine: 'stacks',
          }),
        );
      }
      upsertStackFn(newSections);
    },
    [reportPapEvent, sections, stack, upsertStackFn],
  );

  const onDeleteSection = useCallback(
    async (sectionId: string) => {
      if (!sections) return;

      const newSections = sections.filter(
        (section) => section.id !== sectionId,
      );
      if (stack && stack.namespace_id) {
        reportPapEvent(
          PAP_Delete_StackSection({
            ...stackDerivePAPProps(stack),
            featureLine: 'stacks',
          }),
        );
      }
      upsertStackFn(newSections);
    },
    [reportPapEvent, sections, stack, upsertStackFn],
  );

  const listItems = useMemo(() => {
    if (!otherSections) {
      return [];
    }

    return otherSections.map((section) => {
      const sectionId = section.id ?? '';
      return {
        id: `${sectionId}-${section.title}-${hasWritePermissions}`,
        key: sectionId,
        render: () => (
          <SectionDropTargetWrapper sectionId={sectionId}>
            <NavigationItem
              key={section.id}
              section={section}
              scrollToSection={handleScrollToSection}
              hasWritePermissions={hasWritePermissions ?? false}
              onDeleteSection={onDeleteSection}
              onRenameSection={onRenameSection}
            />
          </SectionDropTargetWrapper>
        ),
      };
    });
  }, [handleScrollToSection, otherSections, hasWritePermissions]);

  useEffect(() => {
    if (currentAction !== SectionsActionType.CREATE_SECTION) {
      setIsEditing(false);
    }
  }, [currentAction]);

  const hasTooManySections = sections
    ? sections.length >= MAX_STACK_SECTION_COUNT
    : false;

  const createButtonDisabled = hasTooManySections || !hasWritePermissions;

  const createButton = (
    <>
      {!hasWritePermissions ? (
        <DigTooltip title={i18n.t('read_only_section_tooltip')}>
          <div style={{ maxWidth: '150px' }}>
            <CreateSectionButton
              disabled={createButtonDisabled}
              stack={stack}
              setIsEditing={setIsEditing}
              setCurrentAction={setCurrentAction}
              alwaysUseAugustRevisionDfBStyles={
                alwaysUseAugustRevisionDfBStyles
              }
            />
          </div>
        </DigTooltip>
      ) : (
        <CreateSectionButton
          disabled={createButtonDisabled}
          stack={stack}
          setIsEditing={setIsEditing}
          setCurrentAction={setCurrentAction}
          alwaysUseAugustRevisionDfBStyles={alwaysUseAugustRevisionDfBStyles}
        />
      )}
    </>
  );

  const inlineCreateSection = isEditing &&
    currentAction === SectionsActionType.CREATE_SECTION && (
      <div
        className={classNames(styles.fancyEditInput, {
          [styles.augustRevision]: isAugustRevisionEnabled,
        })}
      >
        <FancyInlineEdit
          onSubmit={handleCreateSection}
          title=""
          onCancel={() => {
            setIsEditing(false);
            setCurrentAction(null);
            if (stack) {
              reportPapEvent(
                PAP_Cancel_AddStackSection({
                  ...stackDerivePAPProps(stack),
                  featureLine: 'stacks',
                }),
              );
            }
          }}
          data-testid="Stacks-createSectionInput"
        />
      </div>
    );

  if (isAugustRevisionEnabled) {
    return (
      <Card.Module
        breakout="standard"
        withHeader={
          <Card.Header
            title={
              <Text className={styles.sectionsTitle} variant="label" isBold>
                {i18n.t('sections')}
              </Text>
            }
            actions={createButton}
            withMargin={false}
          />
        }
      >
        {listItems.length === 0 && !inlineCreateSection && (
          <Text className={styles.emptySectionsStateText} variant="paragraph">
            {i18n.t('sections_empty_cta')}
          </Text>
        )}
        <DragDropList
          droppableId="sectionsNav"
          items={listItems}
          dragDisabled={!hasWritePermissions}
          getListContainerProps={() => ({
            className: styles.sectionsNavDragDrop,
          })}
          getItemContainerProps={(isDragging) =>
            isDragging
              ? { className: styles.sectionsNavItemIsDragging }
              : undefined
          }
          onDrop={onDrop}
        />

        {inlineCreateSection}
      </Card.Module>
    );
  } else {
    return (
      <TwoColumnGridCard
        settingId={`stack_sections:${stack?.namespace_id}`}
        showDividerLine={false}
        cardTypeProps={{
          cardType: CardHeaderType.STACKED_SUBTITLE,
          title: i18n.t('sections'),
          featureLine: 'stacks',
          dashCardType: 'section_navigator',
        }}
        subtitle={hasWritePermissions ? i18n.t('sections_subtitle') : undefined}
        theme={getTheme(stack?.stack_data?.color_index).subtle}
        isAlwaysCollapsed={listItems.length > 0}
        collapseBtnSize="large"
      >
        <DragDropList
          droppableId="sectionsNav"
          items={listItems}
          dragDisabled={!hasWritePermissions}
          getListContainerProps={() => ({
            className: styles.sectionsNavDragDrop,
          })}
          getItemContainerProps={(isDragging) =>
            isDragging
              ? { className: styles.sectionsNavItemIsDragging }
              : undefined
          }
          onDrop={onDrop}
        />
        {inlineCreateSection}
        {!isEditing && createButton}
      </TwoColumnGridCard>
    );
  }
};

const CreateSectionButton = ({
  disabled,
  stack,
  setIsEditing,
  setCurrentAction,
  alwaysUseAugustRevisionDfBStyles,
}: {
  disabled: boolean;
  stack: stacks.Stack | null;
  setIsEditing: (isEditing: SetStateAction<boolean>) => void;
  setCurrentAction: (action: SectionsActionType | null) => void;
  // this override flag is used to display the dfb august revision styles
  // when we want to render the new styles but can't feature gate users
  // ex. public view of the stacks page using (dfi styles, and we wanted to display dfb styles)
  alwaysUseAugustRevisionDfBStyles?: boolean;
}) => {
  const { reportPapEvent } = useMirageAnalyticsContext();
  const isAugustRevisionEnabled = Boolean(
    useStackPageAugustRevisionEnabled() || alwaysUseAugustRevisionDfBStyles,
  );

  const onClick = useCallback(() => {
    setCurrentAction(SectionsActionType.CREATE_SECTION);
    setIsEditing(true);
    if (stack) {
      reportPapEvent(
        PAP_Initiate_AddStackSection({
          ...stackDerivePAPProps(stack),
          featureLine: 'stacks',
        }),
      );
    }
  }, [stack, setIsEditing, setCurrentAction, reportPapEvent]);

  if (isAugustRevisionEnabled) {
    return (
      <IconButtonWithTooltip
        variant="transparent"
        tooltipProps={{ title: i18n.t('create_section') }}
        aria-label={i18n.t('create_section')}
        onClick={onClick}
        data-uxa-log={createUxaElementId('add_section_button', {
          featureLine: 'stacks',
        })}
        data-testid="Stacks-createSectionButton"
        disabled={disabled}
      >
        <UIIcon src={AddCircleLine} />
      </IconButtonWithTooltip>
    );
  } else {
    return (
      <Button
        variant="borderless"
        aria-label={i18n.t('create_section')}
        withIconStart={<UIIcon src={AddLine} />}
        onClick={onClick}
        data-uxa-log={createUxaElementId('add_section_button', {
          featureLine: 'stacks',
        })}
        data-testid="Stacks-createSectionButton"
        disabled={disabled}
        className={styles.addSectionButtonV2}
        size="large"
      >
        <Text color={disabled ? 'faint' : 'standard'}>
          {i18n.t('create_section')}
        </Text>
      </Button>
    );
  }
};

const NavigationItem = ({
  section,
  scrollToSection,
  hasWritePermissions,
  onDeleteSection,
  onRenameSection,
}: {
  section: stacks.Section;
  scrollToSection: (sectionId: string) => void;
  hasWritePermissions: boolean;
  onDeleteSection: (sectionId: string) => void;
  onRenameSection: (newName: string, sectionId: string) => void;
}) => {
  const [isInteracting, setIsInteracting] = useState(false);
  const [hasDragged, setHasDragged] = useState(false);
  const linkSectionMap = useAtomValue(activeStackLinkSectionsMapAtom);
  const sectionId = section.id ?? DEFAULT_SECTION_ID;
  const items = linkSectionMap ? (linkSectionMap.get(sectionId) ?? []) : [];
  const isAugustRevision = useStackPageAugustRevisionEnabled();
  const [isEditing, setIsEditing] = useState(false);
  const stack = useAtomValue(activeStackAtom);
  const [isHovering, setIsHovering] = useState(false);
  const setCurrentAction = useSetAtom(activeStackActionAtom);
  const [currentSectionId, setCurrentSectionId] = useAtom(
    activeStackSectionIdAtom,
  );

  const [isHoveringOnMenu, setIsHoveringOnMenu] = useState(false);

  const handleInteractionStart = () => {
    setIsInteracting(true);
    setHasDragged(false);
  };

  const handleInteractionMove = () => {
    if (isInteracting) {
      setHasDragged(true);
    }
  };

  /**
   * Only scroll to the section if the user has not dragged the item.
   */
  const handleInteractionEnd = () => {
    if (!hasDragged && !isHoveringOnMenu) {
      scrollToSection(section.id ?? DEFAULT_SECTION_ID);
    }
    setIsInteracting(false);
    setHasDragged(false);
  };

  const handleSubmit = useCallback(
    (title: string) => {
      setIsEditing(false);
      setCurrentAction(null);
      onRenameSection(title, sectionId);
    },
    [onRenameSection, sectionId, setCurrentAction],
  );

  if (section.id === DEFAULT_SECTION_ID) {
    return null;
  }

  const headerMenu = (
    <HeaderMenu
      stack={stack}
      onDeleteSection={() => onDeleteSection(sectionId)}
      onEditSection={() => {
        setIsEditing(true);
        setCurrentAction(SectionsActionType.EDIT_SECTION);
        setCurrentSectionId(sectionId);
      }}
      isHoveringSection={isHovering}
      setIsHoveringOnMenu={setIsHoveringOnMenu}
    />
  );

  const fancyLineEdit = (
    <FancyInlineEdit
      title={section.title ?? ''}
      onSubmit={handleSubmit}
      onCancel={() => {
        setIsEditing(false);
        setCurrentAction(null);
        setCurrentSectionId(null);
      }}
    />
  );

  if (isAugustRevision) {
    return (
      <List.Item
        tabIndex={0}
        className={styles.navigationItemAugust}
        // Don't use onClick as this will always fire even after drag
        onMouseDown={handleInteractionStart}
        onMouseMove={handleInteractionMove}
        onMouseUp={handleInteractionEnd}
        onTouchStart={handleInteractionStart}
        onTouchMove={handleInteractionMove}
        onTouchEnd={handleInteractionEnd}
        onKeyDown={onKeyDownCommitFn(() => {
          scrollToSection(section.id ?? DEFAULT_SECTION_ID);
        })}
        data-uxa-log={createUxaElementId('section_navigation_item', {
          featureLine: 'stacks',
        })}
        onMouseEnter={() => setIsHovering(true)}
        onMouseLeave={() => setIsHovering(false)}
        spacing="small"
        onBlur={() => setIsHovering(false)}
      >
        <List.Content>
          {isEditing && section.id === currentSectionId ? (
            fancyLineEdit
          ) : (
            <Metadata size="medium" withDividers="bullet">
              <Metadata.Item>
                <Metadata.Label>
                  <Text variant="label">{section.title}</Text>
                </Metadata.Label>
              </Metadata.Item>
              <Metadata.Item>
                <Metadata.Label>
                  {i18n.t('num_items', {
                    count: items.length,
                  })}
                </Metadata.Label>
              </Metadata.Item>
            </Metadata>
          )}
        </List.Content>
        {!isEditing && hasWritePermissions && headerMenu}
      </List.Item>
    );
  } else {
    // Cannot use a <button> tag because that is not draggable. We might be
    // able to make it work but safer to just use a <span> here.
    return (
      <span
        role="button"
        tabIndex={0}
        className={classNames(
          styles.navigationItem,
          styles.marchNavigationItem,
        )}
        // Don't use onClick as this will always fire even after drag
        onMouseDown={handleInteractionStart}
        onMouseMove={handleInteractionMove}
        onMouseUp={handleInteractionEnd}
        onTouchStart={handleInteractionStart}
        onTouchMove={handleInteractionMove}
        onTouchEnd={handleInteractionEnd}
        onKeyDown={onKeyDownCommitFn(() => {
          scrollToSection(section.id ?? DEFAULT_SECTION_ID);
        })}
        onMouseEnter={() => setIsHovering(true)}
        onMouseLeave={() => setIsHovering(false)}
        data-uxa-log={createUxaElementId('section_navigation_item', {
          featureLine: 'stacks',
        })}
        onBlur={() => setIsHovering(false)}
      >
        <div className={styles.draggableSectionRow}>
          {isEditing && section.id === currentSectionId ? (
            fancyLineEdit
          ) : (
            <Text>
              <Truncate maxWidth={250}>{section.title}</Truncate>
            </Text>
          )}
          {!isEditing && hasWritePermissions && headerMenu}
        </div>
      </span>
    );
  }
};
