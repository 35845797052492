import {
  $convertFromMarkdownString,
  $convertToMarkdownString,
  ElementTransformer,
  Transformer,
  TRANSFORMERS,
} from '@lexical/markdown';
import { createTableTransformer } from '@mirage/mosaics/ComposeAssistant/components/editor/table/TableTransformers';

export const TABLE: ElementTransformer = createTableTransformer(
  true,
  TRANSFORMERS,
);
export const TABLE_NO_SPECIAL_ATTRIBUTES: ElementTransformer =
  createTableTransformer(false, TRANSFORMERS);

export const COMPOSE_TRANSFORMERS: Array<Transformer> = [
  ...TRANSFORMERS,
  TABLE,
];
export const COMPOSE_TRANSFORMERS_NO_SPECIAL_ATTRIBUTES: Array<Transformer> = [
  ...TRANSFORMERS,
  TABLE_NO_SPECIAL_ATTRIBUTES,
];

export function $composeConvertFromMarkdownString(
  markdownString: string,
  supportsTables: boolean,
) {
  $convertFromMarkdownString(
    markdownString,
    supportsTables ? COMPOSE_TRANSFORMERS : TRANSFORMERS,
    undefined,
    true /* shouldPreserveNewLines */,
  );
}

export function $composeConvertToMarkdownString({
  includeSpecialAttributes,
}: {
  includeSpecialAttributes: boolean;
}) {
  return $convertToMarkdownString(
    includeSpecialAttributes
      ? COMPOSE_TRANSFORMERS
      : COMPOSE_TRANSFORMERS_NO_SPECIAL_ATTRIBUTES,
    undefined /* node */,
    true /* shouldPreserveNewLines */,
  );
}
