import { useEffect } from "react";

import { useIsDropboxer } from "@mirage/service-auth/useDropboxAccount";
import {
  WEB_HOST_STAGING_AMPLIFY,
  WEB_HOST_PROD,
} from "@mirage/shared/util/build-vars";
/**
 * To avoid exposure of the web-master environment to non-Dropboxers, redirect them to the production environment.
 */
export function useRedirectFromAmplifyIfNeeded() {
  const isDropboxer = useIsDropboxer();
  const location = window.location.origin;
  useEffect(() => {
    if (location === WEB_HOST_STAGING_AMPLIFY) {
      if (isDropboxer || isDropboxer === undefined) {
        return;
      }
      window.location.href = WEB_HOST_PROD;
    }
  }, [isDropboxer, location]);
}
