import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin';
import {
  $composeConvertFromMarkdownString,
  $composeConvertToMarkdownString,
} from '@mirage/mosaics/ComposeAssistant/components/editor/ComposeMarkdownTransformers';
import { tagged } from '@mirage/service-logging';
import { EditorState } from 'lexical';
import { memo, useCallback, useEffect, useRef } from 'react';

const logger = tagged('ComposeContentUpdaterPlugin');

/**
 * This plugin is responsible for propagating changes down to the editor when markdownContent changes,
 * as well as to manage changes from the editor (i.e. user edits).
 */
interface ComposeContentUpdaterPluginProps {
  supportsTables: boolean;
  markdownContent: string;
  onChangeContent: (markdownContent: string) => void;
}
export const ComposeContentUpdaterPlugin = memo(
  ({
    supportsTables,
    markdownContent,
    onChangeContent,
  }: ComposeContentUpdaterPluginProps) => {
    const [editor] = useLexicalComposerContext();
    const markdownContentRef = useRef('');
    const renderedMarkdownContentRef = useRef('');
    const isPropagatingUpdateRef = useRef(false);

    useEffect(() => {
      if (markdownContentRef.current === markdownContent) {
        return; // only update if markdownContent prop has changed
      }
      if (renderedMarkdownContentRef.current === markdownContent) {
        return; // ignore updates that are already reflected in the editor
      }
      markdownContentRef.current = markdownContent;
      logger.log('updating editor content', markdownContent);

      isPropagatingUpdateRef.current = true;
      editor.update(
        () => {
          $composeConvertFromMarkdownString(markdownContent, supportsTables);
        },
        {
          onUpdate: () => {
            editor.getEditorState().read(() => {
              isPropagatingUpdateRef.current = false;
              renderedMarkdownContentRef.current =
                $composeConvertToMarkdownString({
                  includeSpecialAttributes: true,
                });
            });
          },
        },
      );
    }, [editor, markdownContent, supportsTables]);

    const handleChange = useCallback(
      (editorState: EditorState) => {
        editorState.read(() => {
          if (isPropagatingUpdateRef.current) {
            return; // ignore changes from the editor while we're propagating updates down
          }
          const mdText = $composeConvertToMarkdownString({
            includeSpecialAttributes: true,
          });
          if (mdText === renderedMarkdownContentRef.current) {
            return; // ignore changes that don't actually change the content
          }
          renderedMarkdownContentRef.current = mdText;
          onChangeContent(mdText);
        });
      },
      [onChangeContent],
    );
    return <OnChangePlugin onChange={handleChange} />;
  },
);
ComposeContentUpdaterPlugin.displayName = 'ComposeContentUpdaterPlugin';
